import { FC } from 'react';
import * as S from './style';

interface ModelProps {
  children: any;
  footer?: any;
  visible?: any;
  onCancel?: any;
  cancelText?: string;
  width?: number;
}

const Modal: FC<ModelProps> = ({
  width = 1000,
  children = null,
  ...others
}) => (
  <S.Modal width={width} {...others}>
    {children}
  </S.Modal>
);

export default Modal;
