const Dm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#006B3F"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M36 15H21V5h-6v10H0v6h15v10h6V21h15z" />
    <path fill="#FCD116" d="M15 5h2v26h-2z" />
    <path fill="#FCD116" d="M0 15h36v2H0z" />
    <path fill="#141414" d="M17 5h2v26h-2z" />
    <path fill="#141414" d="M0 17h36v2H0z" />
    <circle fill="#DD2E44" cx="18" cy="18" r="6" />
    <path
      d="M18 12.64l-.169.524h-.555l.451.325-.173.524.446-.325.445.325-.171-.528.449-.32h-.552zm-3.08 1l.171.523-.448.326.555-.001.168.525.169-.525.552.001-.448-.326.175-.522-.447.324zm-1.904 2.621l.446.322-.171.527.449-.327.445.326-.172-.525.447-.323-.555-.001-.166-.525-.17.526zm0 3.239l.55-.002.171.527.171-.529.552.002-.447-.322.172-.525-.45.325-.442-.327.171.525zm1.904 2.619l.444-.324.448.326-.172-.529.448-.322-.552.001-.17-.525-.172.527-.55-.003.447.324zm3.08 1l.169-.523h.554l-.451-.326.173-.524-.445.326-.445-.326.17.527-.448.321h.552zm3.08-1l-.172-.523.448-.326-.555.001-.168-.525-.169.525-.552-.001.448.326-.175.523.448-.326zm1.903-2.621l-.446-.322.171-.527-.449.327-.444-.326.171.524-.446.324h.554l.166.525.171-.525zm-.001-3.238l-.549.001-.171-.527-.171.529-.552-.003.447.323-.171.525.449-.325.442.327-.171-.526zm-1.903-2.62l-.444.324-.448-.325.171.528-.447.322.552-.001.17.525.172-.528.549.005-.446-.324z"
      fill="#006000"
    />
    <path
      fill="#00693F"
      d="M19.594 20.818c-.018-.137-.061-.652-.069-1.062-.005-.276-.506-.646-.837-.643-.226-.462-.887-1.02-1.331-.576-.043.309.034.91.18 1.14l.678.154c.068-.043.145-.144.171-.23.019-.006.031-.024.049-.032.126.487.377 1.247.448 1.429.077.197.189.6.438.67.111.059.266.016.197-.498.076-.018.092-.215.076-.352z"
    />
    <path
      fill="#7D76B6"
      d="M18.86 15.76c.013.102.077.206.154.27-.051.038-.077.09-.09.128-.283.244-.939 1.941-.951 2.314-.045.199-.136.515-.213.65-.437-.392-.887-1.125-.812-1.455-.026-.004-.064-.008-.107-.021.009-.03.025-.18.03-.27-.026 0-.073 0-.112-.013.009-.056.026-.219.026-.292-.043-.013-.095-.056-.133-.099.029-.077.107-.227.146-.317-.064-.008-.111-.073-.133-.111.056-.034.129-.133.158-.193-.047-.039-.102-.094-.111-.12.06-.056.236-.248.313-.355-.048 0-.064-.004-.104-.013.064-.025.138-.12.224-.201.147.128.428.105.563.028.244.141.508.161.849.051.066.019.239.045.303.019z"
    />
    <path
      fill="#00693F"
      d="M19.838 20.428c.051-1.813-.258-3.472-.733-4.076-.476-.605-1.349-.47-1.594.051-.104.219-.118.741-.014.965.181.386.875 1.607 1.351 2.07.475.462.809.771.99.99z"
    />
    <path
      fill="#FFD420"
      d="M19.122 19.996c-.137.016-.3-.018-.429-.078-.129-.061-.24-.119-.377-.119-.138 0-.883-.053-1.003-.096-.12-.043-.455-.102-.592-.094-.137.008-.352.104-.438.104-.086 0-.128.068-.128.137 0 .068.017.197.119.266.181-.008.438-.025.472-.06.249.068.583.139.789.146.206.008.574.025.712.018.137-.01.162-.053.343.008.18.06.42.189.531.223.111.035.18-.076.205-.197.028-.121-.058-.268-.204-.258z"
    />
    <path
      fill="#FFF"
      d="M17.279 15.002c-.005 0-.011.006-.016.006-.039-.173-.219-.295-.32-.29-.314.083-.296.663-.013.862-.008-.104.028-.188.078-.261.023.07.077.136.218.153.149-.053.098-.381.053-.47z"
    />
    <path
      fill="#7D76B6"
      d="M17.146 15.662c.147.128.428.105.563.028.244.141.508.161.849.051.064.02.237.045.302.02.039-.039.077-.064.116-.09-.077-.064-.154-.18-.193-.295 0 0 .065-.078.09-.116-.063-.064-.192-.167-.257-.206.039-.038.077-.154.077-.154-.129-.038-.257-.128-.309-.18l.013-.077c-.21-.013-.201-.27-.655-.347-.309-.052-.772.077-.798.424.172.167.434.39.283.754-.039.052-.111.12-.154.142.026.028.043.032.073.046z"
    />
    <path
      fill="#FFF"
      d="M18.342 20.219c-.086-.01-.171-.104-.171-.18 0-.078.008-.233.154-.25-.048-.082-.116-.104-.182-.094-.062-.075-.161-.091-.256-.07.003-.004.005-.013.009-.016-.08-.065-.172-.051-.252-.011-.148-.087-.373.022-.408.294-.034.266.103.455.3.445v-.003c.089.044.216.004.249-.048l-.001-.019c.082.054.164.055.237.003.026.024.051.05.09.059.112.027.197-.067.231-.11z"
    />
    <path
      fill="#FFD420"
      d="M17.562 14.59c-.231 0-.225.347 0 .347s.231-.347 0-.347z"
    />
    <path d="M17.562 14.72c-.058 0-.056.086 0 .086.056.001.058-.086 0-.086z" />
  </svg>
);

export default Dm;
