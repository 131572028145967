import { FC } from 'react';
import icon from './antd';

interface IconsProps {
  className?: string;
  iconName: string;
  rotate?: number;
  spin?: boolean;
  style?: any;
  twoToneColor?: string;
}

const Icons: FC<IconsProps> = ({ ...others }) => {
  const { className, iconName, rotate, spin, style, twoToneColor } = others;
  const IconContent = icon.find(item => item.name === iconName).icon;
  return IconContent ? (
    <IconContent
      className={className}
      rotate={rotate}
      spin={spin}
      style={style}
      twoToneColor={twoToneColor}
    />
  ) : (
    <span>{iconName}</span>
  );
};
export default Icons;
