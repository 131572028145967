const Sb = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#0051BA"
      d="M33.552 5.312C33.075 5.111 32.55 5 32 5H4C1.791 5 0 6.791 0 9v18c0 .806.239 1.556.649 2.184L33.552 5.312z"
    />
    <path
      fill="#215B33"
      d="M2.448 30.687l.006.003c.476.2.998.31 1.546.31h28c2.209 0 4-1.791 4-4V9c0-.806-.238-1.556-.649-2.184L2.448 30.687z"
    />
    <path
      fill="#FCD116"
      d="M.651 29.189c.434.662 1.059 1.188 1.797 1.499L35.351 6.816l-.001-.002c-.434-.663-1.059-1.19-1.798-1.501L.649 29.184l.002.005z"
    />
    <g fill="#FFF">
      <path d="M7.069 12.021l.01.007-.484 1.49 1.292-.939 1.293.939-.484-1.49.009-.007-.009-.029 1.258-.915H8.387l-.5-1.537-.499 1.537H5.82l1.259.915z" />
      <path d="M8.705 12.021l1.299-.944h-.05l-1.258.915zm-1.636 0l.01-.029-1.259-.915h-.049zm-.474 1.497l-.016.048 1.308-.951 1.308.951-.015-.048-1.293-.939zM2.676 8.255l.01.007-.485 1.491 1.293-.939 1.293.939-.485-1.491.01-.007-.01-.029 1.259-.914H3.994l-.5-1.538-.5 1.538H1.427l1.259.914z" />
      <path d="M2.201 9.753l-.015.047 1.308-.95 1.308.95-.015-.047-1.293-.939zm.475-1.498l.01-.029-1.259-.914h-.049zm1.636 0l1.299-.943h-.05l-1.259.914zm7.151 7.531l.009.007-.484 1.491 1.293-.94 1.292.94-.484-1.491.01-.007-.01-.029 1.258-.914H12.78l-.499-1.538-.5 1.538h-1.567l1.258.914z" />
      <path d="M13.099 15.786l1.298-.943h-.05l-1.258.914zm-1.636 0l.009-.029-1.258-.914h-.05zm-.475 1.498l-.015.047 1.308-.95 1.308.95-.016-.047-1.292-.94zm-8.312-1.498l.01.007-.485 1.491 1.293-.94 1.293.94-.485-1.491.01-.007-.01-.029 1.259-.914H3.994l-.5-1.538-.5 1.538H1.427l1.259.914z" />
      <path d="M4.312 15.786l1.299-.943h-.05l-1.259.914zm-2.111 1.498l-.015.047 1.308-.95 1.308.95-.015-.047-1.293-.94zm.475-1.498l.01-.029-1.259-.914h-.049zm8.787-7.531l.009.007-.484 1.491 1.293-.939 1.292.939-.484-1.491.01-.007-.01-.029 1.258-.914H12.78l-.499-1.538-.5 1.538h-1.567l1.258.914z" />
      <path d="M11.463 8.255l.009-.029-1.258-.914h-.05zm1.636 0l1.298-.943h-.05l-1.258.914zm-2.111 1.498l-.015.047 1.308-.95 1.308.95-.016-.047-1.292-.939z" />
    </g>
  </svg>
);

export default Sb;
