import { FC } from 'react';
import { Icon } from '@/core';
import * as S from './styles';

const Table: FC<any> = ({ ...others }) => {
  const { loading } = others;
  return (
    <S.Table
      {...others}
      loading={{
        indicator: <Icon iconName="LoadingOutlined" />,
        spinning: loading,
      }}
    />
  );
};

export default Table;
