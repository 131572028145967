const Ni = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#265FB5"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#A9BF4C"
      d="M22.557 18c0 2.517-2.04 4.557-4.557 4.557s-4.557-2.04-4.557-4.557 2.041-4.557 4.557-4.557 4.557 2.04 4.557 4.557z"
    />
    <circle fill="#EEE" cx="18" cy="18" r="4" />
    <path fill="#265FB5" d="M14.758 20.155l3.228-5.591 3.228 5.591z" />
    <path fill="#55ACEE" d="M15.116 19.938l2.87-4.969 2.869 4.969z" />
    <path fill="#BBDDF5" d="M15.116 19.938l.693-1.199 4.322-.054.724 1.253z" />
    <path
      fill="#5C913B"
      d="M15.672 19.156l1.078-.875.469.531.406-.438.344.344.328-.359.391.359.359-.375.328.375.312-.344.516.641.094.188s-.547.109-1.297.047-1.188.109-1.781.188c-.594.078-1.656-.078-1.656-.078l.109-.204z"
    />
    <path
      fill="#E2F09F"
      d="M16.734 18.469l.719.781-.359.031-.406-.593zm.86.062l.578.516-.266.047-.281-.391zm.734-.031l.5.469-.125.078-.406-.438zm.656 0l-.203.188.094.109zm.625.016l-.234.296.078.141.172-.328z"
    />
    <path
      fill="#BBDDF5"
      d="M18.031 16.43l.067.929.531-.763-.399.83.83-.399-.763.532.93.066-.93.066.763.532-.83-.399.399.83-.531-.763-.067.929-.066-.929-.531.763.398-.83-.83.399.764-.532-.93-.066.93-.066-.764-.532.83.399-.398-.83.531.763z"
    />
    <path
      fill="#DD2E44"
      d="M18 17.203l-.188.188s.047.281.031.328c-.015.047.157.281.157.281l.234-.281-.062-.375-.172-.141z"
    />
    <path
      fill="#269"
      d="M18.094 16.875c.766 0 1.203.375 1.203.375l-.281-.5s-.453-.344-.984-.344c-.531 0-1.125.422-1.125.422l-.266.531s.687-.484 1.453-.484z"
    />
    <path
      fill="#FFCC4D"
      d="M18.094 16.875c.766 0 1.203.375 1.203.375l-.203-.359s-.547-.328-1.078-.328-1.188.422-1.188.422l-.188.375c.001-.001.688-.485 1.454-.485z"
    />
    <path
      fill="#DD2E44"
      d="M18.094 16.875c.766 0 1.203.375 1.203.375l-.141-.234s-.422-.297-1.141-.266c-.531 0-1.266.391-1.266.391l-.109.219c.001-.001.688-.485 1.454-.485z"
    />
    <path
      fill="#EEE"
      d="M17.587 21.747h.996v.955h-.996zm-.159-7.405h-.716l-.358-.875.716-.08zm1.632.398l-.477-.159.239-1.155.915.08zm-4.299 4.897l-1.393.318-.04-.955 1.393-.199zm.359.995l-1.155.478-.279-.756 1.075-.359zm7.683-.875l-1.274-.359v-.676l1.433-.2zm-.637 1.313l-1.194-.557.397-.717 1.315.398z"
    />
  </svg>
);

export default Ni;
