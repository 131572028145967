const Ve = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CF142B"
      d="M0 27c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-4H0v4z"
    />
    <path
      fill="#00247D"
      d="M0 23h36V13H0v10zm25.316-3.55l.269.74.787-.027-.62.485.269.74-.653-.44-.62.485.217-.757-.653-.44.787-.027.217-.759zM24.1 17.785l.73-.295-.417.667.506.603-.764-.19-.417.667-.055-.785-.764-.19.73-.295-.055-.785.506.603zm-2.9-2.145l.682.394.585-.527-.164.77.682.394-.783.082-.164.77-.32-.719-.783.082.585-.527-.32-.719zm-2.002-.493l.37-.695.11.779.775.137-.707.345.11.779-.547-.566-.709.345.37-.695-.547-.566.775.137zm-2.875.084l.11-.779.37.695.775-.137-.547.566.37.695-.707-.345-.547.566.11-.779-.707-.345.773-.137zm-2.789.276l.585.527.681-.394-.32.719.585.527-.783-.082-.32.719-.164-.77-.783-.082.682-.394-.163-.77zM11.17 17.49l.73.295.506-.603-.055.785.73.295-.764.19-.055.785-.417-.667-.764.19.506-.603-.417-.667zm-.756 2.699l.269-.74.217.757.787.027-.653.44.217.757-.62-.485-.653.44.269-.74-.62-.485.787.029z"
    />
    <path
      fill="#FC0"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFF"
      d="M18.6 16.271l.707-.345.547.566-.11-.779.708-.345-.775-.137-.11-.779-.369.695-.776-.137.547.566zm3.118.532l.32.719.163-.769.783-.083-.681-.393.163-.77-.585.526-.681-.393.32.719-.585.527zm1.201 1.459l.764.19.055.785.417-.667.763.19-.506-.603.418-.667-.73.295-.506-.603.055.785zm2.046 2.412l-.217.756.621-.484.652.44-.269-.74.62-.484-.786.027-.27-.739-.217.756-.786.028zm-8.819-4.182l.547-.566.707.345-.369-.695.547-.566-.776.137-.369-.695-.11.779-.775.137.708.345zm-2.347.261l.163.769.32-.719.783.083-.585-.527.32-.719-.681.393-.585-.526.163.77-.681.393zm-1.954 1.817l.417.667.055-.785.764-.19-.73-.295.055-.785-.506.603-.73-.295.418.667-.506.603zm-1.866 2.816l.652-.44.621.484-.217-.756.652-.44-.786-.028-.217-.756-.27.739-.786-.027.62.484z"
    />
  </svg>
);

export default Ve;
