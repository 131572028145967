const Pn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#DD2E44"
      d="M30.464 20.153c0 .49-.561.888-1.253.888-.691 0-1.252-.397-1.252-.888s.561-.889 1.252-.889c.692.001 1.253.398 1.253.889z"
    />
    <path
      fill="#009A67"
      d="M24.744 18.771c0 .421 1.267.916 2.286-.326 1.02-1.242.723.448.305.71-.418.262-.865.894-1.767.797-.901-.098-1.131-.454-1.339-.974-.208-.52.515-.207.515-.207z"
    />
    <path
      fill="#FFF100"
      d="M24.926 16.122c.104.256-.018.547-.274.652l-.926.378c-.256.105-.548-.018-.652-.273-.104-.256.018-.547.274-.652l.926-.379c.255-.105.547.018.652.274zm7.972 2.816c-.15.232-.419.324-.6.207l-1.31-.85c-.181-.117-.206-.399-.056-.631.15-.232.419-.325.601-.208l1.31.85c.18.118.206.4.055.632z"
    />
    <path
      fill="#FFF100"
      d="M33.068 18.63c.074.266-.081.542-.347.616-.267.074-.542-.081-.616-.347l-.537-1.927c-.074-.266.081-.542.347-.616.267-.074.542.081.616.347l.537 1.927z"
    />
    <path
      fill="#FFF100"
      d="M33.479 18.852c0 .277-.224.5-.5.5s-.5-.223-.5-.5c0-.276.224-.5.5-.5s.5.224.5.5z"
    />
    <path
      fill="#009A67"
      d="M25 18.625c0 .207-.224.375-.5.375s-.5-.167-.5-.375v-2.25c0-.208.224-.375.5-.375s.5.167.5.375v2.25z"
    />
    <path
      fill="#009A67"
      d="M25.719 17.587c.166.221.167.499.003.622l-1.185.889c-.163.123-.431.043-.597-.178-.166-.222-.167-.5-.003-.623l1.185-.889c.163-.122.431-.043.597.179z"
    />
    <path
      fill="#009A67"
      d="M24.535 18.615c0 .272-.239.493-.535.493s-.536-.221-.536-.493.24-.493.536-.493.535.221.535.493zm9.311-1.614c-.109.258-.657.271-1.225.029-.566-.242-.937-.646-.826-.904.109-.258.657-.271 1.225-.029.566.241.936.646.826.904z"
    />
    <path
      fill="#D4D5D6"
      d="M27.805 17.255c-.58.376-1.357 1.076-1.357 1.076l.433.055s.529.554.621 1.079c.092.524-.118.169-.162.575-.044.406.184.68.184.68s.628-.212 1.04-.528c.412-.316.688-.79.923-.547.235.243.207.355.207.355H30s-.506-.494-.498-.777c.008-.283-.443-1.689-.443-1.689l-1.254-.279zm-.681-1.261s.475-.361.425-.316c-.05.045.537.25.894.104.356-.146 1.052-.849 1.223-.745.171.103-.863.709-.431.805s.568-.268.835-.4.685-.148.281.119c-.403.267-.959 1.422-.959 1.422l-1.63-.128-.427-.313-.211-.548z"
    />
    <path
      fill="#009A67"
      d="M29.905 17.203c0 .23-.602.417-1.344.417-.741 0-1.343-.187-1.343-.417 0-.23.601-.417 1.343-.417.743-.001 1.344.186 1.344.417zm-4.439 4.943c-.069.22-.57.255-1.12.081-.549-.174-.938-.491-.869-.71.07-.22.571-.256 1.121-.082.548.174.937.493.868.711zm7.605 1.623c-.03.17-.526.225-1.108.122-.582-.101-1.03-.322-1-.491.029-.169.525-.224 1.107-.121.582.101 1.031.322 1.001.49z"
    />
    <path
      fill="#009A67"
      d="M32.082 23.851c-.157.757-.423 1.34-.592 1.305-.169-.035-.179-.676-.021-1.432.157-.756.422-1.34.591-1.305.169.035.179.677.022 1.432zm-2.513-6.514c.274.715 1.049 2.129 1.788 2.139.739.01.837-.31.837-.31s.027.562-.645.666c-.672.104-1.054-.228-.931-.113.123.114.395.374 1.021.538.626.164 1.696.357 1.664.956-.032.599-.45.831-.408.567.042-.264-.429-.744-1.256-.93-.827-.186-1.763-.637-2.172-1.977-.409-1.341-.076-.123-.076-.123s-.81.247-.805-.157.628-.192.5-.706-.432-.865-.13-.756c.303.11.613.206.613.206zm-2.038 1.83c-.406.624-1.585 1.502-1.965 1.914-.38.412-.655 1.191-.736 1.708-.081.517.074 1.313.362.419s.798-1.241 1.313-1.818c.516-.577.866-.959 1.055-1.344.189-.385-.029-.879-.029-.879z"
    />
    <path
      fill="#FFF100"
      d="M29.955 19.589c.127.419.114.793-.029.836-.143.043-.361-.261-.487-.68-.127-.419-.113-.794.03-.837.142-.043.36.262.486.681zm-3.539-.006c0 .127-.308.229-.688.229-.38 0-.688-.103-.688-.229 0-.127.308-.229.688-.229.38 0 .688.102.688.229zm-1.101 4.145c.149.772.127 1.425-.048 1.458-.174.033-.436-.564-.583-1.337-.149-.772-.127-1.426.048-1.459.174-.034.436.565.583 1.338z"
    />
    <path
      fill="#FFF100"
      d="M25.821 23.585c0 .156-.442.281-.986.281-.545 0-.987-.125-.987-.281 0-.155.442-.281.987-.281.544 0 .986.126.986.281zm.225 2.193c.188.382.24.744.111.807-.127.063-.384-.196-.574-.578-.188-.382-.239-.745-.111-.807.128-.064.384.196.574.578zm5.431.244c-.277.396-.65.614-.834.486-.185-.129-.108-.555.169-.951.277-.397.65-.615.835-.486.183.128.107.554-.17.951zm2.279-4.598c.124.203-.253.66-.844 1.021-.59.361-1.169.488-1.293.285-.125-.204.252-.661.842-1.022.591-.361 1.17-.488 1.295-.284zm-10.113-.21c.281-.662 1.153-1.016 1.73-1.168.576-.152.705-.325 1.171-.692.466-.367.354-.615.581-.449.227.166.417.243.198.553-.219.31-.983.966-1.479 1.157-.495.191-1.469.601-1.531.712-.063.111-.764.108-.67-.113z"
    />
    <path
      fill="#4D8FCC"
      d="M25.88 20.192h4.775v3.632c0 1.547-2.388 3.154-2.388 3.154s-2.388-1.577-2.388-3.229l.001-3.557z"
    />
    <path
      fill="#009A67"
      d="M30.354 24.818l-2.059-4.078-2.218 3.83c.558 1.316 2.189 2.408 2.189 2.408s1.454-.988 2.088-2.16z"
    />
    <path
      fill="#FFF100"
      d="M26.072 24.312l.157.386 2.067-3.741 2.005 3.71.163-.412-2.125-4.063zm4.47-3.736c.333.549.942.386 1.204 1.312.262.927.123 1.931-.151 1.137s-.354-1.24-1.131-1.445c-.776-.205.078-1.004.078-1.004zm-4.976 4.797l-.194 1.158L26.416 26zm5.535.184l-.984.457 1.079.648z"
    />
    <path
      fill="#FFF100"
      d="M29.251 23.813c0 .103-.084.187-.186.187h-1.456c-.103 0-.187-.084-.187-.187s.084-.186.187-.186h1.456c.102 0 .186.084.186.186z"
    />
    <path
      fill="#FFF100"
      d="M28.428 25.738c0 .144-.038.262-.084.262h-.154c-.046 0-.084-.117-.084-.262v-2.477c0-.144.038-.262.084-.262h.154c.046 0 .084.117.084.262v2.477z"
    />
    <path
      fill="#FFF100"
      d="M27.049 24.83l.584.61-.102.125.659.435.901-.434-.192-.121.653-.492-.094.832-.239-.069-.943.796-.991-.841-.189.02z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Pn;
