const Mo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00785E"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#FBD116"
        d="M17.976 8.567c.181.347.279.775.407 1.174h1.272c-.328.256-.678.489-1.008.743.094.41.248.759.36 1.15-.068.027-.164-.064-.24-.119-.22-.163-.513-.383-.742-.552-.373.186-.713.533-1.031.695.101-.402.257-.749.36-1.151-.307-.277-.657-.51-1.007-.743.367-.056.839-.008 1.246-.023.143-.375.278-.76.383-1.174zm-5.441 3.404c.246-.147.466-.317.695-.479-.104-.247-.149-.554-.264-.791.221.075.458.341.695.479.247-.144.469-.314.695-.479-.019.229-.18.523-.239.791.184.214.439.358.67.527-.241.058-.589.014-.838 0-.124.243-.21.525-.288.815-.126-.233-.192-.527-.264-.815h-.647c-.017-.07-.196.021-.215-.048zm10.714.048h-.623c-.103.257-.168.551-.264.815-.109-.259-.193-.543-.289-.815-.27.024-.561.029-.862.024.208-.192.464-.335.671-.527-.066-.293-.178-.541-.264-.815.243.149.465.319.695.479.271-.138.46-.356.719-.503-.104.246-.148.554-.264.79.202.198.454.346.695.504-.018.069-.196-.022-.214.048zM11.266 13.48c.117.226.118.568.191.839.301.019.569.069.862.096-.241.142-.48.286-.742.407.016.304.095.545.144.815-.07.016-.21-.121-.312-.216-.112-.104-.213-.236-.311-.311-.27.122-.498.284-.767.407.12-.248.219-.516.359-.743-.169-.239-.4-.414-.623-.599.251-.043.571.051.839.072.138-.237.254-.496.36-.767zm13.804.767c.288-.031.559-.081.863-.096-.204.206-.435.381-.625.6.129.24.229.507.361.743-.229-.052-.505-.263-.744-.383-.232.15-.441.453-.646.551.042-.277.122-.517.144-.814-.236-.148-.476-.292-.743-.408.243-.077.558-.081.839-.119.105-.237.107-.58.192-.839.133.241.248.501.359.765z"
      />
      <path
        fill="#FFF"
        d="M21.427 23.211c-1.558-.08-2.548-.728-3.451-1.463-.844.776-1.874 1.368-3.402 1.463h-4.506c.005.019 0 .047.023.047.104.16.209.318.336.455 1.401.096 3.067.049 4.481.025 1.357-.082 2.26-.617 3.092-1.223.829.608 1.735 1.14 3.091 1.223 1.441.023 3.107.07 4.482-.025.121-.145.232-.295.336-.455.007.003.019-.004.025 0-.176-.107-.718-.047-1.008-.047h-3.499zm3.594 1.149c.042.007.084-.093 0-.071-4.689.031-9.494-.064-14.092.048.024 0 .028.019.048.023.062.07.145.189.24.216.189.052.532 0 .838 0h12.774c.051-.087.143-.129.192-.216zm-.862.839c.024.001.028-.018.048-.024.002-.029.03-.032.023-.071-3.994.078-8.175-.106-12.15 0-.068.002-.271-.068-.288.071.024 0 .029.02.048.024.147.109.29.223.432.335 3.764.041 7.693.041 11.456 0 .142-.112.286-.225.431-.335zm-1.101.79c.063-.046.056-.033 0-.047-.332-.078-.844 0-1.271 0h-8.34c-.155 0-.605-.032-.503.047.281.175.572.339.887.48 2.724.039 5.616.039 8.339 0 .314-.141.606-.304.888-.48zm-1.582.793h.047c-.001-.041-.009-.044-.047-.049-.526-.077-1.158 0-1.75 0-1.762 0-3.616-.039-5.249.049h.049c.861.318 1.788.575 2.924.622.096.056.295.009.431.024h.24c.136-.016.335.031.431-.024 1.13-.051 2.062-.3 2.924-.622z"
      />
      <path
        fill="#FFF"
        d="M15.915 16.644c.046 1.144.355 2.025.839 2.732-.385-.855-.726-1.797-.623-3.02.133-1.597.953-2.864 1.894-3.618.887.799 1.696 2.014 1.845 3.595.123 1.316-.277 2.358-.743 3.211.521-.669.916-1.671.959-2.9.04-.041.04-.247 0-.288-.031-.839-.218-1.525-.503-2.109.151-.303.396-.515.478-.886.38.602.625 1.34.695 2.253v.743c-.067 1.131-.45 1.947-.91 2.684-.021.003-.074.079-.024.072.049-.087.142-.129.192-.216 1.035-.881 2.253-1.581 4.098-1.653h.432c.045.072-.116.163-.192.264-.082.108-.141.252-.216.336-2.006.039-3.316.774-4.361 1.772.789-.543 1.871-1.156 3.14-1.269 1.213-.108 2.265.079 3.163.455-.255.688-.616 1.269-1.078 1.75-.114.095-.218.198-.312.311-.826.748-1.83 1.316-3.307 1.414h-.575c0 .023.029.018.048.023.1.062.272.048.431.049.113.039.392.039.504 0 1.606-.062 2.612-.727 3.522-1.486.126.09.692.066.576.192-.13.109-.249.229-.359.359-.848.742-1.877 1.302-3.38 1.39h-.504c-1.517-.088-2.552-.658-3.402-1.414-.081-.086-.142-.192-.264-.239-.058.094-.157.146-.215.239-.851.756-1.887 1.325-3.403 1.414h-.503c-1.517-.091-2.558-.654-3.403-1.414-.103-.122-.214-.233-.336-.335.013-.034-.094-.06-.024-.072.21-.022.408-.056.576-.12.937.74 1.925 1.43 3.547 1.486.111.039.391.039.503 0 .158-.001.333.013.431-.049h.048c-.144-.055-.391-.008-.574-.023-1.479-.096-2.481-.667-3.308-1.414-.094-.113-.198-.217-.312-.311-.46-.482-.827-1.059-1.078-1.75.893-.377 1.936-.556 3.139-.455 1.26.107 2.345.702 3.116 1.246.046.058.112.096.168.145-.009-.08-.072-.104-.12-.145-1.071-.956-2.345-1.713-4.339-1.748-.155-.179-.345-.454-.455-.599h.455c1.814.08 3.045.742 4.051 1.629.098.102.175.225.311.287.018-.104-.047.019-.048-.047-.025-.055-.055-.106-.096-.144-.435-.723-.799-1.518-.863-2.612v-.743c.061-.916.331-1.619.672-2.253.126.328.316.595.503.863-.24.526-.42 1.113-.479 1.821-.055.034-.009.168-.024.24v.071c-.043.039-.043.245-.003.286zm.839 2.732c.019.085.061.146.12.191-.019-.085-.062-.145-.12-.191z"
      />
    </g>
  </svg>
);

export default Mo;
