const Sm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#5EB6E4"
      d="M0 18v9c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-9H0z"
    />
    <path
      fill="#EEE"
      d="M32 5.041H4c-2.209 0-4 1.791-4 4V18h36V9.041c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#F4900C"
      d="M18 14.26c-2.433 0-2.656-.984 0-.984 2.656-.001 2.433.984 0 .984z"
    />
    <path
      fill="#FFAC33"
      d="M19.875 13.979c-.609 0-1.094.375-1.531.609-.125-.156-.344-.141-.344-.141s-.219-.016-.344.141c-.438-.234-.922-.609-1.531-.609s-2.047.594-2.047 3.609c0 3.969 3.922 5.234 3.922 5.234s3.922-1.266 3.922-5.234c0-3.016-1.438-3.609-2.047-3.609zm-4.687-1.329c.391.078.904.797.904 1.117.361-.398 1.908-.43 1.908-.43s1.547.031 1.908.43c0-.32.514-1.039.904-1.117-.078-.156-.297-.109-.297-.109s.484-.828.297-1.219c-.188-.391-.922-.375-1.516-.438-.594-.062-.703-.328-.703-.328l.156-.188s-.297-.062-.234-.266c.062-.203-.188-.188-.188-.188s.188-.188.109-.359c-.078-.172-.25-.219-.25-.219s-.109-.25 0-.328c.109-.078.359.219.359-.078s-.281-.047-.359-.156c-.077-.108.142-.389-.186-.389s-.109.281-.188.391c-.078.109-.359-.141-.359.156s.25 0 .359.078c.109.078 0 .328 0 .328s-.172.047-.25.219c-.078.172.109.359.109.359s-.25-.016-.188.188c.062.203-.234.266-.234.266l.156.188s-.109.266-.703.328-1.328.047-1.516.438c-.188.391.297 1.219.297 1.219s-.217-.049-.295.107zm4.124-1.421c.531.094 1.391.281.375.953.016-.234-.109-.422-.109-.422s-.25.031-.453.219c-.087-.31-.234-.406-.234-.406s-.297.093-.391.327c-.037-.101-.088-.186-.144-.257.088-.153.144-.361.144-.591 0-.049-.012-.092-.017-.139.258.158.572.27.829.316zm-2.624 0c.255-.045.567-.156.823-.312-.005.046-.016.087-.016.135 0 .232.056.442.146.596-.054.07-.104.154-.14.253-.094-.234-.391-.328-.391-.328s-.148.097-.234.406c-.203-.188-.453-.219-.453-.219s-.125.188-.109.422c-1.017-.672-.158-.86.374-.953z"
    />
    <path
      fill="#A0CFEB"
      d="M20.887 17.979c0-2.328-.672-2.594-.672-2.594s-.324.391-.787.234c-.463-.156-1.119-.672-1.119-.672s-.115.146-.326.154c-.211-.007-.327-.154-.327-.154s-.656.516-1.118.672c-.462.156-.788-.234-.788-.234s-.672.266-.672 2.594c0 2.202 2.608 4.276 2.891 4.494v.021l.014-.011.014.011v-.021c.282-.218 2.89-2.292 2.89-4.494z"
    />
    <path
      fill="#CCD6DD"
      d="M17.312 20.197c.156-.453.25-.75.25-1.016V18.04s-.25-.125-.25-.266v-.422l.25-.094v.281l.25-.094v-.25H18v.266l.219.047v-.234l.281.078v.391s-.219.234-.219.297v1.141c0 .109.328 1.016.328 1.016h-1.297zm.649-3.218s-.07-.297-.242-.547c-.172-.25-.115-.718.264-.719.705-.001.236.633.236.633s-.156-.32-.219-.227c-.062.094.164.453.062.656s-.101.204-.101.204zm-1.578.281s-.07-.297-.242-.547c-.172-.25-.115-.718.264-.719.705-.001.236.633.236.633s-.156-.32-.219-.226c-.062.094.164.453.062.656l-.101.203zm3.131 0s-.07-.297-.242-.547-.115-.718.264-.719c.705-.001.236.633.236.633s-.156-.32-.219-.226c-.062.094.164.453.062.656l-.101.203zm-3.662 2.865c.084-.268.132-.473.132-.662v-1.141s-.25-.125-.25-.266v-.422l.25-.094v.281l.25-.094v-.25h.188v.266l.219.047v-.234l.281.078v.391s-.219.234-.219.297v1.141c0 .11.328 1.016.328 1.016s-1.213-.247-1.179-.354zm3.013.354c.156-.453.25-.75.25-1.016v-1.141s-.25-.125-.25-.266v-.422l.25-.094v.281l.25-.094v-.25h.188v.266l.219.047v-.234l.281.078v.391s-.219.234-.219.297v1.141c0 .068.131.457.228.733.055.16-1.197.283-1.197.283z"
    />
    <path
      fill="#99AAB5"
      d="M16.383 17.479h-.148v.25l-.25.094v-.281l-.25.094v.422c0 .141.25.266.25.266v1.141c0 .189-.048.395-.132.662-.016.051.248.133.531.205v-2.853zm1.568-.282h-.149v.25l-.25.094v-.281l-.25.094v.422c0 .141.25.266.25.266v1.141c0 .189-.048.395-.132.662-.016.051.249.133.531.205v-2.853zm1.601.328l-.196-.047v.25l-.25.094v-.281l-.25.094v.422c0 .141.25.266.25.266v1.141c0 .189-.049.395-.133.662-.016.051.248.133.579.252v-2.853z"
    />
    <path
      fill="#8BB273"
      d="M18.984 20.033s-.328-.422-.984-.422c-.656 0-.938.438-.938.438s-.579-.228-1.251.093c.794 1.249 1.973 2.189 2.157 2.331v.021l.014-.011.014.011v-.021c.185-.142 1.371-1.09 2.165-2.345-.716-.304-1.177-.095-1.177-.095z"
    />
    <path
      fill="#658D5C"
      d="M18.855 24.541c-.442-.723-.839-1.547-1.917-2.047s-1.25-.641-1.562-1.156-.922-.859-.922-.859.016-.484-.188-.797c-.203-.312-.188-.703-.188-.703s-.25.133-.188-.355-.25-.816-.25-.816.391-.516.062-1.18c.375-.648.203-1.383.203-1.383s.328-.609 0-1.266c-1.047.438-1.109 1.828-1.109 1.828l-.594-.859s-.5.328-.375.938c.125.609.094.742.094.742l-.562-.476s-.406.672.156 1.203.656.688.656.688-.281-.156-.281.094.062.359.062.359l-.736-.609s-.217.828.298 1.297c.516.469.828.641.828.641s-.984-.281-.969-.211c.016.07.203.586.703.867.5.281.875.391.875.391l-1.016.184s.25.723.844.723 1.156-.188 1.156-.188-.422.344-.547.609c-.125.266-.484.297-.484.297s.328.344.938.234c.609-.109.688-.938 1.438-.672.75.266 2.094.75 2.531 1.422s.594.828.688 1.062c.095.232.485.209.356-.002z"
    />
    <path
      fill="#658D5C"
      d="M17.027 24.541c.441-.723.839-1.547 1.917-2.047 1.078-.5 1.25-.641 1.562-1.156s.922-.859.922-.859-.016-.484.188-.797.188-.703.188-.703.25.133.188-.355.25-.816.25-.816-.391-.516-.062-1.18c-.375-.648-.203-1.383-.203-1.383s-.328-.609 0-1.266c1.047.438 1.109 1.828 1.109 1.828l.594-.859s.5.328.375.938c-.125.609-.094.742-.094.742l.562-.476s.406.672-.156 1.203-.656.688-.656.688.281-.156.281.094-.062.359-.062.359l.735-.609s.218.828-.298 1.297-.828.641-.828.641.984-.281.969-.211c-.016.07-.203.586-.703.867-.5.281-.875.391-.875.391l1.016.184s-.25.723-.844.723-1.156-.188-1.156-.188.422.344.547.609c.125.266.484.297.484.297s-.328.344-.938.234-.688-.938-1.438-.672c-.75.266-2.094.75-2.531 1.422s-.594.828-.688 1.062c-.094.232-.484.209-.355-.002z"
    />
    <path
      fill="#E1E8ED"
      d="M15.625 23.144c-.734.085-.922.569-1.953.116-.047.234.328.594.328.594s-.562.016-.828-.078c.141.578.797.797 1.344.75.547-.047.891-.453 1.524-.312.633.141 1.088.335.962-.797-.424-.454-1.377-.273-1.377-.273zm4.75 0c.734.085.922.569 1.953.116.047.234-.328.594-.328.594s.562.016.828-.078c-.141.578-.797.797-1.344.75s-.891-.453-1.524-.312c-.633.141-1.088.335-.962-.797.424-.454 1.377-.273 1.377-.273z"
    />
    <path
      fill="#F5F8FA"
      d="M20.422 22.416c-.422-.187-1.344.281-2.422.281s-2-.469-2.422-.281c-.422.188-.344.25-.312.406.031.156.078.578.109.812.031.234.281.391.578.188.297-.203.672-.297 1.188-.141.516.156.834.146.834.146s.369.01.885-.146.891-.062 1.188.141c.297.203.547.047.578-.188.031-.234.078-.656.109-.812.031-.156.109-.218-.313-.406z"
    />
    <path
      fill="#99AAB5"
      d="M19.838 22.822c0 .096-.191.48-1.952.48s-1.892-.385-1.892-.48c0-.096.288.212 2.048.212s1.796-.307 1.796-.212z"
    />
  </svg>
);

export default Sm;
