const Py = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#0038A8"
      d="M0 27c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-4H0v4z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#D52B1E"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path d="M22 18c0 2.209-1.791 4-4 4s-4-1.791-4-4 1.791-4 4-4 4 1.791 4 4z" />
    <path
      fill="#EEE"
      d="M21.75 18c0 2.071-1.68 3.75-3.75 3.75s-3.75-1.68-3.75-3.75 1.679-3.75 3.75-3.75 3.75 1.679 3.75 3.75z"
    />
    <path d="M21.25 18c0 1.795-1.455 3.25-3.25 3.25s-3.25-1.455-3.25-3.25 1.455-3.25 3.25-3.25 3.25 1.455 3.25 3.25z" />
    <path
      fill="#EEE"
      d="M21.126 18c0 1.726-1.4 3.126-3.126 3.126s-3.124-1.4-3.124-3.126 1.399-3.124 3.124-3.124c1.726 0 3.126 1.399 3.126 3.124z"
    />
    <path
      fill="#99AAB5"
      d="M21.132 17.98c0-1.73-1.402-3.132-3.132-3.132s-3.132 1.402-3.132 3.132c0 .573.154 1.111.423 1.573l2.712-1.491 2.707 1.491c.268-.463.422-1 .422-1.573z"
    />
    <path d="M20.5 18c0 1.381-1.119 2.5-2.5 2.5s-2.5-1.119-2.5-2.5 1.12-2.5 2.5-2.5 2.5 1.119 2.5 2.5z" />
    <circle fill="#EEE" cx="18" cy="18" r="2.4" />
    <path
      fill="#FFAC33"
      d="M18 17.182l.184.565h.594l-.481.35.183.565-.48-.349-.48.349.183-.565-.481-.35h.594z"
    />
    <path
      fill="#007127"
      d="M18.497 16.517s.3-.07.476-.088c.176-.018.334.388.545.458.214.072.212.282.3.529.088.247.035.299.035.581s.158.423.123.634c-.035.211-.211.264-.316.405-.105.142-.018.317-.124.423-.105.105-.458.088-.581.194-.123.105-.352.264-.511.176-.158-.088-.493-.123-.493-.123s.088-.317.335-.423c.247-.105.352-.088.581-.158.229-.071.211-.177.37-.317.158-.141.176-.335.264-.476.089-.141.106-.247.071-.441-.036-.194-.107-.405-.177-.582-.07-.176-.229-.387-.335-.511-.105-.123-.563-.281-.563-.281z"
    />
    <path
      fill="#007127"
      d="M17.405 16.622s.175-.383 0-.405c-.705-.088-.846.546-1.057.616-.213.072-.247.423-.334.669-.088.247-.053.299-.053.582 0 .282.005.334.041.546.035.211.083.37.188.511.106.141.146.211.252.316.105.105.312.212.435.317.123.105.498.141.657.053.158-.088.435-.123.435-.123s-.118-.212-.364-.317c-.247-.105-.264-.193-.493-.264-.229-.071-.211-.177-.37-.317-.158-.141-.176-.335-.264-.476-.088-.141-.041-.176-.005-.37.035-.194.105-.352.176-.528.07-.176.247-.405.352-.528.105-.123.404-.282.404-.282z"
    />
    <path
      fill="#007127"
      d="M17.423 19.863l.229.158.299-.211.282.194.37-.141-.37.265-.264-.212-.282.194-.282-.159z"
    />
  </svg>
);

export default Py;
