import { FC } from 'react';
import * as S from './styles';

interface LinkProps {
  children: any;
  href?: string;
  rel?: any;
  role?: string;
  target?: string;
  onClick?: any;
}

const Link: FC<LinkProps> = ({ children, ...others }) => {
  return <S.Link {...others}>{children}</S.Link>;
};
export default Link;
