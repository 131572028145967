const Ec = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#ED1C23"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#034EA2" d="M0 17h36v7H0z" />
    <path
      fill="#FD0"
      d="M36 17V9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9v8h36z"
    />
    <path
      fill="#FD0"
      d="M23 19c0 1.104-.896 2-2 2h-6c-1.104 0-2-.896-2-2v-3c0-1.104.896-2 2-2h6c1.104 0 2 .896 2 2v3z"
    />
    <path
      fill="#034EA2"
      d="M22 18c0 1.104-.896 3-2 3h-4c-1.104 0-2-1.896-2-3v-1c0-1.104.896-2 2-2h4c1.104 0 2 .896 2 2v1z"
    />
    <path
      fill="#ED1C23"
      d="M21 18c0 1.104-.896 3-2 3h-2c-1.104 0-2-1.896-2-3v-1c0-1.104.896-2 2-2h2c1.104 0 2 .896 2 2v1z"
    />
    <path
      fill="#6DBE46"
      d="M19.973 14.135c-.133.488-.961.865-1.973.865s-1.84-.377-1.973-.865C14.822 14.307 15 14.628 15 15c0 .552.791 1 3 1s3-.448 3-1c0-.372.178-.693-1.027-.865z"
    />
    <path
      fill="#A6D388"
      d="M19 19c0 .553-.448 1-1 1s-1-.447-1-1v-3c0-.552.448-1 1-1s1 .448 1 1v3z"
    />
    <path
      fill="#662113"
      d="M21.5 12c.44 0 .858.052 1.246.137C22.123 11.061 20 10 18 12c-2-2-4.123-.939-4.746.137.388-.085.806-.137 1.246-.137 1.933 0 3.5.896 3.5 2 0-1.104 1.566-2 3.5-2z"
    />
    <ellipse fill="#8ED2E7" cx="18" cy="17.5" rx="2" ry="3.5" />
    <path
      fill="#6DBE46"
      d="M17.5 17c-.536 0-1.037.13-1.491.345-.001.052-.009.102-.009.155 0 1.933.896 3.5 2 3.5 1.003 0 1.825-1.295 1.97-2.979-.633-.63-1.506-1.021-2.47-1.021z"
    />
    <ellipse fill="#FFCC4D" cx="18" cy="22" rx="3" ry="1" />
    <path
      fill="#034EA2"
      d="M20 23c0 .828-.896 1-2 1s-2-.172-2-1 .896-2 2-2 2 1.172 2 2z"
    />
    <path
      fill="#ED1C23"
      d="M17 23c0-.828.448-2 1-2s1 1.172 1 2-.448 1-1 1-1-.172-1-1z"
    />
    <path
      fill="#CCD6DD"
      d="M17 11c0-.552.448 0 1 0s1-.552 1 0-.448 1-1 1-1-.448-1-1z"
    />
    <ellipse fill="#FFF" cx="18" cy="17.5" rx="1" ry=".5" />
    <ellipse fill="#C1694F" cx="13" cy="15.5" rx="1" ry=".5" />
    <ellipse fill="#C1694F" cx="14" cy="13.5" rx="1" ry=".5" />
    <ellipse fill="#C1694F" cx="23" cy="15.5" rx="1" ry=".5" />
    <ellipse fill="#C1694F" cx="22" cy="13.5" rx="1" ry=".5" />
  </svg>
);

export default Ec;
