import { css } from '@emotion/react';

const fontSize = (level, theme) => {
  switch (level) {
    case 1:
      return `${theme.fontSizeH1}`;
      break;
    case 2:
      return `${theme.fontSizeH2}`;
      break;
    case 3:
      return `${theme.fontSizeH3}`;
      break;
    case 4:
      return `${theme.fontSizeH4}`;
      break;
    case 5:
      return `${theme.fontSizeH5}`;
      break;
    default:
      return `${theme.fontSizeH6}`;
  }
};

const animation = (type: string, duration: string, intarations: string = 'infinite') => {
  switch (type) {
    case 'fade-in':
      return `
        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        -webkit-animation: ${type} ${duration} cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: ${type} ${duration} cubic-bezier(0.39, 0.575, 0.565, 1) both;
      `;
      break;
    case 'fade-out':
      return `
        @-webkit-keyframes fade-in {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        -webkit-animation: ${type} ${duration}  cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: ${type} ${duration} cubic-bezier(0.39, 0.575, 0.565, 1) both;
      `;
      break;
    case 'bounce-top':
      return `
      @-webkit-keyframes bounce-top {
        0% {
          -webkit-transform: translateY(-45px);
                  transform: translateY(-45px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 1;
        }
        24% {
          opacity: 1;
        }
        40% {
          -webkit-transform: translateY(-24px);
                  transform: translateY(-24px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        65% {
          -webkit-transform: translateY(-12px);
                  transform: translateY(-12px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        82% {
          -webkit-transform: translateY(-6px);
                  transform: translateY(-6px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        93% {
          -webkit-transform: translateY(-4px);
                  transform: translateY(-4px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        100% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
      }
      @keyframes bounce-top {
        0% {
          -webkit-transform: translateY(-45px);
                  transform: translateY(-45px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 1;
        }
        24% {
          opacity: 1;
        }
        40% {
          -webkit-transform: translateY(-24px);
                  transform: translateY(-24px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        65% {
          -webkit-transform: translateY(-12px);
                  transform: translateY(-12px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        82% {
          -webkit-transform: translateY(-6px);
                  transform: translateY(-6px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        93% {
          -webkit-transform: translateY(-4px);
                  transform: translateY(-4px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        100% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
      }
      -webkit-animation: ${type} ${duration} both;
      animation: ${type} ${duration} both;
      `;
      break;
    case 'heartbeat':
      return `
      @-webkit-keyframes heartbeat {
        from {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: center center;
                  transform-origin: center center;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        10% {
          -webkit-transform: scale(0.91);
                  transform: scale(0.91);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        17% {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        33% {
          -webkit-transform: scale(0.87);
                  transform: scale(0.87);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        45% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      @keyframes heartbeat {
        from {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: center center;
                  transform-origin: center center;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        10% {
          -webkit-transform: scale(0.91);
                  transform: scale(0.91);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        17% {
          -webkit-transform: scale(0.98);
                  transform: scale(0.98);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        33% {
          -webkit-transform: scale(0.87);
                  transform: scale(0.87);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        45% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      -webkit-animation:${type} ${duration} ease-in-out ${intarations} both;
      animation:${type} ${duration} ease-in-out ${intarations} both;
      `;
      break;
    case 'slide-in-left':
        return `
        @-webkit-keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-1000px);
                    transform: translateX(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-1000px);
                    transform: translateX(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
          }
        }
        -webkit-animation: ${type} ${duration} cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: ${type} ${duration} cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        `;
      break;
    default:
      return ``;
  }
};
export default {
  animation,
  fontSize,
  transition: (properties, type = 'linear', time = '10.25s') => css`
    transition: ${time} ${type};
    transition-property: ${properties};
    will-change: ${properties};
  `,
};
