const Gd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CE1126"
      d="M32 5H4C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path fill="#007A5E" d="M3.5 8.5h29v19h-29z" />
    <path fill="#FCD116" d="M3.5 8.5h29l-29 19h29l-29-19z" />
    <circle fill="#CE1126" cx="18" cy="18" r="3.5" />
    <path
      d="M18 14.798l.719 2.213h2.326l-1.882 1.367.719 2.212L18 19.223l-1.882 1.367.719-2.212-1.882-1.367h2.326zm0-9.616l.386 1.188h1.249l-1.01.734.386 1.188L18 7.558l-1.011.734.386-1.188-1.01-.734h1.249zm8.02 0l.386 1.188h1.249l-1.01.734.386 1.188-1.011-.734-1.01.734.386-1.188-1.011-.734h1.249zm-16.04 0l.386 1.188h1.249l-1.011.734.386 1.188-1.01-.734-1.011.734.386-1.188-1.01-.734h1.249zm8.02 22.5l.386 1.188h1.249l-1.01.734.386 1.188L18 30.058l-1.011.734.386-1.188-1.01-.734h1.249zm8.02 0l.386 1.188h1.249l-1.01.734.386 1.188-1.011-.734-1.01.734.386-1.188-1.011-.734h1.249zm-16.04 0l.386 1.188h1.249l-1.011.734.386 1.188-1.01-.734-1.011.734.386-1.188-1.01-.734h1.249z"
      fill="#FCD116"
    />
    <path
      fill="#CE1126"
      d="M6.118 19.067c.304.315.645.579.962.712.005-.349-.057-.748-.164-1.148-.268-.251-.572-.464-.868-.577-.043.318-.012.673.07 1.013z"
    />
    <path
      fill="#FCD116"
      d="M7.643 19.563c.803-2.916-2.06-2.738-2.803-3.67.512 1.068-1.329 3.617 1.739 4.214-.202-.276-.366-.649-.46-1.04-.082-.34-.113-.695-.07-1.013.297.113.6.326.868.577.313.293.578.637.726.932z"
    />
  </svg>
);

export default Gd;
