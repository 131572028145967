/**
 * The error category enum.
 */
enum ErrorCategory {
  OTHER = 0,
  NOT_IMPLEMENTED,
  UNAVAILABILITY,
}

type IErrorCategoryLabel = {
  readonly [key in ErrorCategory]?: string;
};

/**
 * The error category labels.
 */
const ErrorCategoryLabel: IErrorCategoryLabel = {
  [ErrorCategory.OTHER]: '',
  [ErrorCategory.UNAVAILABILITY]: 'indisponível',
};

/**
 * The error category values.
 */
const ErrorCategoryValues = Object.values(ErrorCategory).filter(
  v => typeof v === 'number',
) as Readonly<ErrorCategory[]>;

export { ErrorCategory, ErrorCategoryLabel, ErrorCategoryValues };
