const Hu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CF1B2B"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <circle fill="#F8DC15" cx="17.635" cy="16.575" r="1.383" />
    <circle fill="#F8DC15" cx="17.635" cy="17.316" r="1.383" />
    <path
      fill="#FFF"
      d="M9.858 12.11c.407-.204 1.33 1.544 2.059 1.501.729-.043 2.616-1.439 3.646-1.535 0 0 0-.439.858-.439s.986.43 1.072.987c0 0 .685 1.072.986 1.887.301.815.301 1.373.301 1.373s-.644.643-1.03.686c-.386.043-1.458-.343-1.458-.343s-.214-1.626-.3-2.121l-.3-.324s-.428.472-1.201.601c-.773.129-1.973.085-2.402.171-.429.086-.386.644-.772.601-.386-.043-.085-.901-.772-1.373-.687-.471-1.202-1.415-.687-1.672zm7.563 14.08c-.38-.252.672-1.926.271-2.535-.402-.609-2.555-1.545-3.153-2.389 0 0-.379.219-.808-.525-.429-.742-.121-1.068.318-1.422 0 0 .585-1.129 1.142-1.797.555-.668 1.038-.947 1.038-.947s.879.236 1.109.548c.23.312.432 1.436.432 1.436s-1.301.997-1.687 1.32l-.131.422s.623.135 1.12.74c.499.604 1.061 1.666 1.35 1.994.289.328.75.012.907.369.156.353-.738.523-.803 1.353-.065.831-.625 1.749-1.105 1.433zm8.365-13.525c-.027.455-2.003.38-2.33 1.032-.327.653-.062 2.985-.492 3.925 0 0 .379.218-.051.961-.429.743-.865.639-1.39.436 0 0-1.271.058-2.128-.09-.856-.146-1.338-.425-1.338-.425s-.235-.879-.081-1.235c.156-.355 1.027-1.092 1.027-1.092s1.516.628 1.987.801l.432-.097s-.196-.608.08-1.341c.273-.734.912-1.751 1.052-2.166.14-.415-.363-.656-.134-.97.229-.313.822.377 1.573.018.753-.358 1.828-.33 1.793.243z"
    />
    <path
      fill="#F8DC15"
      d="M11.628 14.892l.376.327.481-.124-.195.458.266.419-.495-.044-.318.383-.111-.485-.462-.183.428-.255zm7.318 8.374l.095-.491-.348-.355.494-.059.23-.441.209.453.491.082-.364.34.071.49-.433-.242zm3.546-10.46l-.471.163-.133.479-.299-.398-.497.021.287-.406-.174-.467.475.145.39-.308.007.498z"
    />
  </svg>
);

export default Hu;
