const Sz = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#3E5EB9"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#FFD900" d="M0 10h36v16H0z" />
    <path fill="#B10D0D" d="M0 11h36v14H0z" />
    <path fill="#FFF" d="M27.513 16.779l1.562.594 1.531-.594-1.531-.5z" />
    <path
      fill="#FFD900"
      d="M28.531 16.8c0 .155-.126.281-.281.281H5.781c-.155 0-.281-.126-.281-.281 0-.155.126-.281.281-.281H28.25c.155 0 .281.126.281.281z"
    />
    <path fill="#FFF" d="M28.513 15.779l1.562.594 1.531-.594-1.531-.5z" />
    <path
      fill="#FFD900"
      d="M29.531 15.8c0 .155-.126.281-.281.281H6.781c-.155 0-.281-.126-.281-.281 0-.155.126-.281.281-.281H29.25c.155 0 .281.125.281.281zm4.196 2.192c0 .233-.188.422-.422.422H3.148c-.233 0-.422-.189-.422-.422 0-.233.189-.422.422-.422h30.156c.234 0 .423.189.423.422z"
    />
    <path
      fill="#FFF"
      d="M27.229 18.031s-4.058 5.49-9.062 5.49-9.063-5.49-9.063-5.49 4.058-5.49 9.063-5.49 9.062 5.49 9.062 5.49z"
    />
    <path d="M18.979 22.354c-.438-.208-1.188-.458-1.167-.646s.583-.25 1.104-.312c.521-.062.5-.312.354-.438s-.75-.292-1.104-.5-.021-.396.297-.646.328-.541.328-.75.25-.646.667-.917.021-.312-.312-.479-.313-.729-.25-1.146.042-.646-.104-.667-.229-.146-.312-.261c-.083-.115 0-.323-.312-.364s-.292-.146-.375-.333-.354-.479-.479-.833c-.105-.298.149-.863.828-1.519-4.993.019-9.036 5.488-9.036 5.488s4.058 5.49 9.063 5.49c.641 0 1.266-.092 1.868-.249-.143-.587-.705-.75-1.058-.918z" />
    <path
      fill="#FFF"
      d="M12.479 16.042h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.855-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438z"
    />
    <path d="M19.229 16.042h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.855-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.853-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.855-2.458h.438v1.479h-.438zm0 2.458h.438v1.479h-.438zm.854-2.458h.438v1.479H23.5zm0 2.458h.438v1.479H23.5z" />
    <path
      fill="#3D5DA7"
      d="M15.156 13.594c-.4.265-1.219 1.078-1.266 1.672.172-.156.375-.188.375-.188s-.234.328-.203.734c.141-.156.281-.219.281-.219s.083.375.391.766c.156-.094.203-.25.203-.25s.094.328.422.578c.12-.031.12-.172.12-.172s.193.406.99.578c-.141-.266.312-.635.141-1.052.14.083.281.286.281.286s.14-.203-.141-.578c.172.156.266.292.266.292s.108-.651-.266-1.058c.235.094.438.203.438.203s-.039-.787-.625-1.141c-.751-.451-1.407-.123-1.407-.451zm-11 4.312s.359.328-.141.375c-.5.047-.906.641-.891 1.234l.266-.125s-.078.406.047.672c.109-.266.266-.25.266-.25s.031.391.094.688c.125-.156.109-.328.109-.328s.188.062.281.328.141.469.359.594c.094-.047.141-.172.141-.172s.188.156.484.281c-.062-.422.344-.375.516-.672.062.078.078.234.078.234s.125-.234.125-.469.141-.422.141-.422.016.109 0 .281c.141-.078.172-.969.109-1.25.094.078.172.25.172.25s.109-.453 0-.742-.422-.524-.812-.383c-.391.141-.969-.234-.969-.234s.094.234-.031.312-.016-.124-.344-.202zm27.391-.156s-.312.109-.812.578-.656 1.266-.656 1.266.281-.234.391-.266c-.266.609.25.891.172 1.375.203-.031.391-.391.391-.391s.375.672.906 1.047c.125-.359.484-.656.484-.656l.078.203.219-.547.156.266s.141-.307 0-.646c.021.061.234.224.234.224v-.75s.125.234.219.297c0-.328-.188-.562-.109-.703l.109.193s.094-.521-.453-.928c-.547-.406-1.057-.375-1.169-.32-.113.055-.16-.242-.16-.242z"
    />
    <path
      fill="#A70000"
      d="M14.859 14.578c.29-.088.188.844-.156 1.219.188-.641-.203-1.109.156-1.219zm.68-.266c.069-.24.569.391 1.21.422-.561.094-1.296-.125-1.21-.422zm-.195.969c.17-.079.219.859 0 1.125.031-.468-.235-1.015 0-1.125zm.625-.234c-.217.059.421.578.781.547-.338-.064-.438-.641-.781-.547zm-.125.995c-.226 0 0 .599.406.739-.141-.219-.042-.739-.406-.739zm-11.61 2.567c.206 0-.062.703-.5.984.235-.343.204-.984.5-.984zm.672-.109c-.167.128.297.699.828.74-.343-.24-.625-.895-.828-.74zm-.5.906c.124-.021.148.653-.203.828.188-.437.016-.796.203-.828zm.594 0c.207-.127.31.402.609.573-.5-.12-.812-.448-.609-.573zm-.257.5c.207-.091.319.659 0 .875.116-.484-.18-.797 0-.875zm26.413-.666c.216-.006-.172.635-.5.807.313-.469.266-.801.5-.807zm.782-.334c-.161.111.297.422.516.422-.271-.084-.313-.562-.516-.422zm-.563 1.073c.165-.126.234.286 0 .583.143-.302-.219-.416 0-.583zm.672-.424c-.171.069.156.555.406.586-.273-.168-.156-.688-.406-.586zm-.235.492c.142-.107.432.594.091.953.162-.521-.359-.75-.091-.953z"
    />
  </svg>
);

export default Sz;
