const Ky = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#F9D050"
      d="M33.74 22.72c-.279.04-.396.44-.516.68 0 0-.279.24-.72.2 0 0 .202-.32.401-.641.2-.319.081-.72-.199-.759-.279-.041-.319-.201-.68-.52-.36-.32-1-.36-1.12-.08-.12.279-.681.48-.6.88.079.4.279.92.479 1.24 0 0-.946.6-1.707.88-.76.28-1.311.32-1.311.32s-.799-.04-1.559-.32c-.761-.28-1.484-.88-1.484-.88.199-.32.399-.84.481-1.24.079-.399-.481-.601-.601-.88-.12-.28-.76-.24-1.12.08-.36.319-.399.479-.681.52-.279.039-.4.439-.2.759s.398.641.398.641c-.44.04-.721-.2-.721-.2-.12-.24-.244-.64-.524-.68-.28-.04-.286.239-.246.521.04.279.225.8.344 1.119.12.32.331.36.772.44.44.08 1.102-.279 1.102-.279C25.29 26.04 27.536 26 27.536 26s2.465.04 4.025-1.479c0 0 .772.359 1.213.279.44-.08.707-.12.826-.44.12-.319.334-.84.374-1.119.042-.282.047-.561-.234-.521z"
    />
    <path
      fill="#DB1C1C"
      d="M33.588 24.319s.319-.959.399-1.28c.08-.319-.24-.44-.479-.24-.24.201-.601 1.361-.601 1.361s.12-.24.36-.201c.241.041.321.36.321.36zm-1.921-1.479s.44-.521.48-.841c.04-.319-.4-.72-.721-.68-.319.04-.56.28-.479.56.08.28.359.841.359.841s0-.08.161-.08c.16 0 .2.2.2.2zm-9.741 1.479s-.32-.959-.401-1.28c-.079-.319.24-.44.48-.24.24.201.601 1.361.601 1.361s-.12-.24-.36-.201c-.241.041-.32.36-.32.36zm1.92-1.479s-.44-.521-.48-.841c-.04-.319.4-.72.72-.68.32.04.561.28.48.56-.08.28-.36.841-.36.841s0-.08-.159-.08c-.161 0-.201.2-.201.2z"
    />
    <path fill="#CF1B2B" d="M27.767 15.838h-3.231v2.928h7v-2.928z" />
    <path
      fill="#FFF"
      d="M30.271 23.358c.56-.681 1.265-1.919 1.265-3.64v-1.486c-.214-.004-.445-.024-.503-.007-.369.106-.431.493-.879.493-.519 0-.661-.52-1.181-.52-.521 0-.691.52-1.211.52-.439 0-.805-.52-1.205-.52s-.843.52-1.203.52c-.318 0-.528-.405-.819-.5l.002 1.5c0 1.196.243 2.146.527 2.864.165.417.623 1.042.706 1.107 0 0 .945.205 1.494 1.169 0 0 .098.071.514.071.416 0 .507-.092.507-.092.547-.999 1.486-.871 1.986-1.479z"
    />
    <path
      fill="#0B50A0"
      d="M25.231 20.879c.479 0 1.079-.439 1.399-.439.32 0 .6.439 1.04.439.44 0 1-.439 1.28-.439s.72.439 1.159.439c.44 0 .6-.439.999-.439.08 0 .316.031.38.059.041-.261.061-.698.047-1.106-.167-.004-.284-.01-.364-.01-.047 0-.106-.009-.173.014-.34.119-.411.483-.846.483-.519 0-.661-.52-1.181-.52-.521 0-.691.52-1.211.52-.439 0-.805-.52-1.205-.52s-.843.52-1.203.52c-.247 0-.427-.245-.631-.398-.093-.07-.186-.093-.186-.093-.024.554.03 1.103.03 1.103.198.115.289.387.666.387zm3.764 2.361c-.515 0-.679.505-1.185.516-.506-.011-.671-.516-1.185-.516-.348 0-.625.296-.856.45.267.169.655.276 1.163.653.362.269.878.297.878.297s.516-.029.878-.297c.509-.377.897-.484 1.163-.653-.23-.154-.508-.45-.856-.45zm2.311-2.121c-.56 0-.518.52-1.037.52-.521 0-.641-.52-1.16-.52-.521 0-.681.52-1.2.52-.441 0-.801-.52-1.201-.52-.399 0-.84.52-1.199.52-.274 0-.572-.302-.857-.447 0 0 .139.835.405 1.374.091.04.214.073.331.073.48 0 1.079-.439 1.4-.439.32 0 .6.439 1.04.439.44 0 1-.439 1.28-.439s.72.439 1.159.439c.285 0 .48-.192.672-.322.184-.338.323-.728.434-1.195-.019-.002-.045-.003-.067-.003z"
    />
    <path
      fill="#059334"
      d="M25.841 19.834l.355-1.207.365 1.204 1.258-.036-1.032.72.422 1.185-1.003-.76-.997.769.412-1.189-1.038-.711zm3.681 0l.354-1.207.366 1.204 1.258-.036-1.033.72.423 1.185-1.004-.76-.996.769.412-1.189-1.039-.711z"
    />
    <path
      fill="#059334"
      d="M27.682 21.994l.354-1.208.366 1.205 1.258-.035-1.033.719.423 1.185-1.003-.758-.998.767.413-1.188-1.039-.712z"
    />
    <path
      fill="#F9D050"
      d="M25.184 16.098c.25-.205.48.12.521.32.04.2.28.48.52.48 0 0 .16-.48.28-.72.12-.24.44-.32.641-.24.2.08.56.04.52.4-.04.36-.16.52-.16.52s.72.04 1.04 0c.32-.04.681-.24.921-.12.239.12.6.16.72.08s.2-.32-.08-.28c-.28.041-.52.041-.76.081-.24.039-1.28.08-1.28-.24s.399-.36.479-.32c.081.04.681-.4.801-.2s.12.44-.24.44-.881-.08-.881.04.161.2.401.2c.239 0 .64-.16.919-.16.28 0 .681-.32.92-.12.24.2.44.48.24.6-.199.12-.92.16-1.16.12 0 0 .28.16.521.2.24.041.72.12.681.36-.041.24.079.56-.24.52-.32-.04-.28-.2-.2-.32.08-.12-.12-.24-.48-.2-.359.04-.8-.16-.8-.16s-.12.32.04.4c0 0-.48.2-.76.24-.28.04-.56.16-.56 0s.2-.4.36-.32c.16.08.439-.041.521-.041.079 0-.281-.24-.081-.32.2-.08-.159-.12-.359 0-.2.12-1.4.44-1.801.6-.399.16-.76.12-.92.2-.16.08-.479-.12-.32-.32.16-.2.24-.32.36-.2s.32.08.6 0c.28-.08-.159-.16-.239-.24-.08-.08-.4-.56-.4-.68-.005-.12-.725-.24-.285-.6zm1.483-3.019c-.24-.36-.96-.84-.96-.84l.68.2s-.32-.44-.68-.52c.079 0 .72.08.919.24 0 0-.56-.64-.84-.76 0 0 .681.2.921.44l-.28-.6s.52.24.68.56c0 0-.16-.64-.48-.96 0 0 .48.281.642.52l.118-.571.353.318.241-.444.282.459.485-.503s-.12.39-.16.751c0 0 .2-.325.64-.485 0 0-.56.858-.64 1.178 0 0 .8-.792 1.32-.992 0 0-.721.845-.92 1.165 0 0 .6-.518 1.159-.678 0 0-.72.761-.84.921 0 0 .4-.16.92-.12 0 0-1.199.68-1.479.92-.281.24-2.081-.199-2.081-.199z"
    />
    <path
      fill="#FFF"
      d="M26.098 14.844c-.432.079-.55.315-.511.551.039.236.315.432.589.314.275-.118.402-.176.512-.196.267-.049.629-.157.629-.157s.433.078.669 0c0 0 .432.196.786.039 0 0 .865.393 1.258.236.393-.157.072-.684-.118-.747-.354-.118-1.219-.236-1.888-.275-.668-.04-1.926.235-1.926.235z"
    />
    <path
      fill="#073163"
      d="M29.947 14.879c-.147-.049-.383-.099-.655-.143 0 0 .055.222.095.462.027.161.036.339.123.439.205.065.413.058.558 0 .399-.158.073-.694-.121-.758zm-2.343-.273c.123.144.199.521.371.757.062.038.458.214.795.044.037-.067-.28-.771-.28-.771-.159-.016-.733-.041-.886-.03zm-.86.896c.061.037.543-.105.543-.138-.006-.063-.06-.15-.139-.326-.065-.142-.15-.258-.241-.353-.395.06-.733.13-.817.148.168.286.353.489.654.669z"
    />
    <path
      fill="#059334"
      d="M24.787 13.239s.12-.441.64-.441.28.32.88.36c.601.04 2.721.32 3.04.48.32.16.721.12.92.08.2-.041.041.2-.159.32s-.601.04-.601.04.441.4.561.68c.12.28 0 .4-.2.28s-.44.12-.481-.04c-.04-.16-.439-.72-.439-.72s-1.16-.16-1.48.12c-.319.28-.712.384-1.36.32-.399-.04-.64-.24-.559-.48.08-.24.279-.32.279-.32s-.04-.48-.199-.36c-.16.12-.521.04-.561-.04-.041-.079-.281-.279-.281-.279z"
    />
    <path
      fill="#059334"
      d="M26.066 13.079c.172-.103 1-.48 1.641-.44.64.04 1.04.2 1.479.44.441.24.4.44.641.52.24.08.12.2 0 .32s-.521.8-1.801.76c-1.28-.04-1.4-.4-1.6-.56-.2-.16-.32-.56-.32-.68s-.239-.241-.04-.36z"
    />
    <path
      fill="#00493D"
      d="M28.467 14.279c.434-.119 1.022-.276 1.308-.702-.184-.091-.184-.278-.588-.498-.439-.24-.84-.4-1.479-.44-.452-.029-.999.151-1.341.296-.019.283-.019.624-.019.624s.36.2.64.44c.279.24 1.038.4 1.479.28z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Ky;
