const By = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#4AA657" d="M6 31h26c2.209 0 4-1.791 4-4v-4H6v8z" />
    <path fill="#C8313E" d="M32 5H6v18h30V9c0-2.209-1.791-4-4-4z" />
    <path
      fill="#FFF"
      d="M4 5C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h2V5H4z"
    />
    <g fill="#C8313E">
      <path d="M.766 29.188l.625-1.109.641 1.141-.542.871c.171.139.351.263.544.372l.792-1.437-1.42-2.276-1.117 1.726c.183.46.451.872.781 1.23l-.304-.518zm5.215-.091l-1.497-2.222-1.502 2.171L4.268 31h.647l1.066-1.903zm-1.453-1.019l.641 1.141-.641 1.031-.625-1.062.625-1.11zM6 18.333v-.894l-.313.436zM2.825 7.026l-.894-1.434C1.153 6.065.557 6.791.246 7.662l1.285 1.713 1.294-2.349zm-2.059.162l.625-1.109.641 1.141-.641 1.03-.625-1.062zm3.851 2.343l1.364-2.435L4.569 5h-.171L2.983 7.046l1.634 2.485zm.551-2.312l-.64 1.031-.625-1.062.625-1.109.64 1.14z" />
      <path d="M4.953 15l.875-1.453.172.076V13l-1.406-2.188-1.688 2.344-1.5-2.406L0 12.923v.65L.859 15l.609-.891-.437-.609.328-.438.953 1.234-1.406 2.312L0 15.361v1.553l.058-.077.953 1.234L0 19.735v1.177l.906-1.248 1.406 2.312L1.522 23l-.163.211L1.201 23l-.17-.227.438-.609-.609-.891L0 22.701v.575l1.438 2.193L2.838 23l.053-.094.058.094 1.614 2.609L6 23.062v-.391l-.125.056L5 21.273l-.61.891.438.609-.17.227-.158.211L4.337 23l-.79-1.023 1.406-2.312L6 21.106v-1.142l-1.157-1.902.953-1.234L6 17.1v-1.997L4.906 16.61 3.5 14.297l.953-1.234.328.437-.438.609.61.891zm-.668 2.031v1.928L2.89 21.162 1.576 18.95v-1.908l1.314-2.213 1.395 2.202z" />
      <path d="M2.031 16.823l.598 1.091-.598 1.091.438.24.445-.811.445.811.438-.24-.598-1.091.598-1.091-.438-.24-.445.811-.445-.811zM.026 26.188l.625 1.062.641-1.031-.641-1.141zm1.516 0l.625 1.062.64-1.031-.64-1.141zm1.484 0l.625 1.062.641-1.031-.641-1.141zm1.592 0l.625 1.062.64-1.031-.64-1.141zM.026 10.125l.625 1.063.641-1.032-.641-1.14zm1.516 0l.625 1.063.64-1.032-.64-1.14zm1.484 0l.625 1.063.641-1.032-.641-1.14zm1.592 0l.625 1.063.64-1.032-.64-1.14z" />
    </g>
  </svg>
);

export default By;
