/**
 * The error origin enum.
 */
enum ErrorOrigin {
  OTHER = 0,
}

type IErrorOriginLabel = {
  readonly [key in ErrorOrigin]: string;
};

/**
 * The error origin labels.
 */
const ErrorOriginLabel: IErrorOriginLabel = {
  [ErrorOrigin.OTHER]: '',
};

/**
 * The error origin values.
 */
const ErrorOriginValues = Object.values(ErrorOrigin).filter(
  v => typeof v === 'number',
) as Readonly<ErrorOrigin[]>;

export { ErrorOrigin, ErrorOriginLabel, ErrorOriginValues };
