const BrokenLink = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="33" cy="32.5" r="32.5" fill="#D3EDDE" />
    <path
      d="M25.4454 25.8139C28.6404 23.2945 33.2641 23.5159 36.1554 26.4256C38.6304 28.9178 39.2379 32.5711 37.8766 35.624L39.671 37.0344L45.0654 31.6025C46.8372 29.8183 46.8372 26.8787 45.0654 25.1455C43.5016 23.5669 41.0266 23.3103 39.2266 24.6584L39.1366 24.7207C38.3266 25.3041 37.2016 25.0662 36.6279 24.3004C36.0485 23.4859 36.2341 22.3537 37.0441 21.772L37.1341 21.7085C40.3685 19.3813 44.801 19.7505 47.6135 22.5819C50.7916 25.7799 50.7916 30.9682 47.6135 34.1627L42.5229 39.2887L50.4822 45.5701C51.0672 46.0346 51.1741 46.8898 50.7129 47.4789C50.2516 48.068 49.4022 48.1756 48.8172 47.7111L15.517 21.4293C14.9302 20.966 14.8273 20.1118 15.2873 19.5209C15.7473 18.93 16.5956 18.8264 17.1828 19.2895L25.4454 25.8139ZM28.3929 28.1248L34.8785 33.2564C35.2104 31.7725 34.7829 30.1695 33.6579 29.0367C32.246 27.615 30.1366 27.3262 28.3929 28.1248ZM29.7935 40.5234C28.1116 38.8299 27.3016 36.5473 27.4254 34.293L37.871 42.5851C35.1035 43.4121 31.9816 42.7267 29.7935 40.5234ZM21.5585 29.6428L24.4047 31.9027L20.9341 35.3975C19.1617 37.1307 19.1617 40.0703 20.9341 41.8545C22.4979 43.4348 24.9729 43.6387 26.7729 42.3416L26.8629 42.2283C27.6729 41.6959 28.7979 41.8828 29.3716 42.6984C29.951 43.5141 29.7654 44.6469 28.9554 45.2303L28.8654 45.2926C25.5804 47.6205 21.1985 47.2467 18.3866 44.4203C15.2102 41.2201 15.2102 36.0318 18.3866 32.8373L21.5585 29.6428Z"
      fill="#50B277"
    />
  </svg>
);

export default BrokenLink;
