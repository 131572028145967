const Ki = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EEE"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#CE1126"
      d="M32 5H4C1.791 5 0 6.791 0 9v9h35.926L36 9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFAC33"
      d="M20.878 17.737c.277-.377.663-.375 1.124-.135-.064-.611-.793-.997-1.122-1.049.134-.45.565-.675 1.078-.608-.266-.553-1.146-.571-1.475-.504-.025-.471.306-.832.808-.948-.434-.427-1.267-.137-1.555.038-.18-.433.007-.887.442-1.171-.552-.251-1.239.311-1.449.576-.317-.344-.292-.836.021-1.253-.604-.045-1.059.725-1.167 1.046-.414-.212-.557-.681-.406-1.182-.58.169-.749 1.049-.741 1.388-.459-.055-.753-.447-.78-.971-.488.361-.351 1.246-.229 1.562-.451.109-.859-.159-1.06-.641-.337.509.09 1.293.311 1.547-.388.258-.86.15-1.212-.235-.146.596.52 1.185.814 1.347-.277.377-.444.654-.904.417.063.611.575.715.905.766l6.597.01z"
    />
    <path
      fill="#FFF100"
      d="M19.922 17.142l2.266-.823-2.404.012 1.85-1.563-2.257.848 1.213-2.112-1.836 1.581.429-2.406-1.193 2.123-.407-2.409-.407 2.409-1.192-2.123.429 2.406-1.835-1.581 1.212 2.112-2.257-.848 1.851 1.563-2.407-.012 2.268.823z"
    />
    <path
      fill="#FFF100"
      d="M17.583 19.521c1.292 0 2.339-1.064 2.339-2.377 0-1.313-1.047-2.377-2.339-2.377s-2.339 1.063-2.339 2.377c0 1.312 1.047 2.377 2.339 2.377z"
    />
    <path
      fill="#F15A29"
      d="M17.583 19.551c-1.307 0-2.369-1.08-2.369-2.407s1.062-2.406 2.369-2.406c1.306 0 2.369 1.079 2.369 2.406s-1.063 2.407-2.369 2.407zm0-4.754c-1.273 0-2.31 1.053-2.31 2.347s1.036 2.347 2.31 2.347c1.273 0 2.309-1.053 2.309-2.347s-1.036-2.347-2.309-2.347z"
    />
    <path
      fill="#FFAC33"
      d="M17.438 9.504c-.188-.253-.421-.522-.702-.649-.281-.126-.783-.205-1.325-.063-.607.158-1.331.403-1.901.237-.109-.031-.187.08.031.206.218.127.772.159 1.324.087.39-.05.905-.032 1.107.047.242.095.678.42.865.752l.601-.617zm1.231 1.378c.169-.067.553-.099.708-.073.204.034.486-.067.579-.109.168-.075-.011-.203-.663-.166-.429.023-.595.212-.624.348z"
    />
    <path
      fill="#FCD116"
      d="M21.094 8.491c-1.349-.063-1.692.214-2.346.269-.598.05-.974.232-1.278.586-.273.317-.544.605-.694.705-.018.012-.029.025-.044.038-.222-.016-.445-.105-.572-.228-.211-.205-.494-.229-.701-.02-.039.04-.086.104-.215.104-.222 0-.576.004-.581.376.117-.147.199-.171.577-.171.058 0 .129.028.187.127.086.146.276.261.468.265.19.004.271.133.397.269.367.396 1.185.657 1.949.364.395-.151.765-.666 1.435-.649.639.016.973-.19.421-.333-.554-.142-.976-.087-1.349-.015-.132.025-.265.006-.404-.026.054-.014.094-.045.107-.097.097.036.245-.023.277-.127.096.04.24-.016.277-.11.128.047.26.012.318-.103.085.051.233-.004.257-.123.086.056.219.048.25-.079.109.075.241.016.234-.111.05.024.154 0 .163-.079.202.008.459 0 .459-.15.172.008.413-.07.408-.256.12-.008.273-.102.309-.234.034-.111-.091-.182-.309-.192z"
    />
    <path fill="#EEE" d="M15 18h5v2h-5z" />
    <path
      fill="#00247D"
      d="M35.926 27.733c-.384.211-.818.313-1.562.313-1.817 0-1.817-.984-3.636-.984-1.818 0-1.818 1-3.693 1-1.762 0-1.762-1-3.608-1-1.789 0-1.789 1-3.607 1-1.818 0-1.818-1-3.637-1-1.818 0-1.818 1-3.636 1s-1.818-1-3.636-1-1.818 1-3.636 1-1.818-1-3.637-1c-.762 0-1.201.178-1.591.383C.269 29.441 1.943 31 4 31h28c1.958 0 3.581-1.408 3.926-3.267zm.074-2.35v-1.944c-.402.228-.846.374-1.637.374-1.817 0-1.817-1.007-3.636-1.007-1.818 0-1.818 1-3.693 1-1.762 0-1.762-1-3.608-1-1.789 0-1.789 1-3.607 1-1.818 0-1.818-1-3.637-1-1.818 0-1.818 1-3.636 1s-1.818-1-3.636-1-1.818 1-3.636 1-1.818-1-3.637-1c-.791 0-1.234.19-1.636.404v2c.402-.214.846-.404 1.636-.404 1.818 0 1.818 1 3.637 1 1.818 0 1.818-1 3.636-1s1.818 1 3.636 1 1.818-1 3.636-1 1.818 1 3.637 1c1.818 0 1.818-1 3.629-1 1.825 0 1.825 1 3.64 1 1.822 0 1.822-1 3.639-1 2.378 0 2.323.991 3.638.991.789 0 1.233-.207 1.635-.414zm0-4.257v-1.944c-.402.228-.846.365-1.637.365-1.817 0-1.817-.999-3.636-.999-1.818 0-1.818 1-3.693 1-1.762 0-1.762-1-3.608-1-1.789 0-1.789 1-3.607 1-1.818 0-1.818-1-3.637-1-1.818 0-1.818 1-3.636 1s-1.818-1-3.636-1-1.818 1-3.636 1-1.818-1-3.637-1c-.791 0-1.234.191-1.636.404v2c.402-.214.846-.404 1.636-.404 1.818 0 1.818 1 3.637 1 1.818 0 1.818-1 3.636-1s1.818 1 3.636 1 1.818-1 3.636-1 1.818 1 3.637 1c1.818 0 1.818-1 3.629-1 1.825 0 1.825 1 3.64 1 1.822 0 1.822-1 3.639-1 1.82 0 1.82.969 3.638.969.789.001 1.233-.184 1.635-.391z"
    />
    <path
      fill="#EEE"
      d="M36 19.125v-1.979c-.402.228-.846.43-1.637.43-1.817 0-1.817-1.063-3.636-1.063-1.818 0-1.818 1-3.693 1-1.762 0-1.762-1-3.608-1-1.789 0-1.789 1-3.607 1-1.818 0-1.818-1-3.637-1-1.818 0-1.818 1-3.636 1s-1.818-1-3.636-1-1.818 1-3.636 1-1.818-1-3.637-1c-.791 0-1.234.19-1.636.404v2c.402-.213.846-.404 1.636-.404 1.818 0 1.818 1 3.637 1 1.818 0 1.818-1 3.636-1s1.818 1 3.636 1 1.818-1 3.636-1 1.818 1 3.637 1c1.818 0 1.818-1 3.629-1 1.825 0 1.825 1 3.64 1 1.822 0 1.822-1 3.639-1 1.82 0 1.82.987 3.638.987.789 0 1.233-.168 1.635-.375z"
    />
  </svg>
);

export default Ki;
