const Ag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#FFF" d="M11.077 21L18 31l6.923-10z" />
    <path
      fill="#141414"
      d="M10.279 15l-.105-.022 3.988-.827-3.392-2.417 3.977.889-2.28-3.64 3.36 2.47-.821-4.308 2.232 3.675L18 6.5l.763 4.321 2.232-3.675-.821 4.308 3.36-2.47-2.28 3.64 3.977-.889-3.392 2.417 3.988.827-.106.021h3.356l5.956-8.603C34.299 5.543 33.214 5 32 5H4C2.786 5 1.701 5.543.967 6.397L6.923 15h3.356z"
    />
    <path
      fill="#FCD116"
      d="M25.826 14.978l-3.988-.827 3.392-2.417-3.976.889 2.28-3.64-3.36 2.47.821-4.308-2.232 3.676L18 6.5l-.763 4.321-2.232-3.676.821 4.308-3.36-2.47 2.28 3.64-3.976-.889 3.392 2.417-3.988.827.105.022h15.442z"
    />
    <path fill="#0072C6" d="M10.279 15H6.923l4.154 6h13.846l4.154-6h-3.356z" />
    <path
      fill="#CE1126"
      d="M29.077 15l-4.154 6L18 31h14c2.209 0 4-1.791 4-4V9c0-.995-.366-1.903-.967-2.603L29.077 15zM6.923 15L.967 6.397C.366 7.097 0 8.005 0 9v18c0 2.209 1.791 4 4 4h14l-6.923-10-4.154-6z"
    />
  </svg>
);

export default Ag;
