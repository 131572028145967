const Gq = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#E32118"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#3E9A00"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#0173CE"
      d="M7.333 13L1.91 5.604C.768 6.308 0 7.56 0 9v18c0 1.44.768 2.692 1.91 3.396L7.333 23 11 18l-3.667-5z"
    />
    <path d="M18.443 15h-2.396v4.75c0 .969.547 1.125.828 1.125h.922c.578 0 .646.479.646.479s.068-.479.646-.479h.922c.281 0 .828-.156.828-1.125V15h-2.396z" />
    <path
      fill="#FFF"
      d="M18.443 20.93c-.133-.141-.339-.255-.646-.255h-.922c-.188 0-.628-.09-.628-.925V15.2h4.392v4.55c0 .835-.439.925-.628.925h-.921c-.308 0-.514.114-.647.255z"
    />
    <path d="M14.922 18.703s.249.438.562.734c.578.547-.352.984-.352.984s-.289-.188-.383-.406c-.093-.218.235-.874.173-1.312z" />
    <path
      fill="#FFF"
      d="M15.148 20.185c-.085-.069-.179-.164-.215-.248-.015-.056.04-.273.077-.418.021-.078.041-.16.06-.243.082.104.176.213.276.308.143.135.129.22.125.248-.02.131-.189.27-.323.353z"
    />
    <path d="M15.202 19.73s.001.776.767 1.081c.943.376 1.594.22 1.672.251.078.031 0 .797 0 .797s-1.043.172-1.967-.266c-.924-.438-.877-.782-.829-.876.048-.094.11-.161.155-.405.042-.227.202-.582.202-.582z" />
    <path
      fill="#FFF"
      d="M17.134 21.692c-.357 0-.887-.048-1.374-.279-.668-.316-.75-.56-.735-.608.066-.115.122-.212.164-.414.128.229.343.462.706.607.452.181.913.271 1.37.271.082 0 .153-.002.211-.005.001.105-.007.262-.019.416-.082.007-.192.012-.323.012z"
    />
    <path d="M21.965 18.703s-.249.438-.562.734c-.578.547.352.984.352.984s.289-.188.383-.406c.092-.218-.236-.874-.173-1.312z" />
    <path
      fill="#FFF"
      d="M21.738 20.185c.085-.069.179-.164.215-.248.015-.056-.04-.273-.077-.418-.021-.078-.041-.16-.06-.243-.082.104-.176.213-.276.308-.143.135-.129.22-.125.248.02.131.189.27.323.353z"
    />
    <path d="M21.685 19.73s-.001.776-.767 1.081c-.943.376-1.594.22-1.672.251-.078.031 0 .797 0 .797s1.043.172 1.967-.266.877-.782.829-.876c-.049-.094-.11-.161-.155-.405-.042-.227-.202-.582-.202-.582z" />
    <path
      fill="#FFF"
      d="M19.753 21.692c.358 0 .887-.048 1.374-.279.668-.316.75-.56.735-.608-.066-.115-.122-.212-.164-.414-.128.229-.343.462-.706.607-.452.181-.913.271-1.37.271-.082 0-.153-.002-.211-.005-.001.105.007.262.02.416.081.007.191.012.322.012z"
    />
    <path d="M19.715 21.354s-.012.338-1.271.338c-1.26 0-1.271-.338-1.271-.338s-.047.538 0 .755.375.344 1.271.344c.896 0 1.225-.127 1.271-.344.047-.216 0-.755 0-.755z" />
    <path
      fill="#FFF"
      d="M18.443 22.253c-1.007 0-1.072-.167-1.076-.185-.015-.067-.018-.19-.016-.319.219.083.56.145 1.092.145.531 0 .873-.061 1.092-.145.002.13-.001.252-.016.319-.003.018-.069.185-1.076.185z"
    />
    <path
      fill="#FFD701"
      d="M17.854 13.476l.169.293h.339l-.17.293.17.294h-.339l-.169.293-.169-.293h-.339l.169-.294-.169-.293h.339zm-1.339.155l.23.248.33-.076-.099.324.231.248-.33.076-.099.324-.231-.248-.33.076.098-.324-.23-.248.33-.076zm-1.259.431l.273.201.31-.138-.037.338.274.199-.309.137-.036.337-.274-.2-.309.137.035-.336-.274-.2.31-.137zm3.862-.586l-.169.293h-.339l.17.293-.17.294h.339l.169.293.17-.293h.339l-.169-.294.169-.293h-.339zm1.34.155l-.23.248-.331-.076.1.324-.231.248.33.076.098.324.232-.248.33.076-.099-.324.231-.248-.33-.076zm1.259.431l-.274.201-.309-.138.037.338-.275.199.31.137.036.337.274-.2.309.137-.035-.336.273-.2-.309-.137z"
    />
    <path
      fill="#A36629"
      d="M18.443 20.625c-.6 0-.693-.219-.49-.688.203-.469.312-1.104.312-1.873 0-.768-.273-.94-.59-1.237-.316-.297.162-.188.384 0s.303.359.303.359v-.719l.248.078v.547s.36-.312.602-.344c.241-.031.382.156.069.25-.312.094-.671.255-.671 1.065 0 .81.124 1.654.28 1.951.157.298.153.611-.447.611z"
    />
    <path
      fill="#009A3B"
      d="M17.75 17.547c-.318.167-.543-.203-.543-.203s-.289.203-.561.062c-.271-.141-.229-.266 0-.391s.505-.156.505-.156.071-.344.194-.578c.123-.234.33-.328.33-.328s.252-.547.687-.578c.435-.031.435.422.435.422s.27 0 .322.156c.24-.25.662-.219.756-.109.094.109 0 .344 0 .344s.371-.109.451.016c.08.125 0 .286 0 .286s.299.058.158.386-.578.234-.578.234-.178.188-.37.172c-.192-.016-.255-.282-.255-.282s-.298.078-.484-.188c-.187.281-.531.125-.578.016-.047.36-.141.547-.469.719z"
    />
  </svg>
);

export default Gq;
