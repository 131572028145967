import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';
import getScreenSize from '@/lib/utils/getScreenSize';
import { Step } from './antd';
import * as S from './styles';

export type StepItem = {
  title: string;
  description: string;
};
interface StepsProps {
  current: number;
  items: StepItem[];
  onChange?: any;
}

const Steps: FC<StepsProps> = ({ current, items, ...others }) => {
  const { width } = getScreenSize();

  return (
    <S.Steps
      direction="horizontal"
      size={width <= 768 ? 'small' : 'default'}
      responsive={false}
      current={current}
      {...others}
    >
      {items.map(item => (
        <Step key={uuid()} title={item.title} description={item.description} />
      ))}
    </S.Steps>
  );
};
export default Steps;
