import { useEffect } from 'react';
import { notification } from './antd';

const Notification = ({ type, description, message, ...params }) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    api[type]({
      description,
      message,
      ...params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return contextHolder;
};

export default Notification;
