import styled from '@emotion/styled';
import { Checkbox as AntdCheckbox } from './antd';

export const Checkbox = styled(AntdCheckbox)`
  ${({ theme }) => {
    return `  
      &:hover {
        .ant-checkbox {
          &.ant-checkbox-checked {
            border-color: ${theme.primaryColor};
          }
          .ant-checkbox-inner {
            border-color: ${theme.primaryColor};
          }
        }
      }
      .ant-checkbox {
        border: ${({ status }) => status && '1px solid red'};
        border-radius: ${theme.borderRadius};
        &:hover,
        &:focus {
          .ant-checkbox-checked .ant-checkbox-inner {
            border-color: ${theme.primaryColor};
          }
          .ant-checkbox .ant-checkbox-inner {
            border-color: ${theme.primaryColor};
          }
        }
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: ${theme.primaryColor};
          background: ${theme.primaryColor};
        }
      } 
    `;
  }}
`;
