/**
 * The error type enum.
 */
enum ErrorType {
  UNKOWN = 0,
  JAVASCRIPT,
  SERVICE,
  API,
}

/**
 * The error type values.
 */
const ErrorTypeValues = Object.values(ErrorType).filter(
  v => typeof v === 'number',
) as Readonly<ErrorType[]>;

export { ErrorType, ErrorTypeValues };
