import { FC } from 'react';
import * as S from './styles';

interface CardProps {
  children: any;
  style?: any;
}

const Card: FC<CardProps> = ({ children, ...others }) => {
  return <S.Card {...others}>{children}</S.Card>;
};

export default Card;
