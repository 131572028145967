const Ke = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#060"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#BB1600" d="M0 13h36v10H0z" />
    <path
      fill="#141414"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path fill="#EEE" d="M0 13h36v1H0zm0 9h36v1H0z" />
    <path
      fill="#141414"
      d="M23.054 9.404c-.066-.039-.186.089-.794.764-.216.24-.486.539-.785.86-.608.653-1.244 1.461-.783 1.935l-7.265 12.211c-.011.018-.019.047.003.087.046.083.199.175.294.177h.003c.046 0 .068-.021.079-.039l7.268-12.215c.626.148 1.024-.784 1.305-1.616.14-.417.274-.796.381-1.1.302-.856.356-1.027.294-1.064z"
    />
    <path
      fill="#FFF"
      d="M22.305 10.208c-.216.24-.486.539-.786.861-.886.952-1.124 1.528-.769 1.868l.018.016-7.29 12.252c-.004.008.001.021.005.027.035.063.169.144.242.145h.002c.01 0 .023-.001.028-.01l7.279-12.234.012-.02.022.006c.458.13.846-.355 1.254-1.572.14-.417.274-.796.381-1.101.168-.475.314-.889.314-.984-.082.046-.375.372-.712.746z"
    />
    <path
      fill="#141414"
      d="M15.308 12.963c.461-.474-.174-1.282-.783-1.935-.299-.322-.569-.62-.785-.86-.608-.674-.728-.803-.794-.764-.062.038-.008.208.293 1.063.107.304.241.683.381 1.1.28.833.678 1.764 1.305 1.616l7.268 12.215c.011.018.033.039.079.039h.003c.095-.002.248-.094.294-.177.021-.04.014-.069.003-.087l-7.264-12.21z"
    />
    <path
      fill="#FFF"
      d="M15.25 12.937c.355-.34.118-.916-.769-1.868-.3-.322-.569-.621-.786-.861-.337-.374-.631-.7-.714-.745 0 .095.146.509.314.984.107.305.242.684.381 1.101.409 1.217.796 1.702 1.254 1.572l.022-.006.012.02 7.279 12.234c.005.009.019.01.028.01h.002c.073-.001.207-.081.242-.145.004-.007.009-.02.005-.027l-7.29-12.252.02-.017z"
    />
    <path
      fill="#141414"
      d="M18.018 10.458L18 10.444l-.018.014c-2.492 1.87-3.704 4.331-3.704 7.523s1.211 5.653 3.704 7.524l.018.013.018-.013c2.492-1.87 3.704-4.331 3.704-7.524s-1.212-5.655-3.704-7.523z"
    />
    <path
      fill="#BB1600"
      d="M20.879 14.059c-.603-1.363-1.551-2.54-2.88-3.54-1.326.999-2.273 2.174-2.877 3.533.525 1.181.782 2.468.782 3.937 0 1.467-.256 2.751-.779 3.928.604 1.356 1.55 2.529 2.873 3.527 1.326-.999 2.273-2.174 2.876-3.534-.521-1.178-.776-2.461-.776-3.921.002-1.462.258-2.747.781-3.93z"
    />
    <path
      d="M18 18.927c.306 0 .555-.424.555-.946s-.249-.947-.555-.947c-.306 0-.554.424-.554.947-.001.522.248.946.554.946zm-.231-2.497c-.502-.739-.746-1.677-.746-2.821 0-1.145.244-2.083.746-2.823v5.644zm.462 0c.501-.739.744-1.677.744-2.821 0-1.145-.243-2.083-.744-2.823v5.644zm-.462 3.1c-.502.738-.746 1.677-.746 2.821 0 1.146.244 2.082.746 2.822V19.53zm.462 0c.501.738.744 1.677.744 2.821 0 1.146-.243 2.082-.744 2.822V19.53z"
      fill="#FFF"
    />
  </svg>
);

export default Ke;
