const Mm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EA2839"
      d="M0 27c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-4H0v4z"
    />
    <path fill="#34B232" d="M0 13h36v10H0z" />
    <path
      fill="#FECB01"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFF"
      d="M18 8.76L20.353 16h7.613l-6.159 4.333 2.352 7.169L18 22.992l-6.159 4.458 2.353-7.107L8.035 16h7.613z"
    />
  </svg>
);

export default Mm;
