const Bo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#007934"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#FFE000" d="M0 13h36v10H0z" />
    <path
      fill="#D52B1E"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFF"
      d="M12.894 16.335c.041.062.381.55.381.55h.489l-.2-.275-.67-.275zm.733-.488c.041.061.381.55.381.55h.489l-.2-.275-.67-.275zm.682-.614c.019.071.185.643.185.643l.462.157-.1-.324-.547-.476z"
    />
    <path
      fill="#A05A2D"
      d="M13.953 21.498c-.04.021-.089.005-.109-.036-.021-.04-.004-.09.035-.109l2.467-1.26c.04-.021.089-.005.109.034.021.041.004.09-.035.11l-2.467 1.261zm.494.456c-.038.024-.088.013-.113-.024-.024-.036-.015-.088.023-.113l2.31-1.527c.038-.025.088-.016.113.022.025.038.015.087-.023.112l-2.31 1.53z"
    />
    <path
      fill="#A05A2D"
      d="M14.947 22.377c-.03.033-.082.037-.115.008-.034-.03-.037-.082-.007-.116l1.831-2.078c.03-.034.081-.036.115-.007.034.029.037.08.007.114l-1.831 2.079z"
    />
    <path
      fill="#A05A2D"
      d="M17.348 20.715l-.57 1.181-.102-.104-.222.307-.603-.328.749-1.464z"
    />
    <path
      fill="#D52B1E"
      d="M17.593 22.525L18 20.592l-.042-1.691-2.931-2.8-.204.642-.407-.326-.122.672s-.51-.366-.53-.305c-.02.061-.143 1.548-.184 2.383-.04.834-.074 2.076.835 2.24 1.588.285 2.769-.896 2.769-.896s.387.367.407.428-.143.773-.204 1.059c-.059.283.206.527.206.527z"
    />
    <path
      fill="#FFE000"
      d="M15.555 16.644l-.162.465-.265-.143s-.675 3.401.426 3.666c1.101.265 1.224-.184 1.224-.184l-.53-.712-.397.183s-.193-.526-.193-1.24c0-.637.102-1.265.102-1.265l.202.142.042-.468-.449-.444z"
    />
    <path
      fill="#007934"
      d="M16.676 17.659l-.672-.571-.042.468-.263-.183s-.146.489-.144 1.12c.002.631.083 1.243.083 1.243s.468.162.712 0c.245-.163.326-2.077.326-2.077z"
    />
    <path
      fill="#E8A30F"
      d="M16.454 19.573c-.529.227-1.611.635-1.326 1.142.286.508 1.12.305 1.65-.267.53-.57-.324-.875-.324-.875z"
    />
    <path
      fill="#FFF"
      d="M23.105 16.335c-.04.062-.381.55-.381.55h-.489l.199-.275.671-.275zm-.733-.488c-.04.061-.38.55-.38.55h-.489l.199-.275.67-.275zm-.681-.614l-.185.643-.463.157.101-.324.547-.476z"
    />
    <path
      fill="#A05A2D"
      d="M22.047 21.498c.04.021.09.005.109-.036.021-.04.005-.09-.035-.109l-2.466-1.26c-.041-.021-.089-.005-.11.034-.021.041-.005.09.036.11l2.466 1.261zm-.494.456c.038.024.089.013.113-.024.025-.036.015-.088-.023-.113l-2.309-1.527c-.037-.025-.088-.016-.112.022-.025.038-.015.087.022.112l2.309 1.53z"
    />
    <path
      fill="#A05A2D"
      d="M21.054 22.377c.029.033.081.037.114.008.034-.03.037-.082.008-.116l-1.831-2.078c-.028-.034-.08-.036-.114-.007-.034.029-.037.08-.008.114l1.831 2.079z"
    />
    <path
      fill="#A05A2D"
      d="M18.652 20.715l.57 1.181.102-.104.222.307.603-.328-.749-1.464z"
    />
    <path
      fill="#D52B1E"
      d="M18.407 22.525L18 20.592l.042-1.691 2.931-2.8.205.642.407-.326.122.672s.508-.366.528-.305c.021.061.144 1.548.184 2.383.041.834.075 2.076-.834 2.24-1.589.285-2.77-.896-2.77-.896s-.387.367-.407.428.143.773.204 1.059c.06.283-.205.527-.205.527z"
    />
    <path
      fill="#FFE000"
      d="M20.445 16.644l.161.465.265-.143s.676 3.401-.426 3.666c-1.101.265-1.224-.184-1.224-.184l.529-.712.398.183s.192-.526.192-1.24c0-.637-.102-1.265-.102-1.265l-.202.142-.042-.468.451-.444z"
    />
    <path
      fill="#007934"
      d="M19.324 17.659l.672-.571.042.468.263-.183s.146.489.144 1.12c-.002.631-.083 1.243-.083 1.243s-.469.162-.713 0c-.245-.163-.325-2.077-.325-2.077z"
    />
    <path
      fill="#E8A30F"
      d="M19.546 19.573c.529.227 1.61.635 1.325 1.142-.285.508-1.12.305-1.649-.267-.53-.57.324-.875.324-.875z"
    />
    <path
      fill="#D52B1E"
      d="M16.331 15.825c.171.202.163.489-.018.642-.18.153-.464.113-.635-.089-.17-.202-.163-.489.017-.642.18-.153.465-.113.636.089z"
    />
    <path
      fill="#E7E7E7"
      d="M19.75 15.825c-.17.202-.162.489.019.642.181.153.464.113.636-.089.171-.202.163-.489-.018-.642-.181-.153-.465-.113-.637.089z"
    />
    <path
      fill="#007934"
      d="M19.1 13.484c-.143.102.265.753.184 1.466-.083.713-1.284 1.283-1.284 1.283s-1.202-.57-1.283-1.283c-.082-.712.326-1.364.183-1.466-.143-.102-.896.55-.936 1.242-.041.692.66 1.735 1.12 1.935.46.2.917.305.917.305s.456-.106.917-.305c.461-.2 1.162-1.243 1.12-1.935-.042-.692-.796-1.344-.938-1.242z"
    />
    <path
      fill="#FFF"
      d="M18 16.213s.122-.428.917-.672c.794-.245 1.547-.428 1.833-.448.286-.021-1.13-.57-1.466-.611-.336-.04-.753.041-.815.286-.061.244-.163.163-.468.204-.306.04-.509-.245-.652-.448-.142-.204-.683-.102-.947-.081-.265.02-1.273.651-1.273.651s1.752.285 2.118.468c.366.184.02.835-.081 1.019-.103.182.605-.057.834-.368z"
    />
    <path d="M18 16.213s.122-.428.917-.672c.794-.245 1.547-.428 1.833-.448.286-.021-1.038-.428-1.375-.469-.336-.04-.6-.04-.661.204-.061.244-.407.102-.713.143-.306.04-.652-.061-.794-.265-.143-.204-.387-.122-.652-.102-.264.02-1.425.489-1.425.489s1.752.285 2.118.468c.366.184.02.835-.081 1.019-.104.183.604-.056.833-.367z" />
    <path
      fill="#00A6DE"
      d="M15.821 18.656c0-1.316.976-2.382 2.179-2.382 1.204 0 2.179 1.067 2.179 2.382 0 1.316-.975 2.383-2.179 2.383-1.203 0-2.179-1.066-2.179-2.383z"
    />
    <path
      fill="#E8A30F"
      d="M16.401 18.656c0-1.034.716-1.873 1.599-1.873s1.599.839 1.599 1.873c0 1.036-.716 1.874-1.599 1.874s-1.599-.838-1.599-1.874z"
    />
    <path
      fill="#D52B1E"
      d="M17.308 17.842c0-.382.31-.692.692-.692s.692.311.692.692c0 .383-.31.693-.692.693s-.692-.31-.692-.693z"
    />
    <path
      fill="#007934"
      d="M18 20.53c.849 0 1.544-.775 1.596-1.755-.521-.193-1.382-.933-1.596-.933-.224 0-.509.245-.875.57-.188.168-.48.303-.718.395.065.964.753 1.723 1.593 1.723z"
    />
    <path
      fill="#E8A30F"
      d="M18.204 15.133c0 .102-.21.184-.468.184-.259 0-.469-.082-.469-.184 0-.101.21-.183.469-.183.258 0 .468.083.468.183z"
    />
    <path
      fill="#FFF"
      d="M17.185 19.573h.489l.142-.407.184.223-.204.591h-.448z"
    />
    <path
      fill="#B37D0A"
      d="M18.427 19.919c0 .191-.095.347-.213.347S18 20.111 18 19.919c0-.191.096-.346.214-.346s.213.155.213.346zm.306.347c0 .024-.019.041-.041.041-.022 0-.041-.017-.041-.041v-1.059c0-.022.018-.041.041-.041.022 0 .041.019.041.041v1.059z"
    />
    <path
      fill="#32B266"
      d="M19.038 19.104c0 .18-.159.326-.356.326-.197 0-.356-.146-.356-.326 0-.18.16-.326.356-.326.197.001.356.146.356.326z"
    />
  </svg>
);

export default Bo;
