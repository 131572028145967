import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DatePicker as AntdDatePicker } from './antd';

export const Datepicker = styled(AntdDatePicker)`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      font-size: ${theme.fontSizeP};
      margin-top: 4px;
      padding: ${theme.paddingInput};
      width: 100%;
      .ant-picker-input > input {
        color: ${theme.colorTextInput};
        font-size: ${theme.fontSizeP};
      }
      &:hover,
      &:active,
      &:focus {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
      &.ant-picker-focused {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
    `;
  }}
`;

export const Panel = styled.div`
  ${({ theme }) => {
    return css`
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: ${theme.primaryColor};
          border-radius: 4px;
        }
      }
      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border: 1px solid ${theme.primaryColor};
            border-radius: 4px;
          }
        }
      }
      .ant-picker-today-btn {
        color: ${theme.primaryColor};
      }
    `;
  }}
`;
