const Br = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#009B3A"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#FEDF01" d="M32.728 18L18 29.124 3.272 18 18 6.875z" />
    <circle fill="#002776" cx="17.976" cy="17.924" r="6.458" />
    <path
      fill="#CBE9D4"
      d="M12.277 14.887c-.332.621-.558 1.303-.672 2.023 3.995-.29 9.417 1.891 11.744 4.595.402-.604.7-1.28.883-2.004-2.872-2.808-7.917-4.63-11.955-4.614z"
    />
    <path fill="#88C9F9" d="M12 18.233h1v1h-1zm1 2h1v1h-1z" />
    <path
      fill="#55ACEE"
      d="M15 18.233h1v1h-1zm2 1h1v1h-1zm4 2h1v1h-1zm-3 1h1v1h-1zm3-6h1v1h-1z"
    />
    <path fill="#3B88C3" d="M19 20.233h1v1h-1z" />
  </svg>
);

export default Br;
