const Si = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#ED1C23"
      d="M36 27v-4H0v4c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4z"
    />
    <path
      fill="#EEE"
      d="M36 23H0V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v14z"
    />
    <path fill="#005DA4" d="M0 13h36v10H0z" />
    <path
      fill="#ED1C23"
      d="M11.125 7.917c-2.25 0-3.833.833-3.833.833s.146 2 .333 5.083 3.5 4.167 3.5 4.167 3.312-1.083 3.5-4.167.333-5.083.333-5.083-1.583-.833-3.833-.833z"
    />
    <path
      fill="#004A77"
      d="M14.592 8.586c-.588-.242-1.849-.67-3.467-.67s-2.879.428-3.467.67c.011.21.137 2.503.299 5.164.17 2.791 3.167 3.771 3.167 3.771s2.998-.98 3.167-3.771c.164-2.66.29-4.954.301-5.164z"
    />
    <path
      fill="#FFF"
      d="M12.104 15.92c-.354 0-.521.211-1.042.211s-.604-.211-.958-.211c-.268 0-.434.12-.639.179.812.61 1.66.86 1.66.86.711-.118 1.27-.466 1.693-.859-.269-.059-.445-.18-.714-.18zm-1.958-1.383c.333 0 .625.26.979.26s.604-.26.979-.26c.321 0 .743.419 1.36.179.278-.469.411-.841.411-.841l-1.25-1.792-.625.759-.875-1.675-.833 1.675-.604-.799s-.542.643-1.438 1.851c.107.286.251.534.407.766.709.35 1.187-.123 1.489-.123zm2.958.755c-.458 0-.646-.26-1-.26s-.521.26-1.042.26-.604-.26-.958-.26-.53.26-.854.26c-.117 0-.248-.036-.373-.085.127.168.252.341.39.484.386-.035.673-.273.879-.273.333 0 .625.278.979.278s.604-.278.979-.278c.231 0 .516.235.887.271.109-.142.205-.283.293-.423-.058.009-.113.026-.18.026z"
    />
    <path
      fill="#FD0"
      d="M10.318 8.807l.217.233-.309-.072-.094.304-.092-.304-.311.072.218-.233-.218-.233.311.072.092-.304.094.304.309-.072zm2.094 0l.217.233-.31-.072-.093.304-.093-.304-.31.072.217-.233-.217-.233.31.072.093-.304.093.304.31-.072zm-1.084 1.396l.216.233-.309-.072-.093.303-.093-.303-.31.072.217-.233-.217-.233.31.072.093-.304.093.304.309-.072z"
    />
  </svg>
);

export default Si;
