import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Steps as AntdSteps } from './antd';

export const Steps = styled(AntdSteps)`
  ${({ theme }) => {
    return css`
      .ant-steps-item {
        &:not(.ant-steps-item-active) {
          &.ant-steps-item- {
            &wait {
              &:not(.ant-steps-item-finish) {
                .ant-steps-item-container {
                  &:hover {
                    cursor: auto;
                    .ant-steps-item-icon {
                      border-color: rgba(0, 0, 0, 0.25);
                      .ant-steps-icon {
                        color: rgba(0, 0, 0, 0.25);
                      }
                    }
                    .ant-steps-item-content {
                      .ant-steps-item-title {
                        color: rgba(0, 0, 0, 0.45);
                      }
                    }
                  }
                }
              }
            }
            &finish {
              &:not(.ant-steps-item-process) {
                .ant-steps-item-container {
                  &:hover {
                    cursor: pointer;
                    .ant-steps-item-icon {
                      border-color: ${theme.primaryGreen};
                      .ant-steps-icon {
                        color: ${theme.primaryGreen};
                      }
                    }
                    .ant-steps-item-content {
                      .ant-steps-item-title {
                        color: ${theme.primaryGreen};
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-steps-item-wait .ant-steps-item-icon,
      .ant-steps-item-finish .ant-steps-item-icon {
        background-color: transparent;
      }

      .ant-steps-item-active {
        font-weight: 600;
      }

      .ant-steps-item-active {
        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-icon,
        .ant-steps-item-icon {
          background-color: ${theme.primaryGreen};
        }
      }

      .ant-steps-item-process .ant-steps-item-icon {
        border-color: ${theme.primaryGreen};
      }

      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after,
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: ${theme.gray6};
      }

      .ant-steps-item-finish .ant-steps-item-icon {
        border-color: ${theme.primaryGreen};
        .ant-steps-icon {
          color: ${theme.primaryGreen};
        }
      }

      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: ${theme.primaryGreen};
      }
    `;
  }}
`;
