import { forwardRef, FC } from 'react';
import { Text } from '@/core';
import * as S from './styles';

interface InputProps {
  children?: string;
  disabled?: boolean;
  error?: any;
  mask?: string;
  placeholder?: string;
  prefix?: any;
  status?: string;
  type?: string;
  value?: string;
}

const Input = forwardRef<FC, InputProps>(
  ({ type = null, children = '', error = null, ...others }, ref) => {
    const { mask } = others;
    switch (type) {
      case 'password':
        return (
          <>
            <S.Password ref={ref} {...others} />
            {error && <Text textType="error">{error}</Text>}
          </>
        );
      case 'mask':
        return (
          <>
            <S.MaskInput ref={ref} mask={mask} {...others} />
            {error && <Text textType="error">{error}</Text>}
          </>
        );
      case 'search':
        return (
          <>
            <S.Search ref={ref} {...others} />
            {error && <Text textType="error">{error}</Text>}
          </>
        );
      case 'textarea':
        return (
          <>
            <S.Textarea ref={ref} {...others} />
            {error && <Text textType="error">{error}</Text>}
          </>
        );
      case 'group':
        return <S.Group {...others}>{children}</S.Group>;
      default:
        return (
          <>
            <S.Input ref={ref} {...others} />
            {error && <Text textType="error">{error}</Text>}
          </>
        );
    }
  },
);

export default Input;
