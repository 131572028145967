import { useTranslation } from 'react-i18next';

const getSteps = () => {
  const { t } = useTranslation();

  return new Array(
    { title: t('step1.title'), description: '' },
    { title: t('step2.title'), description: '' },
    { title: t('step3.title'), description: '' },
  );
};

export { getSteps };
