import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List as AntdList } from './antd';

export const List = styled(AntdList)`
  ${({ theme }) => {
    return css`
      .ant-list-item-meta-description {
        color: ${theme.colorText};
        font-size: ${theme.fontSizeP};
      }
    `;
  }}
`;
