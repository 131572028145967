const Aq = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#265FB5"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#FFF"
      d="M27.532 18.933c-.051-.525.801-1.828.117-1.734-.837.114-1.491-.462-1.774-.448.906-1.281.233-1.607.031-2.438-.356-1.458.781-2.172-1.25-2.484-1.321-.203-2.233-.066-2.63-1.575-.583.409-1.275.511-1.932.224-.42-.183-.551-.532-.946-.701-.299-.127-.925-.126-1.26-.179-.923-.146-1.399.264-2.227.127.079.12.091.274.146.402-.511.018-.821.36-.876.837-.747-.075-.937.898-.853 1.512L14 12.5l.031.031c-.157 1.625-.818 2.438-2.483 2.693-1.096.168-2.07-.56-3.017-1.146-.208-.128-.571-.409-.766-.625-.28-.311-.478-.747-.75-.969-.125-.102-.391-.188-.354.021-.172 1.078.615 2.421 1.522 2.939-1.242.573.314.916.537 1.111.005.004.54.74.544.768.085.526-.278.466-.316.887-.039.437-.039.787.107 1.222l-.031.031c.006.35-.259.225-.603.146.047 1.062 1.059 1.154 1.228 1.362.545.669.357 1.642.993 2.265 1.564 1.532 3.346.628 5.117.885.994.145 1.846.979 2.747.037 1.059 1.16-.815 2.535-.357 2.927.131.112.269.159.41.167-.026.071-.067.136-.085.211 1.272.12 2.612.424 3.802-.202-.002.191.126.423.133.524.292-.349.52-.329.892-.515.466-.232 1.286-.511 1.595-.976.368-.554-.21-1.319.948-1.082.09-.4.127-.358.34-.624-.319-.8.629-1.34.914-1.912.057-.116.061-.652.137-.854.144-.385.556-.599.67-1.081.208-.887-.299-1.04-.373-1.808z"
    />
  </svg>
);

export default Aq;
