const Ls = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#009543"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#00209F"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#292F33"
        d="M21.199 19.859c-.015-.047-.426.141-.426.141l-1.846-2.362c.226-.405.49-1.073.49-1.904 0-1.241-.658-1.567-1.077-1.654v-.055c0-.172-.139-.311-.311-.311h-.059c-.172 0-.311.139-.311.311v.056c-.419.087-1.076.413-1.076 1.654 0 .848.275 1.528.504 1.93L15.328 20s-.359-.188-.375-.141c-.016.047-.75 1.219-.75 1.219s1.562 1.02 3.797 1.02c2 0 3.873-1.02 3.873-1.02s-.658-1.172-.674-1.219zm-2.105-4.078c0 .554-.221 1.157-.424 1.529l-.352-.45c.004-.02.022-.033.022-.054v-.728h.488l-.488-.488v-1.121c.494.174.754.719.754 1.312zm-2.188 0c0-.594.26-1.138.753-1.312v1.121l-.488.488h.488v.728c0 .025.021.041.026.064l-.345.458c-.204-.37-.434-.982-.434-1.547z"
      />
      <path
        fill="#53646D"
        d="M20.797 20.344s-1.422.526-2.797.526-2.797-.526-2.797-.526l-.344.649s1.453.649 3.141.649 3.141-.649 3.141-.649l-.344-.649z"
      />
    </g>
  </svg>
);

export default Ls;
