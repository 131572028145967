const Bm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CF1B2B"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path d="M22.583 12.585v9.067c0 .639.146 1.515 1.312 2.057.993.462 2.473.993 3.146 1.419.377.236.959.638.959.638s.582-.401.958-.638c.674-.426 2.049-.957 3.042-1.419 1.167-.542 1.312-1.418 1.312-2.057v-9.067H22.583z" />
    <path
      fill="#2E8F22"
      d="M23.108 21.453c.007.594.16 1.484 1.227 1.98.93.432 2.137.929 2.768 1.328.352.222.897.597.897.597s.545-.375.896-.597c.631-.399 1.842-.896 2.771-1.328 1.067-.496 1.221-1.387 1.228-1.98h-9.787z"
    />
    <path fill="#FFF" d="M23.108 13.106h9.787v8.439h-9.787z" />
    <path
      fill="#CF1B2B"
      d="M31.862 20.376c-.07-.39 0-.993-.106-1.171-.106-.177-1.099-.531-1.809-.461-.708.071-.213-.886.284-1.134.497-.249.958-.462 1.028-.745.071-.284-.178-.497-.603-.426-.426.071-.674.071-.923.319-.248.248-.283-.035-.39-.319-.105-.283.07-.39.107-.674.035-.283-.036-1.064-.249-1.383-.212-.319-.103-.531-.457-.709-.355-.178-.742-.319-.742-.319s-.39.142-.745.319c-.354.178-.248.39-.46.709-.213.319-.284 1.1-.249 1.383.036.284.212.391.107.674-.107.284-.142.567-.39.319-.249-.248-.497-.248-.923-.319-.425-.071-.674.142-.603.426.07.283.531.496 1.028.745.497.248.992 1.205.284 1.134-.71-.07-1.702.284-1.809.461-.106.178-.036.781-.106 1.171-.071.39-.248.709-.071.638.178-.07.993-.354 1.384-.638.39-.284.851 1.135.674 1.489-.179.354-.213.603-.497.461-.284-.142-.709.071-.851.355-.142.283.177.46.532.708.354.249.779-.248.957-.496.177-.249 1.737-1.028 1.737-1.028s1.558.779 1.734 1.028c.178.248.602.745.957.496.354-.248.673-.425.53-.708-.142-.284-.567-.497-.851-.355-.284.142-.319-.107-.498-.461-.176-.354.284-1.773.674-1.489.391.284 1.206.567 1.384.638.179.071.003-.248-.069-.638z"
    />
    <path
      fill="#FFE000"
      d="M30.866 18.39s.107.248.248.248.391-.318.178-.566c-.212-.249-.391-.355-.283-.567.105-.213-.178-.568-.178-.568-.142.212-.532.532-1.206.106-.674-.425-1.206-.497-1.312-.248h-.627c-.105-.248-.638-.177-1.312.248s-1.065.106-1.206-.106c0 0-.283.355-.178.568.107.212-.071.318-.283.567-.213.248.036.566.178.566s.248-.248.248-.248.46.39.426.922c-.036.531-.745 1.027-.745 1.027.213.248.213.604.426 1.241.212.639.957.461.957.461.036.391.71.781.957.781.249 0 .319.212.319.212s-.283.178-.142.32c.133.132.609-.047.668-.069.059.022.535.201.668.069.142-.143-.142-.32-.142-.32s.07-.212.319-.212c.247 0 .921-.391.957-.781 0 0 .745.178.957-.461.213-.638.213-.993.426-1.241 0 0-.709-.496-.745-1.027-.033-.532.427-.922.427-.922z"
    />
    <path
      fill="#64B4D1"
      d="M31.298 18.106c-.213-.248-.39-.355-.284-.567.107-.213-.142-.354-.142-.354-.142.213-.734.516-1.24.213-.532-.32-.674-.249-.816-.32 0 0 .087.247-.106.32-.283.106-.391-.143-.391-.143h-.637s-.107.249-.391.143c-.193-.073-.106-.32-.106-.32-.143.071-.284 0-.816.32-.506.303-1.099 0-1.24-.213 0 0-.249.142-.142.354.106.212-.071.319-.284.567-.213.248.106.035.248.035.142 0 .497.249.497.249s.39.284.354.958c-.028.532-.816 1.062-.816 1.062.212.25.425 1.136.425 1.136.213.462 4.816-1.666 4.787-2.198-.035-.674.354-.958.354-.958s.355-.249.497-.249c.142.001.462.213.249-.035z"
    />
    <path
      fill="#CCC"
      d="M30.198 19.348c-.005-.094-.001-.18.008-.258-.337.02-.667-.158-.788-.31-.142-.177-.318-.177-.638 0-.319.178-.319.673-.319.673v.072l1.95 1.275.562-.336c.014-.02.026-.037.041-.055 0 .001-.787-.529-.816-1.061z"
    />
    <path
      fill="#E4CB5E"
      d="M25.66 17.894l.248 2.624 1.134.851 1.171-.958-.248-.744-.142-.957-.426-.284-.355-.709-.46-.036-.248.177s-.213-.142-.213-.106c0 .036-.178.213-.178.213l-.283-.071z"
    />
    <path d="M27.645 18l.426-.106.603 1.064.602-.071.107.283-1.631.532v-.426l.071-.141z" />
    <path d="M26.511 18.354l.177 2.554.178-.107s-.248-2.092-.213-2.057c.035.036.532 1.809.532 1.809l.213.071-.603-2.199.957 1.667.177-.426-.992-1.347-.284-.354-.142.389z" />
    <path
      fill="#9F5A2B"
      d="M27.716 19.453l1.597-.354.709 1.063-.284.815s-1.206.603-1.24.639c-.036.035-1.171-.107-1.171-.107l.389-2.056z"
    />
    <path
      fill="#CCC"
      d="M29.56 21.724s.071-.213.284-.178c.212.035.142.283.142.283s.39.179.603-.283c.015-.021.026-.044.035-.07.128-.384.178-.815.39-1.065 0 0-.02-.012-.053-.037-.188.076-.337.18-.337.18s0-.318-.354-.39c-.354-.071-.709.461-.709.461-.142-.425-.71 0-.958.39-.247.391-.851.284-.851.284l-.108-.213s.037-.354-.247-.532c-.284-.177-.71.248-.71.248.107-.461-.39-.391-.495-.318-.107.07-.284-.178-.532-.248-.186-.053-.352.35-.438.689.051.178.094.37.154.552.009.026.021.05.035.07.213.462.603.283.603.283s-.07-.248.142-.283c.213-.035.284.178.284.178s.248-.071.319.142c.07.213-.248.284-.248.284-.035.07.177.39.915.555.141.1.219.294.219.294v.318h.71V23s.077-.194.219-.296c.738-.165.95-.484.915-.555 0 0-.318-.071-.248-.284.071-.213.319-.141.319-.141z"
    />
    <path
      fill="#FFF"
      d="M27.503 14.064l.356.177-.071.142-.354-.071.069-.248zm.958 0l-.354.177.07.142.354-.071-.07-.248z"
    />
    <path d="M28.745 14.524l.07-.248-.318.32-.142.177-.178-.177-.177.177-.177-.177-.178.177-.142-.177-.318-.32.07.248-.355.249.32-.142.177.071.177.177.249-.07.177.212.177-.212.249.07.177-.177.177-.071.32.142z" />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Bm;
