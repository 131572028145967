const Cx = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#0021AD"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#1B8A42"
      d="M34.618 30.002C35.458 29.269 36 28.202 36 27V9c0-2.209-1.791-4-4-4H4c-1.006 0-1.915.384-2.618.998l33.236 24.004z"
    />
    <path
      d="M8.52 22.262l.169.584h.587l-.464.357.171.567-.463-.358-.464.342.17-.551-.464-.357h.572zm-1.532-6.504l-.678.627.067-.921-.914-.139.763-.523-.46-.801.883.27.339-.859.339.859.884-.27-.46.801.762.522-.913.14.067.921zM3.365 21.07l-.678.627.067-.921-.914-.139.763-.522-.46-.802.883.271.339-.859.339.859.884-.271-.46.802.762.522-.913.139.067.921zm7.111-.438l-.679.627.067-.921-.914-.14.763-.522-.46-.801.884.27.339-.859.338.859.884-.27-.46.801.762.521-.913.141.067.921zm-3.547 7.741L6.25 29l.067-.922-.914-.138.763-.524-.46-.801.884.27.339-.86.339.86.883-.27-.46.801.762.522-.913.14.067.922z"
      fill="#FFF"
    />
    <path
      fill="#FFC639"
      d="M14.437 18c0-1.968 1.595-3.563 3.563-3.563s3.563 1.595 3.563 3.563-1.595 3.563-3.563 3.563-3.563-1.595-3.563-3.563z"
    />
    <path
      fill="#1B8A42"
      d="M15.862 16.359s1.085 1.006 1.643 1.049c.91.07 1.777-.688 1.777-1.247l.855-.361v1.12l.815.559-1.527 1.399v1.679l-.914-.222-.066-1.166s-.041-.443-.6-.443c-.56 0-.998-.035-1.114-.338-.032-.083-1.513.21-1.513.21l.959-1.337-.315-.341v-.561z"
    />
    <path
      fill="#FFC639"
      d="M29.617 6.518c.232-.182 2.409 1.969 2.668 2.384.259.415.156 1.969.415 2.669s.491.856.751.7c.259-.156 1.167 0 1.296.337 0 0-.726.441-.777.752 0 0-2.306.803-3.628 1.477-1.321.674-.979.328-2.072.804-1.607.699-3.059.362-4.12.207-1.064-.155-.753-.389 0-.284.751.104 3.004-.13 3.601-.337.596-.207 1.763-1.14 2.255-1.373.492-.233 1.114-1.088.57-1.607-.546-.521-2.178-1.247-2.826-1.947-.648-.699-.647-.803.363-.647 1.011.156 1.898.689 2.383 1.173.52.519.981-.145.669-.975-.311-.83-.745-.743-1.031-1.495-.284-.751-.751-1.657-.517-1.838z"
    />
  </svg>
);

export default Cx;
