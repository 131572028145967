const Vg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFF"
      d="M33.466 22.644c-.281-.184-.694-.577-.766-.693-.282-.469-.605-.566-.827-.566-.027 0-.058.005-.089.008.106-.177.175-.352.175-.517v-9.229H21.875v9.229c0 .165.069.339.175.517-.031-.004-.062-.008-.089-.008-.222 0-.545.098-.827.566-.071.116-.484.51-.766.693-.231.149-.367.389-.384.672-.023.399.206.861.586 1.178.155.13.34.197.533.197.26 0 .482-.119.657-.262 1.661 1.372 3.353 2.04 5.156 2.04s3.495-.668 5.156-2.04c.175.143.398.262.657.262.193 0 .378-.067.533-.197.38-.316.609-.778.586-1.178-.015-.284-.152-.523-.382-.672z"
    />
    <path
      fill="#006129"
      d="M26.917 24.037c-2.23-.995-4.542-2.47-4.542-3.162v-8.729h9.084v8.729c0 .693-2.312 2.168-4.542 3.162z"
    />
    <path
      fill="#FFC6B5"
      d="M25.078 17.891c-.204.079-.266-.469 0-.859.266-.391 1.047-1.188 1.219-1.922s.475-.625.62-.828c.146-.203.167-.438 0-.484s-.401-.109-.245-.719c.156-.609.828-.547.828-.125 0 .422-.156.797 0 1.125.156.328.516.25.734.875.219.625.297 1.234.156 1.5s-.219 1.281-.406 1.547-.438.156-.391-.328c.047-.484.359-1.141.453-1.484.094-.344-.141-1.047-.484-1.125-.344-.078-.969.719-1.25 1.047-.281.328-.891.578-.922.844-.031.264-.109.857-.312.936z"
    />
    <path
      fill="#FFF"
      d="M28.766 21.875c-.359.078-1.156.375-1.594.266-.438-.109-1.219.172-1.328.024-.109-.149.109-.961.141-1.336.031-.375.078-.75.016-.75s-.326 0-.187-.672c.14-.672.374-1.906.374-1.906s-.234-.094-.094-.469c.141-.375.312-1.266.266-1.469s-.391-.141-.266-.453.219-.375.312-.609c.094-.234.286-.419.422-.359s.289.234.441.141c.152-.094.168-.234.371-.219.203.016.638.424.547.516s-.256.41-.284.807c-.028.396-.278 1.365-.356 1.678s.266 1.062.453 1.766c.188.7.766 3.044.766 3.044z"
    />
    <path
      fill="#9C5100"
      d="M26.682 12.628c.091-.241.537-.441.912-.144s.531.844.266 1.172c-.266.328-.42.25-.522 0-.103-.25.007-.474-.134-.549-.141-.076-.016-.294-.141-.341-.125-.047-.512.21-.381-.138z"
    />
    <path
      fill="red"
      d="M23.114 12.484c-.063-.07-.223.22-.16.423.062.204.046.395.169.364.123-.031.093-.208.174-.35.062-.11-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M22.859 13.203h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016.594.016.266.203c-.328.188-.141-.203-.328 0s-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.435-.061-.404-.186.031-.125-.172-.172-.406-.219-.235-.046-.329-.265-.329-.265z"
    />
    <path
      fill="red"
      d="M23.114 14.072c-.063-.07-.223.22-.16.423s.046.396.169.365c.123-.031.093-.209.174-.35.062-.11-.188-.078-.109-.203l-.074-.235z"
    />
    <path
      fill="#F8C600"
      d="M22.859 14.791h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016c.172-.109.594.016.266.203-.328.188-.141-.203-.328 0s-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.435-.061-.404-.186.031-.125-.172-.172-.406-.219-.235-.046-.329-.265-.329-.265z"
    />
    <path
      fill="red"
      d="M23.114 15.838c-.063-.07-.223.22-.16.423s.046.395.169.364c.123-.031.093-.208.174-.35.062-.11-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M22.859 16.557h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016.594.016.266.203c-.328.188-.141-.203-.328 0s-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.435-.062-.404-.187.031-.125-.172-.172-.406-.219-.235-.046-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M24.114 17.557c-.063-.07-.223.22-.16.423s.046.395.169.364c.123-.031.093-.208.174-.35.062-.11-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M23.859 18.275h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016c.172-.109.594.016.266.203-.328.188-.141-.203-.328 0-.188.203-.359.281-.328.359.031.078-.098.14-.361.14-.264 0-.435-.062-.404-.187.031-.125-.172-.172-.406-.219s-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M23.056 18.957c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M22.801 19.676h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016c.172-.109.594.016.266.203-.328.188-.141-.203-.328 0-.188.203-.359.281-.328.359.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219s-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M23.056 20.629c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M22.801 21.348h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016.594.016.266.203c-.328.188-.141-.203-.328 0-.188.203-.359.281-.328.359.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219s-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M29.021 20.629c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 21.348h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0c-.188.203-.359.281-.328.359.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219-.236-.046-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M29.021 19.059c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 19.777h.828s.109-.375.281-.391.219.375.219.375.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219-.236-.045-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M29.021 17.452c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 18.17h.828s.109-.375.281-.391.219.375.219.375.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.436-.061-.404-.186.031-.125-.172-.172-.406-.219-.236-.046-.329-.265-.329-.265z"
    />
    <path
      fill="red"
      d="M29.021 15.838c-.063-.07-.223.22-.16.424.062.203.046.395.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 16.557h.828s.109-.375.281-.391.219.375.219.375.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219-.236-.046-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M29.021 14.174c-.063-.07-.223.22-.16.424.062.203.046.394.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 14.893h.828s.109-.375.281-.391c.172-.016.219.375.219.375s.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0c-.188.203-.359.281-.328.359.031.078-.098.14-.361.14-.264 0-.436-.062-.404-.187.031-.125-.172-.172-.406-.219-.236-.046-.329-.264-.329-.264z"
    />
    <path
      fill="red"
      d="M29.021 12.484c-.063-.07-.223.22-.16.424.062.203.046.394.169.363.123-.03.093-.208.174-.35.062-.109-.188-.078-.109-.203l-.074-.234z"
    />
    <path
      fill="#F8C600"
      d="M28.766 13.203h.828s.109-.375.281-.391.219.375.219.375.391.094.562-.016c.172-.109.594.016.266.203s-.141-.203-.328 0-.359.281-.328.359c.031.078-.098.14-.361.14-.264 0-.436-.061-.404-.186.031-.125-.172-.172-.406-.219-.236-.046-.329-.265-.329-.265zm4.427 9.859c-.312-.203-.797-.646-.922-.854s-.375-.499-.641-.189c-.266.309-2.381 2.372-4.714 2.372s-4.448-2.062-4.714-2.372c-.266-.31-.516-.019-.641.189s-.609.651-.922.854-.125.734.25 1.047.828-.375.828-.375c1.297 1.156 2.984 2.234 5.198 2.234s3.901-1.078 5.198-2.234c0 0 .453.688.828.375s.565-.843.252-1.047z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Vg;
