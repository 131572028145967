const Bl = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EEE"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#CE1A24"
      d="M10.391 21.254s-.337 1.015-.253 2.029c.085 1.015 2.452.93 2.959.846.507-.085.846-.085.507.339-.337.421-.76 1.098-.676 1.859.084.759-1.015.253-1.352-.509-.338-.76-.677-.76-1.269-1.098-.592-.338-1.165-1.19-1.522-1.691-.423-.591-.677-1.014 1.606-1.775zm15.217 0s.339 1.015.254 2.029c-.083 1.015-2.452.93-2.959.846-.508-.085-.845-.085-.508.339.34.421.762 1.098.678 1.859-.085.759 1.014.253 1.352-.509.34-.76.677-.76 1.269-1.098.593-.338 1.164-1.19 1.522-1.691.422-.591.676-1.014-1.608-1.775z"
    />
    <path
      fill="#F6E4BE"
      d="M27.047 22.859c-.931.424-2.861.703-3.637.762-1.025.079-1.183.338-1.183.592 0 .255 1.099.423 1.69.338.592-.083 1.269.085.254.677-1.015.591-2.367 1.438-3.636 1.775-1.267.339-2.535.254-2.535.254s-1.268.085-2.537-.254c-1.268-.338-2.621-1.184-3.635-1.775-1.015-.592-.339-.76.253-.677.592.085 1.691-.083 1.691-.338 0-.254-.158-.513-1.184-.592-.774-.059-2.706-.338-3.635-.762-.392-.178-.507-.506-.507-.506s0 .17.085.676c.084.507.507.845.592 1.353.084.506.084.761.507.845.423.085 1.353.17 1.353.17s0 .759-.169 1.183c-.17.423.423 1.185 2.705 2.029 2.283.847 3.298.762 4.481.762 1.184 0 2.198.085 4.48-.762 2.284-.845 2.874-1.607 2.706-2.029-.169-.424-.169-1.183-.169-1.183s.93-.085 1.352-.17c.425-.084.425-.339.508-.845.085-.509.507-.847.592-1.353.084-.506.084-.676.084-.676s-.115.329-.506.506z"
    />
    <path
      fill="#BCAD96"
      d="M14.28 27.342c.938.313 4.833 1.13 7.185.085.762-.341 1.015.674.339.93-.531.198-2.621.506-3.551.506-.93 0-3.482-.295-4.227-.591-.423-.171-.253-1.101.254-.93z"
    />
    <path fill="#666" d="M12.589 10.517H23.41v1.521H12.589z" />
    <path
      fill="#CE1A24"
      d="M18 11.531h-5.411v6.425c0 3.214 2.029 4.482 3.043 5.242 1.015.761 3.72.761 4.734 0 1.015-.76 3.044-2.027 3.044-5.242v-6.425H18z"
    />
    <path
      fill="#1353B4"
      d="M12.723 19.31c.468 2.239 2.052 3.246 2.909 3.889 1.015.761 2.368 1.27 2.368 1.27s1.353-.509 2.366-1.27c.86-.642 2.442-1.649 2.911-3.889H12.723zm-.134-7.779H23.41v3.974H12.589z"
    />
    <path
      fill="#EACE23"
      d="M14.788 12.969s.084-.169 0-.423c-.076-.227-.423-.423-.423-.423s-.347.196-.422.423c-.085.253 0 .423 0 .423-.423-.169-.592.254-.592.507 0 .254.339.422.339.422s0-.338.168-.338c.169 0 .169.338.169.338-.338 0 0 .254 0 .254l-.253.422.338-.085c-.084.254.254.507.254.507s.339-.253.253-.507l.339.085-.253-.422s.338-.254 0-.254c0 0 0-.338.168-.338.169 0 .169.338.169.338s.338-.168.338-.422c-.001-.253-.17-.677-.592-.507zm7.271 0s.084-.169 0-.423c-.075-.227-.423-.423-.423-.423s-.347.196-.423.423c-.085.253 0 .423 0 .423-.423-.169-.592.254-.592.507 0 .254.338.422.338.422s0-.338.169-.338.169.338.169.338c-.338 0 0 .254 0 .254l-.253.422.337-.085c-.084.254.255.507.255.507s.338-.253.253-.507l.339.085-.254-.422s.338-.254 0-.254c0 0 0-.338.169-.338s.169.338.169.338.339-.168.339-.422c-.001-.253-.171-.677-.592-.507zm-3.636 0s.084-.169 0-.423c-.076-.227-.423-.423-.423-.423s-.347.196-.423.423c-.084.253 0 .423 0 .423-.423-.169-.592.254-.592.507 0 .254.338.422.338.422s0-.338.169-.338.169.338.169.338c-.338 0 0 .254 0 .254l-.253.422.338-.085c-.084.255.254.509.254.509s.338-.253.253-.507l.338.085-.253-.422s.338-.254 0-.254c0 0 0-.338.169-.338s.169.338.169.338.338-.168.338-.422c.001-.255-.168-.679-.591-.509z"
    />
    <path
      fill="#FFF"
      d="M18.93 17.45l.423-.593-1.184.339.338-1.1-.507.339-.507-.339.338 1.1-1.184-.339.423.593-.423.506 1.184-.422-.423 1.183.592-.338.592.338-.423-1.183 1.184.422z"
    />
    <path
      fill="#EACE23"
      d="M18.93 22.015s-.254 0-.254.254c0 .255.254.338.254.338s-.17.253-.423.253c-.254 0-.338-.253-.338-.253s.338-.083.338-.253c0-.17-.254-.254-.254-.254s0-.423-.253-.423-.253.423-.253.423-.254.084-.254.254c0 .17.338.253.338.253s-.085.253-.338.253-.422-.253-.422-.253.253-.083.253-.338c0-.254-.253-.254-.253-.254 0-.338-.338-.17-.338-.17l.253 1.776h2.029l.253-1.776s-.338-.168-.338.17zm-1.945-2.2s-.253 0-.253.256c0 .253.253.338.253.338s-.168.253-.422.253c-.253 0-.338-.253-.338-.253s.338-.085.338-.253c0-.171-.253-.256-.253-.256s0-.421-.254-.421c-.253 0-.253.421-.253.421s-.254.085-.254.256c0 .168.338.253.338.253s-.084.253-.338.253c-.253 0-.423-.253-.423-.253s.254-.085.254-.338c0-.256-.254-.256-.254-.256 0-.336-.337-.168-.337-.168l.253 1.776h2.03l.253-1.776c-.001 0-.34-.168-.34.168zm3.889 0s-.253 0-.253.256c0 .253.253.338.253.338s-.168.253-.423.253c-.253 0-.338-.253-.338-.253s.338-.085.338-.253c0-.171-.253-.256-.253-.256s0-.421-.254-.421c-.253 0-.253.421-.253.421s-.255.085-.255.256c0 .168.339.253.339.253s-.084.253-.339.253c-.253 0-.422-.253-.422-.253s.253-.085.253-.338c0-.256-.253-.256-.253-.256 0-.336-.338-.168-.338-.168l.254 1.776h2.029l.254-1.776s-.339-.168-.339.168zM24.51 7.474l-.17.507-.337-.085.084-.507-.845-.169-.093.536-.331-.092.001-.613-.846-.084-.071.528-.605-.106v.338h-.169v-.423h-.422v.339h-.255V7.22h-.422v.338l-.338-.084v-.762l-.846-.084v.507h-.423v-.507h-.846v.507h-.423v-.507l-.843.084v.761l-.339.084V7.22h-.422v.423h-.253v-.339h-.423v.423h-.168v-.338l-.606.106-.071-.528-.847.083v.613l-.331.092-.092-.535-.846.169.084.507-.338.085-.168-.507-.845.253.423 1.099.253-.084 1.268 2.79s1.438-.422 2.79-.592c1.353-.169 2.621-.169 2.621-.169s1.268 0 2.621.169c1.352.169 2.789.592 2.789.592l1.268-2.79.254.084.422-1.099-.844-.253z"
    />
    <path
      fill="#5E520D"
      d="M18 7.981c-.187 0-.338.151-.338.337v.846h.677v-.846c-.001-.186-.152-.337-.339-.337zm-5.122.526c-.181.047-.29.231-.242.413l.213.818.654-.17-.213-.818c-.046-.182-.231-.29-.412-.243zm10.243 0c.182.047.289.231.243.413l-.214.817-.654-.17.213-.818c.047-.181.231-.289.412-.242z"
    />
  </svg>
);

export default Bl;
