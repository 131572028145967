const email = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="33" cy="32.5" r="32.5" fill="#D3EDDE" />
    <path
      d="M34.7019 35.3023C33.8606 35.9333 32.854 36.2488 31.8624 36.2488C30.8559 36.2488 29.8493 35.9333 29.023 35.3023L17.5 26.6638V39.9746C17.5 41.2967 18.5817 42.3784 19.9038 42.3784H43.8211C45.1432 42.3784 46.2249 41.2967 46.2249 39.9746V26.6488L34.7019 35.3023Z"
      fill="#50B277"
    />
    <path
      d="M30.27 33.6197C31.2014 34.3258 32.5085 34.3258 33.4399 33.6197L46.2399 24.0347V23.4038C46.2399 22.0817 45.1582 21 43.8362 21H19.9038C18.5817 21 17.5 22.0817 17.5 23.4038V24.0347L30.27 33.6197Z"
      fill="#50B277"
    />
    <g clipPath="url(#clip0_1647_18048)">
      <path
        d="M50.5798 33.0399C50.5798 34.907 49.8381 36.6976 48.5178 38.0178C47.1976 39.3381 45.407 40.0798 43.5399 40.0798C41.6728 40.0798 39.8822 39.3381 38.5619 38.0178C37.2417 36.6976 36.5 34.907 36.5 33.0399C36.5 31.1728 37.2417 29.3822 38.5619 28.0619C39.8822 26.7417 41.6728 26 43.5399 26C45.407 26 47.1976 26.7417 48.5178 28.0619C49.8381 29.3822 50.5798 31.1728 50.5798 33.0399Z"
        fill="#A7DABC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6576 30.2714C46.74 30.3045 46.8148 30.3538 46.8777 30.4164H46.8786C47.0007 30.5386 47.07 30.7039 47.0716 30.8766C47.0733 31.0494 47.0071 31.2159 46.8874 31.3404L43.3745 35.7315C43.3141 35.7964 43.2413 35.8485 43.1604 35.8847C43.0794 35.9208 42.9921 35.9403 42.9034 35.9419C42.8148 35.9436 42.7268 35.9273 42.6446 35.8942C42.5624 35.8611 42.4877 35.8118 42.425 35.7491L40.0965 33.4198C39.9799 33.2947 39.9165 33.1292 39.9195 32.9583C39.9225 32.7873 39.9918 32.6241 40.1127 32.5032C40.2336 32.3823 40.3967 32.313 40.5677 32.31C40.7387 32.307 40.9042 32.3705 41.0293 32.487L42.8711 34.3297L45.9273 30.4358C45.9876 30.3706 46.0603 30.3183 46.1413 30.2819C46.2222 30.2456 46.3097 30.2259 46.3984 30.2241C46.4871 30.2223 46.5753 30.2384 46.6576 30.2714Z"
        fill="#50B277"
      />
    </g>
    <defs>
      <clipPath id="clip0_1647_18048">
        <rect
          width="14.0798"
          height="14.0798"
          fill="white"
          transform="translate(36.5 26)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default email;
