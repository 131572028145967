const Ph = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CE1126"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#0038A8"
      d="M32 5H4C1.791 5 0 6.791 0 9v9h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#EEE"
      d="M1.313 29.945l17.718-11.881L1.33 6.041C.519 6.773 0 7.822 0 9v18c0 1.171.512 2.214 1.313 2.945z"
    />
    <path
      fill="#FCD116"
      d="M16.07 16.52l.043 1.153 1.115.294-1.083.396.065 1.152-.712-.908-1.075.417.643-.957-.73-.893 1.11.316zM1.603 7.982l.866.763.98-.607-.458 1.059.88.745-1.148-.108-.437 1.066-.251-1.125-1.151-.086.993-.586zm.431 17.213l.574 1 1.124-.257-.774.854.594.989-1.052-.472-.757.871.123-1.148-1.061-.45 1.128-.238zM10 18c0 1.657-1.343 3-3 3s-3-1.344-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
    />
    <path
      d="M7.595 12.597l-.157 2.648-.244-.036.085-3.074L7 11.953l-.279.182.085 3.074-.244.036-.157-2.648-.353.218.329 2.697h1.238l.329-2.697zm-1.19 10.806l.157-2.648.244.036-.086 3.074.28.182.279-.182-.085-3.074.244-.036.157 2.648.353-.218-.329-2.698H6.381l-.329 2.698zm-3.647-2.004l1.985-1.759.146.196-2.233 2.113.068.327.327.069 2.113-2.235.197.147L3.6 22.242l.404.094 1.675-2.139-.438-.438-.438-.438-2.139 1.675zm8.484-6.799l-1.985 1.761-.146-.197 2.233-2.113-.068-.327-.327-.069-2.113 2.234-.197-.146 1.761-1.985-.404-.094-1.675 2.139.438.438.438.438 2.139-1.675zm-9.645 2.805l2.649.157-.037.244-3.074-.086-.182.28.182.279 3.074-.085.037.244-2.649.157.218.353 2.697-.329V17.38l-2.697-.328zm10.806 1.19l-2.649-.157.037-.244 3.074.085.182-.279-.182-.28-3.074.086-.037-.244 2.649-.157-.218-.353-2.698.328v1.239l2.698.329zM3.6 13.758l1.761 1.985-.197.146-2.113-2.234-.327.069-.068.327 2.233 2.113-.146.197L2.758 14.6l-.094.404 2.139 1.675.438-.438.438-.438-1.675-2.139zm6.8 8.484l-1.761-1.985.197-.147 2.113 2.235.327-.069.068-.327-2.233-2.113.146-.196 1.985 1.759.094-.403-2.139-1.675-.438.438-.438.438 1.675 2.139z"
      fill="#FCD116"
    />
  </svg>
);

export default Ph;
