const Mz = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#FCE100"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#141414" d="M0 13h36v10H0z" />
    <path
      fill="#007168"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path fill="#EEE" d="M0 13h36v1H0zm0 9h36v1H0z" />
    <path
      fill="#DD2E44"
      d="M1.313 29.945l15.719-11.881L1.33 6.041C.519 6.773 0 7.822 0 9v18c0 1.171.512 2.214 1.313 2.945z"
    />
    <path
      fill="#FCE100"
      d="M9.478 23.062l-3.271-2.395-3.267 2.395 1.267-3.851L.92 16.845l4.054.015 1.234-3.858 1.239 3.86 4.05-.018-3.288 2.371 1.269 3.847z"
    />
    <path
      fill="#141414"
      d="M3.369 20.702h2.356c.19.213.613.3 1.016-.002.738-.404 2.167.002 2.167.002l.281-.299-.686-2.25-.246-.264s-.528-.317-1.53-.211c-1.002.106-1.354-.036-1.354-.036s-.879.106-1.125.229c-.027.022-.282.281-.282.281l-.597 2.55z"
    />
    <path
      fill="#FFF"
      d="M3.369 20.376h2.356c.19.213.613.3 1.016-.002.738-.404 2.167.002 2.167.002l.281-.299-.686-2.25-.246-.264s-.528-.317-1.53-.211c-1.002.105-1.354-.036-1.354-.036s-.879.106-1.125.229c-.027.022-.282.281-.282.281l-.597 2.55z"
    />
    <path
      fill="#141414"
      d="M2.213 17.012l1.263-1.851.773.124.446-.46 1.541 1.888 1.236-.878.757-.632.454.279 1.607-1.036-1.453 1.362-1.205.856-.959.432 3.242 3.822-.424.301-3.091-3.75-1.002.805 1.317.421-.042.631-1.85-.51.083 1.309-.427.185-.378-.755-1.635 1.917-.589-1.01 1.851-1.557.042-.547 1.01-.925.294-.042.842-.337-1.263-1.346-1.686 2.352-.722-.87"
    />
  </svg>
);

export default Mz;
