const As = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#BD1721"
      d="M34.184 5.654L0 17.966v.04l34.185 12.338C35.276 29.631 36 28.401 36 27V9c0-1.402-.725-2.631-1.816-3.346z"
    />
    <path
      fill="#EEE"
      d="M35.271 29.293c.456-.65.729-1.439.729-2.293V9c0-.856-.274-1.645-.732-2.296L3.944 17.986l31.327 11.307z"
    />
    <path
      fill="#D89500"
      d="M22 19.537s.767.121 1.089 0c.323-.121.726-.242.726-.242l.605.645.242-.605.444.121-.041.202s.363.121.363.161c0 .04-.04.363-.04.363l1.129.282.202-.443s.726.645 1.009.685c.281.041 1.129 0 1.29.121.161.122.524.363.524.363s2.864.646 2.864.687c0 .04-.04.281-.282.241-.242-.04-3.953-.767-4.438-.928-.484-.161-1.21.161-1.21.161v-.443l-1.251-.363-.12.323h-.323l-.121.282-.443-.121.08-.606s-.362-.04-.523.081c-.162.121-.403.242-.403.242s-.242-.443-.606-.686c-.363-.241-.766-.523-.766-.523z"
    />
    <path
      fill="#D89500"
      d="M26.114 21.675s5.405.443 5.729.483c.322.041.322.323 0 .363-.323.041-5.729-.403-5.729-.403s-.242.242-.242.323c0 .08 2.138.121 2.138.121l-.201.201s.847.04 1.17-.04c.322-.081.362-.322.403-.242.04.081-.242.403-.161.403.08 0 .968-.081.968-.081l-.646.323.969.363s-.484.121-.687.121c-.201 0-.524-.081-.524-.081s.323.443.565.524c0 0-.807-.202-.929-.323-.12-.12.242.606-.483-.04-.726-.646-.726-.322-.726-.322l.241.403s-.604 0-1.009-.242c-.403-.242-.887-.363-1.25-.484s-.685-.242-.968-.403c-.282-.161-.888-.524-.767-.887s.604-.242.604-.242.323-.081.565-.041c.242.041.403-.282.646-.161.244.122.324.364.324.364z"
    />
    <path
      fill="#FFF"
      d="M31.722 15.422c.483.121 1.371-.04 1.774-.121.403-.081.685-.202.847-.04.162.161.122.242.122.242s.241.121.241.242v.121s.162.282 0 .403c0 0 .162.444-.121.524 0 0 .202.363-.12.403l-.122.403-2.662-.444.041-1.733z"
    />
    <path
      fill="#66757F"
      d="M34.396 17.686l-2.791-.465.001-.065.043-1.828.092.023c.229.057.72.083 1.741-.122.085-.017.165-.036.239-.053.289-.069.52-.124.675.034.112.11.144.196.148.252.084.049.237.156.237.284v.102c.062.118.12.317.011.444.026.091.067.285-.006.428-.023.047-.059.084-.102.112.03.078.062.203.011.304-.023.046-.071.102-.172.127l-.127.423zm-2.639-.593l2.533.422.117-.387.048-.006c.084-.011.101-.044.106-.055.032-.064-.015-.188-.042-.237l-.046-.083.091-.026c.042-.012.07-.034.088-.07.054-.105.006-.293-.017-.356l-.02-.053.045-.033c.105-.079-.019-.304-.021-.306l-.01-.017v-.141c0-.042-.105-.127-.199-.175l-.067-.033.033-.066c-.003 0-.004-.054-.107-.157-.093-.094-.256-.061-.534.006-.076.018-.158.037-.245.055-.774.156-1.353.202-1.716.139l-.037 1.579z"
    />
    <path
      fill="#FFC220"
      d="M28.857 19.819c-.016.037-.322.807-.484.928-.161.121-.524.403-.685.443-.161.041-.161.202-.161.202s-.363.202-.484.282c-.121.081-.242.081.081.121.322.04.403.081.403.081s-.242.241-.161.363c.08.12.407.142.807.241.322.081.646-.04.646-.161s-.081-.443-.081-.403c0 .04.242-.121.484-.242s.362-.403.282-.606c-.081-.201 0-.524.201-.887.202-.363-.04-.807-.241-.848-.203-.038-.526.284-.607.486z"
    />
    <path
      fill="#9C3900"
      d="M25.711 16.431c.363-.242.686-.726.686-.726s-.121-.162-.564.04c-.443.202-1.009.444-1.251.162-.37-.434.171-.98.605-1.13 1.291-.443-.524-.927 1.009-1.613.643-.288 1.896-.484 2.138-.605s.604-.565.604-.524c0 .04.041.323.041.323s.888-.242 1.17-.363c.282-.121.524-.443.524-.443l.08.282s.727-.081 1.009-.282c.282-.202.484-.04.484-.04s.807-.202 1.129-.363c.323-.161.767-.242.686.041-.08.282-.564 1.694-.767 1.977-.201.282-.161.726-.403.928-.241.202-1.895 1.129-1.895 1.129s.807.04 1.13.162c.322.121.161.524.161.524s.081.161.081.322c0 .162-.202.202-.202.202s.202.081.161.242c-.04.162-.403.162-.322.162.081 0 .403.322.363.443-.041.121-.686-.161-.969-.08 0 0 .041.202.282.323.242.121.242.202.041.323-.202.121-.404.282-.202.282s.282.121.161.202c-.121.08.484 0 .121.282-.362.283-.443.283-.443.283s.363.443.201.484c-.161.04-.686-.243-.686-.243s.121.404.323.525c0 0-.444.281-.686-.041 0 0 .04.444.161.565 0 0-.242.04-.444-.121 0 0-.161.322-.08.524l-.403-.646-.565-.16-.322.564s-.202-.404-.161-.767c.04-.362 0-.887-.041-1.089-.04-.202-.524-.766-.927-.847-.404-.081-1.171-.081-1.171-.081l-.928-1.13"
    />
    <path
      fill="#FFC220"
      d="M23.653 18.69s.848.282 1.13-.283c.282-.564-.685-.766-1.049-.564-.362.202-.646.564-.604.847.04.282.403.404.403.404l.12-.404z"
    />
    <path
      fill="#FFF"
      d="M23.573 17.923l.202.484h1.008s.928-.766 1.654-.807c.726-.04 1.25-.403.443-.444 0 0 1.089-.484-.081-.403 0 0 .767-.524-.242-.323 0 0 .888-.605-.282-.322l.121-.444s-.483.564-.807.726c-.322.161-1.331.646-1.573.726-.241.081-.201.202-.241.403-.041.203-.202.404-.202.404z"
    />
    <path
      fill="#66757F"
      d="M24.811 18.482h-1.085l-.238-.571.027-.035c.002-.002.15-.19.188-.372.007-.037.012-.072.016-.104.019-.142.036-.275.275-.355.231-.077 1.213-.547 1.563-.722.306-.153.778-.703.783-.708l.217-.253-.176.645c.411-.089.525-.048.563.041.035.079-.025.174-.119.266.187-.017.285.013.318.092.035.082-.03.178-.118.263h.009c.245 0 .356.042.379.133.022.091-.069.194-.273.31.123.028.223.081.223.182 0 .204-.478.357-.921.381-.693.039-1.602.782-1.61.79l-.021.017zm-.986-.15h.932c.146-.118.986-.768 1.677-.806.463-.026.757-.176.778-.233-.008 0-.065-.047-.335-.061l-.313-.016.287-.127c.185-.083.361-.19.406-.246-.036-.013-.141-.035-.452-.014l-.274.019.228-.156c.112-.077.219-.177.241-.224-.029-.01-.13-.023-.427.037l-.361.072.305-.208c.13-.089.255-.202.283-.256-.038-.01-.161-.016-.506.068l-.123.03.072-.267c-.173.18-.421.415-.619.514-.293.147-1.326.645-1.583.73-.149.05-.156.1-.173.232-.005.035-.01.073-.019.114-.033.167-.14.329-.189.399l.165.399z"
    />
    <path d="M24.523 17.519c.038.077-.021.186-.134.242-.112.057-.235.039-.274-.038-.038-.077.021-.186.134-.242.112-.057.235-.04.274.038z" />
  </svg>
);

export default As;
