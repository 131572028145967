const My = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#DD2E44"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#EEE"
      d="M.555 29h34.891c.343-.591.554-1.268.554-2H0c0 .732.211 1.409.555 2zM0 23h36v2H0zm0-4h36v2H0zm0-4h36v2H0zm0-4h36v2H0zm.555-4C.211 7.59 0 8.268 0 9h36c0-.732-.211-1.41-.555-2H.555z"
    />
    <path fill="#010066" d="M18 5H4C1.791 5 0 6.791 0 9v10h18V5z" />
    <path
      fill="#FFCC4D"
      d="M9.534 17.233C6.644 17.233 4.3 14.89 4.3 12c0-2.89 2.343-5.233 5.233-5.233.715 0 1.397.146 2.018.405C10.556 6.438 9.331 6 8 6c-3.313 0-6 2.687-6 6 0 3.314 2.687 6 6 6 1.331 0 2.556-.438 3.551-1.172-.621.26-1.302.405-2.017.405z"
    />
    <path
      fill="#FFCC4D"
      d="M12.922 8.829l.334 1.62 1.003-1.315-.402 1.604 1.475-.749-1.059 1.271 1.654-.035-1.505.686 1.505.686-1.654-.035 1.059 1.271-1.475-.749.402 1.605-1.003-1.316-.334 1.621-.334-1.621-1.003 1.316.402-1.605-1.475.749 1.058-1.271-1.653.035 1.505-.686-1.505-.686 1.653.035-1.058-1.271 1.475.749-.402-1.604 1.003 1.315z"
    />
  </svg>
);

export default My;
