const Sv = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#0F47AF"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#1F601A"
      d="M17.462 21.297c.246-.764-.609-.906-.609-.906s.062.438-.453.453c.172.344.016.625-.203.641.359.327 1.109.296 1.265-.188zm-1.27-.463c.473-.578-.221-.992-.221-.992s-.095.4-.547.239c.033.355-.199.547-.394.486.201.408.862.635 1.162.267zm-.895-.795c.564-.302.152-.857.152-.857s-.207.284-.511.01c-.091.291-.336.366-.471.255.025.388.472.783.83.592zm-.346-1.009c.594-.121.371-.736.371-.736s-.265.202-.467-.129c-.162.239-.405.241-.497.104-.082.358.216.838.593.761zm-.14-.865c.545-.004.455-.586.455-.586s-.269.133-.39-.195c-.185.183-.399.142-.456.005-.134.302.045.778.391.776zm.129-.825c.425.146.492-.396.492-.396s-.241.044-.257-.28c-.187.112-.344.017-.356-.121-.177.232-.15.704.121.797zm.327-.683c.212.124.45-.288.45-.288s-.184-.066-.137-.369c-.164.074-.276.029-.26-.1-.179.187-.253.641-.053.757zm3.116 4.64c-.246-.764.609-.906.609-.906s-.062.438.453.453c-.172.344-.016.625.203.641-.359.327-1.109.296-1.265-.188zm1.269-.463c-.473-.578.221-.992.221-.992s.096.4.547.239c-.032.355.199.547.395.486-.202.408-.862.635-1.163.267zm.896-.795c-.563-.302-.151-.857-.151-.857s.207.284.51.01c.092.291.337.366.471.255-.025.388-.472.783-.83.592zm.346-1.009c-.594-.121-.37-.736-.37-.736s.265.202.467-.129c.162.239.404.241.496.104.081.358-.216.838-.593.761zm.139-.865c-.545-.004-.455-.586-.455-.586s.27.133.39-.195c.184.183.399.142.456.005.135.302-.045.778-.391.776zm-.129-.825c-.425.146-.492-.396-.492-.396s.242.044.258-.28c.188.112.344.017.356-.121.177.232.15.704-.122.797zm-.326-.683c-.213.124-.45-.288-.45-.288s.185-.066.138-.369c.163.074.275.029.26-.1.177.187.251.641.052.757z"
    />
    <circle fill="#06209F" cx="17.906" cy="21.301" r=".4" />
    <path
      fill="#06209F"
      d="M17.821 17.774l-1.859-1.993s-.312 2.297.594 2.906c.906.61 1.265-.913 1.265-.913z"
    />
    <path
      fill="#FFF"
      d="M16.854 16.739l-.414-.444s-.213 1.783.693 2.393c-.437-.891-.279-1.949-.279-1.949z"
    />
    <path
      fill="#06209F"
      d="M17.821 18.602l-2.549-1.445s-.125 1.484.322 2.203c.446.719 2.085.766 2.312.062.228-.703-.085-.82-.085-.82z"
    />
    <path
      fill="#FFF"
      d="M16.194 17.679l-.477-.271s-.284 2.983 1.977 2.298c-1.498-.07-1.5-2.027-1.5-2.027z"
    />
    <path
      fill="#06209F"
      d="M17.989 17.774l1.859-1.993s.312 2.297-.594 2.906c-.905.61-1.265-.913-1.265-.913z"
    />
    <path
      fill="#FFF"
      d="M18.956 16.739l.414-.444s.213 1.783-.693 2.393c.437-.891.279-1.949.279-1.949z"
    />
    <path
      fill="#06209F"
      d="M17.989 18.602l2.549-1.445s.125 1.484-.321 2.203c-.446.719-2.085.766-2.312.062-.228-.703.084-.82.084-.82z"
    />
    <path
      fill="#FFF"
      d="M19.616 17.679l.479-.271s.283 2.983-1.979 2.298c1.498-.07 1.5-2.027 1.5-2.027z"
    />
    <path fill="#FFCC01" d="M17.821 15.297l-2.474 4.265h4.949z" />
    <path fill="#FFF" d="M15.694 19.362l2.127-3.667 2.127 3.667z" />
    <path fill="#017E93" d="M16.303 18.312l-.609 1.05h4.254l-.609-1.05z" />
    <path
      fill="#FFCC01"
      d="M16.103 18.831s.688-.972.953-.972c.266 0 .391.305.391.305s.104-.164.247-.164c.143 0 .212.165.212.165s.103-.161.165-.131c.062.029.267.279.267.279s.061-.108.202-.148c.141-.04.234.148.234.148s.138-.296.17-.148c.033.148.543.667.543.667h-3.384z"
    />
    <circle fill="#E60000" cx="17.883" cy="17.236" r=".189" />
    <path
      d="M14.002 16.24c-.255.504-.413 1.059-.459 1.651l.498.013c.044-.52.185-1.007.411-1.45l-.45-.214zm4.754-1.858c.633.133 1.199.429 1.68.826l.345-.361c-.547-.457-1.192-.799-1.915-.951l-.11.486zm2.437 1.629c.434.633.69 1.398.69 2.224 0 .15-.027.293-.044.439l.49.09c.022-.176.054-.348.054-.529 0-.928-.286-1.787-.773-2.499l-.417.275zm-4.97 5.748c-1.184-.58-2.022-1.731-2.171-3.098l-.494.056c.169 1.546 1.119 2.845 2.459 3.498l.206-.456zm-1.336-5.975c.722-.899 1.817-1.487 3.06-1.487.026 0 .05.007.076.008l.031-.497c-.036-.001-.07-.011-.107-.011-1.413 0-2.656.672-3.469 1.701l.409.286zm6.8 3.621c-.33 1.055-1.081 1.916-2.068 2.382l.203.456c1.113-.523 1.962-1.491 2.337-2.679l-.472-.159z"
      fill="#FFCC01"
    />
  </svg>
);

export default Sv;
