const Pg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#141414"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#CE1126"
      d="M34.618 30.002C35.458 29.269 36 28.202 36 27V9c0-2.209-1.791-4-4-4H4c-1.006 0-1.915.384-2.618.998l33.236 24.004z"
    />
    <path
      d="M7.721 13.536l.383 1.316h1.324l-1.045.804.383 1.279-1.045-.805-1.045.768.383-1.242-1.045-.804h1.289zm-4.014 5.133l.383 1.314h1.324l-1.045.804.383 1.279-1.045-.803-1.045.767.383-1.243L2 19.983h1.289zm4.014 6.933l.383 1.315h1.324l-1.045.804L8.766 29l-1.045-.804-1.045.767.383-1.242-1.045-.804h1.289zm1.792-2.794l.192.657h.662l-.523.403.192.639-.523-.403-.522.384.191-.62-.522-.403h.644zm2.203-4.504l.383 1.315h1.324l-1.045.804.383 1.279-1.045-.804-1.045.767.383-1.242-1.045-.804h1.289z"
      fill="#FFF"
    />
    <path
      fill="#FCD116"
      d="M22.723 12.734c-.309.399-1.039-.401-1.914-.419-.875-.019-1.496.838-1.75 1.13-.256.292-1.077 1.823-1.059 1.988.02.164.512-.457.693-.566.017.219-.201.475-.146.547.055.074.639-.438.84-.455.055.146-.109.291-.055.51.072.074.4-.364.619-.455.037.164-.201.474-.072.674.09.019.639-.619.82-.692.182-.073-.092.602 0 .657.164-.109.674-.693.838-.766.164-.073-.018.747.055.747.201-.145.711-.838.766-.947.055-.109-.055 1.057.512 1.677.566.62 1.422 1.331 1.514 1.331.109-.2-.037-.62.055-.602.256.273.729.675.838.675s-.072-.438.074-.438c.291.273 1.129 1.35 2.771 1.842-.109-.311-.348-.584-.365-.729.109.018.492.2.564.183.074-.019-.801-1.095-.748-1.24.201-.019.293.109.365.018-.09-.146-.857-1.003-.857-1.203 0-.201.238.036.311 0 .074-.037-.291-.802-.219-.894.072-.09.875.492 1.076.492.201 0 .055-.711.147-.747.09-.036.766.383.91.383.146 0 .129-.31.184-.274.055.036 1.641 1.623 1.094 3.482-.547 1.331-1.641 2.042-3.246 1.459.092.146.949.856 1.879.602.93-.255 2.061-.984 1.934-2.808-.129-1.824-.73-2.079-.82-2.352-.094-.273.145.073.344.145.203.074-.035-.383-.09-.583-.055-.2.602.419.766.474.164.055-.383-1.495-.547-1.604-.164-.109.164.037.273.073.109.036-1.004-1.696-1.24-1.696s.201-.091.328-.091-1.275-1.13-1.514-1.13c-.236 0 .512-.292.584-.311.127.019-1.623-.93-3.191.019-.09-.073.738-.648.766-.894.01-.063-.273-.2-.291-.273-.018-.073.438-.292.455-.456-.109-.146-.309-.091-.418-.127-.027-.137.383-.475.346-.621-.027-.082-.328-.109-.4-.055-.074.055.192-.383.145-.51-.035-.055-.182-.018-.328.018-.09-.018.475-.802.365-.912-.109-.109-1.641.802-2.205 1.823-.566 1.021-.129 1.842-.201 2.188-.072.346-.164.693-.4.693-.238 0-.785-.365-.967-.547-.184-.183-.219-.31-.475-.383-.256-.073-.84.146-1.059.164-.219.019-1.074-.164-1.129-.109-.055.055.838.383 1.039.493.199.109.219.254.363.346.143.09 1.512.491 1.073 1.056zm5.343 2.571c.438.438 3.391 2.407 3.391 4.814s-2.152 2.334-2.772 2.261c.693.584 3.318.475 3.318-2.261 0-2.407-3.391-4.814-3.828-5.033-.437-.219-.548-.219-.109.219z"
    />
  </svg>
);

export default Pg;
