const Cc = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="green"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      d="M30.529 19.661l.17.583h.587l-.464.358.171.567-.464-.357-.464.34.17-.55-.464-.358h.573zm-1.531-6.504l-.679.627.068-.921-.914-.139.762-.523-.46-.801.884.27.339-.859.339.859.884-.27-.46.801.761.522-.913.14.068.921zm-3.623 5.313l-.679.627.068-.922-.914-.139.762-.522-.46-.802.884.271.339-.859.339.859.884-.271-.46.802.761.522-.913.139.068.922zm7.11-.439l-.678.627.067-.922-.914-.139.763-.522-.46-.802.883.271.339-.859.339.859.884-.271-.46.802.762.522-.913.139.067.922zm-3.547 7.74l-.678.627.067-.921-.914-.139.763-.523-.46-.801.884.27.338-.859.339.859.884-.27-.46.801.762.522-.913.14.067.921zm-8.573-3.831c-2.175 0-3.94-1.766-3.94-3.941 0-2.177 1.765-3.94 3.94-3.94.828 0 1.594.258 2.228.695-.905-1.039-2.235-1.699-3.724-1.699-2.731 0-4.944 2.214-4.944 4.945 0 2.73 2.213 4.945 4.944 4.945 1.488 0 2.819-.66 3.727-1.7-.635.437-1.403.695-2.231.695zM11.973 12c0 2.809-2.276 5.086-5.085 5.086S1.801 14.809 1.801 12s2.277-5.086 5.086-5.086S11.973 9.191 11.973 12z"
      fill="#FFE000"
    />
    <path
      fill="#802000"
      d="M7.144 16H6.01s.372-.352.567-.67c.195-.319.973-2.012.885-3.557-.087-1.545.178-1.287.267-.365.089.921 0 2.812-.159 3.362-.16.549-.336.899-.106.97.229.071.3.26.3.26h-.62z"
    />
    <path
      fill="green"
      d="M5.177 8.919h.691l1.418 1.348-.479-.869.436-.271.131-1.075 1.187-.107-.159.603.62.319-.691 1.009 1.506-.141-.283.886h.815l.337 1.116-1.985-.159.992.744v1.134l-2.091-1.967-.177.833-.124.833-.458.137-.109-.633-.479.797h-.637l.62-2.248-3.19.6.851-1.027 1.683-.338-1.488-.779 2.197.39-2.268-1.135z"
    />
  </svg>
);

export default Cc;
