const Nu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#FFE150"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
    <circle fill="#004A77" cx="9.5" cy="11.5" r="2.5" />
    <path
      fill="#FFE150"
      d="M9.5 8.966l.599 1.6 1.824.038-1.454 1.225.529 1.746L9.5 12.533l-1.498 1.042.529-1.746-1.454-1.225 1.824-.038zm-5.676 1.519l.246.707.748.016-.596.452.216.716-.614-.427-.614.427.217-.716-.597-.452.749-.016zm11.352 0l.246.707.748.016-.596.452.216.716-.614-.427-.614.427.216-.716-.596-.452.748-.016zM9.5 5.821l.246.707.748.016-.596.452.216.716-.614-.427-.614.427.217-.716-.597-.452.748-.016zm0 9.467l.246.707.748.015-.596.452.216.717-.614-.428-.614.428.217-.717-.597-.452.748-.015z"
    />
  </svg>
);

export default Nu;
