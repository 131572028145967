import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Spin as AntdSpin } from './antd';

export const Spin = styled(AntdSpin)`
  ${({ theme, width, height }) => {
    return css`
      svg {
        fill: ${theme.primaryColor};
        background: transparent;
        border-radius: 80px;
        width: ${width || 80}px;
        height: ${height || 80}px;
      }
    `;
  }}
`;
