const Ai = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#CCCC32"
      d="M33.938 12.324s-1.468.51-3.112.51c-1.643 0-2.879-.854-2.879-.854s-1.232.854-2.877.854c-1.644 0-3.194-.51-3.194-.51-.154 4.121.1 8.458 1.609 10.73C25.108 25.498 27.946 27 27.946 27s2.754-1.502 4.378-3.946c1.512-2.272 1.768-6.61 1.614-10.73z"
    />
    <path
      fill="#FFF"
      d="M33.416 12.811s-1.175.439-2.734.439c-1.562 0-2.735-.78-2.735-.78s-1.171.78-2.732.78c-1.562 0-2.731-.439-2.731-.439-.148 3.901 0 7.901 1.512 9.998 1.513 2.099 3.951 3.561 3.951 3.561s2.443-1.463 3.956-3.561c1.51-2.096 1.659-6.096 1.513-9.998z"
    />
    <path
      fill="#F90"
      d="M26.58 13.397s.049.683.341.926c0 0 .732-.146 1.317.098.463.193 1.317.536 1.756.39.438-.146.829-.292.928-.195.097.097-.489.244-.587.341-.098.098-.438.196-.243.488.196.293.537.537.439.83 0 0-.439-.341-.634-.634-.196-.293-.635-.537-1.367-.439-.732.098-1.316.488-1.413.927-.098.438.438.39.877.487 0 0-.634.244-.975.244 0 0-.293.537-.586 1.024-.292.488-.342.781-.487.732-.146-.049-.196-.732-.098-1.122 0 0-.341-.195-.293-1.17.042-.833.634-1.61.634-1.61s-.048-.292 0-.634c.05-.342.391-.683.391-.683z"
    />
    <path
      fill="#FFF"
      d="M26.044 16.835c0 .095-.076.171-.171.171-.095 0-.171-.076-.171-.171 0-.094.076-.17.171-.17.095 0 .171.076.171.17z"
    />
    <path
      fill="#F90"
      d="M25.024 21.728s.499-.47.502-.85c0 0-.575-.475-.757-1.083-.144-.48-.417-1.359-.808-1.607-.392-.248-.751-.458-.738-.595.015-.137.498.224.636.238.137.014.428.217.532-.119.104-.336.076-.755.364-.865 0 0 .014.556-.09.892-.104.335-.015.83.522 1.335.539.505 1.211.712 1.613.509.402-.201.025-.585-.178-.988 0 0 .591.337.807.603 0 0 .601-.113 1.163-.195.563-.082.82-.229.875-.084.055.144-.443.613-.809.785 0 0 .066.387-.721.966-.673.495-1.649.527-1.649.527s-.194.223-.491.4c-.292.179-.773.131-.773.131z"
    />
    <path
      fill="#FFF"
      d="M28.027 19.969c.073-.059.182-.048.24.025.061.072.049.181-.024.24-.073.059-.18.048-.239-.024-.061-.073-.049-.182.023-.241z"
    />
    <path
      fill="#F90"
      d="M32.781 18.83s-.637-.256-.983-.1c0 0-.188.721-.664 1.141-.377.334-1.059.95-1.12 1.408-.06.459-.1.874-.229.919-.13.045-.006-.545-.051-.676-.045-.13.017-.48-.332-.433-.348.047-.718.247-.938.032 0 0 .5-.246.848-.294.348-.046.76-.335.993-1.034.232-.702.138-1.398-.215-1.678-.351-.28-.543.224-.823.577 0 0 .059-.679.208-.985 0 0-.354-.498-.664-.975-.31-.477-.553-.648-.444-.759.107-.109.744.145 1.051.404 0 0 .326-.222 1.182.249.731.403 1.168 1.275 1.168 1.275s.285.084.571.278c.284.194.442.651.442.651z"
    />
    <path
      fill="#FFF"
      d="M29.925 16.841c-.085-.041-.121-.143-.079-.228.041-.085.143-.12.229-.079.084.042.119.143.077.228-.041.085-.144.121-.227.079z"
    />
    <path
      fill="#9CF"
      d="M23.771 22.47c.071.118.146.232.225.34 1.513 2.099 3.951 3.561 3.951 3.561s2.443-1.463 3.956-3.561c.078-.108.152-.222.224-.34h-8.356z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Ai;
