import { FC } from 'react';
import * as S from './styles';

interface DividerProps {
  children?: string;
}

const Divider: FC<DividerProps> = ({ children = null, ...others }) => {
  return <S.Divider {...others}>{children}</S.Divider>;
};
export default Divider;
