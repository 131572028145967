const Trophy = () => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="33" cy="32.5" r="32.5" fill="#D3EDDE" />
    <g clipPath="url(#clip0_1674_17979)">
      <path
        d="M50.2563 24.1487C50.0938 23.4744 49.4876 23 48.7938 23H42.4938C42.5089 22.2219 42.5006 21.5187 42.4841 20.9362C42.4563 19.8519 41.5751 19 40.4876 19H24.5126C23.4251 19 22.5438 19.8519 22.5126 20.9362C22.4438 21.5187 22.4876 22.2219 22.5063 23H16.2038C15.5101 23 14.9086 23.4744 14.7446 24.1487C14.6938 24.3612 13.5182 29.45 16.8169 34.3687C19.1569 37.8575 23.1044 40.3081 28.5357 41.7062C29.7044 42.0066 30.4988 43.085 30.4988 44.2919C30.5001 45.7812 29.2813 47 27.7876 47H27.5001C25.8432 47 24.5007 48.3431 24.5007 50C24.5007 50.5522 24.9484 51 25.4444 51H39.3882C39.9404 51 40.3319 50.5522 40.3319 50C40.3319 48.3431 38.9894 47 37.3326 47H37.0423C35.5511 47 34.3323 45.7812 34.3323 44.2906C34.3323 43.0837 35.1267 42.005 36.2954 41.705C41.7304 40.3088 45.6767 37.8581 48.0142 34.3675C51.4813 29.45 50.3063 24.3612 50.2563 24.1487ZM19.3382 32.7375C17.5919 30.1625 17.4382 27.4812 17.5238 26H22.5482C22.8831 29.7262 23.8201 34.1938 26.1526 37.8188C23.0876 36.6 20.8063 34.9 19.3382 32.7375ZM45.6626 32.7375C44.1976 34.9 41.9163 36.5969 38.8501 37.8137C41.1813 34.1937 42.1188 29.725 42.4501 26H47.4744C47.5063 27.4812 47.4063 30.1688 45.6626 32.7375Z"
        fill="#50B277"
      />
      <path
        d="M37.1768 27.1692L34.179 26.7585L32.8389 24.1981C32.8023 24.128 32.7421 24.0712 32.6677 24.0367C32.4811 23.9499 32.2544 24.0223 32.1612 24.1981L30.8211 26.7585L27.8233 27.1692C27.7406 27.1803 27.665 27.217 27.6072 27.2726C27.5372 27.3404 27.4987 27.4315 27.5 27.5261C27.5014 27.6206 27.5425 27.7107 27.6143 27.7767L29.7832 29.7697L29.2708 32.5839C29.2588 32.6493 29.2665 32.7167 29.293 32.7782C29.3195 32.8398 29.3638 32.8931 29.4208 32.9321C29.4779 32.9712 29.5454 32.9944 29.6157 32.9991C29.686 33.0038 29.7563 32.9899 29.8186 32.9589L32.5 31.6302L35.1814 32.9589C35.2546 32.9956 35.3396 33.0078 35.4211 32.9945C35.6265 32.9611 35.7647 32.7775 35.7292 32.5839L35.2168 29.7697L37.3858 27.7767C37.4448 27.7222 37.4838 27.651 37.4956 27.5731C37.5275 27.3784 37.3834 27.1981 37.1768 27.1692Z"
        fill="#A7DABC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1674_17979">
        <rect
          width="36"
          height="32"
          fill="white"
          transform="translate(14.5 19)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Trophy;
