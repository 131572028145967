const Fj = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#68BFE5"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path d="M27.646 11.625h-4.875v8.25c0 2.791 4.875 4.354 4.875 4.354s4.875-1.563 4.875-4.354v-8.25h-4.875z" />
    <path
      fill="#FFF"
      d="M27.646 24.019c-.555-.191-4.675-1.694-4.675-4.144v-8.05h9.35v8.05c0 2.449-4.12 3.952-4.675 4.144z"
    />
    <path
      fill="#D21034"
      d="M32.321 14.896v-3.071h-9.35v3.071h4.008v3.396h-4.008v1.312h4.008v4.158c.317.132.56.219.667.256.104-.036.338-.12.646-.247v-4.167h4.029v-1.312h-4.029v-3.396h4.029z"
    />
    <path
      fill="#FFF"
      d="M24.184 13.692c0 .275-.257.498-.572.498-.316 0-.572-.223-.572-.498s.256-.498.572-.498c.315 0 .572.223.572.498z"
    />
    <path
      fill="#FFD101"
      d="M23.719 13.297c-.326.057-.484-.141-.422-.328s.375-.359.672-.391c.297-.031.578.266.812.281.234.016.359-.114.406-.284s.375-.557.172-.654c.266 0 .266.281.266.281s.297-.297.516-.281c.125.219 0 .531 0 .531s.312-.125.531.016c-.422.062-.484.312-.484.312s.078.203-.031.312c-.109.109.859.312 1.391.156.531-.156 1.547-.516 2.203-.516.656 0 .797.25.953.031.156-.219-.188-.453-.75-.406s-1.516.698-1.891.263c-.094 0-.141.112-.141.112s-.234-.078-.469-.203c-.234-.125-.188.297-.062.359-.391-.016-.378-.268-.378-.268s-.482-.354-.169-.62c.023.485.453.344.688.25.234-.094.531-.016.672.109.141.125.547.234 1.062-.062.516-.297 1.906-.406 1.922.219s-.688.672-.688.672.078.375.516.297.719-.203.844.141.406.359.313.641c-.094.281-.406.412-.828.339-.422-.073-.469-.245-.125-.308.344-.062.406-.328.281-.391s-.359.188-.672.062-.719-.375-.719-.375.016.172-.266.266c-.281.094-.047.203.031.375s-.281.312-.656.39-.656.282-1.016-.156c-.359-.438.094-.562.281-.422s.375.391.531.266c.156-.125-.109-.203-.141-.438-.031-.234.016-.515.016-.515s-.5.78-1.562.936c-1.062.156-1.25.156-1.25.156l-.312-.219-.375.25-.016-.281s-.594.466-.906.538-1.166-.131-1.185-.366c-.019-.234.075-.344.356-.438.281-.094.328.062.469.188s.297.297.5.125.312-.234.312-.234l.062-.297-.297-.016.109-.343s-.219.046-.375-.095c-.156-.141-.172-.281-.312-.234-.139.048-.03.204-.389.267z"
    />
    <path
      fill="#964B36"
      d="M24.469 17.828c-.266.109-.672-.766-.438-1.016.235-.25.703.907.438 1.016zm.515-.109c-.249.022-.156-1.188 0-1.188.157 0 .344 1.157 0 1.188zm.438.191c-.311-.057.156-.785.281-.785s.078.851-.281.785zm4.937.199c-.609.017-.266-.107-.203-.272.062-.166.025-1.4.025-1.4h.35s-.109 1.063 0 1.282c.11.219.391.375-.172.39zm1.203 2.157c.145-.162-.219-.422-.469-.422s-.533.141-.743.734c-.21.594.235.422.349.094s.207-.516.379-.531c.172-.016.234.406.484.125zm-2.39 2.078c-.321-.024-.594.812-.594.812s1.438-.75.594-.812z"
    />
    <path
      fill="#00A651"
      d="M24.184 17.125c.078.25-.715-.04-.73.394-.328-.05.141-.691.438-.597-.062-.344-.506-.484-.55.078-.497-.438.136-.859.421-.672-.152-.531-.443-.522-.479-.203-.376-.312.003-.812.329-.578.326.234.35.484.35.484s.227-.809.805-.545c-.701.296-.838.826-.584 1.639z"
    />
    <path
      fill="#00A651"
      d="M25.004 16.777c-.207-.199-.332-.418-.332-.418s.221.594-.124.844c.015-.547-.36-.734-.157-.953.203-.219.547 0 .547 0s.081-.127-.271-.438c-.352-.31-.604-.297-.62-.562.353.21.893.211.957.797.074-.703.391-.719.537-.734s.49.062.615-.062c0 .25-.281.172-.469.25.25.172.266.727.266.727s-.359-.633-.516-.586c-.156.047-.26.516-.26.516s.571-.017.363.75c-.088-.422-.182-.469-.291-.469s-.245.338-.245.338z"
    />
    <path
      fill="#00A651"
      d="M25.541 17.307c.021-.292.162-.792.318-.831.052-.32.219-.773.562-.851.344-.078.422.281.344.484-.203-.297-.484-.375-.547.328.297-.078.469.156.391.469-.281-.328-.484-.367-.609-.129.328.129.334.41.167.645-.073-.406-.29-.375-.405 0-.116.374-.221-.115-.221-.115zm4.754-.53c-.357 0-.826.369-.857.942-.375-.563.084-1.188.565-1.407-.847.016-1.159.391-1.268.703-.109-.828.943-1.156 1.268-1.016-.206-.359-1.018-.329-1.425-.032 0-.687 1.215-.852 1.717-.231-.061-.503.143-.628.143-.628v.672s1.131-1.062 1.753.219c-1.003-.516-1.491.016-1.491.016s1.039-.25.896.953c-.33-.734-1.018-.656-1.018-.656s.797.36.484 1.25c-.312-.859-.767-.785-.767-.785z"
    />
    <path
      fill="#FFF202"
      d="M29.531 20.688c-.047-.469.547-.734.547-.734s1 .609 1.141.734c.141.125-.086.648-.52.785-.433.136-1.168-.785-1.168-.785z"
    />
    <path
      fill="#00A651"
      d="M29.256 22.355c-.235-.148-.385-.98 0-1.059-.146-.5.166-.75.275-.781.109-.031.514.152.819.443.306.291.571.361.595.475.024.113-.191.457-.56.488.115.345-.764.665-1.129.434z"
    />
    <path d="M24.047 20.407s.234-.095.375-.235.328-.141.484.117c.156.258.271.586.271.586s-.053-.469.432-.719.558-.312.558-.312l-.167.668s.578-.355.672-.559c.125.797-.469 1.367-.844 1.52.203.34.109.621.109.621l.688.562-.75.234-.334-.719s-.867.031-.993-.625.173-1.008 0-1.035c-.173-.027-.501-.104-.501-.104z" />
    <path
      fill="#FFF"
      d="M25.604 22.069l-.067.003c-.146 0-.791-.028-.89-.544-.066-.343-.01-.597.028-.765.028-.129.047-.214-.001-.284l-.024-.036-.086-.03c-.063-.01-.148-.027-.23-.045.056-.035.112-.078.159-.126.03-.029.076-.065.128-.065.065 0 .137.058.2.163.149.246.262.565.263.567l.194-.044c-.002-.017-.036-.405.378-.619.157-.081.271-.143.355-.189l-.167.67.209-.129c.07-.044.345-.216.535-.391-.021.639-.533 1.066-.798 1.174l-.107.044.06.101c.175.292.103.528.101.538l-.021.065.596.488-.489.153-.326-.699z"
    />
    <path
      fill="#00A651"
      d="M24.255 20.299c-.114-.267-.114-.267-.294-.346-.008.172 0 .281 0 .281s-.101-.328-.258-.281c-.094.375.242.454.242.454s-.148.048-.133.312c.266.108.234-.126.234-.126s-.055.24.137.378c.129-.205.072-.672.072-.672z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Fj;
