const Sh = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#EEE"
      d="M27.47 23.513c-4.601-1.797-4.426-5.971-4.239-10.389l.011-.25.206-.141c.379-.26 2.206-.765 4.204-.765 1.999 0 3.825.505 4.204.765l.206.142.011.249c.187 4.418.363 8.592-4.239 10.389l-.182.071-.182-.071z"
    />
    <path
      fill="#FF0"
      d="M31.572 13.146c-.274-.188-1.982-.677-3.921-.677-1.939 0-3.647.489-3.921.677-.189 4.479-.279 8.261 3.921 9.901 4.202-1.641 4.111-5.422 3.921-9.901z"
    />
    <path
      fill="#8FC5FF"
      d="M23.668 16c.047 3.191.713 5.77 3.983 7.047 3.271-1.277 3.938-3.855 3.983-7.047h-7.966z"
    />
    <path
      fill="#366CC9"
      d="M27.651 23.047c1.724-.673 2.72-1.711 3.287-3.006h-6.574c.568 1.295 1.564 2.333 3.287 3.006z"
    />
    <path
      fill="#00D860"
      d="M27.614 20.793c-.098 0-.177.079-.177.177s.079.177.177.177h2.672c.087-.115.17-.233.246-.353h-2.918zm-.322.906c0 .098.079.177.177.177h2.143c.128-.114.247-.232.359-.353h-2.503c-.097 0-.176.079-.176.176zm-.459.73c0 .098.079.177.177.177h1.566c.199-.112.387-.229.562-.354H27.01c-.098 0-.177.079-.177.177z"
    />
    <path
      fill="#5D3100"
      d="M24.562 17.886c.168-.449.541-1.084.688-1.104.146-.021 1.746 3.113 1.621 3.26s-1.912.24-2.267.178-.042-2.334-.042-2.334z"
    />
    <path
      fill="#CF6200"
      d="M26.438 20.562c-.125-.396-2.104-4.084-2.229-4.084-.088 0-.319.34-.493.644.112 1.519.44 2.864 1.232 3.948.667-.056 1.578-.227 1.49-.508z"
    />
    <path
      d="M27.099 16.271h.131v3.792h-.131zm1.333-.105h.131v3.897h-.131zm1.229.105h.131v3.792h-.131z"
      fill="#FF0"
    />
    <path d="M25.312 19.098c.198.055 1.039.521 1.559.677.625.188 1.546.163 1.796.163s.728-.729.937-.729h1.251c.188 0 .124 1.25-.354 1.25h-3.375c-.313 0-1.375-.979-1.583-1.083s-.407-.327-.231-.278z" />
    <path
      d="M27.651 16.677c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.088.198.198zm1.325-.198c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109-.001.198.088.198.198zm1.361.396c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm0 1.094c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.088.198.198zm0 .725c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm-1.361-1.486c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm0 .761c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.088.198.198zm0 .725c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm-1.325-1.288c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm0 .761c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109 0 .198.089.198.198zm0 .726c0 .109-.089.198-.198.198h-.692c-.109 0-.198-.089-.198-.198 0-.109.089-.198.198-.198h.692c.109-.001.198.088.198.198z"
      fill="#EEE"
    />
    <path
      fill="#AC5F1A"
      d="M25.867 13.241c.023-.165.446-.032.612-.178.166-.146.617-.498.984-.042.205.255.153.43.382.639.229.209 1.446.736 1.842.611-.021.25-.375.375-.375.375s.335.249.377.541c.042.292-.502-.453-1.273-.249-.308.082-.651.403-1.276.249s-.642-1.093-.658-1.487c-.005-.143-.63-.349-.615-.459z"
    />
    <path
      fill="#EEE"
      d="M26.18 13.156c.03-.134.364.004.51-.104.144-.106.537-.363.805.033.148.221.095.36.268.546.171.185 1.126.697 1.457.623-.033.201-.331.279-.331.279s.255.226.27.467c.015.239-.377-.404-1.018-.29-.256.046-.558.284-1.055.115-.499-.167-.449-.933-.436-1.255.003-.116-.491-.327-.47-.414z"
    />
    <path d="M27.375 15.66c0 .041-.033.074-.074.074-.041 0-.075-.033-.075-.074v-.47c0-.041.034-.074.075-.074.041 0 .074.033.074.074v.47zm.554 0c0 .041-.033.074-.074.074-.041 0-.075-.033-.075-.074v-.47c0-.041.034-.074.075-.074.041 0 .074.033.074.074v.47zm-.659-2.394c0 .078-.063.141-.141.141-.078 0-.141-.062-.141-.141 0-.078.062-.141.141-.141.077 0 .141.063.141.141z" />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Sh;
