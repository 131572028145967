import { forwardRef, FC, CSSProperties } from 'react';
import Spin from '@/core/Spin';
import Text from '@/core/Text';
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Option } from './antd';
import * as S from './styles';

interface SelectProps {
  error?: any;
  fetching?: boolean;
  options?: any;
  ref: any;
  status?: any;
}

/**
 * Fix the suffix icon not being clickable to open the dropdown.
 * @see https://github.com/ant-design/ant-design/issues/27479
 */
const suffixIconStyle: CSSProperties = { pointerEvents: 'none' };

const Select = forwardRef<FC, SelectProps>(
  ({ error = null, fetching = false, ...others }, ref) => {
    return (
      <>
        <S.Select
          ref={ref}
          suffixIcon={<CaretDownOutlined style={suffixIconStyle} />}
          notFoundContent={
            fetching ? (
              <Spin indicator={<LoadingOutlined />} width={24} height={24} />
            ) : null
          }
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={children => <S.Dropdown>{children}</S.Dropdown>}
          {...others}
        />
        {error && <Text textType="error">{error}</Text>}
      </>
    );
  },
);

export { Option };

export default Select;
