const Bi = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EEE"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#CE1126"
      d="M13.685 13.068c1.15-1.028 2.662-1.658 4.325-1.658 1.646 0 3.145.617 4.291 1.626l10.833-7.854C32.772 5.075 32.397 5 32 5H4c-.411 0-.8.08-1.174.195l10.859 7.873z"
    />
    <path
      fill="#1EB53A"
      d="M24.038 15.483c.303.751.473 1.569.473 2.427 0 .928-.198 1.809-.548 2.608l11.544 8.369c.304-.564.493-1.2.493-1.887V9c0-.67-.181-1.292-.472-1.847l-11.49 8.33zm-11.999 4.994c-.339-.788-.528-1.655-.528-2.566 0-.842.165-1.644.457-2.383L.456 7.182C.174 7.73 0 8.342 0 9v18c0 .675.183 1.301.478 1.859l11.561-8.382z"
    />
    <path
      fill="#CE1126"
      d="M22.157 22.916c-1.125.933-2.57 1.495-4.146 1.495-1.593 0-3.051-.575-4.181-1.526L2.881 30.822c.357.104.728.178 1.119.178h28c.377 0 .734-.069 1.079-.166l-10.922-7.918z"
    />
    <path
      fill="#1EB53A"
      d="M19.077 15.266l.317-.548.216-.375h-1.066l-.317-.548-.216-.375-.217.375-.316.548h-1.067l.217.375.316.548-.316.549-.217.375h1.067l.316.548.217.375.216-.375.317-.548h1.066l-.216-.375z"
    />
    <path
      fill="#CE1126"
      d="M19.177 14.593H18.4l-.389-.673-.389.673h-.778l.389.673-.389.674h.778l.389.673.389-.673h.777l-.389-.674z"
    />
    <path
      fill="#1EB53A"
      d="M21.928 19.805l.317-.549.217-.375h-1.067l-.316-.548-.217-.375-.216.375-.317.548h-1.066l.216.375.317.549-.317.548-.216.375h1.066l.317.548.216.376.217-.376.316-.548h1.067l-.217-.375z"
    />
    <path
      fill="#CE1126"
      d="M20.862 18.458l-.389.673h-.777l.388.674-.388.673h.777l.389.673.389-.673h.778l-.389-.673.389-.674h-.778z"
    />
    <path
      fill="#1EB53A"
      d="M16.542 20.353l-.316-.548.316-.549.217-.375h-1.066l-.317-.548-.217-.375-.216.375-.317.548H13.56l.216.375.317.549-.317.548-.216.375h1.066l.317.548.216.376.217-.376.317-.548h1.066z"
    />
    <path
      fill="#CE1126"
      d="M16.326 19.131h-.778l-.389-.673-.388.673h-.778l.389.674-.389.673h.778l.388.673.389-.673h.778l-.389-.673z"
    />
  </svg>
);

export default Bi;
