const Bq = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EEEDED"
      d="M1.369 30.011L34.633 5.99C33.93 5.376 33.006 5 32 5H13.25L0 15.833V27c0 1.203.529 2.278 1.369 3.011z"
    />
    <path
      fill="#012A87"
      d="M34.633 5.99L1.369 30.011C2.072 30.625 2.994 31 4 31h28c2.209 0 4-1.791 4-4V9c0-.552-.112-1.078-.314-1.557-.239-.563-.599-1.057-1.053-1.453z"
    />
    <path fill="#F9D90F" d="M4 5C1.791 5 0 6.791 0 9v6.833L13.25 5H4z" />
    <path d="M16.513 15.894l1.192-.688-1.192-.688c-.312-2.515-2.31-4.513-4.825-4.825L11 8.5l-.688 1.192c-2.515.312-4.513 2.31-4.825 4.825l-1.192.688 1.192.688c.312 2.515 2.31 4.513 4.825 4.825L11 21.912l.688-1.192c2.515-.313 4.513-2.311 4.825-4.826zM11 20.048c-2.67 0-4.843-2.172-4.843-4.842 0-2.67 2.172-4.842 4.843-4.842s4.842 2.172 4.842 4.842c0 2.67-2.172 4.842-4.842 4.842z" />
    <path
      fill="#DC171D"
      d="M12.914 15.206l.957-1.658h-1.914L11 11.891l-.957 1.657H8.129l.957 1.658-.957 1.657h1.914L11 18.521l.957-1.658h1.914z"
    />
  </svg>
);

export default Bq;
