const Ar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#75AADB"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#FCBF49"
      d="M18 13l.488 2.548 1.426-2.167-.525 2.54 2.146-1.457-1.457 2.147 2.541-.524-2.167 1.425L23 18l-2.548.488 2.167 1.426-2.541-.525 1.457 2.146-2.146-1.457.525 2.541-1.426-2.167L18 23l-.488-2.548-1.425 2.167.524-2.541-2.147 1.457 1.457-2.146-2.54.525 2.167-1.426L13 18l2.548-.488-2.167-1.425 2.54.524-1.457-2.147 2.147 1.457-.524-2.54 1.425 2.167z"
    />
    <path
      fill="#843511"
      d="M18 14.33l.242 1.265.116.605.339-.514.708-1.076-.261 1.261-.125.604.51-.346 1.066-.723-.723 1.066-.346.51.603-.125 1.262-.26-1.076.708-.515.337.605.116L21.67 18l-1.265.242-.605.116.514.339 1.076.708-1.262-.261-.604-.125.346.51.723 1.065-1.065-.723-.51-.346.125.604.261 1.262-.708-1.076-.338-.515-.116.605L18 21.67l-.242-1.265-.116-.605-.339.515-.708 1.076.26-1.262.125-.603-.51.346-1.066.723.723-1.066.346-.51-.604.125-1.261.261 1.076-.708.514-.339-.605-.116L14.33 18l1.265-.242.605-.116-.515-.339-1.076-.708 1.261.26.603.125-.346-.51-.724-1.066 1.066.724.51.346-.125-.603-.26-1.261.708 1.076.339.515.116-.605L18 14.33M18 13l-.488 2.548-1.425-2.167.524 2.541-2.147-1.457 1.457 2.147-2.54-.524 2.167 1.425L13 18l2.548.488-2.167 1.426 2.54-.525-1.457 2.146 2.147-1.457-.524 2.541 1.425-2.167L18 23l.488-2.548 1.426 2.167-.525-2.541 2.146 1.457-1.457-2.146 2.541.525-2.167-1.426L23 18l-2.548-.488 2.167-1.425-2.541.524 1.457-2.147-2.146 1.457.525-2.541-1.426 2.167L18 13zm1.914.381h.005-.005zm1.621 1.083h.005-.005zm1.084 1.623h.005-.005z"
    />
    <circle fill="#FCBF49" cx="18" cy="18" r="2" />
    <path
      fill="#843511"
      d="M18 20.125c-1.172 0-2.125-.953-2.125-2.125s.953-2.125 2.125-2.125 2.125.953 2.125 2.125-.953 2.125-2.125 2.125zm0-4c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z"
    />
    <path
      fill="#C16540"
      d="M17.801 17.774c0 .155-.261.28-.583.28-.323 0-.584-.125-.584-.28 0-.155.261-.28.584-.28.322 0 .583.125.583.28zm1.553-.024c0-.161-.266-.292-.594-.292-.328 0-.594.13-.594.292s.266.292.594.292c.329 0 .594-.131.594-.292z"
    />
    <path
      fill="#ED8662"
      d="M17.463 18.874c0-.126.246-.229.548-.229.303 0 .548.102.548.229 0 .126-.246.229-.548.229-.303 0-.548-.103-.548-.229z"
    />
  </svg>
);

export default Ar;
