const Gi = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#DA1C0B"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4v-5h36v5z"
    />
    <path
      fill="#EEE"
      d="M32 5H4C1.791 5 0 6.791 0 9v14h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#DA1C0B"
      d="M14.451 10.363v.779h-.778v-.779h-1.557v.779h-.779v-.779h-.778v1.557h.778v3.892h3.114V11.92h.779v-1.557z"
    />
    <path
      fill="#DA1C0B"
      d="M15.241 14.255v.779h-.779v-.779h-.778v.779h-1.557v-.779h-.779v.779h-.778v-.779h-.778v1.557-.001.001h.778v4.254l-.778.416v.779h6.227v-.779l-.778-.416v-4.254h.778v-1.557z"
    />
    <path d="M12.116 13.477h1.557v1.557h-1.557z" />
    <path d="M13.673 13.477c0 .43-.348.778-.778.778-.43 0-.778-.348-.778-.778 0-.43.348-.778.778-.778.43-.001.778.348.778.778zm0 4.903c0-.621-.157-1.125-.778-1.125s-.778.504-.778 1.125v1.325h1.557V18.38h-.001z" />
    <path
      fill="#DA1C0B"
      d="M24.91 10.363v.779h-.778v-.779h-1.557v.779h-.778v-.779h-.779v1.557h.779v3.892h3.113V11.92h.78v-1.557z"
    />
    <path
      fill="#DA1C0B"
      d="M25.69 14.255v.779h-.78v-.779h-.778v.779h-1.557v-.779h-.778v.779h-.779v-.779h-.778v1.557-.001.001h.778v4.254l-.778.416v.779h6.227v-.779l-.777-.416v-4.254h.777v-1.557z"
    />
    <path d="M22.575 13.477h1.557v1.557h-1.557z" />
    <path d="M24.132 13.477c0 .43-.349.778-.778.778-.43 0-.778-.348-.778-.778 0-.43.349-.778.778-.778.429-.001.778.348.778.778zm0 4.903c0-.621-.157-1.125-.778-1.125s-.778.504-.778 1.125v1.325h1.557V18.38h-.001z" />
    <path fill="#DA1C0B" d="M20 8v1h-1V8h-2v1h-1V8h-1v2h1v5h4v-5h1V8z" />
    <path fill="#DA1C0B" d="M21 13v1h-1v-1h-1v1h-2v-1h-1v1h-1v-1h-1v9h8v-9z" />
    <path d="M17 12h2v2h-2z" />
    <circle cx="18" cy="12" r="1" />
    <path d="M19.444 18.298c0-.798-.646-1.445-1.444-1.445s-1.444.647-1.444 1.445v2.167h2.889v-2.167h-.001z" />
    <path
      fill="#FFF100"
      d="M18.162 23.349v.133l1.079 1.078-1.079 1.078v4.717h-.348v-.527h-1.191v-.707h.348v.361h.843v-1.049h-.843v.355h-.348v-.702h1.191v-2.45l-1.08-1.076 1.08-1.074v-.137z"
    />
    <path fill="#DA1C0B" d="M17.24 24.557l.748-.748.748.749-.749.748z" />
    <path
      fill="#FFF100"
      d="M18.147 21.32h-.294c-.243 0-.44.3-.44.669v1.338c0 .369.197.669.44.669h.294c.243 0 .44-.3.44-.669v-1.338c0-.369-.197-.669-.44-.669zm.11 2.029c0 .209-.111.377-.247.377s-.248-.168-.248-.377V22c0-.207.111-.375.248-.375s.247.168.247.375v1.349z"
    />
    <path
      fill="#FFF100"
      d="M19.326 20.265c-.107-.06-.25-.058-.318.003L18 21.177l-1.008-.909c-.068-.061-.211-.062-.318-.003-.108.059-.14.156-.072.218l1.232 1.108c.038.034.1.045.166.041.066.004.127-.007.166-.041l1.232-1.108c.069-.062.037-.16-.072-.218z"
    />
  </svg>
);

export default Gi;
