const Uz = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#0099B5"
      d="M0 9v4h36V9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9z"
    />
    <path
      fill="#1EB53A"
      d="M36 27v-4H0v4c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4z"
    />
    <path fill="#CE1126" d="M0 13h36v1.5H0zm0 8.5h36V23H0z" />
    <path fill="#EEE" d="M0 18v3.5h36v-7H0z" />
    <path
      fill="#FFF"
      d="M4.2 9.16c0-1.546 1.125-2.828 2.6-3.076-.169-.028-.343-.044-.52-.044-1.723 0-3.12 1.397-3.12 3.12s1.397 3.12 3.12 3.12c.177 0 .351-.016.52-.044-1.475-.248-2.6-1.53-2.6-3.076zm4.683 2.303l-.14-.431-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267zm2.702 0l-.14-.431-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454zm2.843 2.927l-.14-.431-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267zm-.14-2.927l-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267h-.453zm0-2.496l-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267h-.453zm2.843 5.423l-.14-.431-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267h-.453zm0-2.496l-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267h-.453zm2.842 5.423l-.14-.431-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454zm0-2.496l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454z"
    />
  </svg>
);

export default Uz;
