const Bn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#F7E017"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#141414" d="M36 27L0 19v-5l36 8z" />
    <path fill="#EEE" d="M36 22L0 14V9l36 8z" />
    <g fill="#CF1B26">
      <path d="M21.596 14.935c.443.688.699 1.507.699 2.386 0 2.44-1.978 4.419-4.418 4.419-2.441 0-4.419-1.979-4.419-4.419 0-.879.257-1.698.699-2.386-.838.905-1.351 2.116-1.351 3.447 0 2.801 2.27 5.071 5.071 5.071 2.801 0 5.071-2.271 5.071-5.071 0-1.331-.513-2.542-1.352-3.447zm-11.893.571c.537.118.656.323.629.758 0 0 .273.053.463.325.191.272.367.543.693.652.327.109.585.325.585.978v2.42s-.06.245.076.57c.136.327.059.789-.947.789s-1.012-.435-.877-.761c.136-.326.293-.599.293-.599v-2.08s-.196-.285-.468-.476c-.272-.19-.846-.87-.927-1.523-.082-.651-.234-1.209.48-1.053zm16.257 0c-.537.118-.656.323-.629.758 0 0-.274.053-.464.325s-.268.543-.594.652c-.326.109-.485.325-.485.978v2.42s-.039.245-.176.57c-.136.327-.107.789.898.789 1.006 0 1.087-.435.951-.761s-.219-.599-.219-.599v-2.08s.097-.285.368-.476c.273-.19.796-.87.878-1.523.083-.651.185-1.209-.528-1.053z" />
      <path d="M23.435 24.738s.325-.652-.028-.788c-.354-.136-1.06 0-1.06 0s.589-.747.026-1.061c-.561-.313-.947.462-1.439.815-.491.354-.986.549-1.586.748-.598.199-1.378.285-1.378.285s-.875-.086-1.473-.285c-.599-.199-1.188-.395-1.68-.748s-.877-1.129-1.439-.815c-.562.313.028 1.061.028 1.061s-.707-.136-1.06 0c-.354.136-.026.788-.026.788-.788.109-.513.599-.513.599s.196-.253 1.012-.253 1.153.797 1.697.797.54-.517.54-.517c1.007.843 2.915.843 2.915.843s1.814 0 2.82-.843c0 0-.051.517.493.517s.857-.797 1.674-.797c.815 0 1 .253 1 .253s.267-.489-.523-.599zm-2.16-10.571c-.598-.163-1.238-.924-2.081-.924s-1.225.653-1.225.653-.467-.653-1.31-.653-1.569.761-2.168.924c-.598.164-1.169 0-1.169 0 .136.625.835.768.835.768.074.388.659.401.659.401.079.462.927.144.927.144s-.053.455.382.4c.435-.054 1.172-.816 1.172-.816s-.212.218-.184.415c.027.198.365.183.365.183-.299.299.241.653.241.653l.25-.19.165.19s.497-.353.197-.653c0 0 .316.015.344-.183.027-.198-.195-.415-.195-.415s.732.762 1.167.816c.436.054.38-.4.38-.4s.847.319.927-.144c0 0 .584-.014.657-.401 0 0 .699-.143.835-.768-.001 0-.572.164-1.171 0zm-4.254-2.447c0-.121.383-.218.857-.218.473 0 .857.097.857.218 0 .12-.384.217-.857.217-.474.001-.857-.097-.857-.217z" />
      <path d="M18.882 12.155c-.027-.191-.142-.218-.142-.218s-.449.116-.863.116c-.415 0-.857-.116-.857-.116s-.109.027-.136.218c-.027.19-.217.775-.217.775l.354-.388.299.388.299-.388.258.388.265-.388.303.388.3-.388.355.388s-.19-.584-.218-.775zm-.913-2.324v1.06l.729.313-.281-.435.572-.273zm-.31 3.099h.449v1.142h-.449zm.816 8.076s.294-.407.233-.714c-.061-.306-.346-.789-.427-1.098-.082-.309.198-.595.198-.595l-.217-.041s.292-.265.217-.551c-.075-.286-.217-.652-.258-.978-.041-.327.258-.714.258-.714l-.387.224-.211-.224-.205.224-.38-.224s.299.387.258.714c-.041.326-.184.693-.258.978-.075.286.218.551.218.551l-.218.041s.279.286.197.595c-.081.309-.367.792-.428 1.098-.061.307.231.714.231.714l-.584-.122c.319.652.692.733 1.169.733.477 0 .856-.081 1.175-.733l-.581.122z" />
    </g>
  </svg>
);

export default Bn;
