const Va = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#FDDF00"
      d="M19 31H9c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h10v26z"
    />
    <path
      fill="#EEE"
      d="M31 27c0 2.209-1.791 4-4 4h-9V5h9c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#A1A1A1" d="M28.816 14.234l.329-.609-.672.305z" />
    <path
      fill="#A1A1A1"
      d="M28.846 13.682c0 .137.11.248.247.248s.247-.111.247-.248c0-.136-.11-.247-.247-.247s-.247.111-.247.247zm-.576.145c-.116.115-.057.362.131.549.188.189.434.247.549.131.116-.115.058-.361-.131-.549-.186-.188-.433-.248-.549-.131z"
    />
    <path
      fill="#CB9C2B"
      d="M22.561 19.562c.379.359.701-.105 1.306-.641.553-.49 4.188-3.87 4.578-4.214.391-.344.028-.864-.425-.442s-4.801 4.062-5.127 4.359c-.327.298-.752.54-.332.938z"
    />
    <path
      fill="#CB9C2B"
      d="M28.348 14.558l.437.488-.219.188.219.187.219-.187.312.344v.343l-.609.517-.359-.235v-.282h-.297v.267l.156.015.141.156.015.36-.54.437-1.108-1.051zm-8.688 6.103c0 .822.667 1.489 1.489 1.489.822 0 1.489-.667 1.489-1.489 0-.822-.667-1.489-1.489-1.489-.822 0-1.489.667-1.489 1.489zm.954.001c-.267-.004-.482-.22-.482-.486 0-.27.219-.488.488-.488.27 0 .488.219.488.488l-.001.007c.014-.001.026-.009.041-.009.001-.268.22-.486.488-.486.27 0 .488.219.488.488 0 .267-.215.482-.481.486.267.005.481.221.481.487 0 .269-.219.488-.488.488s-.487-.219-.488-.487c-.015 0-.027-.008-.042-.009l.002.008c0 .269-.219.488-.488.488-.27 0-.488-.219-.488-.488.001-.266.216-.482.482-.487z"
    />
    <path
      fill="#A1A1A1"
      d="M22.122 19.247c-.116.115-.057.362.131.549.188.188.434.247.549.131.116-.115.058-.361-.131-.549-.187-.188-.434-.247-.549-.131zm-2.394.188c0 .141.114.255.256.255.141 0 .255-.114.255-.255 0-.142-.114-.256-.255-.256-.142 0-.256.114-.256.256zm2.223 2.461c0 .141.114.255.256.255.141 0 .255-.114.255-.255 0-.142-.114-.256-.255-.256-.142 0-.256.114-.256.256zm-2.291-.083c0 .141.114.255.256.255.141 0 .255-.114.255-.255 0-.142-.114-.256-.255-.256-.142.001-.256.115-.256.256z"
    />
    <path
      fill="#B4B4B5"
      d="M26.77 17.083c.139-.091.245-.215.316-.37.299-.652-.072-1.701-.299-2.106-.277-.497-.107-.821.026-.976-.037-.014-.082-.025-.131-.025-.109 0-.215.053-.312.158-.292.311-.154.726.253 1.669.306.707.425 1.217.147 1.65z"
    />
    <path
      fill="#B4B4B5"
      d="M26.211 14.043c.143-.208.363-.324.633-.324.219 0 .445.082.635.226l.28-.422c-.124-.103-.423-.317-.761-.317-.089 0-.175.015-.258.044-.383.137-.512.49-.529.793z"
    />
    <path fill="#CB9C2B" d="M20.184 14.234l-.329-.609.672.305z" />
    <path
      fill="#CB9C2B"
      d="M20.154 13.682c0 .137-.11.248-.247.248s-.247-.111-.247-.248c0-.136.11-.247.247-.247s.247.111.247.247zm.575.145c.116.115.057.362-.131.549-.188.189-.434.247-.549.131-.116-.115-.058-.361.131-.549.187-.188.434-.248.549-.131z"
    />
    <path
      fill="#A1A1A1"
      d="M26.439 19.562c-.379.359-.701-.105-1.306-.641-.553-.49-4.188-3.87-4.578-4.214-.391-.344-.028-.864.425-.442s4.801 4.062 5.127 4.359c.327.298.752.54.332.938z"
    />
    <path
      fill="#A1A1A1"
      d="M20.652 14.558l-.437.488.219.188-.219.187-.219-.187-.312.344v.343l.609.517.359-.235v-.282h.297v.267l-.156.015-.141.156-.015.36.54.437 1.108-1.051zm7.199 4.614c-.822 0-1.489.667-1.489 1.489 0 .822.667 1.489 1.489 1.489.822 0 1.489-.667 1.489-1.489 0-.822-.667-1.489-1.489-1.489zm1.016 1.977c0 .269-.219.488-.488.488-.27 0-.488-.219-.488-.488l.002-.008c-.015.001-.027.009-.042.009-.001.268-.219.487-.488.487s-.488-.219-.488-.488c0-.267.215-.482.481-.487-.267-.004-.481-.22-.481-.486 0-.27.219-.488.488-.488s.487.218.488.486c.015 0 .027.008.041.009l-.001-.007c0-.27.219-.488.488-.488.27 0 .488.219.488.488 0 .267-.215.482-.481.486.266.005.481.221.481.487z"
    />
    <path
      fill="#CB9C2B"
      d="M26.878 19.247c.116.115.057.362-.131.549-.188.188-.434.247-.549.131-.116-.115-.058-.361.131-.549.187-.188.434-.247.549-.131zm2.394.188c0 .141-.114.255-.256.255-.141 0-.255-.114-.255-.255 0-.142.114-.256.255-.256.142 0 .256.114.256.256zm-2.223 2.461c0 .141-.114.255-.256.255-.141 0-.255-.114-.255-.255 0-.142.114-.256.255-.256.142 0 .256.114.256.256zm2.291-.083c0 .141-.114.255-.256.255-.141 0-.255-.114-.255-.255 0-.142.114-.256.255-.256.142.001.256.115.256.256z"
    />
    <path d="M25.918 13.719c0 1.467-.639 1.427-1.428 1.427-.788 0-1.427.04-1.427-1.427s.639-3.052 1.427-3.052c.789 0 1.428 1.585 1.428 3.052z" />
    <path
      fill="#FFF"
      d="M24.427 15.046c-.424 0-.72-.013-.923-.17-.232-.181-.341-.548-.341-1.157 0-1.49.657-2.952 1.327-2.952.67 0 1.328 1.462 1.328 2.952 0 1.327-.533 1.327-1.208 1.327h-.183z"
    />
    <path
      fill="#CB9C2B"
      d="M25.236 14.042c-.5-.146-.419-.625-.746-.625s-.246.479-.746.625c-.499.146-.746-.083-.746-.083l.233.958s.419-.354 1.259-.354c.84 0 1.259.354 1.259.354l.233-.958c0-.001-.247.228-.746.083z"
    />
    <path
      fill="#CB9C2B"
      d="M25.318 12.774c-.555-.153-.465-.658-.828-.658-.363 0-.274.505-.828.658-.554.153-.827-.087-.827-.087l.258 1.008s.466-.373 1.397-.373c.932 0 1.397.373 1.397.373l.258-1.008c0 .001-.273.241-.827.087z"
    />
    <path
      fill="#CB9C2B"
      d="M25.187 11.445c-.467-.129-.392-.554-.697-.554-.306 0-.23.425-.697.554-.466.129-.696-.073-.696-.073l.218.849s.392-.236 1.176-.236c.784 0 1.176.236 1.176.236l.218-.849c-.001 0-.232.202-.698.073zm-.875-.947c0 .148.08.269.178.269.098 0 .178-.12.178-.269 0-.149-.08-.269-.178-.269-.097 0-.178.12-.178.269z"
    />
    <path
      fill="#CB9C2B"
      d="M24.041 10.206c0 .102.201.185.449.185s.449-.083.449-.185c0-.103-.201-.186-.449-.186-.248.001-.449.084-.449.186z"
    />
    <path
      fill="#CB9C2B"
      d="M24.312 9.935c0 .103.08.186.178.186.098 0 .178-.083.178-.186s-.08-.185-.178-.185c-.097 0-.178.083-.178.185z"
    />
    <path
      fill="#B4B4B5"
      d="M22.211 17.083c-.139-.091-.245-.215-.316-.37-.299-.652.072-1.701.299-2.106.277-.497.107-.821-.026-.976.037-.014.082-.025.131-.025.109 0 .215.053.312.158.292.311.154.726-.253 1.669-.306.707-.425 1.217-.147 1.65z"
    />
    <path
      fill="#B4B4B5"
      d="M22.77 14.043c-.144-.208-.363-.324-.633-.324-.219 0-.445.082-.635.226l-.28-.422c.124-.103.423-.317.761-.317.089 0 .175.015.258.044.382.137.511.49.529.793z"
    />
    <path
      fill="#EC1C24"
      d="M24.49 17c.2.154.246.75.246 1.453v5.484s.307.078.213.891c-.027.232-.484.188-.562 0-.078-.188-.04-.791.104-.875V18.5c0-.812-.136-1.127-.178-1.281-.043-.154.081-.294.177-.219z"
    />
    <path
      fill="#EC1C24"
      d="M26.375 20.799c-.196-.137-.471-.482-.707-.812-.312-.438-.391-.736-.701-1.111-.31-.375-1.122-1.152-1.314-1.125-.076.011-.156.188-.083.25s.302.312.817.703c.516.391.594.828 1.024 1.453.397.577.74.971 1.049 1.024-.045-.121-.072-.249-.085-.382z"
    />
    <path
      fill="#EC1C24"
      d="M25.36 17.551c-.183.175-.749.729-1.057 1.089-.308.36-.698 1.22-1.042 1.61-.221.25-.46.464-.642.596-.017.136-.046.267-.097.389.248-.083.602-.311 1.046-.953.614-.891.911-1.547 1.208-1.797s.416-.324.583-.498c.168-.175.185-.612.001-.436z"
    />
    <path
      fill="#EC1C24"
      d="M24.124 18.281v5.781c-.331.266-.237.707-.175.924.062.217.603.216.687.108.084-.108.107-.844-.202-1.094v-5.359c0-.141-.31-.575-.31-.36z"
    />
    <path
      fill="#EC1C24"
      d="M22.095 20.982c-.297.207-.552.564.112.83s1.543.625 2.283.625 2.123-.502 2.447-.728c.324-.226.464-.495-.052-.647-.271-.08-.312.125-.171.172s.305.193-.051.323c-.355.13-1.168.511-2.23.511s-2.062-.453-2.226-.511c-.164-.058-.209-.245-.093-.323s.114-.344-.019-.252z"
    />
    <path
      fill="#CB9C2B"
      d="M24.856 23.759c0 .117-.095.211-.212.211-.116 0-.211-.094-.211-.211 0-.117.095-.212.211-.212.118 0 .212.095.212.212z"
    />
    <path
      fill="#CB9C2B"
      d="M24.5 23.899c0 .117-.095.211-.212.211-.116 0-.211-.094-.211-.211 0-.117.095-.212.211-.212.117.001.212.095.212.212z"
    />
  </svg>
);

export default Va;
