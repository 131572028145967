import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link as AntdLink } from './antd';

export const Link = styled(AntdLink)`
  ${({ theme }) => {
    return css`
      font-family: ${theme.fontFamily}, sans-serif;
      font-size: ${theme.fontSizeP};
      &.ant-typography {
        color: ${theme.linkColor};
        &:hover {
          color: ${theme.primaryColorHover};
        }
        &:visited {
          color: ${theme.linkColor};
          &:hover {
            color: ${theme.primaryColorHover};
          }
        }
      }
    `;
  }}
`;
