const Rw = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#20603D"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#FAD201" d="M0 19h36v7H0z" />
    <path
      fill="#00A1DE"
      d="M32 5H4C1.791 5 0 6.791 0 9v12h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FAD201"
      d="M26 5l.522 4.034 1.548-3.762-.539 4.032L30 6.072l-1.565 3.755 3.221-2.484-2.483 3.222L32.929 9l-3.234 2.469 4.033-.54-3.762 1.548L34 13l-4.034.523 3.762 1.547-4.033-.539L32.929 17l-3.756-1.565 2.483 3.222-3.221-2.484L30 19.929l-2.469-3.234.539 4.033-1.548-3.762L26 21l-.522-4.034-1.549 3.762.54-4.033L22 19.929l1.565-3.756-3.221 2.484 2.482-3.222L19.071 17l3.233-2.469-4.032.539 3.761-1.547L18 13l4.033-.523-3.761-1.548 4.032.54L19.071 9l3.755 1.565-2.482-3.222 3.221 2.484L22 6.072l2.469 3.232-.54-4.032 1.549 3.762z"
    />
    <circle fill="#00A1DE" cx="26" cy="13" r="4" />
    <circle fill="#FAD201" cx="26" cy="13" r="3" />
  </svg>
);

export default Rw;
