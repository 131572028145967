const Eg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#141414"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path
      fill="#CE1225"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#BF9300"
      d="M14.75 21.562s.016.25.234.375c0 0-.062.188.172.297.234.109 1.078.281 2.547.281s2.297-.156 2.516-.266c.219-.109.234-.359.234-.359s.234-.125.219-.281c-.016-.156-.328-.328-.328-.328s-.078-.203-.297-.281c-.219-.078-.922.344-2.266.281-1.344-.062-2.109-.266-2.359-.25-.25.016-.359.266-.359.266s-.282.125-.313.265z"
    />
    <path
      fill="#EEE"
      d="M14.922 21.547c-.013.093.188.297.188.297s0 .234.203.297 1.031.219 2.375.203c1.344-.016 2.297-.094 2.406-.188.109-.094.203-.297.203-.297s.219-.156.203-.281-.328-.203-.328-.203-.088-.188-.192-.266-.776.312-2.214.312c-1.407 0-2.219-.344-2.359-.328-.141.016-.234.281-.234.281s-.235.064-.251.173z"
    />
    <path
      fill="#BF9300"
      d="M20.859 15.484s-.031-.734-.703-.641c-.672.094-.719.422-1.438.5l-.148.014c-.108-.285-.196-.697-.196-1.014 0-.469.141-.672-.344-.906-.484-.234-.578-.016-.578-.016s-.375-.188-.547-.047c-.172.141-.078.5 0 .391.078-.109.391.203.391.203.106.519-.195 1.081-.401 1.394-.071-.007-.128-.01-.208-.019-.719-.078-.766-.406-1.438-.5-.672-.094-.703.641-.703.641l-.266 5.672.547-.422.003-.067 1.138-1.011.19-.19-.471 1.377s-.719-.047-.516.531c0 0 .109-.328.297-.266s.734.203.734.203l.188.297.234-.219.734-.031s.234.062.219.266c.049-.113.055-.206.037-.283l.221-.015c-.022.08-.018.178.034.299-.016-.203.219-.266.219-.266l.734.031.235.219.188-.297s.546-.141.733-.203.297.266.297.266c.203-.578-.516-.531-.516-.531l-.449-1.313.126.126 1.138 1.011.003.067.547.422-.265-5.673zm-3.107 3.438c-1.127-.696-1.22-2.453-1.22-2.453s.697-.036 1.203-.391c.456.405 1.234.359 1.234.359s-.053 1.764-1.217 2.485zm2.576-3.656l-.953.906-.16.064c-.164.006-.962.008-1.465-.439-.625.438-1.484.469-1.484.469l-.133-.053-1.055-.947-.277.114c.012-.113.074-.367.418-.317.564.081.797.391 1.391.453l.168.017-.059.076c.092 0 .228-.027.328-.049l.113.011-.051.178c.08-.024.196-.093.291-.154l.26.025.059.082.198-.099.133-.013c.115.067.279.127.279.127l.031-.156c.141.094.375.094.375.094-.027-.022-.054-.078-.082-.126l.144-.015c.594-.062.826-.372 1.391-.453.344-.049.406.204.418.317l-.278-.112z"
    />
    <path
      fill="#EEE"
      d="M17.375 18.891l-.563 2.14-.937-.187.969-2.75zm.699 0l.562 2.14.938-.187-.969-2.75z"
    />
    <path
      fill="#BF9300"
      d="M16.766 16.641s.078.906.484 1.609v-1.812s-.266.156-.484.203zm1.953.031s-.078.906-.484 1.609v-1.812c-.001 0 .265.156.484.203z"
    />
    <path
      fill="#EEE"
      d="M16.953 13.578s.109-.109.422.109c.203.142.383.25.383.25s.128-.135.316-.104c.188.031.083.292.114.838.031.547.25.781.25.781l-.266-.156.016.172-.297-.141-.141.203-.141-.219-.228.139-.069-.186-.266.141s.391-.484.422-1.016c.009-.159-.031-.516-.031-.516s-.249-.311-.484-.295z"
    />
    <path
      fill="#BF9300"
      d="M15.547 21.656c-.179.107-.109.172 0 .219s.765.126 2.094.156c1.359.031 2.203-.125 2.312-.188.109-.062.125-.172-.062-.203-.188-.031-1.125.125-2.266.125-1.406.001-2-.156-2.078-.109z"
    />
  </svg>
);

export default Eg;
