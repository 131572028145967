const Rs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#0C4076" d="M0 13h36v10H0z" />
    <path
      fill="#EEE"
      d="M0 27c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-4H0v4z"
    />
    <path
      fill="#C6363C"
      d="M36 9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9v4h36V9zM7 13v9.5c0 3.037 2.462 5.5 5.5 5.5s5.5-2.463 5.5-5.5V13H7z"
    />
    <path
      fill="#EDB92E"
      d="M12.5 7.062c-3.938 0-5.172 1.672-4.844 2.297.328.625 1.312 2.234 1.312 2.234s-.312.125 0 .75 1.531.203 3.531.203 3.219.422 3.531-.203 0-.75 0-.75.984-1.609 1.313-2.234c.329-.625-.905-2.297-4.843-2.297zm-3.438 2.969c-.283.088 0 .953-.1.579-.082-.309-.4-.438-.4-.266s-.125 0-.094-.312c.031-.313-.062-.5-.25-.766-.187-.266-.187-.5.048-.657.234-.156.75-.281.75-.281s.203.891.312 1.094c.109.203.312.336.312.336s-.094.148 0 .226c.094.078.203.266.203.266s-.343-.281-.405-.219c-.062.062.078.141.062.25-.016.11-.188-.328-.438-.25zm3.188.016c-.078.078-.141-.063-.297-.172-.156-.109-.25-.046-.219.079s-.188.109-.188.297-.203.109-.203.109.25-.656-.172-.641c-.239.009-.156.5 0 .672-.069-.105-.266-.109-.43-.234s.023-.329-.102-.282c-.125.047-.203.25-.328.297s-.062-.142 0-.297c.062-.156-.072-.234-.072-.234s.01-.109.104-.172c.094-.062.125-.172 0-.297s-.577-.828-.437-1.063c.141-.234.609-.5 1.922-.469.234 1.078-.125 1.609-.125 1.609s.266.172.344.266c-.188.079-.047.157.078.266.125.11.203.188.125.266zm2.438-.172c.062.156.125.344 0 .297s-.203-.25-.328-.297c-.125-.047.062.157-.102.282-.164.125-.36.129-.43.234.156-.172.239-.663 0-.672-.422-.016-.172.641-.172.641s-.203.078-.203-.109-.219-.172-.188-.297-.062-.188-.219-.079c-.156.109-.219.25-.297.172-.078-.078 0-.156.125-.266.126-.109.267-.187.079-.265.078-.094.344-.266.344-.266s-.359-.531-.125-1.609c1.312-.031 1.781.234 1.922.469.141.234-.312.938-.438 1.062s-.094.234 0 .297c.094.062.104.172.104.172s-.135.078-.072.234zm2.093-.609c-.188.266-.281.453-.25.766.031.312-.094.484-.094.312s-.318-.042-.4.266c-.1.374.183-.49-.1-.579-.25-.078-.578.672-.5.594s.188-.531.125-.594c-.062-.063-.406.219-.406.219s.109-.188.203-.266c.094-.078 0-.226 0-.226s.203-.133.312-.336c.109-.203.312-1.094.312-1.094s.516.125.75.281.236.391.048.657z"
    />
    <path
      fill="#FFAC33"
      d="M12.609 7.242c0 .099-.081.18-.18.18-.099 0-.18-.081-.18-.18V5.664c0-.099.081-.18.18-.18.099 0 .18.081.18.18v1.578z"
    />
    <path
      fill="#EDB92E"
      d="M12.969 6.086c0 .091-.073.164-.164.164h-.781c-.091 0-.164-.073-.164-.164 0-.091.073-.164.164-.164h.781c.091 0 .164.073.164.164z"
    />
    <path
      fill="#0C4076"
      d="M13.016 7.062c0 .332-.269.602-.602.602-.332 0-.602-.27-.602-.602s.269-.602.602-.602c.332.001.602.27.602.602zm-3.412 5.214c0 .147-.169.266-.378.266s-.378-.119-.378-.266c0-.146.169-.265.378-.265s.378.118.378.265z"
    />
    <path
      fill="#C6363C"
      d="M11.125 12.185c0 .147-.169.266-.378.266s-.378-.119-.378-.266c0-.146.169-.265.378-.265.208 0 .378.119.378.265zm3.375 0c0 .147-.169.266-.378.266s-.378-.119-.378-.266c0-.146.169-.265.378-.265.208 0 .378.119.378.265z"
    />
    <path
      fill="#0C4076"
      d="M16.062 12.276c0 .147-.169.266-.378.266s-.378-.119-.378-.266c0-.146.169-.265.378-.265s.378.118.378.265zm-3.254-.11c0 .147-.169.266-.378.266s-.378-.119-.378-.266c0-.147.169-.265.378-.265s.378.118.378.265z"
    />
    <path
      fill="#EDB92E"
      d="M10.286 22.562c-.342.776-.378 1.158-.783 1.131-.406-.027-.692-.096-.692.068 0 .163.645.095.645.095s-.609.26-.489.559c.119.3.645-.381.645-.381s.107.027.31-.137c.203-.163.74-.953.74-.953l-.376-.382zm4.412 0c.342.776.378 1.158.783 1.131.406-.027.692-.096.692.068 0 .163-.644.095-.644.095s.609.26.489.559c-.119.3-.645-.381-.645-.381s-.107.027-.31-.137c-.203-.163-.74-.953-.74-.953l.375-.382z"
    />
    <path
      fill="#FFF"
      d="M17.114 15.688c-.176-1.208-1.313-.833-1.796 0-.483.833-.9 1.333-1.347.75-.447-.583-.742-1.208-.096-2l.646-.792c-.092-.479-.479-.375-1.033-.146-.554.229-.904.125-.849.375.056.25.258.271.258.271s-.349.34-.441 1.513c-.092-1.173-.441-1.513-.441-1.513s.203-.021.258-.271c.055-.25-.295-.146-.849-.375s-.941-.333-1.033.146l.646.792c.646.792.351 1.417-.096 2-.446.583-.864.146-1.347-.688-.483-.833-1.621-1.208-1.796 0-.176 1.208.028 3.312.028 3.312s-.111 1.146.111 2.125c.221.979.351 1.854 0 2.604.387-.104.627-.604.627-.604s-.129.604.111.542c.24-.062.295-.521.479-.688.185-.166.333-.541.333-.541s.388-.146 0 .5c.314.021.517-.188.517-.188l.11.584.185-.312.092.562.332-.542.218.25s.041-1.062.336-.854c.295.208.313.896.111 1.312-.203.416 0 .584 0 .584s-.295.438-.446.688c-.151.25.078.541.078.541s-.572.854-.413 1.188c.154.32 1.107.524 1.804.539l.006.003.039-.001.039.001.006-.003c.697-.015 1.65-.219 1.804-.539.16-.333-.413-1.188-.413-1.188s.228-.291.078-.541c-.151-.25-.447-.688-.447-.688s.203-.168 0-.584c-.202-.416-.184-1.104.111-1.312.295-.208.335.854.335.854l.218-.25.332.542.092-.562.185.312.11-.584s.203.208.517.188c-.388-.646 0-.5 0-.5s.148.375.332.542.24.625.48.688c.24.062.111-.542.111-.542s.24.5.627.604c-.35-.75-.221-1.626 0-2.604.222-.979.111-2.125.111-2.125s.206-2.167.03-3.375z"
    />
    <path
      fill="#EDB92E"
      d="M11.891 14.312c-.44-.422-1.538-.969-1.902-.891-.364.079-.656.329-.385.72.271.391.385.375.385.375s.433-.391.621-.172c.188.219.031.297-.312.328-.344.031-.693-.203-.693-.203s.113.406.59.453c.321.312.726.078.93.078s1.156-.312.766-.688zm1.131 0c.44-.422 1.538-.969 1.902-.891.364.078.656.328.385.719-.271.391-.385.375-.385.375s-.433-.391-.621-.172c-.188.219-.031.297.312.328.344.031.693-.203.693-.203s-.113.406-.59.453c-.321.313-.726.079-.93.079s-1.157-.312-.766-.688zM9.969 25.199c.137-.316.041-.361-.069-.471-.111-.109-.166-.262-.166-.262s-.055.152-.166.262c-.111.109-.207.154-.07.471-.333-.197-.388.164-.235.383.043-.197.305-.152.305.088s-.217.131-.191.229c.027.099.225.306.356.306.131 0 .33-.207.356-.306.027-.099-.19.011-.19-.229 0-.24.261-.285.305-.088.154-.219.099-.58-.235-.383zm5.438 0c.137-.316.041-.361-.07-.471-.111-.109-.166-.262-.166-.262s-.055.152-.166.262c-.111.109-.207.154-.07.471-.333-.197-.388.164-.235.383.043-.197.305-.152.305.088s-.217.131-.19.229c.026.099.225.306.356.306.131 0 .33-.207.356-.306.027-.099-.19.011-.19-.229 0-.24.261-.285.305-.088.153-.219.099-.58-.235-.383z"
    />
    <path
      fill="#C6363C"
      d="M10.244 16.875v3.859c0 1.233 1 2.234 2.234 2.234s2.234-1.001 2.234-2.234v-3.859h-4.468z"
    />
    <path
      fill="#EEE"
      d="M14.712 19.172H12.96v-2.297h-.962v2.297h-1.753v.963h1.753v2.779c.155.034.315.055.481.055.166 0 .326-.021.482-.055v-2.779h1.753v-.963z"
    />
  </svg>
);

export default Rs;
