const Ws = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CE1126"
      d="M32 5H19v13H0v9c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#002B7F"
      d="M19 5H4C1.791 5 0 6.791 0 9v9h19V5zM9.076 6.591l.341-1.049.341 1.049h1.103l-.893.649.341 1.049-.892-.648-.893.648.341-1.049-.893-.649h1.104zm-2.739 4.948l-.893-.649-.893.649.341-1.049L4 9.841h1.103l.341-1.049.341 1.049h1.103l-.892.649.341 1.049zm4.196 5.919l-1.116-.811-1.116.811.426-1.312-1.116-.811H8.99l.426-1.312.426 1.312h1.379l-1.116.811.428 1.312zm1.428-4.152l-.558-.406-.558.405.213-.656-.558-.405h.69l.213-.656.213.656h.69l-.558.405.213.657zm2.355-2.48l-.837-.608-.837.608.32-.984-.837-.608h1.034l.32-.984.32.984h1.034l-.837.608.32.984z"
    />
    <path
      fill="#FFF"
      d="M8.524 8.289l.893-.648.892.648-.341-1.049.893-.649H9.758l-.341-1.049-.341 1.049H7.972l.893.649zM5.785 9.841l-.341-1.049-.341 1.049H4l.893.649-.341 1.049.892-.648.893.648-.341-1.049.893-.649zm8.014-.607l-.32-.984-.32.984h-1.034l.837.608-.32.984.837-.608.837.608-.32-.984.837-.608zm-2.183 3.01l-.213-.656-.213.656h-.69l.558.406-.213.656.558-.406.558.406-.213-.656.558-.406zm-1.773 3.092l-.426-1.312-.427 1.312H7.611l1.116.81-.426 1.312 1.116-.81 1.116.81-.427-1.312 1.116-.81z"
    />
  </svg>
);

export default Ws;
