import { FC } from 'react';
import * as S from './styles';

interface ButtonProps {
  block?: boolean;
  children: any;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon?: any;
  loading?: boolean;
  onClick?: any;
  size?: string;
  type?: string;
}

const Button: FC<ButtonProps> = ({ children, ...others }) => {
  return <S.Button {...others}>{children}</S.Button>;
};

export default Button;
