const Tk = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#FFF"
      d="M3.354 11.146l.342 1.102 1.154-.008-.943.665L4.271 14l-.924-.69-.928.684.371-1.092-.938-.671 1.153.015zM8.101 18l.44 1.417 1.483-.01-1.212.857.469 1.407-1.189-.887-1.195.879.477-1.404-1.207-.864 1.484.019zm.186-11.481l.342 1.101 1.153-.008-.942.666.364 1.095-.924-.69-.928.684.37-1.093-.938-.671 1.154.015zm4.777 4.401l.242.78.816-.006-.667.471.258.775-.654-.488-.658.484.263-.773-.664-.475.816.01z"
    />
    <path
      fill="#FED100"
      d="M32 29H5s-.266-.172-.266-.5S5 28 5 28h27l1.484.5L32 29zM5 27s5-2 11-8S26 9 28 8c-1.753 1.753-3.771 14 1.229 18 0 0 2.771.521 2.771 1H5z"
    />
  </svg>
);

export default Tk;
