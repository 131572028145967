const BackIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1394 7.67486L8.37379 11.8467C8.34983 11.864 8.33032 11.8867 8.31686 11.913C8.3034 11.9393 8.29639 11.9684 8.29639 11.9979C8.29639 12.0274 8.3034 12.0565 8.31686 12.0828C8.33032 12.1091 8.34983 12.1318 8.37379 12.1491L14.1394 16.321C14.2636 16.41 14.437 16.321 14.437 16.1686V15.0694C14.437 14.8303 14.3222 14.603 14.1277 14.4624L10.7199 11.9991L14.1277 9.53345C14.3222 9.39283 14.437 9.16783 14.437 8.92642V7.8272C14.437 7.67486 14.2636 7.58579 14.1394 7.67486Z"
      fill="#237804"
    />
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="#237804"
    />
  </svg>
);

export default BackIcon;
