const Km = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#3A75C4"
      d="M0 18v9c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-9H0z"
    />
    <path
      fill="#FFC61E"
      d="M36 18V9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9v9h36z"
    />
    <path fill="#CE1126" d="M0 18h36v6.5H0z" />
    <path fill="#EEE" d="M0 11.5h36V18H0z" />
    <path
      fill="#3D8E33"
      d="M1.351 6.004H1.35C.522 6.737 0 7.808 0 9v18c0 1.193.522 2.264 1.351 2.997L17.5 18 1.351 6.004z"
    />
    <g fill="#FFF">
      <path d="M3.926 18c0-2.627 1.837-4.822 4.295-5.38-.395-.09-.805-.142-1.227-.142-3.05 0-5.522 2.472-5.522 5.522s2.472 5.522 5.522 5.522c.422 0 .832-.052 1.227-.142-2.458-.558-4.295-2.753-4.295-5.38z" />
      <path d="M7.62 14.875l.601-.437.601.437-.23-.706.601-.437H8.45l-.229-.707-.23.707h-.743l.601.437zm0 2.635l.601-.437.601.437-.23-.707.601-.436H8.45l-.229-.707-.23.707h-.743l.601.436zm0 2.635l.601-.437.601.437-.23-.707.601-.437H8.45l-.229-.706-.23.706h-.743l.601.437zm.601 2.198l.601.436-.23-.706.601-.437H8.45l-.229-.706-.23.706h-.743l.601.437-.229.706z" />
    </g>
  </svg>
);

export default Km;
