import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Progress as AntdProgress } from './antd';

export const Progress = styled(AntdProgress)`
  ${({ theme }) => {
    return css`
      .ant-progress-text {
        color: #389e0d;
        font-family: ${theme.fontFamily}, sans-serif;
        font-size: ${theme.fontSizeP};
        font-weight: 400;
        line-height: 20px;
      }
      .ant-progress-circle {
        background-color: ${theme.gray1};
        circle:nth-of-type(2) {
          &.ant-progress-circle-path {
            ${theme.borderRedTest};
            stroke: ${theme.processingColor};
          }
        }
      }
      .ant-progress-circle-path {
        stroke: ${theme.processingColor};
      }
      .ant-progress-circle-trail {
        stroke: ${theme.processingColor};
      }
      .ant-progress-circle-trail {
        stroke: ${theme.greenBackground};
      }
      &.ant-progress-status-success {
        .ant-progress-circle {
          background-color: ${theme.greenBackground};
        }
        .ant-progress-text {
          color: ${theme.processingColor};
          font-size: 20px;
        }
      }
    `;
  }}
`;
