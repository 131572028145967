const Ba = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#2D3189"
      d="M32 5H9.177l-.665.484.365 1.123-.956-.695-.956.695.365-1.123L6.665 5H4C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h19.725l.105-.324-.955-.695h1.181l.365-1.123.365 1.123h1.181l-.955.695.105.324H32c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#E1E8ED"
      d="M6.965 6.607l.956-.695.955.695-.364-1.123L9.177 5H6.665l.665.484zm19.002 23.374h-1.181l-.365-1.122-.365 1.122h-1.181l.955.695-.105.324h1.392l-.106-.324zM9.999 6.815l.364 1.123h1.182l-.956.695.365 1.123-.955-.695-.956.695.365-1.123-.955-.695h1.18zm2.069 3.15l.365 1.122h1.182l-.956.695.365 1.123-.956-.694-.955.694.365-1.123-.956-.695h1.181zm2.054 3.148l.365 1.123h1.181l-.956.695.365 1.123-.955-.695-.956.695.365-1.123-.955-.695h1.181zm2.061 3.15l.365 1.123h1.181l-.955.694.365 1.123-.956-.694-.955.694.365-1.123-.956-.694h1.181zm2.061 3.149l.365 1.123h1.182l-.956.694.365 1.124-.956-.695-.955.695.365-1.124-.955-.694h1.181zm2.062 3.149l.365 1.123h1.181l-.955.694.364 1.123-.955-.694-.955.694.364-1.123-.955-.694h1.181zm2.053 3.148l.365 1.124h1.182l-.956.694.365 1.123-.956-.694-.955.694.365-1.123-.956-.694h1.181z"
    />
    <path fill="#FBD116" d="M28 31V5H11z" />
  </svg>
);

export default Ba;
