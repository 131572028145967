const Vu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#D21034"
      d="M32 5H4C1.791 5 0 6.791 0 9v9h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#009543"
      d="M4 31h28c2.209 0 4-1.791 4-4v-9H0v9c0 2.209 1.791 4 4 4z"
    />
    <path
      fill="#141414"
      d="M17 15L1.732 5.705c-.474.327-.874.754-1.17 1.25l-.002.002C.205 7.555 0 8.254 0 9v18c0 .747.205 1.447.562 2.045l.001.003c.301.504.71.937 1.195 1.265h.001L17 21h19v-6H17z"
    />
    <path
      fill="#FDCE12"
      d="M.562 6.955l-.002.002C.205 7.555 0 8.254 0 9l15 9-15 9c0 .747.205 1.447.562 2.045L17 19h19v-2H17L.562 6.955z"
    />
    <path
      fill="#FDCE12"
      d="M4.375 21.606s3.25-.5 3.25-3.71c0-1.974-1.708-3.062-3.042-3.062-1.333 0-2.75 1-2.75 2.75s1.625 2.292 2.417 2.292 2-.625 1.833-2c-.464 1.913-2.413 1.858-3.167.708-.833-1.271.459-3.084 2.126-2.584 1.579.474 1.661 2.081 1.125 3.084-.667 1.25-1.771 1.188-1.771 1.188l-.021 1.334z"
    />
    <path
      fill="#FDCE12"
      d="M5.376 18.021c-.183-.242-.407-.461-.636-.653.492-.505.911-.648.781-.722-.089-.051-.549.156-1.041.516-.492-.36-.952-.567-1.041-.516-.13.075.289.218.781.722-.229.192-.453.41-.636.653-.199.263-.167.708-.167.708s.269-.108.5-.333c.187-.181.347-.446.563-.723.216.277.376.542.563.723.231.225.5.333.5.333s.032-.445-.167-.708z"
    />
  </svg>
);

export default Vu;
