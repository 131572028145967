const Gs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#CDAD57"
      d="M26.656 10c-.135-.104-.344-.234-.641-.406-.297-.172-.359-.531-.219-.797.141-.266.475-.359.393-.109-.081.25-.169.557 0 .583.169.026.122-.333.278-.317.156.016-.156.578 0 .594.156.016.109-.5.281-.438s.141.516 0 .547.018.234.119.343c.102.109-.07.108-.211 0zm.799 0c.135-.104.344-.234.641-.406.297-.172.359-.531.219-.797-.141-.266-.475-.359-.394-.109s.169.557 0 .583-.122-.333-.278-.317c-.156.016.156.578 0 .594-.156.016-.109-.5-.281-.438-.172.062-.141.516 0 .547.141.031-.016.234-.117.344-.102.108.07.107.21-.001z"
    />
    <path
      fill="#923F00"
      d="M26.917 10.875c.091-.348-.021-.687-.292-.583s-.521-.062-.479-.146.355-.333.771-.333.957.229.916.333-.5.198-.5.198.063.406.834.406 1.416.188 1.271 1.125-.188 1.062-.312 1.021-.146-.354-.083-.625-.104-.646-.188-.375-.251.792-.459.833.083-.792.062-.979-.5-.146-.521.146.037.771-.138.896c-.175.125-.195-.292-.175-.583s-.187-.75-.208-.354-.042.896-.229.958 0-.479-.062-.812-.312-.73-.208-1.126z"
    />
    <path
      fill="#FFF"
      d="M27.516 15.609c-.266.25-1.266.812-1.656-.109.938.375 1.491-.438 1.491-.438l.165.547zm-1.375-2.218C25.726 12.109 24.797 12 24.797 12s-.047.406.156.695c-.67-.062-1.328-.367-1.328-.367-.188.531.938 1.094 1.625 1.062-.328.328-.016.61.297.665-.156-.383.344-.639.453-.339s.141-.325.141-.325z"
    />
    <path
      fill="#1500FF"
      d="M26.312 15.597c-.843.091-1.442-1.203-1.439-1.587.503.147.909.491.909.491s.133-1.142.238-1.422c.418.313.527 1.188.387 1.594.459.022.547.406.406.688-.219-.314-.451-.017-.501.236z"
    />
    <path
      fill="#FFF"
      d="M28.745 15.609c.266.25 1.266.812 1.656-.109-.938.375-1.491-.438-1.491-.438l-.165.547zm1.375-2.218C30.535 12.109 31.464 12 31.464 12s.047.406-.156.695c.67-.062 1.328-.367 1.328-.367.188.531-.938 1.094-1.625 1.062.328.328.016.61-.297.665.156-.383-.344-.639-.453-.339-.11.3-.141-.325-.141-.325z"
    />
    <path
      fill="#1500FF"
      d="M29.949 15.597c.843.091 1.442-1.203 1.44-1.587-.503.147-.909.491-.909.491s-.133-1.142-.238-1.422c-.418.313-.527 1.188-.387 1.594-.459.022-.547.406-.406.688.218-.314.449-.017.5.236z"
    />
    <path
      fill="#656263"
      d="M28.203 17.406c-1.297 0-1.75-.594-1.625-1s.562-.188.703-.297c.141-.109.141-.828-.188-1.156-.328-.328-.531-.672-.391-.844-.188-.312.107-.453.194-.812.087-.359.18-.694.316-.734.137-.04.208.297.255.234.047-.062.1-.344.331-.359.231-.016.2.234.278.297.078.062.141-.29.359-.297.219-.008.281.297.375.313.094.016.125-.225.312-.188s.125.516.25.719c.125.203.391.594.234.828.219.156-.016.438-.281.641-.266.203-.578.703-.391 1.266.172.375.828-.016.984.219.16.233.098 1.17-1.715 1.17z"
    />
    <path
      fill="#FFF"
      d="M26.559 13.891c0-.375 1.004-.5 1.566-.5s1.594.13 1.594.5-.785 0-1.58 0-1.58.359-1.58 0z"
    />
    <path
      fill="#1500FF"
      d="M27.588 13.423c-.176.02-.353.051-.511.093-.052.169-.022.353.069.493.151-.025.321-.055.503-.08-.105-.124-.147-.306-.061-.506zm.49-.031v.502c.021-.001.04-.003.061-.003.192 0 .383.022.563.05.056-.062.095-.133.095-.218 0-.119-.073-.219-.17-.304-.182-.02-.534-.027-.549-.027zm.97.606c.392.07.671.112.671-.108 0-.194-.285-.321-.636-.398.058.072.104.149.104.231.001.116-.065.204-.139.275z"
    />
    <path
      fill="#ADB0B2"
      d="M26.662 14.286c.041-.176 1.477.808 1.477.808s1.519-1.061 1.534-.917c.015.144-1.534 1.182-1.534 1.182s-1.518-.897-1.477-1.073z"
    />
    <path
      fill="#6A4C2D"
      d="M26.688 18.438c-.688.656-3.438 3.031-3.594 3.531-.156.5.172.625.656.297-.172.391.047.547.156.562.109.016.562-.141.75-.547.203.328.719.375.812.312.094-.062.141-.172.141-.172s.09.471.264.407c.174-.063.328-.313.439-.298.11.016-.007.234.247.219.254-.016.357-.125.493-.266.136-.141.354.062.464.031.109-.031.219-.703.391-1.188.171-.482-1.219-2.888-1.219-2.888z"
    />
    <path
      fill="#FFF"
      d="M29.188 20.875c.459.104.969-.021 1.214.125s.432.271.786.292 1.021-.291 1.167 0 .333.708.521.771.896.291.188.333-2.291 0-2.291 0l.188.25-1.386.084s-.813-.709-.776-1.209.389-.646.389-.646z"
    />
    <path
      fill="#00713D"
      d="M27.516 20.875c-.565.342-.302.771-.302.771s.091.584.137.521c.046-.062.299-.271.299-.271s-.044.466-.037.447c.007-.019.309.001.309.001s.11.409.157.491c.047.082.308-.189.308-.189s.25.16.359.205c.109.045.151-.288.151-.288s.296.378.486.272c.19-.105.43-.418.43-.418l-.354-.312s.062-.082 0-.239c-.062-.157-.291-.22-.291-.22s.229-.166.062-.271-.783-1.063-1.714-.5z"
    />
    <path
      fill="#FFF"
      d="M30.065 16.984l-.287-.002L28.267 18l-1.512-1.018-.25.001-.13.001v2.219c0 .193.034.497.146.833.088.265.226.548.441.82.105.133.232.26.375.382.198.168.443.317.729.446.069.031.127.069.202.097.078-.029.138-.068.209-.101.285-.13.53-.279.728-.447.149-.127.281-.26.388-.398.215-.278.334-.572.431-.833.118-.317.136-.613.136-.8v-2.219l-.095.001z"
    />
    <path
      fill="#1E5AA6"
      d="M30.161 17.921l-.408-.53.313-.407-.287-.002L28.267 18l-1.512-1.018-.25.001.313.407-.443.576v.036l.429.557-.429.557v.036l.413.537-.267.347c.088.265.226.548.441.82l.387-.503.335.434-.347.45c.198.168.443.317.729.446l.207-.269.204.265c.285-.13.53-.279.728-.447l-.343-.445.347-.451.384.499c.215-.278.346-.566.431-.833l-.242-.314.378-.491v-.127l-.394-.511.394-.511.001-.127zm-.582.638l-.37.481-.37-.481.37-.481.37.481zm-.724 1.129l.354-.461.354.461-.354.461-.354-.461zm-1.149-1.129l-.357.464-.357-.464.357-.464.357.464zm-.699 1.129l.342-.444.342.444-.342.444-.342-.444zm.909 1.1l.356-.463.356.463-.356.463-.356-.463z"
    />
    <path
      fill="#016C00"
      d="M28.268 17.25c-.403-.246-1.011-.269-1.513-.268l1.506 4.234c0 .018.012.018.012 0l1.506-4.234c-.502-.001-1.11.022-1.511.268z"
    />
    <path
      fill="#8B9497"
      d="M23.922 17.641c.036-1 .203-1.5.188-1.891s.328-.391.594-.469c.266-.078.391.141.609.156.219.016.344.047.281.312s-.266.608-.312.703c-.047.094.266.688.516 1.188s.609 1.141.344 2.141c-.266 1-.422 1.359-.531 1.438-.109.078-.025-.656-.122-1s-.222-.422-.222-.422-.422.398-.578.902c-.156.504-.031.871.266 1.078.297.207.969.332 1.078.395.109.062-.219.203-.672.172-.453-.031-1.525-.412-1.927-.839-.401-.427-.949-1.125-.636-1.896.172-.422.5-.562.609-.703.109-.14.504-.966.515-1.265z"
    />
    <path
      fill="#FFF"
      d="M31.531 15.601c.341-.047 1.188 2.133 1.281 3.071.094.938.078 2.625-.484 2.688-.562.062-1.068-.156-1.245-1.094-.177-.938-.042-1.656 0-2.266s-.052-2.329.448-2.399z"
    />
    <path d="M31.389 15.601c.024-.4.408-.539.861-.46.453.078.422.781.406 1.109-.016.328.711.25.715 2.016.004 1.766-.496 2.766-.356 3.016.141.25.781.578.422.641s-.812-.312-1.125-.422.027-.547.154-1.078.064-1.111-.201-1.04c-.266.071.109 1.36-.328 1.317-.438-.043-.672-1.009-.625-1.893.047-.884.219-.947.469-1.228.25-.281.094-.406-.172-.703s-.172-.531.109-.688c.282-.157-.363-.048-.329-.587z" />
    <path
      fill="#FFF"
      d="M30.994 17.109c-.375.031-.76.141-.76.297s-.016.672 0 .734.531-.265.76-.25v-.781z"
    />
    <path
      fill="#F66B01"
      d="M31.531 15.601c.092-.483-.781-.023-.828.102-.047.125.735.39.828-.102z"
    />
    <path
      fill="#F8F001"
      d="M31.797 15.359c.047-.14 1.094-.312 1.266-.297s.162-.022.115.057c-.017.028-.515.096-.521.145-.014.087.439.032.516.095.051.042-.504.037-.41.106.125.093.598.305.459.253-.242-.09-1.502-.131-1.425-.359z"
    />
    <path
      fill="#F66B01"
      d="M31.63 21.233c-.233.043-.958-.011-.708.136.25.146.328.162.328.162s-.449.164-.256.246c.193.082.637.031.931.025.294-.006.51-.171.388-.303s-.334-.329-.683-.266z"
    />
    <path
      fill="#C11600"
      d="M25.054 25.078c.562.109 1.297.344 1.297.344l-.094 1.016s-.234-.344-1.469-.562c-.141-.407.266-.798.266-.798zm.187-1.703s.016.328-.422.703-.937.344-.906-.078c.422-.109.312-.946.663-1.054-.038.351.103.585.665.429zm6.473 1.703c-.562.109-1.297.344-1.297.344l.094 1.016s.234-.344 1.469-.562c.14-.407-.266-.798-.266-.798z"
    />
    <path
      fill="#FFBC00"
      d="M31.464 24.188c.359-.391.609-.375.797-.75s-.094-.672-.578-.583c-.484.089-.984.864-.984.864s-.921-.547-2.314-.547c-1.394 0-2.315.547-2.315.547s-.5-.775-.984-.864c-.484-.089-.766.208-.578.583s.438.359.797.75c-.578.391-1.047 1.172-1.047 1.172l.531.516s.297-.5.922-1.016c.547.625.547 1.578.547 1.578s.164-.672.418-.891c-.012-.609-.184-1.062-.184-1.062s.816-.5 1.893-.5c1.076 0 1.893.5 1.893.5s-.172.453-.184 1.062c.254.219.418.891.418.891s0-.953.547-1.578c.625.516.922 1.016.922 1.016l.531-.516c-.001-.001-.47-.782-1.048-1.172z"
    />
    <path
      fill="#C11600"
      d="M31.526 23.375s-.016.328.422.703.938.344.906-.078c-.422-.109-.312-.946-.663-1.054.038.351-.102.585-.665.429z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Gs;
