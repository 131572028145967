const Om = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="green" d="M8 31h24c2.209 0 4-1.791 4-4v-4H8v8z" />
    <path fill="#EEE" d="M32 5H8v8h28V9c0-2.209-1.791-4-4-4z" />
    <path
      fill="#DB161B"
      d="M36 13H9V5H4C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h5v-8h27V13z"
    />
    <g fill="#EEE">
      <path d="M3.625 8.453c.313.567.313.789.222.839-.09.05-.238-.09-.55-.657-.313-.567-.672-1.345-.582-1.395.091-.051.597.647.91 1.213zm1.891-.349c-.313.567-.493 1.067-.402 1.117.09.05.417-.369.73-.935.312-.567.493-1.067.402-1.117-.091-.05-.418.369-.73.935zm-.563.912l-.297-1.422-.028-.006c.103-.049.184-.126.184-.237 0-.16-.147-.289-.328-.289s-.328.13-.328.29c0 .108.075.193.175.243l-.284 1.436.906-.015z" />
      <path d="M6.703 11.766c-.271-.226-1.349-1.159-1.861-1.716.046-.16.073-.332.062-.516h.017v.008h.891v.172h1.094v-.531H5.812v.172h-.89v.008h-.031v-.269h-.813v.269H3.75v.016h-.641v-.22H2.078v.578h1.031v-.218h.641v.016h.276l-.036.374s-1.039.046-1.318.091c-.688.109-.469.312.094.5.259.086.517.12.761.133-.397.424-1.207 1.053-1.402 1.164-.219.125-.219.281.203.188.409-.091 1.355-.926 1.684-1.348l.285-.012c.15-.006.269-.1.369-.224.427.505 1.264 1.254 1.6 1.458.359.219.527.214.654.193.127-.021.064-.052-.217-.286z" />
    </g>
  </svg>
);

export default Om;
