import { FC } from 'react';
import { Col, Row, ColProps, RowProps } from './antd';

interface GridProps extends ColProps, RowProps {
  children: any;
  type?: string;
}

const Grid: FC<GridProps> = ({ type = null, children, ...others }) => {
  switch (type) {
    case 'col':
      return <Col {...others}>{children}</Col>;
    case 'row':
      return <Row {...others}>{children}</Row>;
    default:
      return <div>{children}</div>;
  }
};

export default Grid;
