import CoreSelect from '@/core/Select';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Flag = styled.span`
  font-size: 22px;
`;

export const Select = styled(CoreSelect)`
  .ant-select-selector {
    background-color: inherit !important;
    border: inherit !important;
    min-height: unset !important;
  }
`;

export const WrapperFlag = styled.div`
  ${({ theme }) => {
    return css`
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${theme.primaryColor};
      font-weight: 600;
      svg {
        width: 24px;
      }
    `;
  }}
`;
