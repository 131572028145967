const Mw = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#77B255"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#DD2E44" d="M0 13h36v10H0z" />
    <path
      fill="#141414"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      d="M23.191 12C22.153 10.208 20.22 9 18 9c-2.22 0-4.153 1.208-5.191 3h10.382zm-5.493-6h.603L18 8.163zm-1.514.18l.594-.102.068 2.183zm-1.463.433l.569-.201.435 2.14zm-1.368.673l.527-.293.79 2.036zm-1.235.895l.47-.378 1.122 1.873zm-1.067 1.09l.401-.452 1.421 1.658zm-.866 1.255l.317-.513 1.681 1.393zM10.898 12l-1.13-.65-.226.559.364.091zm15.355 0l.218-.054-.221-.557-1.074.611zm-.731-1.951l.312.512-2.001.872zm-.943-1.198l.394.451-1.826 1.198zm-1.132-1.023l.464.38-1.596 1.487zm-1.288-.816l.521.296-1.323 1.735zm-1.408-.588l.564.204-1.011 1.934zm-1.487-.341l.59.106-.67 2.077z"
      fill="#DD2E44"
    />
  </svg>
);

export default Mw;
