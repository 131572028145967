import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Upload as AntdUpload } from './antd';

export const Upload = styled(AntdUpload)`
  ${({ theme }) => {
    return css`
      .ant-btn {
        border-radius: ${theme.borderRadius};
        color: ${theme.gray8};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeButton};
        font-weight: 600;
        padding: ${theme.paddingButton};
        border: 1px solid ${theme.primaryColor};
        &:hover {
          border: 1px solid ${theme.primaryColorHover};
          background-color: rgba(${theme.primaryColorRGB}, 0.2);
          color: ${theme.primaryColor};
        }
        &.ant-btn-icon-only {
          height: 24px;
          padding: 4px;
          border: 0;
          span {
            width: 1em;
          }
          &:hover {
            border: 1px solid ${theme.primaryColorHover};
            span {
              svg {
                fill: ${theme.primaryColor};
              }
            }
          }
        }
      }
    `;
  }}
`;
