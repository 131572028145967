const Ck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      d="M27.803 11.783l.812-.59h-1.004l-.31-.955-.31.955h-1.004l.812.59-.31.954.812-.589.812.589zm2.562.741l.982-.208-.917-.409.105-.998-.672.746-.916-.409.501.87-.672.745.982-.208.502.869zm2.05 1.762l.983.206-.674-.744.5-.87-.916.41-.673-.745.107.998-.916.411.982.206.107.998zm1.144 2.4l.816.585-.316-.953.809-.594-1.003.006-.316-.953-.305.956-1.003.005.815.586-.305.956zm.081 2.69l.503.868.103-.998.982-.211-.918-.406.103-.999-.67.747-.918-.406.504.868-.671.747zm-1.038 2.446l.105.998.502-.869.982.209-.672-.746.503-.869-.917.408-.672-.747.105.999-.917.407zm-1.938 1.88l-.306.956.809-.593.815.587-.314-.954.809-.593-1.003.004-.314-.953-.307.956-1.003.004zm-2.52.879l-.669.748.981-.212.505.867.101-.998.981-.213-.919-.405.102-.998-.669.748-.919-.405zm-3.906-12.057l-.982-.208.917-.409-.105-.998.671.746.917-.409-.502.87.672.745-.982-.208-.501.869zm-2.051 1.762l-.982.206.673-.744-.5-.87.916.41.673-.745-.107.998.916.411-.982.206-.107.998zm-1.144 2.4l-.815.585.315-.953-.809-.594 1.004.006.315-.953.305.956 1.004.005-.815.586.305.956zm-.08 2.69l-.503.868-.104-.998-.981-.211.918-.406-.104-.999.671.747.917-.406-.503.868.67.747zM22 21.822l-.104.998-.502-.869-.982.209.671-.746-.502-.869.917.408.671-.747-.104.999.917.407zm1.939 1.88l.306.956-.81-.593-.814.587.314-.954-.81-.593 1.004.004.314-.953.306.956 1.004.004zm2.52.879l.669.748-.981-.212-.505.867-.101-.998-.981-.213.918-.405-.101-.998.669.748.918-.405z"
      fill="#FFF"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Ck;
