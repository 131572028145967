import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal as AntMessage } from './antd';

export const Message = styled(AntMessage)`
  ${({ theme }) => {
    return css`
      .ant-modal-content {
        border-radius: ${theme.borderRadius};
      }
      .ant-modal-body {
        display: flex;
        font-size: ${theme.fontSizeP};
        .anticon-close-circle {
          svg {
            fill: red;
          }
        }
      }
    `;
  }}
`;
