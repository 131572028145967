interface IOptionsObject {
  label: string;
  value: any;
}

/**
 * The country object.
 */
const Options: IOptionsObject[] = [
  {
    label: 'yes',
    value: 1,
  },
  {
    label: 'no',
    value: 0,
  },
];

export { Options };
