interface IStatesObject {
  label: string;
  value: any;
}

/**
 * The country object.
 */
const States: IStatesObject[] = [
    {
        label: 'Acre',
        value: 'AC',
    },
    {
      label: 'Alagoas',
      value: 'AL',
    },
    {
      label: 'Amapá',
      value: 'AP',
    },
    {
      label: 'Amazonas',
      value: 'AM',
    },
    {
        label: 'Bahia',
        value: 'BA',
      },
    {
      label: 'Ceará',
      value: 'CE',
    },
    {
      label: 'Distrito Federal',
      value: 'DF',
    },
    {
      label: 'Espírito Santo',
      value: 'ES',
    },
    {
      label: 'Goiás',
      value: 'GO',
    },
    {
      label: 'Maranhão',
      value: 'MA',
    },
    {
      label: 'Mato Grosso do Sul',
      value: 'MS',
    },
    {
      label: 'Mato Grosso',
      value: 'MT',
    },
    {
      label: 'Minas Gerais',
      value: 'MG',
    },
    {
      label: 'Pará',
      value: 'PA',
    },
    {
      label: 'Paraíba',
      value: 'PB',
    },
    {
      label: 'Paraná',
      value: 'PR',
    },
    {
      label: 'Pernambuco',
      value: 'PE',
    },
    {
      label: 'Piauí',
      value: 'PI',
    },
    {
      label: 'Rio de Janeiro',
      value: 'RJ',
    },
    {
      label: 'Rio Grande do Norte',
      value: 'RN',
    },
    {
      label: 'Rio Grande do Sul',
      value: 'RS',
    },
    {
      label: 'Rondônia',
      value: 'RO',
    },
    {
      label: 'Roraima',
      value: 'RR',
    },
    {
      label: 'Santa Catarina',
      value: 'SC',
    },
    {
      label: 'São Paulo',
      value: 'SP',
    },
    {
      label: 'Sergipe',
      value: 'SE',
    },
    {
      label: 'Tocantins',
      value: 'TO',
    },    
];

export { States };
