const Kh = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#032EA1"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#E01E24" d="M0 10.572h36v14.855H0z" />
    <path
      fill="#FFF"
      d="M27.021 22.897v-.902h-.542v-.901h-.496v-.586h-.226v-.451h-.438l-.238-.341v-1.599l.271-.271v-1.488l-.226.203v-.474h-.181v.226h-.359v-.723l-.429.384.136-.485-.249-1.116h-.136s-.111-.474-.337-.474c0 0 .09-.292-.091-.292s-.136.225-.136.225-.315.136-.315.473l-.18-.022-.271 1.307.191.384-.44-.384v.993H19.94v-.902l-.136.135v.316h-.315v-.316l.226-.203v-.428l-.182.191-.271-.372v-.316l-.157.157-.046-.27.226-.36-.034-.293-.258.315v-.27l.113-.248-.519-1.309-.124-.362-.191-.022v-.181s-.136-.315-.316-.315-.315.315-.315.315v.181l-.191.022-.125.362-.518 1.309.113.248v.27l-.259-.315-.035.293.226.36-.044.27-.158-.157v.316l-.27.372-.181-.191v.428l.226.203v.316h-.315v-.316l-.136-.135v.902H13.58v-.993l-.44.384.191-.384-.271-1.307-.181.022c0-.337-.315-.473-.315-.473s.045-.225-.136-.225c-.18 0-.09.292-.09.292-.226 0-.338.474-.338.474h-.135l-.248 1.116.135.485-.428-.384v.722h-.361v-.226h-.181v.474l-.225-.203v1.488l.27.271v1.599l-.239.341h-.348v.451h-.314v.586h-.452v.901h-.495v.902h-.497l-.045.991h19.035l.045-.991h-.496z"
    />
    <path
      fill="#DB7F86"
      d="M11.596 17.869v1.735h-.813l-.194.378h1.007v3.907h.342v-6.02zm13.731 2.113l-.178-.378h-.736v-1.735h-.338v6.02h.338v-3.907zm-8.222-2.113v1.735h-3.692v-1.745h-.33v6.02h.33v-3.897h3.692v3.907h.353v-6.02zm5.406 0v1.735h-3.599v-1.735h-.349v6.02h.349v-3.907h3.599v3.907h.427v-6.02z"
    />
  </svg>
);

export default Kh;
