const Cv = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#003893"
      d="M32 5H4C1.791 5 0 6.791 0 9v10h36V9c0-2.209-1.791-4-4-4z"
    />
    <path fill="#EEE" d="M0 19h36v2H0zm0 5h36v2H0z" />
    <path fill="#CF2027" d="M0 21v3h36v-3H0z" />
    <path
      fill="#003893"
      d="M4 31h28c2.209 0 4-1.791 4-4v-1H0v1c0 2.209 1.791 4 4 4z"
    />
    <path
      fill="#F7D116"
      d="M14.347 15.903l.764-.555h-.944l-.292-.898-.292.898h-.944l.764.555-.292.899.764-.555.764.555zm6.182 4.492l.764-.555h-.944l-.292-.899-.292.899h-.945l.765.555-.292.898.764-.555.764.555zm-12.364 0l.765-.555h-.945l-.292-.899-.292.899h-.944l.764.555-.292.898.764-.555.764.555zm0 4.017l.765-.555h-.945l-.292-.898-.292.898h-.944l.764.555-.292.898.764-.555.764.555zm12.364 0l.764-.555h-.944l-.292-.898-.292.898h-.945l.765.555-.292.898.764-.555.764.555zm-6.362 3.936l-.292-.898-.292.898h-.944l.764.555-.292.899.764-.555.764.555-.292-.899.764-.555zm4.001-.686l.764-.555h-.945l-.291-.898-.292.898h-.945l.764.555-.292.898.765-.555.764.555zm0-10.504l.764-.555h-.945l-.291-.898-.292.898h-.945l.764.555-.292.898.765-.555.764.555zM9.582 27.662l-.764-.555h.945l.291-.898.292.898h.945l-.764.555.292.898-.765-.555-.764.555zm0-10.504l-.764-.555h.945l.291-.898.292.898h.945l-.764.555.292.898-.765-.555-.764.555z"
    />
  </svg>
);

export default Cv;
