const Fk = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#BA6747"
      d="M23.278 22.656c.276-.516.468-1.031.692-1.188.225-.156 1.484-.031 1.729.25.245.281.12 1.734-.364 2.547-.484.812-1.387 1.125-1.725 1.062s-.557-.672-.619-.891c-.062-.219.144-.297.619-.156s1.178-1.062 1.162-1.5c-.406-.297-.762-.062-.92.297-.157.361-.574-.421-.574-.421z"
    />
    <path
      fill="#D7A896"
      d="M22.648 23.703c.066.291.338.982.63 1.366-.142-.852.495-.585.448-.241.219.062-.059-.484-.115-.797-.057-.312-.01-.922-.525-.891-.516.032-.485.354-.438.563z"
    />
    <path
      fill="#BA6747"
      d="M32.612 22.656c-.276-.516-.468-1.031-.692-1.188-.225-.156-1.484-.031-1.73.25-.245.281-.12 1.734.364 2.547.484.812 1.387 1.125 1.725 1.062.338-.062.549-.672.611-.891.062-.219-.144-.297-.619-.156s-1.17-1.062-1.154-1.5c.406-.297.762-.062.92.297.158.361.575-.421.575-.421z"
    />
    <path
      fill="#E9CFC6"
      d="M32.909 23.453c.073-.312-.297-.797-.531-1.263-.954 1.792-3.47 2.025-4.433 2.028-.963-.003-3.478-.236-4.433-2.028-.234.466-.604.95-.531 1.263.073.312 2.337 1.938 4.948 1.951v.002l.016-.001.016.001v-.002c2.611-.013 4.875-1.639 4.948-1.951z"
    />
    <path
      fill="#D7A896"
      d="M33.242 23.703c-.066.291-.338.982-.63 1.366.142-.852-.495-.585-.448-.241-.219.062.059-.484.115-.797.057-.312.01-.922.525-.891.516.032.486.354.438.563z"
    />
    <path
      fill="#FFF"
      d="M27.958 10.646H23.27v6.5c0 5.708 4.688 6.958 4.688 6.958s4.688-1.25 4.688-6.958v-6.5h-4.688z"
    />
    <path
      fill="#0072C4"
      d="M27.957 23.703c-.517-.155-4.354-1.481-4.354-6.55V11.04h8.709v6.113c0 5.022-3.872 6.398-4.355 6.55z"
    />
    <path
      fill="#FFF"
      d="M26.094 16.688c.109-.438.188-1.047-.078-1.141-.266-.094-.736-.375-.892-.875-.155-.5-.077-1.656-.218-1.734-.141-.078-.562.109-.812-.203s-.094-.423.078-.532c.172-.109.656-.359.625-.453-.031-.094.031-.243.327-.344.548-.188.985.297 1.157.484.172.188.625.438.828.453.203.016 2.656.047 2.938.078.281.031 1 .219.938 1.078s-.184 1.688-.303 1.844c-.119.156-.197.203-.197.203s.125.688.109.812c-.016.125-.234.172-.25-.047-.016-.219-.094-.734-.094-.734l-.422-.077s.016.75-.125.969c-.141.219-.5.078-.406-.078.094-.156.141-.297.141-.484s-.047-.469-.141-.5c-.094-.031-.562.156-.906.156s-1.109-.219-1.25-.094c-.141.125-.328.766-.422 1.125-.094.359-.25.141-.234-.031s.078-.75-.016-.656c-.094.094-.062.531-.125.672-.063.14-.25.109-.25.109z"
    />
    <path
      fill="#015121"
      d="M24.984 15.906s.203 1.166.969 1.672 3.979.375 4.531-.016c.553-.391 1.062-1.344 1.062-1.344s-.453 0-.609-.172c-.453.25-.719.141-.812-.016-.359.359-.328.141-.375.031-.312.359-.766.141-.906.016-.312.203-.781.078-.875-.047-.359.312-.891.234-1 .094-.234.391-.578.25-.766.125-.266.25-.375-.031-.469-.203-.218.376-.578.11-.75-.14z"
    />
    <path
      fill="#FFF"
      d="M23.422 17.375s.484.062.859-.281c.375-.344 1.016-.516 1.484 0 .469.516 1 .5 1.406.078.406-.422 1.016-.622 1.594-.045.578.577 1.078.33 1.422 0 .344-.33 1.062-.545 1.531 0 .469.545.797.249.797.249l-.203 1.438s-.141.031-.578-.344c-.438-.375-1.047-.516-1.484-.047-.438.469-1.094.484-1.562.031-.469-.453-1.016-.406-1.422-.047-.406.359-1.109.531-1.672 0-.562-.531-1.047-.266-1.328.016-.281.281-.662.391-.662.391l-.182-1.439zm.481 2.565c.124-.042.257-.11.378-.222.375-.344 1.016-.516 1.484 0 .469.516 1 .5 1.406.078.406-.422 1.016-.622 1.594-.045.578.576 1.078.329 1.422 0 .344-.33 1.062-.546 1.531 0 .094.108.182.183.263.234.081.311-.359 1.018-.419.979-.415-.272-.93-.329-1.312.081-.438.469-1.094.484-1.562.031-.469-.453-1.016-.406-1.422-.047-.406.359-1.109.531-1.672 0-.486-.46-.915-.322-1.203-.095-.045.038-.746-.905-.488-.994zm1.221 2.049c.223.014.447.109.642.323.469.516 1 .5 1.406.078.406-.422 1.016-.622 1.594-.045.578.576 1.078.329 1.422 0 .125-.12.3-.226.494-.284-.644.924-2.479 1.861-2.724 1.86-.248 0-2.239-.874-2.834-1.932z"
    />
    <path d="M24.703 12.094c.078-.172.342-.125.421 0s-.499.172-.421 0z" />
    <path
      fill="#DD8B59"
      d="M23.846 19.719c-.095.115 1.201.682 1.201.682s.115.615.219.537.547.007.547.007.106 1.837 2.156 1.837c2.05 0 2.601-1.963 2.601-1.963s.74.009.827.054c.087.045-.178-.243-.068-.472.109-.229.375-.494.219-.557-.156-.062-1.812.391-1.812.391s.031 1.484-1.766 1.484-1.562-.969-1.562-.969-2.416-1.21-2.562-1.031z"
    />
    <path
      fill="#DD8B59"
      d="M26.484 21.322c0 .048-.038.086-.086.086-.048 0-.086-.038-.086-.086v-3.955c0-.047.038-.086.086-.086.048 0 .086.038.086.086v3.955zm1.485.483c0 .039-.031.07-.07.07-.039 0-.07-.031-.07-.07v-4.719c0-.039.031-.07.07-.07.039 0 .07.031.07.07v4.719zm1.547-.258c0 .043-.035.078-.078.078s-.078-.035-.078-.078v-4.188c0-.043.035-.078.078-.078s.078.035.078.078v4.188z"
    />
    <path
      fill="#FECF3D"
      d="M25.875 18s.141.281.609.156c.469-.125.484-.516.484-.516l-1.093.36zm2.578-.625l-1.062.266s.109.347.508.267c.398-.08.554-.533.554-.533zm.469.5s.25.297.594.188c.344-.109.562-.516.562-.516l-1.156.328zm-1.797.313l-1.34.368s-.129.24-.082.49.172.484.172.484.125-.312.438-.375.531.188.531.188-.12-.572.281-1.155zm.266 0s-.273.155-.266.625c.004.257.266.581.266.581s.141-.253.438-.347c.297-.094.625.172.625.172s-.123-.379-.078-.672c.045-.293.25-.638.25-.638l-1.235.279zm1.437.234s-.094.204-.094.438.188.671.188.671.094-.328.438-.406c.344-.078.531.156.531.156s-.115-.231-.065-.569c.05-.337.394-.625.394-.625l-1.392.335zm-1.937 1.031l-1.171.219s.14.375.585.328c.445-.047.586-.547.586-.547zm1.625-.059s-1.125.061-1.125.138c0 .076.109.359.484.297s.641-.435.641-.435zm1.453.059l-1.109.248s.156.283.555.205c.397-.078.554-.453.554-.453z"
    />
    <path
      fill="#FF0100"
      d="M26.312 17.367l-.828.274.828.088zm1.578-.267l-.828.274.828.088zm1.563.181l-.828.274.828.089z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Fk;
