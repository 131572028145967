const Tm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#29AE66"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#CA3745" d="M4 5h7v26H4z" />
    <path
      fill="#FAAE29"
      d="M4 8.312l.805.805-.219.219.32.32.211-.211.664.664-.656.657-.273-.274-.29.289.243.242-.805.711v-.375l.441-.418-.238-.191.608-.607.351.304.328-.328-.351-.351-.246.246-.641-.641.24-.24L4 8.688zm0 4.626l.805.804-.219.219.32.32.211-.211.664.664-.656.657-.273-.274-.29.289.243.242-.805.711v-.375l.441-.418-.238-.191.608-.607.351.304.328-.328-.351-.351-.246.246-.641-.641.24-.24L4 13.312zm0 4.718l.805.805-.219.219.32.32.211-.211.664.664-.656.656-.273-.273-.29.289.243.242-.805.711v-.375l.441-.418-.238-.191.608-.608.351.305.328-.328-.351-.352-.246.246-.641-.64.24-.24L4 18.031zm0 4.656l.805.805-.219.219.32.32.211-.211.664.664-.656.657-.273-.274-.29.289.243.242-.805.711v-.375l.441-.418-.238-.191.608-.607.351.304.328-.328-.351-.351-.246.246-.641-.641.24-.24L4 22.688zm7-14l-.805.805.219.219-.32.32-.211-.211-.664.664.656.657.273-.274.29.289-.243.242.805.711v-.375l-.441-.418.238-.191-.608-.607-.351.304-.328-.328.351-.351.246.246.641-.641-.24-.24.492-.445zM4.562 5.688l.29-.29.273.274.656-.656L5.766 5h-.301l.025.025-.328.329-.351-.305-.608.607.238.192L4 6.266v.375l.805-.711zm6.235-.032l-.608-.607-.351.305-.328-.329L9.535 5h-.301l-.015.016.656.656.273-.274.29.29-.243.242.805.711v-.375l-.441-.418zM11 12.938l-.805.804.219.219-.32.32-.211-.211-.664.664.656.657.273-.274.29.289-.243.242.805.711v-.375l-.441-.418.238-.191-.608-.607-.351.304-.328-.328.351-.351.246.246.641-.641-.24-.24.492-.446zm0 4.718l-.805.805.219.219-.32.32-.211-.211-.664.664.656.656.273-.273.29.289-.243.242.805.711v-.375l-.441-.418.238-.191-.608-.608-.351.305-.328-.328.351-.352.246.246.641-.64-.24-.24.492-.446zm0 4.656l-.805.805.219.219-.32.32-.211-.211-.664.664.656.657.273-.274.29.289-.243.242.805.711v-.375l-.441-.418.238-.191-.608-.607-.351.304-.328-.328.351-.351.246.246.641-.641-.24-.24.492-.445z"
    />
    <path
      fill="#29AE66"
      d="M10.021 6.938v-.396l-.583-.354H9l-.708-.368H6.708L6 6.188h-.438l-.583.354v.396l-.541.333v.917l.541.333v.396l.583.354H6l.708.368h1.584L9 9.271h.438l.583-.354v-.396l.541-.333v-.917z"
    />
    <path
      fill="#FAAE29"
      d="M4.928 7.728v-.391l.462-.285v-.337l.498-.303h.374l.604-.313h.676v1.629z"
    />
    <path
      fill="#FFF"
      d="M4.887 7.729v.392l.462.284v.338l.498.302h.373l.605.313H7.5V7.729zm5.226 0v-.391l-.462-.284v-.338l-.498-.302H8.78l-.605-.313H7.5v1.628z"
    />
    <path
      fill="#FAAE29"
      d="M10.113 7.729v.392l-.462.284v.338l-.498.302H8.78l-.605.313H7.5V7.729zm-5.185 3.833h.676v.729h-.676zm.676-.104h.676v.729h-.676zm.677-.26h.676v.729h-.676zm3.135.364h.676v.729h-.676zm-.677-.104h.676v.729h-.676zm-.677-.26h.676v.729h-.676z"
    />
    <path
      fill="#FAAE29"
      d="M6.958 10.833h1.105v.901H6.958zm-2.03 1.651h.676v.729h-.676zm.676.104h.676v.729h-.676zm.677.261h.676v.729h-.676zm3.135-.365h.676v.729h-.676zm-.677.104h.676v.729h-.676zm-.677.261h.676v.729h-.676z"
    />
    <path fill="#FAAE29" d="M6.958 13.042h1.105v.901H6.958z" />
    <path
      fill="#FFF"
      d="M8.549 24.608H6.491l-1.456.932v1.316l1.456.931h2.058l1.456-.931V25.54z"
    />
    <path
      fill="#29AE66"
      d="M8.383 15.646H6.657l-1.221.86v1.217l1.221.86h1.727l1.221-.86v-1.217l-1.222-.86zm.736 1.936l-.937.66H6.858l-.937-.66v-.934l.937-.66h1.324l.937.66v.934zm-.526 6.96H6.447l-1.517.971v1.371l1.517.97h2.145l1.517-.97v-1.371l-1.516-.971zm1.059 2.221l-1.25.799H6.637l-1.25-.799v-1.13l1.25-.799h1.766l1.25.799v1.13zm.102-5.325v-.443h-.442v-.493H5.719v.493h-.494v.443h-.433v.646h.433v.479h.494v.521h3.594v-.521h.441v-.479h.517v-.646h-.517zm-.677 1.374H5.959v-2.084h3.118v2.084z"
    />
    <path
      fill="#29AE66"
      d="M7.5 20.25l-1.896 1.125v.729l1.896 1.23 1.916-1.188v-.771z"
    />
    <path
      fill="#CA3745"
      d="M6.651 25.766l.849-.491.848.491v.979l-.848.489-.849-.489z"
    />
    <path
      fill="#FAAE29"
      d="M8.348 30.084l-.244.166s-.208-.396-.771-.438-.875.188-1.354.219c-.479.031-1.955-1.086-1.188-2.797.688.495.331 1.099.645 1.391.314.292.645.49.772.834.128.344.726.059 1.125.125.625.104 1.015.5 1.015.5z"
    />
    <path
      fill="#FAAE29"
      d="M6.651 30.084l.244.166s.208-.396.771-.438.876.188 1.355.219 1.955-1.086 1.187-2.797c-.687.495-.331 1.099-.645 1.391-.314.292-.645.49-.772.834-.128.344-.726.059-1.125.125-.625.104-1.015.5-1.015.5z"
    />
    <path
      fill="#FFF"
      d="M18.18 8.289c.65.564 1.07 1.387 1.07 2.316 0 1.7-1.378 3.078-3.078 3.078-.585 0-1.125-.172-1.591-.455.571.622 1.384 1.018 2.294 1.018 1.726 0 3.125-1.399 3.125-3.125 0-1.26-.748-2.337-1.82-2.832zm-2.334-.422l-.246.483.383.384-.535-.085-.247.483-.084-.535-.536-.085.483-.246-.085-.537.384.384z"
    />
    <path
      fill="#FFF"
      d="M17.377 8.795l-.246.483.384.384-.536-.085-.247.483-.084-.536-.536-.085.483-.246-.084-.536.383.384zm-1.531.665l-.246.483.383.384-.535-.085-.247.483-.084-.536-.536-.085.483-.246-.085-.536.384.384zm-1.594.809l-.246.483.384.384-.536-.085-.247.484-.084-.536-.536-.085.483-.246-.084-.536.383.384zm1.594.902l-.246.483.383.384-.535-.085-.247.483-.084-.536-.536-.085.483-.246-.085-.536.384.384z"
    />
    <path
      fill="#CA3745"
      d="M7.062 20.771h.915v.615h-.915zm0 1.401h.915v.615h-.915z"
    />
    <path
      fill="#FAAE29"
      d="M5.604 21.484h1.253v.615H5.604zm2.584 0h1.228v.615H8.188z"
    />
  </svg>
);

export default Tm;
