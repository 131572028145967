const Tv = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#5B97B1"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      d="M13.598 27.772l-.487-1.499-.487 1.499h-1.577l1.276.927-.487 1.5 1.275-.927 1.276.927-.487-1.5 1.275-.927zm6.485-1.166l.487-1.499-1.276.926-1.275-.926.487 1.499-1.276.927h1.577l.487 1.5.487-1.5h1.577zm.487-6.785l-1.276.926-1.275-.926.487 1.499-1.276.927h1.577l.487 1.5.487-1.5h1.577l-1.275-.927zm4.473 5.449l.487-1.499-1.276.926-1.275-.926.487 1.499-1.276.927h1.577l.487 1.499.488-1.499h1.576zm-1.276-10.574l.487 1.499.488-1.499h1.576l-1.275-.927.487-1.5-1.276.927-1.275-.927.487 1.5-1.276.927zm4.546 6.535l-.487-1.499-.488 1.499h-1.576l1.275.927-.487 1.5 1.276-.927 1.275.927-.487-1.5 1.276-.927zm.043-8.104l.487 1.5.488-1.5h1.576l-1.275-.926.487-1.5-1.276.927-1.275-.927.487 1.5-1.276.926zm4.567 5.136l-.487-1.499-.487 1.499h-1.577l1.276.927-.488 1.5 1.276-.927 1.276.927-.488-1.5 1.276-.927zm-1.275-9.216l-.488 1.5 1.276-.927 1.276.927-.488-1.5L34.5 8.12h-1.577l-.487-1.499-.487 1.499h-1.577z"
      fill="#FFCE00"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 8.969V5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Tv;
