const Je = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EEE"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#D52048"
      d="M3.186 5.084c-1.168.242-2.134 1.002-2.687 2.02L15.286 18 .499 28.896c.553 1.019 1.519 1.778 2.687 2.021L18 20l14.814 10.916c1.168-.242 2.135-1.002 2.688-2.021L20.714 18 35.502 7.104c-.553-1.018-1.52-1.778-2.688-2.02L18 16 3.186 5.084z"
    />
    <path
      fill="#FFCC4D"
      d="M20.531 7c-.168.065-.254.365-.254.365s-.15-.339-.605-.118c0 0 .248.248.195.456-.053.209-.197.301-.443.267-.287-.039-.34-.371-.34-.371s.178-.033.197-.183c.012-.091-.033-.222-.189-.234-.119-.01-.235.065-.235.208 0 .105.131.195.131.195s-.104.224-.34.235c-.299.013-.432-.156-.436-.443-.007-.411.521-.417.521-.417s-.046-.202-.228-.306c-.182-.104-.352.039-.352.039s.13-.293.091-.496c-.034-.173-.164-.346-.211-.403v-.019l-.008.01-.008-.01v.021c-.047.057-.178.229-.211.403-.039.202.091.496.091.496s-.169-.144-.352-.039c-.183.104-.229.306-.229.306s.528.006.521.417c-.004.287-.137.456-.437.443-.235-.011-.339-.235-.339-.235s.13-.09.13-.195c0-.144-.116-.218-.234-.208-.156.013-.201.143-.189.234.02.15.196.183.196.183s-.052.333-.339.371c-.247.034-.391-.058-.443-.267-.052-.208.195-.456.195-.456-.456-.222-.605.118-.605.118s-.085-.302-.253-.367c-.17-.065-.339-.039-.339-.039s.313.496.521.977c.209.482.287.978.287.978s.356-.25.938-.391c.45-.109 1.024-.117 1.1-.118.075.001.649.008 1.098.118.584.142.94.391.94.391s.078-.496.287-.978c.209-.481.521-.977.521-.977s-.171-.026-.341.039z"
    />
    <path
      fill="#DD2E44"
      d="M20.062 8.916s-.477-.313-.977-.424c-.467-.103-.99-.104-1.061-.104s-.594.001-1.06.104c-.502.111-.977.424-.977.424s-.409 1.52-.169 2.626c.473 2.186 2.021 3.086 2.199 3.184v.01l.008-.004.008.004v-.01c.178-.097 1.727-.998 2.199-3.184.241-1.106-.17-2.626-.17-2.626z"
    />
    <path
      fill="#231F20"
      d="M18.018 14.737l-.004-.012c-.174-.094-1.726-.998-2.199-3.183-.237-1.094.166-2.612.169-2.627.006-.005.483-.316.979-.426.439-.097.923-.104 1.061-.104s.622.007 1.062.104c.494.11.972.421.977.424l.001.001c.005.015.408 1.533.17 2.627-.472 2.185-2.024 3.089-2.2 3.185l.001.008c-.003.002-.007 0-.012-.002l-.005.005zm.006-.008l.011.005-.004-.008c.177-.098 1.727-1.001 2.2-3.184.234-1.082-.158-2.58-.17-2.624-.022-.014-.49-.315-.976-.423-.439-.097-.922-.104-1.06-.104s-.621.007-1.06.104c-.496.109-.972.42-.977.423-.003.014-.405 1.531-.168 2.624.472 2.183 2.022 3.086 2.197 3.182l.001.008.006-.003z"
    />
    <path
      fill="#FFCC4D"
      d="M19.406 8.922s-.092.124-.411.124c-.319 0-.177-.042-.43-.042-.255 0-.365.065-.365.143 0 .078.091.127.431.127.338 0 .463-.085.736-.085.273 0 .377.098.377.222s-.176.352-.457.28c0 0 .197.097.406.084.207-.012.383-.051.397-.005.014.045.098.286.086.417-.02.202-.275.273-.379.261-.104-.013-.24.085-.279.13l.025-.137-.105.039.059-.169h-.129s.064-.131.168-.131c.105 0 .34.026.354-.045.012-.071-.066-.169-.215-.163-.15.006-.438.091-.613.045 0 0-.058.084-.006.15.051.065.129.144.09.189-.039.045-.137.045-.227.039-.092-.006-.405.019-.515.059-.111.039-.215.098-.215.098l.052-.15s-.104-.033-.156.013c0 0 .02-.097.104-.117l-.098-.026s.04-.117.183-.104c.143.013.208.045.254-.007.046-.052-.111-.156-.111-.156s-.117.046-.377.085c-.261.04-.43.026-.43.026s-.033.274-.45.319c-.417.045-.625-.15-.723-.189-.098-.039-.235-.006-.235-.006l.065-.124-.157.033s-.01-.15.098-.156l-.143-.039s.104-.124.247-.091c.144.032.217.154.352.221.222.111.737.033.353-.045-.09-.018-.153-.104-.153-.104s-.245.052-.434-.241c-.129-.201-.189-.365-.424-.319 0 0 .111-.143.235-.098 0 0-.072-.091-.182-.085 0 0 .052-.13.208-.084 0 0-.085-.078-.163-.072 0 0 .045-.137.254-.02.207.114.082.213.122.284.026.045.083.125.222.248.235.208.345.261.273.049-.078-.052-.124-.186-.143-.251-.02-.065-.13-.221-.098-.261.033-.039.13.033.13.033s.124-.053.293-.053c.169 0 .234.078.234.078s.117-.091.137-.045c.019.045-.065.118-.104.196-.039.078.006.169.006.169s.114.149.349.156c.234.006.583-.033.707-.059.123-.026.338-.069.482-.042.144.026.548.016.535-.088-.014-.104-.111-.137-.312-.111-.203.026-.378.091-.75.065-.372-.026-.409-.215-.385-.307.033-.124.242-.199.437-.199.196 0 .441.082.583.082.145.002.33-.038.33-.038z"
    />
    <path
      fill="#DD2E44"
      d="M16.995 9.492s.078.037.124.033c.046-.004.127-.045.127-.045s-.026.049-.026.094-.01.092-.088.094c-.117.003-.101-.072-.107-.104-.007-.032-.03-.072-.03-.072z"
    />
    <path
      fill="#11429A"
      d="M17.19 9.913c0 .032-.026.059-.059.059h-.013c-.032 0-.058-.027-.058-.059v-.254c0-.032.026-.059.058-.059h.013c.033 0 .059.026.059.059v.254zm-1.029-.034c-.061.029-.098.073-.098.073l.143.039c-.108.006-.098.156-.098.156l.155-.033-.102-.235zm3.314.317c-.068.035-.107.115-.107.115h.129l-.059.169.105-.039-.025.137c.018-.023.062-.058.113-.086l-.156-.296zm-1.193.307l-.107-.322c-.065.029-.085.089-.085.089l.098.026c-.085.019-.104.117-.104.117.052-.045.156-.013.156-.013l-.052.15c0 .001.038-.021.094-.047zm-2.18-1.47c.045-.004.094.021.125.043l.018-.106c-.114-.022-.143.063-.143.063zm.162 1.083l-.064.123s.051-.012.112-.013l-.048-.11zm-.063-.878l.023-.141c-.125-.015-.168.092-.168.092.063-.003.113.024.145.049zm-.197.134c.072-.014.127-.008.173.012l.02-.118c-.106-.006-.193.106-.193.106z"
    />
    <path
      fill="#FFCC4D"
      d="M19.219 10.719s-.08.111-.368.111c-.287 0-.158-.038-.387-.038-.228 0-.327.058-.327.129 0 .07.082.114.386.114.303 0 .416-.076.66-.076.246 0 .34.088.34.199 0 .112-.158.316-.408.252 0 0 .174.088.361.076.188-.011.346-.046.357-.005s.086.257.076.375c-.018.182-.246.246-.34.234-.094-.011-.217.076-.252.117l.023-.123-.094.035.053-.152h-.117s.059-.117.152-.117c.094 0 .305.023.316-.041.012-.065-.059-.152-.193-.146-.135.005-.393.081-.55.041 0 0-.052.076-.005.135.046.058.116.128.081.169-.035.041-.123.041-.204.035-.082-.005-.363.018-.462.053-.1.035-.193.087-.193.087l.046-.135s-.094-.029-.141.012c0 0 .018-.088.094-.106l-.088-.023s.035-.105.164-.093c.129.011.188.041.229-.006.04-.047-.1-.141-.1-.141s-.105.041-.34.076c-.234.036-.386.023-.386.023s-.029.246-.404.287c-.375.04-.562-.135-.65-.17-.087-.035-.21-.005-.21-.005l.058-.112-.14.03s-.008-.135.087-.141l-.128-.035s.094-.111.222-.082c.129.029.195.139.316.199.198.1.661.029.316-.041-.081-.017-.138-.093-.138-.093s-.219.046-.389-.217c-.116-.18-.17-.328-.38-.287 0 0 .1-.128.21-.088 0 0-.064-.082-.164-.076 0 0 .047-.117.187-.076 0 0-.076-.07-.146-.064 0 0 .041-.123.228-.019.188.106.075.194.111.258.024.041.075.112.199.223.211.188.311.234.246.043-.07-.046-.111-.167-.128-.225-.018-.059-.117-.199-.088-.234.029-.035.117.03.117.03s.111-.047.263-.047c.152 0 .21.071.21.071s.106-.082.123-.042c.018.042-.058.105-.093.176-.035.07.006.152.006.152s.102.135.313.141c.21.005.523-.03.634-.053.111-.023.305-.061.433-.038.13.023.492.015.48-.08-.012-.093-.1-.122-.281-.099-.181.023-.338.082-.672.058-.333-.023-.367-.192-.345-.275.029-.111.216-.178.392-.178.175 0 .395.073.524.073.132 0 .298-.035.298-.035z"
    />
    <path
      fill="#DD2E44"
      d="M17.042 11.234s.07.032.111.03c.041-.004.114-.042.114-.042s-.023.044-.023.085-.009.083-.08.085c-.106.003-.09-.065-.096-.093-.005-.031-.026-.065-.026-.065z"
    />
    <path
      fill="#11429A"
      d="M17.218 11.611c0 .03-.023.053-.053.053h-.011c-.029 0-.053-.023-.053-.053v-.228c0-.03.024-.053.053-.053h.011c.029 0 .053.023.053.053v.228zm-.965-.033c-.054.025-.088.066-.088.066l.129.035c-.097.005-.088.141-.088.141l.139-.029-.092-.213zm2.976.283c-.061.032-.098.103-.098.103h.117l-.051.152.094-.035-.023.123c.016-.02.055-.052.102-.078l-.141-.265zm-1.071.277l-.096-.289c-.059.026-.077.081-.077.081l.087.023c-.076.018-.093.106-.093.106.047-.042.14-.012.14-.012l-.047.135c.001-.001.036-.02.086-.044zm-1.957-1.32c.042-.004.084.018.113.038l.016-.096c-.104-.018-.129.058-.129.058zm.145.973l-.058.11s.045-.011.101-.011l-.043-.099zm-.056-.789l.021-.127c-.112-.013-.15.083-.15.083.055-.003.1.022.129.044zm-.177.12c.064-.012.114-.008.155.011l.018-.106c-.096-.005-.173.095-.173.095z"
    />
    <path
      fill="#FFCC4D"
      d="M18.864 12.462s-.062.082-.274.082c-.213 0-.117-.028-.287-.028-.169 0-.243.044-.243.095 0 .053.06.085.287.085.226 0 .309-.057.491-.057s.252.066.252.148c0 .083-.118.235-.304.187 0 0 .131.065.269.057.139-.009.258-.035.266-.004.008.031.065.191.057.278-.014.135-.184.183-.252.174-.07-.009-.161.057-.187.087l.017-.092-.069.026.04-.113h-.088s.044-.087.112-.087c.07 0 .228.018.236-.03.008-.048-.043-.114-.144-.108-.099.004-.29.06-.408.03 0 0-.039.057-.004.1.035.044.087.096.061.127-.026.029-.091.029-.153.025-.06-.004-.269.013-.343.039-.074.026-.143.065-.143.065l.035-.101s-.07-.021-.104.009c0 0 .013-.064.07-.078l-.065-.018s.025-.078.121-.07c.096.009.139.031.17-.004.03-.035-.074-.105-.074-.105s-.079.031-.252.057c-.174.026-.287.018-.287.018s-.022.183-.3.213c-.278.031-.417-.1-.483-.125-.065-.026-.156-.004-.156-.004l.044-.083-.104.022s-.006-.101.065-.104l-.096-.026s.069-.083.165-.061.145.103.235.148c.148.073.492.021.235-.031-.06-.012-.102-.07-.102-.07s-.163.035-.29-.161c-.086-.134-.125-.243-.282-.213 0 0 .074-.095.157-.065 0 0-.048-.061-.122-.057 0 0 .035-.086.139-.057 0 0-.057-.052-.109-.048 0 0 .031-.09.17-.012s.055.144.083.192c.018.031.055.083.148.165.156.139.23.174.182.033-.052-.035-.082-.125-.095-.167-.013-.044-.087-.148-.065-.175.022-.026.087.022.087.022s.083-.035.196-.035.157.052.157.052.078-.061.091-.03c.013.03-.044.079-.07.131-.026.052.004.113.004.113s.077.1.233.104c.157.004.389-.022.472-.039.083-.018.227-.045.322-.029.096.018.366.011.357-.059-.009-.069-.074-.091-.209-.074-.134.019-.252.061-.5.044-.247-.018-.272-.143-.256-.205.022-.082.161-.133.291-.133s.294.055.389.055c.09.001.214-.025.214-.025z"
    />
    <path
      fill="#DD2E44"
      d="M17.255 12.843s.052.023.083.021c.03-.002.084-.031.084-.031s-.017.033-.017.064c0 .03-.007.061-.059.062-.078.003-.067-.047-.072-.07-.003-.021-.019-.046-.019-.046z"
    />
    <path
      fill="#11429A"
      d="M17.385 13.123c0 .021-.017.039-.039.039h-.008c-.022 0-.039-.018-.039-.039v-.169c0-.022.017-.04.039-.04h.008c.022 0 .039.017.039.04v.169zm-.686-.023c-.041.019-.065.049-.065.049l.096.026c-.071.003-.065.104-.065.104l.103-.022-.069-.157zm2.21.211c-.044.024-.071.077-.071.077h.088l-.04.113.069-.026-.017.092c.012-.016.042-.039.076-.058l-.105-.198zm-.795.206l-.071-.215c-.043.02-.057.06-.057.06l.065.018c-.057.013-.07.078-.07.078.035-.029.104-.009.104-.009l-.035.101c.001-.001.027-.016.064-.033zm-1.455-.982c.031-.003.063.015.084.029l.012-.07c-.077-.015-.096.041-.096.041zm.109.724l-.043.081s.034-.008.075-.009l-.032-.072zm-.042-.587l.015-.094c-.083-.01-.112.062-.112.062.041-.002.075.016.097.032zm-.132.09c.047-.009.084-.005.115.008l.013-.079c-.07-.003-.128.071-.128.071z"
    />
  </svg>
);

export default Je;
