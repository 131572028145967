const Zm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#1A8A00"
      d="M32 5H4C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path fill="#EF7D00" d="M31 31h1c2.209 0 4-1.791 4-4V15h-5v16z" />
    <path fill="#141414" d="M26 15h5v16h-5z" />
    <path fill="#DE200F" d="M21 15h5v16h-5z" />
    <path
      fill="#EF7D00"
      d="M35.083 8.256c-.109-.021-.716.325-.716.325s.564-.65.434-.65c-.13 0-.866.346-.866.346s.455-.563.303-.498c-.152.065-.976.433-1.604.696.088-.149.065-.372 0-.372-.064 0-.434.238-.997.369-.564.13-1.408.607-1.777.542-.368-.065-1.105 0-1.105 0s-.216-.325-.043-.412c.172-.087.304.13.304.13s.304-.13.304-.314-.521-.206-.521-.206-.218-.281-.563-.281c-.91 0-.976.975-.976.975s-.369-.13-.715-.217c-.347-.087-.954-.043-1.56-.412-.606-.369-.997-.498-.261.282-1.084-.369-1.646-1.105-1.799-.786-.152.32.412.699.412.699s-.304-.086-.563-.325c-.26-.239-.629-.39-.281.13-.455-.13-.13.347-.13.347s-.369.108 0 .368c.368.26 1.04.369 1.04.369s-.304.216-.109.303.521.022.521.022-.26.216 0 .303.585 0 .585 0-.108.217.151.314c.261.098.673-.119.673-.119s-.195.13 0 .238c.195.108.498-.119.498-.119s-.173.292 0 .336c.173.043.455-.173.455-.173s-.173.281 0 .303c.173.022.563-.195.563-.195s-.238.303 0 .303c.239 0 .521-.151.521-.151s-.065.238.13.325c.195.087.476-.152.476-.152s.066.456.022.715c-.043.26.281.065.281.065s-.044.39-.304.369c-.26-.022-.521.325-.433.412.087.087.433-.065.433-.065s-.021.477.108.437c.13-.04.347-.307.347-.307s.13.347.218.304c.087-.044.13-.238.13-.347s.237-.152.237-.152.065.433.152.349c.088-.085.108-.371.238-.457.13-.086.087.498.217.455.131-.043.065-.434.022-.564-.043-.13.261.108.239 0-.021-.109-.261-.325-.261-.325s.303.109.217 0-.13-.39 0-.329.239-.191.239-.191.346.26.562.173c.216-.086-.281-.39-.281-.39s.759.26.926.217c.168-.044-.255-.325-.255-.325s.477.108.564 0c.086-.109-.31-.195-.31-.195s.525.087.591 0c.065-.087-.151-.195-.151-.195s.866-.021 1.04-.325c.867.022 1.82-.671 1.907-.823.086-.152-.151-.152-.151-.152s.521-.412.455-.477c-.065-.065-.347.086-.347.086s.672-.585.564-.606zm-6.436 3.727c-.28 0-.088-.151-.132-.303-.044-.152.152-.021.13-.152-.023-.13 0-.758 0-.758.217.217.325.499.304.715-.021.217.108.238.151.347.044.109-.172.151-.453.151z"
    />
  </svg>
);

export default Zm;
