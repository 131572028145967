import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Table as AntdTable } from './antd';

export const Table = styled(AntdTable)`
  ${({ theme }) => {
    return css`
      &.ant-table-wrapper {
        .ant-spin {
          svg {
            fill: ${theme.primaryColor};
            width: 2em;
            height: 2em;
          }
        }
      }
      tr {
        cursor: pointer;
        .ant-table-cell {
          font-family: ${theme.fontFamily};
        }
        &:hover {
          color: ${theme.primaryColor};
          & > td.ant-table-cell {
            background-color: ${theme.secudaryColorHover};
          }
        }
      }

      .ant-table-cell.ant-table-column-has-sorters {
        &:focus-visible {
          color: ${theme.primaryColor};
        }
      }
      .ant-table-column-sorters {
        :hover {
          color: ${theme.primaryColor};
        }
      }
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        &:hover {
          color: ${theme.primaryColor};
        }
        color: ${theme.primaryColor};
      }
      margin-top: 16px;
      tr {
        cursor: pointer;
      }
      .ant-pagination {
        .ant-pagination-item {
          &:hover {
            a {
              color: ${theme.primaryColor};
            }
          }
        }
        .ant-pagination-item-active {
          border-color: ${theme.primaryColor};
          a {
            color: ${theme.primaryColor};
          }
        }
        .ant-pagination-next:not(.ant-pagination-disabled),
        .ant-pagination-prev:not(.ant-pagination-disabled) {
          &:hover {
            svg {
              fill: ${theme.primaryColor};
            }
          }
        }
      }
    `;
  }}
`;
