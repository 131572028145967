const Me = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#FFCC4D"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#DD2E44"
      d="M35 27c0 1.657-1.343 3-3 3H4c-1.657 0-3-1.343-3-3V9c0-1.657 1.343-3 3-3h28c1.657 0 3 1.343 3 3v18z"
    />
    <path
      fill="#FFCC4D"
      d="M22.885 17.401c.604-2.41-1.354-4.542-1.354-4.542l-.513 2.501-1.055 1.39.438.779-.437.242-1.037-1.437.384-.869 1.336-.689-1.479-.489-.896 1.043L18 16.483l-.342-1.153-.968-1.043-1.388.316 1.163.776.465.955-.953 1.483-.521-.289.438-.779-1.054-1.39-.513-2.501S12.37 14.99 12.973 17.4c.604 2.41 3.201 2.747 3.201 2.747l-1.652 1.117-.967.078-.165 1.062.876.407.77-.844 1.918-.703-1.094 1.594L18 24l2.069-1.141L19 21.351v-.062l1.84.681.761.844.872-.407-.168-1.062-.969-.078-1.652-1.117c0-.002 2.597-.34 3.201-2.749z"
    />
    <path
      fill="#5C913B"
      d="M18 19.5v1.297s-.181-.271-.75-.438c-.75-.219-.875-.859-.875-.859H18zm.002 0v1.297s.181-.271.75-.438c.75-.219.875-.859.875-.859h-1.625z"
    />
    <path
      fill="#269"
      d="M16.734 16.797s.734.297 1.266.031v2.547h-1.625s-.062-.547.156-1.266c.137-.449-.125-.812-.125-.812l.328-.5zm2.534 0s-.734.297-1.266.031v2.547h1.625s.062-.547-.156-1.266c-.137-.449.125-.812.125-.812l-.328-.5zm3.81 4.375c0 .44-.356.797-.797.797-.441 0-.797-.356-.797-.797 0-.441.356-.797.797-.797.441 0 .797.356.797.797z"
    />
    <path
      fill="#FFCC4D"
      d="M19.031 14.023c0 .22-.437.398-.977.398s-.977-.178-.977-.398c0-.22.437-.398.977-.398s.977.178.977.398z"
    />
    <path
      fill="#FFCC4D"
      d="M18.031 12.25c-.846 0-1.531.395-1.531.883s.685.883 1.531.883c.846 0 1.531-.395 1.531-.883s-.685-.883-1.531-.883zm-1.078 1.253c-.188-.082-.188-.3-.156-.457.031-.156.297-.312.297-.312s-.181.379-.028.541c.153.163.075.31-.113.228zm.646-.013c-.068-.021-.076-.096-.119-.223s-.09-.22-.059-.377c.031-.156.421-.287.421-.287s-.109.318-.078.474c.031.156.156.322.156.322s-.253.112-.321.091zm.967-.223c-.043.127-.051.202-.119.223-.068.021-.322-.089-.322-.089s.125-.166.156-.322-.078-.474-.078-.474.39.13.421.287c.032.155-.015.249-.058.375zm.528.236c-.188.082-.266-.066-.113-.228.153-.162-.028-.541-.028-.541s.266.156.297.312c.031.157.031.376-.156.457zm3.981 7.755c-.027.109-.139.174-.247.147l-1.104-.287c-.108-.028-.173-.141-.146-.248.028-.109.139-.174.247-.146l1.104.287c.109.028.176.137.146.247z"
    />
    <path
      fill="#FFCC4D"
      d="M22.495 21.131c-.024.096-.121.154-.219.129-.097-.025-.153-.122-.128-.22l.247-1.131c.024-.095.121-.153.219-.128.097.025.153.123.128.219l-.247 1.131zm-7.66 2.744c-.06.023-.166-.102-.235-.278l-1.945-4.941c-.07-.177-.078-.34-.018-.364l.099-.039c.061-.023.314.043.384.219l1.945 4.941c.07.177-.071.398-.131.423l-.099.039zm2.349-5.627h1.631v1.127h-1.631z"
    />
  </svg>
);

export default Me;
