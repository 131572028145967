const Sy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#032A87"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#DC181E"
      d="M32 5H4C1.791 5 0 6.791 0 9v9h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#EEE"
      d="M1.313 29.945l15.718-11.881L1.33 6.041C.519 6.773 0 7.822 0 9v18c0 1.171.512 2.214 1.313 2.945z"
    />
    <path
      fill="#FADA10"
      d="M8.625 14.739c0 .915-.962 1.657-2.148 1.657-1.187 0-2.148-.742-2.148-1.657s.962-1.656 2.148-1.656c1.186 0 2.148.742 2.148 1.656z"
    />
    <path
      fill="#BD725F"
      d="M3.854 14.042c.46-.46 2.119-.438 2.622-.146-.56-.754.212-.396.816-.396.604-.001.771.186.375.228-.396.043-.729-.02-.625.105.104.125.709.019 1.25-.105.542-.124 1.166.334 1.208.418s-.958-.125-1.312 0-.917.104-.875.229-.589.208-.836.146-1.269-.042-1.102-.146.375-.229.458-.271c.084-.042-1.25-.083-1.979-.062z"
    />
    <path
      fill="#DC181E"
      d="M10.156 14.562s-.939.477-1.891.25c-1.047-.25-1.812-.139-1.812.141 0-.28-.766-.391-1.812-.141-.951.227-1.891-.25-1.891-.25s.694 1.109.281 3.328c-.422 2.266-.297 3.297 1.49 3.364 1.511.057 1.932.511 1.932.511s.421-.454 1.932-.511c1.787-.067 1.912-1.099 1.49-3.364-.413-2.218.281-3.328.281-3.328z"
    />
    <path
      fill="#63B0E2"
      d="M9.469 18.578c-.531-1.828.031-3.328.031-3.328s-.828.25-1.75-.016-1.297.188-1.297.188-.375-.453-1.297-.188c-.922.266-1.75.016-1.75.016s.562 1.5.031 3.328c-.531 1.828.766 2.094 1.641 2.078.875-.016 1.375.484 1.375.484s.5-.5 1.375-.484c.875.016 2.172-.25 1.641-2.078z"
    />
    <path
      fill="#FFF"
      d="M8.547 19.891v-.907h.203v-.359h-.219v-.969h.203l-1.672-1.109v-.453h.25l-.859-.578-.859.578h.25v.453l-1.672 1.109h.203v.969h-.219v.359h.203v.907h-.25v.453h4.688v-.453zm.164-3.774c0 .358-.073.492-.164.492-.091 0-.164-.134-.164-.492s.164-.648.164-.648.164.29.164.648z"
    />
    <path
      fill="#FFF"
      d="M9.18 16.656H7.914c0-.172.283-.312.633-.312.349 0 .633.14.633.312z"
    />
    <path
      fill="#018830"
      d="M4.875 16.188s-.234.422-.281.672c-.047.25-.188-.156-.188-.156s-.25.25-.312.344c-.062.094-.078-.719 0-.828.078-.109-.188-.078-.281-.125-.094-.047.203-.344.203-.344s-.406-.188-.266-.234c.141-.047.656-.016.828.125.172.141.578-.094.734-.125.156-.031 0 .156-.125.266-.125.109-.062.391-.062.531s-.25-.126-.25-.126z"
    />
    <path
      fill="#B6B6B6"
      d="M5.578 18.203h1.844v.531H5.578zm-.89-.437h.562v.734h-.562zm0 1.234h.562v.734h-.562zm3.015-1.234h.562v.734h-.562zm0 1.234h.562v.734h-.562zm-1.656-2.781h.844v.391h-.844z"
    />
    <path
      fill="#FADA10"
      d="M11.5 18.766l-.156-.891-.828.266.25 1.203.188.047s.016.969-.719 1.719-2.031.75-2.031.75-.672.484-1.75.484c-1.078 0-1.75-.484-1.75-.484s-1.297 0-2.031-.75c-.734-.75-.719-1.719-.719-1.719l.188-.047.25-1.203-.828-.266-.156.891-.297.031s.016 1.75.625 2.672c.609.922 1.969 1.203 1.969 1.203s.016.109.047.219c.891.516 2.703.547 2.703.547s1.812-.031 2.703-.547c.031-.109.047-.219.047-.219s1.359-.281 1.969-1.203c.609-.922.625-2.672.625-2.672l-.299-.031z"
    />
  </svg>
);

export default Sy;
