const check = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0176 7.58398H14.01C13.7909 7.58398 13.5825 7.68926 13.4536 7.86973L10.0762 12.5533L8.54652 10.4307C8.41761 10.2523 8.21136 10.1449 7.99007 10.1449H6.98245C6.8428 10.1449 6.76116 10.3039 6.8428 10.4178L9.51976 14.1303C9.583 14.2185 9.66637 14.2905 9.76295 14.3401C9.85954 14.3897 9.96655 14.4156 10.0751 14.4156C10.1837 14.4156 10.2907 14.3897 10.3873 14.3401C10.4839 14.2905 10.5673 14.2185 10.6305 14.1303L15.1551 7.85684C15.2389 7.74297 15.1573 7.58398 15.0176 7.58398Z"
      fill="#73D13D"
    />
    <path
      d="M11 1.375C5.68477 1.375 1.375 5.68477 1.375 11C1.375 16.3152 5.68477 20.625 11 20.625C16.3152 20.625 20.625 16.3152 20.625 11C20.625 5.68477 16.3152 1.375 11 1.375ZM11 18.9922C6.58711 18.9922 3.00781 15.4129 3.00781 11C3.00781 6.58711 6.58711 3.00781 11 3.00781C15.4129 3.00781 18.9922 6.58711 18.9922 11C18.9922 15.4129 15.4129 18.9922 11 18.9922Z"
      fill="#73D13D"
    />
  </svg>
);

export default check;
