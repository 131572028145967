import React from 'react';
import * as S from './styles';

interface Props {
  className?: string;
  children?: any;
  level?: number;
  textType?: any;
  strong?: boolean;
  style?: React.CSSProperties;
}

const Text: React.FC<Props> = ({ textType = null, children, ...others }) => {
  switch (textType) {
    case 'text':
      return <S.Text {...others}>{children}</S.Text>;
    case 'error':
      return <S.Error {...others}>{children}</S.Error>;
    case 'title':
      return <S.Title {...others}>{children}</S.Title>;
    default:
      return <S.Paragraph {...others}>{children}</S.Paragraph>;
  }
};

export default Text;
