import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal as AntdModal } from './antd';

export const Modal = styled(AntdModal)`
  ${({ theme }) => {
    return css`
      .ant-modal-content {
        border-radius: ${theme.borderRadius};
        .ant-modal-body {
          padding: 48px 24px 24px;
        }
      }
    `;
  }}
`;
