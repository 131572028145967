const Et = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#FCDD0A" d="M0 13h36v10H0z" />
    <path
      fill="#088930"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#DA1219"
      d="M4 31h28c2.209 0 4-1.791 4-4v-4H0v4c0 2.209 1.791 4 4 4z"
    />
    <circle fill="#0F47AF" cx="18" cy="18" r="9" />
    <g fill="#FCDD0A">
      <path d="M13.25 24.469l1.719-5.531-2.731-1.985h1.156l3.778 2.893-.594.359-.922-.83-1.468 4.406z" />
      <path d="M22.609 24.469l-4.73-3.345-2.723 1.97.357-1.1 3.964-2.824.158.676-1.128.759 3.739 2.759z" />
      <path d="M25.382 15.64l-4.519 3.372 1.012 3.222-.935-.677-1.463-4.633.693.058.395 1.272 3.7-2.647z" />
      <path d="M17.872 10.07l1.86 5.487 3.344.05-.933.68-4.549-.038.271-.642.979-.06-1.327-4.37zm-7.669 5.477h5.906l1.063-3.254.358 1.098L16.012 18l-.526-.456.476-1.372-4.783.029zm7.526 6.765h.417v3.647h-.417zm7.847-2.087l-.128.396L22 19.466l.128-.396z" />
      <path d="M22.473 11.453l.337.245-2.177 3.021-.337-.244zm-9.359.245l.337-.245 2.174 3.021-.336.245zm-2.637 8.923l-.129-.396 3.454-1.155.129.397z" />
    </g>
  </svg>
);

export default Et;
