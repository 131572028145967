import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input as AntdInput, MaskInput as AntdMaskInput } from './antd';

export const Input = styled(AntdInput)`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.gray4};
      color: ${theme.colorTextInput};
      font-size: ${theme.fontSizeP};
      margin-top: 4px;
      padding: ${theme.paddingInput};
      &:hover,
      &:focus {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
      .ant-form-item-control-input-content {
        ${theme.borderRadiusTest}
      }
      &.ant-input-disabled {
        &:hover,
        &:focus {
          border: 1px solid ${theme.gray4};
          box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 0%);
        }
      }
    `;
  }}
`;

export const MaskInput = styled(AntdMaskInput)`
  ${({ theme }) => {
    return css`
      margin-top: 4px;
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.gray4};
      color: ${theme.colorTextInput};
      font-size: ${theme.fontSizeP};
      padding: ${theme.paddingInput};
      &.ant-input-affix-wrapper {
        &:hover,
        &:focus {
          border: 1px solid ${theme.secudaryColorHover};
          box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
        }
        .ant-input {
          font-size: ${theme.fontSizeP};
        }
      }
      &.ant-input-affix-wrapper-focused {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
      &:hover,
      &:focus {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
    `;
  }}
`;

export const Group = styled(AntdInput.Group)`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      border: 1px solid red;
      color: ${theme.colorTextInput};
      padding: ${theme.paddingInput};
    `;
  }}
`;

export const Textarea = styled(AntdInput.TextArea)`
  ${({ theme }) => {
    return css`
      margin-top: 4px;
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.gray4};
      color: ${theme.colorTextInput};
      font-size: ${theme.fontSizeP};
      padding: ${theme.paddingInput};
      &:hover,
      &:focus {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
    `;
  }}
`;

export const Password = styled(AntdInput.Password)`
  ${({ theme }) => {
    return css`
      margin-top: 4px;
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.gray4};
      color: ${theme.colorTextInput};
      font-size: ${theme.fontSizeP};
      padding: ${theme.paddingInput};
      &.ant-input-password {
        &:hover,
        &:focus {
          border: 1px solid ${theme.secudaryColorHover};
          box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
        }
      }
      &.ant-input-affix-wrapper-focused {
        border: 1px solid ${theme.secudaryColorHover};
        box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 20%);
      }
    `;
  }}
`;

export const Search = styled(AntdInput.Search)`
  ${({ theme }) => {
    return css`
      margin-top: 4px;
      input.ant-input {
        border-radius: ${theme.borderRadius} 0 0 ${theme.borderRadius};
        border: 1px solid ${theme.gray4};
        border-right: 0;
        color: ${theme.colorTextInput};
        font-size: ${theme.fontSizeP};
        padding: ${theme.paddingInput};
        &:focus {
          border: 1px solid ${theme.secudaryColorHover};
          box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 0%);
          border-right: 0;
          & ~ .ant-input-group-addon button {
            border: 1px solid ${theme.secudaryColorHover};
            box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 0%);
            border-left: 0;
          }
        }
      }

      span.ant-input-group-addon {
        padding: ${theme.paddingInput};
      }

      button {
        &.ant-btn {
          &.ant-input-search-button {
            border-radius: 0 ${theme.borderRadius} ${theme.borderRadius} 0 !important;
            border: 1px solid ${theme.gray4};
            color: ${theme.colorTextInput};
            border-left: 0;
            height: 43px;
            width: 80px;
          }
        }
      }

      .ant-input-group {
        &:hover {
          button,
          input.ant-input {
            border: 1px solid ${theme.secudaryColorHover};
            box-shadow: 0 0 0 2px rgb(${theme.primaryColorRGB}, 0%);
          }
          button {
            border-left: 0;
          }
          input.ant-input {
            border-right: 0;
          }
        }
      }
    `;
  }}
`;
