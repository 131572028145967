import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Result as AntdResult } from './antd';

export const Result = styled(AntdResult)`
  ${({ theme }) => {
    return css`
      .ant-result-subtitle {
        color: ${theme.gray8};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeP};
        font-weight: 400;
        line-height: 20px;
      }
    `;
  }}
`;
