import { FC } from 'react';
import * as S from './styles';

interface SpinProps {
  indicator?: any;
  width?: number;
  height?: number;
}

const Spin: FC<SpinProps> = ({ ...others }) => {
  return <S.Spin {...others} />;
};
export default Spin;
