const In = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  >
    <path fill="#138808" d="M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-5H0v5z" />
    <path fill="#F93" d="M36 14V9a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v5h36z" />
    <path fill="#F7F7F7" d="M0 13.667h36v8.667H0z" />
    <circle fill="navy" cx="18" cy="18" r="4" />
    <circle fill="#F7F7F7" cx="18" cy="18" r="3.375" />
    <path
      d="m18.1 16.75-.1.65-.1-.65.1-1.95zm-.928-1.841.408 1.909.265.602-.072-.653zm-.772.32.888 1.738.412.513-.238-.613zm-.663.508 1.308 1.45.531.389-.389-.531zm-.508.663 1.638 1.062.613.238-.513-.412zm-.32.772 1.858.601.653.072-.602-.265zM14.8 18l1.95.1.65-.1-.65-.1zm.109.828 1.909-.408.602-.265-.653.072zm.32.772 1.738-.888.513-.412-.613.238zm.508.663 1.45-1.308.389-.531-.531.389zm.663.508 1.062-1.638.238-.613-.412.513zm.772.32.601-1.858.072-.653-.265.602zM18 21.2l.1-1.95-.1-.65-.1.65zm.828-.109-.408-1.909-.265-.602.072.653zm.772-.32-.888-1.738-.412-.513.238.613zm.663-.508-1.308-1.45-.531-.389.389.531zm.508-.663-1.638-1.062-.613-.238.513.412zm.32-.772-1.858-.601-.653-.072.602.265zM21.2 18l-1.95-.1-.65.1.65.1zm-.109-.828-1.909.408-.602.265.653-.072zm-.32-.772-1.738.888-.513.412.613-.238zm-.508-.663-1.45 1.308-.389.531.531-.389zm-.663-.508-1.062 1.638-.238.613.412-.513zm-.772-.32-.601 1.858-.072.653.265-.602z"
      fill="#6666B3"
    />
    <g fill="navy">
      <circle cx="17.56" cy="14.659" r=".2" />
      <circle cx="16.71" cy="14.887" r=".2" />
      <circle cx="15.948" cy="15.326" r=".2" />
      <circle cx="15.326" cy="15.948" r=".2" />
      <circle cx="14.887" cy="16.71" r=".2" />
      <circle cx="14.659" cy="17.56" r=".2" />
      <circle cx="14.659" cy="18.44" r=".2" />
      <circle cx="14.887" cy="19.29" r=".2" />
      <circle cx="15.326" cy="20.052" r=".2" />
      <circle cx="15.948" cy="20.674" r=".2" />
      <circle cx="16.71" cy="21.113" r=".2" />
      <circle cx="17.56" cy="21.341" r=".2" />
      <circle cx="18.44" cy="21.341" r=".2" />
      <circle cx="19.29" cy="21.113" r=".2" />
      <circle cx="20.052" cy="20.674" r=".2" />
      <circle cx="20.674" cy="20.052" r=".2" />
      <circle cx="21.113" cy="19.29" r=".2" />
      <circle cx="21.341" cy="18.44" r=".2" />
      <circle cx="21.341" cy="17.56" r=".2" />
      <circle cx="21.113" cy="16.71" r=".2" />
      <circle cx="20.674" cy="15.948" r=".2" />
      <circle cx="20.052" cy="15.326" r=".2" />
      <circle cx="19.29" cy="14.887" r=".2" />
      <circle cx="18.44" cy="14.659" r=".2" />
      <circle cx="18" cy="18" r=".9" />
    </g>
  </svg>
);

export default In;
