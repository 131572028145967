const Sz = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#C00"
      d="M32 5H4C1.791 5 0 6.791 0 9v4.5h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#060"
      d="M0 27c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-4.5H0V27z"
    />
    <path fill="#EEE" d="M0 13.5h36v9H0z" />
    <g fill="#FFAC33">
      <path d="M16.13 21.455c-.408.116-.646-.272-.646-.272l.323-.145.156-.5.323.465h.364c.001-.001-.082.327-.52.452zm5.146.437c-.625-.188-2.082-.438-3.188-.438-1.104 0-2.562.25-3.188.438s-.479.791 0 .603 1.646-.375 3.188-.375c1.542 0 2.709.188 3.188.375.48.188.625-.415 0-.603zm-2.937-1.125l-.25-.417-.25.417-.438.188s.312.353.688.353.688-.353.688-.353l-.438-.188zm1.709.688c.408.116.646-.272.646-.272l-.323-.145-.156-.5-.322.465h-.365c-.002-.001.082.327.52.452z" />
      <path d="M22.443 20.2c-.104-.267-.362.448-1.062.338-.792-.125-.958-.917-.958-.917s-.625.626-1.271.334c-.646-.292-.812-.708-.812-1.167 0-.459-.059-.796 0-.979.094-.292.438-.501.792-.167.292-.416-.208-1.291-1.042-1.291-.833 0-1.333.875-1.042 1.291.354-.333.698-.125.792.167.059.183 0 .52 0 .979 0 .459-.167.875-.812 1.167s-1.271-.334-1.271-.334-.167.792-.958.917c-.7.11-.958-.605-1.062-.338-.104.267.458 1.067 1.25.808.792-.26.896-.928.896-.928s.407.749 1.354.458c.714-.22.854-.896.854-.896s.141.676.854.896c.948.291 1.354-.458 1.354-.458s.104.668.896.928c.79.259 1.353-.542 1.248-.808zm-5.042-3.1c.207-.44.688-.375.688-.375s.48-.065.688.375c-.354.041-.688.312-.688.312s-.334-.27-.688-.312zm-4.062.994l.197.61h.641l-.518.377.198.608-.518-.377-.518.377.197-.608-.518-.377h.641zm.625-2.229l.197.61h.641l-.518.377.198.608-.518-.377-.518.377.197-.608-.518-.377h.641zm1.676-1.792l.198.61h.641l-.518.377.197.608-.518-.377-.518.377.198-.608-.518-.377h.641zm2.449-.604l.197.609h.641l-.518.377.198.608-.518-.377-.518.377.197-.608-.518-.377h.641zm4.572 4.625l-.197.61h-.641l.518.377-.198.608.518-.377.518.377-.198-.608.519-.377h-.641zm-.625-2.229l-.197.61h-.641l.518.377-.198.608.518-.377.518.377-.198-.608.519-.377h-.641zm-1.676-1.792l-.198.61h-.641l.519.377-.198.608.518-.377.518.377-.198-.608.518-.377h-.641z" />
    </g>
  </svg>
);

export default Sz;
