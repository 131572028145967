import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as S from './styles';

interface ProgressProps {
  animate?: boolean;
  limit: number;
  percent: number;
}

const Progress: FC<ProgressProps> = ({
  animate = false,
  limit,
  percent,
  ...others
}) => {
  const [currentPercent, setPercent] = useState(percent);

  useEffect(() => {
    if (animate) {
      if (currentPercent + 1 < limit) {
        setTimeout(() => {
          setPercent(currentPercent + 1);
        }, 10);
      }
    }
  }, [currentPercent, animate, limit]);

  return <S.Progress {...others} percent={currentPercent} />;
};

export default styled(Progress)``;
