const Ao = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#141414"
      d="M0 18v9c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4v-9H0z"
    />
    <path
      fill="#CE1B26"
      d="M36 18V9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9v9h36z"
    />
    <path
      fill="#F9D616"
      d="M17.452 13.646l.602 1.221 1.348.196-.975.951.231 1.343-1.206-.634-1.206.634.23-1.343-.976-.951 1.349-.196zm-2.296 3.542c.344.562 3.172 3.516 5.922 5.234.359.234 1.344.984 1.719 1.25-.156.203-.375.5-.609.75-.391-.312-3.969-2.516-5.141-3.109-1.172-.594-2.234-1.625-2.234-2.609-.001-.985.343-1.516.343-1.516zm7.828 6.609c-.172.25-.438.594-.594.781.312.047 1.109.438 1.516 1.281.406.844 1.141.406 1.078-.047s-.953-1.437-2-2.015z"
    />
    <circle fill="#292F33" cx="23.258" cy="24.492" r=".18" />
    <circle fill="#292F33" cx="24.258" cy="25.492" r=".18" />
    <path
      fill="#292F33"
      d="M22.547 24.041c-.029 0-.059-.009-.084-.026l-4.657-3.172c-.068-.047-.086-.14-.04-.208.047-.069.141-.087.208-.04l4.656 3.172c.069.047.087.14.04.208-.028.043-.075.066-.123.066z"
    />
    <path
      d="M24.826 18.648l.008-1.295-1.085.007c-.041-.417-.126-.82-.244-1.208l.972-.39-.438-1.219-1.046.416c-.178-.322-.384-.625-.614-.909l.699-.798-.994-.831-.625.71c-.31-.25-.641-.472-.994-.661l.454-.967-1.213-.457-.442.935c-.357-.103-.725-.176-1.104-.214l-.138 1.347c2.495.257 4.447 2.34 4.447 4.902 0 1.509-.688 2.842-1.751 3.751l.553.382 1.365 1.016.531-.578-.833-.618c.252-.303.476-.627.668-.974l1.006.408.5-1.195-1.001-.406c.112-.369.196-.751.238-1.146l1.081-.008zm-4.93 5.12l-.412-.799-.51-.243c-.464.144-.947.243-1.458.243-1.198 0-2.282-.442-3.139-1.15l-.832.979c.308.255.646.473.999.665l-.446.959 1.194.503.45-.972c.346.104.701.176 1.069.219l-.007 1.01 1.295-.014.007-.961c.404-.038.797-.114 1.175-.226l.344.994.688-.203-.431-.999.014-.005z"
      fill="#F9D616"
    />
  </svg>
);

export default Ao;
