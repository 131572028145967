const Ug = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#D90001"
      d="M36 27v-1H0v1c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4z"
    />
    <path fill="#FCDC02" d="M0 22h36v4H0z" />
    <path fill="#141414" d="M0 18h36v4H0z" />
    <path fill="#D90001" d="M0 14h36v4H0z" />
    <path fill="#FCDC02" d="M0 10h36v4H0z" />
    <path
      fill="#141414"
      d="M0 9v1h36V9c0-2.209-1.791-4-4-4H4C1.791 5 0 6.791 0 9z"
    />
    <circle fill="#FFF" cx="18" cy="18" r="4" />
    <path
      fill="#D90000"
      d="M17.344 15.031l-.267-.703s.579-.203 1.142 0c.429.155.469.703.469.703l-.671.203-.673-.203z"
    />
    <path
      fill="#FCDC02"
      d="M17.594 14.922v-.684s.111-.043.423 0 .453.262.453.262l-.438.532-.438-.11z"
    />
    <path
      fill="#141414"
      d="M17.745 16.896c-.083-.146 0-1 .239-1.062.239-.062.698-.812 0-1.062s-.844.479-.844.479-.69.203-.604.375c.083.167.667-.062.792.083s-.062.354-.562.938-.167 1.375.062 1.579.5.359.708.546.448.292.448.292v.604s-.698.062-1.031.021-.562.188-.646.458.125.417.125.209.083-.084.102.083c.019.167.168.104.168-.104s.083-.125.104-.021.167-.042.188-.167.208-.188.375-.229.614.041.614.041v1.104s-.927.062-1.01.188.562.104.562.104-.188.354-.104.375.667-.354.896-.396.583.104.625 0-.562-.25-.625-.354-.188-1.062-.188-1.062.271-.022.605-.001c.333.021.25-.271.042-.562s-.021-.375.333-.312c.354.063.625 0 .625-.563s-1.916-1.438-1.999-1.584zm.729 2.708h-.229l.083-.417c0 .001.292.417.146.417z"
    />
    <path
      fill="#D90000"
      d="M17.474 15.812c.029-.249-.375-.229-.396 0s.355.355.396 0z"
    />
    <path
      fill="#9CA69C"
      d="M17.75 17.047c.115-.041 1.406.547 1.75.703.344.156.303.75.245 1.109s-.261.469-.261.469-.225-.58-.938-.859c-.957-.375-1.234-1.266-.796-1.422z"
    />
    <path
      fill="#D90000"
      d="M19.203 17.734c.421.11 1.312.312 1.391 1.219.078.906-.849 1.438-.849 1.438l.155-.281-.275.094.12-.204-.433.094s.725-.578.472-1.094c.019.43-.269.547-.269.547s.248-.596-.828-1.25l.281-.072-.28-.225.594.225-.282-.35.562.219-.359-.36z"
    />
    <path
      fill="#9CA69C"
      d="M17.594 15.336c0 .056-.045.102-.102.102-.056 0-.102-.045-.102-.102 0-.056.045-.102.102-.102.056 0 .102.046.102.102z"
    />
  </svg>
);

export default Ug;
