const Pf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CE1126"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v18z"
    />
    <path fill="#EEE" d="M0 12h36v12H0z" />
    <path
      d="M14.962 19.766c-.13-.35-.166-.656-.166-.656s-.078-.109-.318 0-.494.312-.854.391c-.167.036-.311-.049-.42-.147.04.143.101.275.153.413h1.605zm3.571 0c-.099-.202-.221-.359-.533-.359-.302 0-.437.177-.536.359h1.069zm4.11 0c.059-.152.124-.302.167-.461-.331.337-.84.208-.997.008-.172-.219-.562-.252-.562-.252s-.047.315-.15.69c-.001.005-.005.011-.007.016h1.549zm-.356.75c.079-.135.161-.268.228-.411-.097-.049-.215-.094-.358-.097-.344-.008-.377.238-.859.242-.229.002-.35-.056-.416-.13-.116.142-.272.279-.444.396h1.849zm-5.374 0h2.067c-.042-.042-.083-.086-.118-.134l.003.012s-.428-.409-.866-.409c-.562 0-.95.409-.95.409l.013-.024c-.043.049-.094.099-.149.146zm-1.439 0c-.141-.117-.259-.257-.348-.41-.148.102-.315.188-.485.176-.422-.031-.69-.48-.938-.359-.085.042-.165.083-.242.119.074.165.164.32.254.475l1.759-.001zm-1.094.89h7.242c.119-.129.233-.262.339-.402-.168-.144-.422-.316-.711-.316-.484 0-.691.438-1.119.438-.428 0-.475-.391-1.006-.391s-.766.453-1.18.453c-.414 0-.711-.453-1.101-.453-.391 0-.688.453-1.109.453s-.656-.5-1.031-.5c-.236 0-.515.15-.699.265.116.158.242.309.375.453zm6.356.735c.115-.078.236-.147.345-.234-.251-.12-.667-.297-.95-.297-.428 0-.537.234-1.006.234s-.676-.266-1.143-.266c-.467 0-.625.344-1.069.344-.444 0-.632-.375-1.179-.375-.321 0-.698.134-.957.245.149.13.315.237.479.349h5.48zm-1.595.265c-.531 0-.781.453-1.141.453s-.674-.469-1.087-.469c-.248 0-.538.138-.74.25.565.228 1.178.36 1.827.36.652 0 1.265-.139 1.831-.369-.182-.112-.427-.225-.69-.225z"
      fill="#083E9D"
    />
    <path
      d="M14.777 16.677l-1.399-.574c-.147.355-.239.735-.3 1.126l1.699.122v-.674zm1.02.419v.328l.949.068v-.007l-.949-.389zm5.303.252l1.821-.131c-.065-.409-.163-.807-.321-1.177l-1.5.616v.692zm-.969-.294l-.853.35.003.074.85-.061v-.363zm1.562-2.399l-.593.518v.845l1.146-.6c-.163-.271-.341-.53-.553-.763zm-1.562 1.363l-.875.763.007.199.868-.455v-.507zm-3.4.903l-.934-.815v.57l.941.497-.007-.252zm-1.954-1.704l-.543-.473c-.216.249-.398.523-.561.81l1.104.583v-.92zm5.354-.013c0-.152.206-.24.427-.261l.69-.715c-.246-.208-.508-.396-.79-.557l-1.229 2.127c.003.172.009.341.014.51l.889-.922-.001-.182zm-4.334-.188v.46l.921 1.002-.014-.497-1.294-2.238c-.256.156-.495.335-.72.529l.537.584c.273-.035.57.008.57.16zm3.424-.146l.691-1.468c-.265-.112-.536-.211-.822-.275l-.277 1.123s.583.021.458.042c-.033.005-.047.239-.05.578zm-2.594-1.655c-.211.061-.407.152-.605.238l.652 1.462-.047-1.7zm-1.85 4.672l-1.761-.047c-.006.053-.016.105-.016.16 0 .198.032.388.058.578h1.719v-.691zm6.323.691h1.842c.026-.19.058-.38.058-.578 0-.055-.01-.107-.016-.16l-1.884.069v.669z"
      fill="#FF9D11"
    />
    <path
      d="M15.047 15.297v3.578c0 .484.266 1.641 1.031 1.641 1.047 0 1.386-1.297 1.386-1.297s-.755.664-1.291.453c-.536-.211-.573-.531-.573-1.234v-3.052c.001-.277-.553-.339-.553-.089zm5.855 0v3.578c0 .484-.266 1.641-1.031 1.641-1.047 0-1.386-1.297-1.386-1.297s.755.664 1.29.453c.536-.211.573-.531.573-1.234v-3.052c.001-.277.554-.339.554-.089zm-2.09 1.997l-1.797-.551v-2.868c0-.844.646-1.26 1.797-.859-1 .094-1.318.936 0 1.562v2.716z"
      fill="#CE1126"
    />
    <path
      d="M20 18.711c0 .073-.06.133-.133.133h-3.859c-.073 0-.133-.06-.133-.133 0-.073.06-.133.133-.133h3.859c.073 0 .133.06.133.133zm-2.984.702h-.893l.383-.507h.893zm1.853 0h.893l-.383-.507h-.894zm-2.211-1.116l-.223-.243.222-.242c.04-.043.025-.102-.034-.131-.058-.029-.139-.017-.179.027l-.101.109v-.149c0-.035-.028-.063-.063-.063s-.063.028-.063.063v.149l-.1-.109c-.04-.044-.121-.056-.179-.027-.059.029-.074.088-.034.131l.222.242-.223.243c-.04.043-.024.102.034.132.059.028.138.016.179-.028l.164-.179.164.179c.041.044.121.056.179.028.059-.03.074-.089.035-.132zm.828 0l-.223-.243.222-.242c.04-.043.025-.102-.034-.131-.058-.029-.139-.017-.179.027l-.101.109v-.149c0-.035-.028-.063-.063-.063s-.063.028-.063.063v.149l-.1-.109c-.04-.044-.121-.056-.179-.027-.059.029-.074.088-.034.131l.222.242-.223.243c-.04.043-.024.102.034.132.059.028.138.016.179-.028l.164-.179.164.179c.041.044.121.056.179.028.059-.03.075-.089.035-.132zm.829 0l-.223-.243.222-.242c.04-.043.025-.102-.034-.131-.058-.029-.139-.017-.179.027l-.101.11v-.149c0-.035-.028-.063-.063-.063s-.063.028-.063.063v.149l-.1-.109c-.04-.044-.121-.056-.179-.027-.059.029-.074.088-.034.131l.222.242-.223.243c-.04.043-.024.102.034.132.059.028.138.016.179-.028l.164-.179.164.179c.041.044.121.056.179.028.059-.031.075-.09.035-.133zm.831 0l-.223-.243.222-.242c.04-.043.025-.102-.034-.131-.058-.029-.139-.017-.179.027l-.101.109v-.149c0-.035-.028-.063-.063-.063s-.063.028-.063.063v.149l-.1-.109c-.04-.044-.121-.056-.179-.027-.059.029-.074.088-.034.131l.222.242-.223.243c-.04.043-.024.102.034.132.059.028.138.016.179-.028l.164-.179.164.179c.041.044.121.056.179.028.059-.03.075-.089.035-.132zm.828 0l-.223-.243.222-.242c.04-.043.025-.102-.034-.131-.058-.029-.139-.017-.179.027l-.101.109v-.149c0-.035-.028-.063-.063-.063s-.063.028-.063.063v.149l-.1-.109c-.04-.044-.121-.056-.179-.027-.059.029-.074.088-.034.131l.222.242-.223.243c-.04.043-.024.102.034.132.059.028.138.016.179-.028l.164-.179.164.179c.041.044.121.056.179.028.059-.03.075-.089.035-.132z"
      fill="#630810"
    />
  </svg>
);

export default Pf;
