import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Radio as AntdRadio } from './antd';

export const RadioGroup = styled(AntdRadio.Group)`
  ${({ theme }) => {
    return css`
      .ant-radio-checked {
        &::selection {
          background: ${theme.radioBackground};
        }
        & ::after {
          border: 1px solid ${theme.radioBackground};
        }
        .ant-radio-inner {
          border-color: ${theme.radioBackground};
        }
      }
      .ant-radio-inner {
        &::after {
          background: ${theme.radioBackground};
        }
      }
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border: 1px solid ${theme.radioBackground};
      }

      & .ant-radio-wrapper {
        &:last-child {
          margin-right: 0;
        }
      }

      label {
        color: ${theme.gray8};
        font-size: ${theme.fontSizeLabel};
      }
    `;
  }}
`;

export const RadioButton = styled(AntdRadio.Button)``;
