const Sk = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#EE2024"
      d="M36 27v-4H0v4c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4z"
    />
    <path
      fill="#EEE"
      d="M36 23H0V9c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v14z"
    />
    <path fill="#0A4EA2" d="M0 13h36v10H0z" />
    <path
      fill="#FFF"
      d="M11.837 25.09c-1.129-.646-3.638-2.278-4.555-4.488-.925-2.227-.719-5.423-.481-9.124l.06-.936h11.963l.061.936c.238 3.7.444 6.895-.481 9.123-.918 2.211-3.426 3.844-4.556 4.489l-1.004.572-1.007-.572z"
    />
    <path
      fill="#EE2024"
      d="M17.886 11.542H7.798c-.238 3.707-.422 6.68.407 8.676 1.021 2.46 4.516 4.22 4.631 4.276v.006l.005-.003.005.003v-.006c.115-.057 3.61-1.816 4.632-4.276.83-1.996.647-4.97.408-8.676z"
    />
    <path
      fill="#FFF"
      d="M15.865 16.109s-1.401.133-2.632.165c-.009-.269-.014-.506-.014-.681 0-.188.007-.394.017-.605.973.06 1.645.246 1.645.246.247 0 .447-.2.447-.447v-.606c0-.247-.2-.447-.447-.447 0 0-.739.126-1.568.179.071-.782.156-1.435.156-1.435 0-.247-.2-.447-.447-.447h-.605c-.247 0-.447.2-.447.447 0 0 .092.666.17 1.443-.852-.047-1.583-.187-1.583-.187-.247 0-.447.2-.447.447v.606c0 .247.2.447.447.447 0 0 .639-.206 1.67-.255.014.23.024.453.024.646 0 .161-.006.388-.016.649-1.242-.033-2.693-.164-2.693-.164-.247 0-.447.2-.447.447v.606c0 .247.2.447.447.447 0 0 1.319-.108 2.635-.128-.083 1.531-.207 3.322-.207 3.322 0 .247.2.447.447.447h.605c.247 0 .447-.2.447-.447 0 0-.111-1.773-.185-3.317 1.272.03 2.581.123 2.581.123.247 0 .447-.2.447-.447v-.606c0-.247-.2-.448-.447-.448z"
    />
    <path
      fill="#0A4EA2"
      d="M17.079 20.965c-.508-1.086-1.905-1.393-2.568-.066-.438-1.594-1.681-1.594-1.681-1.594s-1.244 0-1.681 1.594c-.658-1.316-2.04-1.024-2.558.041 1.314 2.074 4.143 3.504 4.247 3.555v.005l.005-.003.005.003v-.006c.103-.051 2.91-1.469 4.231-3.529z"
    />
  </svg>
);

export default Sk;
