const It = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#CE2B37"
      d="M36 27c0 2.209-1.791 4-4 4h-8V5h8c2.209 0 4 1.791 4 4v18z"
    />
    <path
      fill="#009246"
      d="M4 5C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h8V5H4z"
    />
    <path fill="#EEE" d="M12 5h12v26H12z" />
  </svg>
);

export default It;
