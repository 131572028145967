import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider as AntdDivider } from './antd';

export const Divider = styled(AntdDivider)`
  ${({ theme }) => {
    return css`
      &.ant-divider-horizontal.ant-divider-with-text {
        color: ${theme.gray3};
        font-weight: 400;
        line-height: 21px;
      }
      .ant-divider-inner-text {
        color: ${theme.gray3};
        font-family: ${theme.fontFamily};
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    `;
  }}
`;
