const Hr = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#EEE" d="M0 12.9h36v10.2H0z" />
    <path
      fill="#171796"
      d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4v-4h36v4z"
    />
    <path
      fill="#D52B1E"
      d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#D52B1E"
      d="M11.409 7.436V18.97c0 3.64 2.951 6.591 6.591 6.591s6.591-2.951 6.591-6.591V7.436H11.409z"
    />
    <path
      d="M14.25 18h2.5v2.5h-2.5zm2.5 2.5h2.5V23h-2.5zm0-5h2.5V18h-2.5zm2.5 2.5h2.5v2.5h-2.5zm0-5h2.5v2.5h-2.5zm2.5 2.5h2.341V18H21.75zm-7.5-2.5h2.5v2.5h-2.5zm7.5 10h.805c.626-.707 1.089-1.559 1.334-2.5H21.75V23zm-2.5 0v1.931c.929-.195 1.778-.605 2.5-1.171V23h-2.5zm-5 0v-2.5h-2.139c.245.941.707 1.793 1.334 2.5h.805zm-2.341-7.5h2.341V18h-2.341zM14.25 23v.76c.722.566 1.571.976 2.5 1.171V23h-2.5z"
      fill="#FFF"
    />
    <path
      fill="#171796"
      d="M24.757 8.141l-1.998.791-1.328-1.682-1.829 1.126L18 6.949l-1.603 1.428-1.826-1.128-1.331 1.684-1.995-.793-1.122 2.08 1.331 2.862.176-.082c.78-.363 1.603-.662 2.443-.888l.04-.011c.854-.227 1.702-.378 2.523-.451l.064-.006c.705-.06 1.896-.06 2.601 0l.058.005c.824.074 1.678.226 2.536.453l.033.009c.836.225 1.658.524 2.441.889l.175.082 1.331-2.861-1.118-2.08z"
    />
    <path
      fill="#0193DD"
      d="M16.638 8.681l.221 2.563c.33-.026.729-.051 1.141-.051.412 0 .811.025 1.141.051l.221-2.563L18 7.468l-1.362 1.213zm7.941-.053l-1.698.673-.668 2.489c.731.206 1.45.468 2.144.779l1.086-2.336-.864-1.605zm-13.157-.002l-.866 1.606 1.087 2.336c.69-.31 1.409-.572 2.144-.779l-.67-2.49-1.695-.673z"
    />
  </svg>
);

export default Hr;
