import {
  AccountBookFilled,
  AccountBookOutlined,
  AccountBookTwoTone,
  AimOutlined,
  AlertFilled,
  AlertOutlined,
  AlertTwoTone,
  AlibabaOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlipayCircleFilled,
  AlipayCircleOutlined,
  AlipayOutlined,
  AlipaySquareFilled,
  AliwangwangOutlined,
  AliyunOutlined,
  AmazonCircleFilled,
  AmazonOutlined,
  AmazonSquareFilled,
  AndroidOutlined,
  AntCloudOutlined,
  AntDesignOutlined,
  ApartmentOutlined,
  ApiFilled,
  ApiOutlined,
  ApiTwoTone,
  AppleOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AppstoreTwoTone,
  AreaChartOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowsAltOutlined,
  ArrowUpOutlined,
  AudioFilled,
  AudioMutedOutlined,
  AudioOutlined,
  AudioTwoTone,
  AuditOutlined,
  BackwardFilled,
  BackwardOutlined,
  BankFilled,
  BankOutlined,
  BankTwoTone,
  BarChartOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BehanceCircleFilled,
  BehanceOutlined,
  BehanceSquareFilled,
  BehanceSquareOutlined,
  BellFilled,
  BellOutlined,
  BellTwoTone,
  BgColorsOutlined,
  BlockOutlined,
  BoldOutlined,
  BookFilled,
  BookOutlined,
  BookTwoTone,
  BorderBottomOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderLeftOutlined,
  BorderlessTableOutlined,
  BorderOuterOutlined,
  BorderOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
  BorderVerticleOutlined,
  BoxPlotFilled,
  BoxPlotOutlined,
  BoxPlotTwoTone,
  BranchesOutlined,
  BugFilled,
  BugOutlined,
  BugTwoTone,
  BuildFilled,
  BuildOutlined,
  BuildTwoTone,
  BulbFilled,
  BulbOutlined,
  BulbTwoTone,
  CalculatorFilled,
  CalculatorOutlined,
  CalculatorTwoTone,
  CalendarFilled,
  CalendarOutlined,
  CalendarTwoTone,
  CameraFilled,
  CameraOutlined,
  CameraTwoTone,
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftFilled,
  CaretLeftOutlined,
  CaretRightFilled,
  CaretRightOutlined,
  CaretUpFilled,
  CaretUpOutlined,
  CarFilled,
  CarOutlined,
  CarryOutFilled,
  CarryOutOutlined,
  CarryOutTwoTone,
  CarTwoTone,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  CheckSquareFilled,
  CheckSquareOutlined,
  CheckSquareTwoTone,
  ChromeOutlined,
  CiCircleFilled,
  CiCircleOutlined,
  CiCircleTwoTone,
  CiOutlined,
  CiTwoTone,
  ClearOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
  CloseSquareFilled,
  CloseSquareOutlined,
  CloseSquareTwoTone,
  CloudDownloadOutlined,
  CloudFilled,
  CloudOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudTwoTone,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeFilled,
  CodeOutlined,
  CodepenCircleFilled,
  CodepenCircleOutlined,
  CodepenOutlined,
  CodepenSquareFilled,
  CodeSandboxCircleFilled,
  CodeSandboxOutlined,
  CodeSandboxSquareFilled,
  CodeTwoTone,
  CoffeeOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  CommentOutlined,
  CompassFilled,
  CompassOutlined,
  CompassTwoTone,
  CompressOutlined,
  ConsoleSqlOutlined,
  ContactsFilled,
  ContactsOutlined,
  ContactsTwoTone,
  ContainerFilled,
  ContainerOutlined,
  ContainerTwoTone,
  ControlFilled,
  ControlOutlined,
  ControlTwoTone,
  CopyFilled,
  CopyOutlined,
  CopyrightOutlined,
  CopyrightTwoTone,
  CopyTwoTone,
  CreditCardFilled,
  CreditCardOutlined,
  CreditCardTwoTone,
  CrownFilled,
  CrownOutlined,
  CrownTwoTone,
  CustomerServiceFilled,
  CustomerServiceOutlined,
  CustomerServiceTwoTone,
  DashboardFilled,
  DashboardOutlined,
  DashboardTwoTone,
  DashOutlined,
  DatabaseFilled,
  DatabaseOutlined,
  DatabaseTwoTone,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteRowOutlined,
  DeleteTwoTone,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  DiffFilled,
  DiffOutlined,
  DiffTwoTone,
  DingdingOutlined,
  DingtalkCircleFilled,
  DingtalkOutlined,
  DingtalkSquareFilled,
  DisconnectOutlined,
  DislikeFilled,
  DislikeOutlined,
  DislikeTwoTone,
  DollarCircleFilled,
  DollarCircleOutlined,
  DollarCircleTwoTone,
  DollarOutlined,
  DollarTwoTone,
  DotChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownCircleFilled,
  DownCircleOutlined,
  DownCircleTwoTone,
  DownloadOutlined,
  DownOutlined,
  DownSquareFilled,
  DownSquareOutlined,
  DownSquareTwoTone,
  DragOutlined,
  DribbbleCircleFilled,
  DribbbleOutlined,
  DribbbleSquareFilled,
  DribbbleSquareOutlined,
  DropboxCircleFilled,
  DropboxOutlined,
  DropboxSquareFilled,
  EditFilled,
  EditOutlined,
  EditTwoTone,
  EllipsisOutlined,
  EnterOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  EnvironmentTwoTone,
  EuroCircleFilled,
  EuroCircleOutlined,
  EuroCircleTwoTone,
  EuroOutlined,
  EuroTwoTone,
  ExceptionOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  ExclamationOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ExperimentFilled,
  ExperimentOutlined,
  ExperimentTwoTone,
  ExportOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  EyeInvisibleTwoTone,
  EyeOutlined,
  EyeTwoTone,
  FacebookFilled,
  FacebookOutlined,
  FallOutlined,
  FastBackwardFilled,
  FastBackwardOutlined,
  FastForwardFilled,
  FastForwardOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FileAddFilled,
  FileAddOutlined,
  FileAddTwoTone,
  FileDoneOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  FileExcelTwoTone,
  FileExclamationFilled,
  FileExclamationOutlined,
  FileExclamationTwoTone,
  FileFilled,
  FileGifOutlined,
  FileImageFilled,
  FileImageOutlined,
  FileImageTwoTone,
  FileJpgOutlined,
  FileMarkdownFilled,
  FileMarkdownOutlined,
  FileMarkdownTwoTone,
  FileOutlined,
  FilePdfFilled,
  FileUnknownFilled,
  FileUnknownOutlined,
  FileUnknownTwoTone,
  FilePdfOutlined,
  FilePdfTwoTone,
  FilePptFilled,
  FilePptOutlined,
  FilePptTwoTone,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextFilled,
  FileTextOutlined,
  FileTextTwoTone,
  FileTwoTone,
  FileWordFilled,
  FileWordOutlined,
  FileWordTwoTone,
  FileZipFilled,
  FileZipOutlined,
  FileZipTwoTone,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
  FireFilled,
  FireOutlined,
  FireTwoTone,
  FlagFilled,
  FlagOutlined,
  FlagTwoTone,
  FolderAddFilled,
  FolderAddOutlined,
  FolderAddTwoTone,
  FolderFilled,
  FolderOpenFilled,
  FolderOpenOutlined,
  FolderOpenTwoTone,
  FolderOutlined,
  FolderTwoTone,
  FolderViewOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  ForkOutlined,
  FormatPainterFilled,
  FormatPainterOutlined,
  FormOutlined,
  ForwardFilled,
  ForwardOutlined,
  FrownFilled,
  FrownOutlined,
  FrownTwoTone,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FunctionOutlined,
  FundFilled,
  FundOutlined,
  FundProjectionScreenOutlined,
  FundTwoTone,
  FundViewOutlined,
  FunnelPlotFilled,
  FunnelPlotOutlined,
  FunnelPlotTwoTone,
  GatewayOutlined,
  GifOutlined,
  GiftFilled,
  GiftOutlined,
  GiftTwoTone,
  GithubOutlined,
  GitlabFilled,
  GitlabOutlined,
  GlobalOutlined,
  GoldenFilled,
  GoldFilled,
  GoldOutlined,
  GoldTwoTone,
  GoogleCircleFilled,
  GoogleOutlined,
  GooglePlusCircleFilled,
  GooglePlusOutlined,
  GooglePlusSquareFilled,
  GoogleSquareFilled,
  GroupOutlined,
  HddFilled,
  HddOutlined,
  HddTwoTone,
  HeartFilled,
  HeartOutlined,
  HeartTwoTone,
  HeatMapOutlined,
  HighlightFilled,
  HighlightOutlined,
  HighlightTwoTone,
  HistoryOutlined,
  HomeFilled,
  HomeOutlined,
  HomeTwoTone,
  HourglassFilled,
  HourglassOutlined,
  HourglassTwoTone,
  Html5Filled,
  Html5Outlined,
  Html5TwoTone,
  IdcardFilled,
  IdcardOutlined,
  IdcardTwoTone,
  IeCircleFilled,
  IeOutlined,
  IeSquareFilled,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InfoOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  InstagramFilled,
  InstagramOutlined,
  InsuranceFilled,
  InsuranceOutlined,
  InsuranceTwoTone,
  InteractionFilled,
  InteractionOutlined,
  InteractionTwoTone,
  IssuesCloseOutlined,
  ItalicOutlined,
  KeyOutlined,
  LaptopOutlined,
  LayoutFilled,
  LayoutOutlined,
  LayoutTwoTone,
  LeftCircleFilled,
  LeftCircleOutlined,
  LeftCircleTwoTone,
  LeftOutlined,
  LeftSquareFilled,
  LeftSquareOutlined,
  LeftSquareTwoTone,
  LikeFilled,
  LikeOutlined,
  LikeTwoTone,
  LineChartOutlined,
  LineHeightOutlined,
  LineOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  LinkOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  LockFilled,
  LockOutlined,
  LockTwoTone,
  LoginOutlined,
  LogoutOutlined,
  MacCommandFilled,
  MacCommandOutlined,
  MailFilled,
  MailOutlined,
  MailTwoTone,
  ManOutlined,
  MedicineBoxFilled,
  MedicineBoxOutlined,
  MedicineBoxTwoTone,
  MediumCircleFilled,
  MediumOutlined,
  MediumSquareFilled,
  MediumWorkmarkOutlined,
  MehFilled,
  MehOutlined,
  MehTwoTone,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MergeCellsOutlined,
  MessageFilled,
  MessageOutlined,
  MessageTwoTone,
  MinusCircleFilled,
  MinusCircleOutlined,
  MinusCircleTwoTone,
  MinusOutlined,
  MinusSquareFilled,
  MinusSquareOutlined,
  MinusSquareTwoTone,
  MobileFilled,
  MobileOutlined,
  MobileTwoTone,
  MoneyCollectFilled,
  MoneyCollectOutlined,
  MoneyCollectTwoTone,
  MonitorOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  NodeIndexOutlined,
  NotificationFilled,
  NotificationOutlined,
  NotificationTwoTone,
  NumberOutlined,
  OneToOneOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PauseCircleFilled,
  PauseCircleOutlined,
  PauseCircleTwoTone,
  PauseOutlined,
  PayCircleFilled,
  PayCircleOutlined,
  PercentageOutlined,
  PhoneFilled,
  PhoneOutlined,
  PhoneTwoTone,
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  PictureFilled,
  PictureOutlined,
  PictureTwoTone,
  PieChartFilled,
  PieChartOutlined,
  PieChartTwoTone,
  PlayCircleFilled,
  PlayCircleOutlined,
  PlayCircleTwoTone,
  PlaySquareFilled,
  PlaySquareOutlined,
  PlaySquareTwoTone,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PlusSquareFilled,
  PlusSquareOutlined,
  PlusSquareTwoTone,
  PoundCircleFilled,
  PoundCircleOutlined,
  PoundCircleTwoTone,
  PoundOutlined,
  PoweroffOutlined,
  PrinterFilled,
  PrinterOutlined,
  PrinterTwoTone,
  ProfileFilled,
  ProfileOutlined,
  ProfileTwoTone,
  ProjectFilled,
  ProjectOutlined,
  ProjectTwoTone,
  PropertySafetyFilled,
  PropertySafetyOutlined,
  PropertySafetyTwoTone,
  PullRequestOutlined,
  PushpinFilled,
  PushpinOutlined,
  PushpinTwoTone,
  QqCircleFilled,
  QqOutlined,
  QqSquareFilled,
  QrcodeOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  QuestionOutlined,
  RadarChartOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusSettingOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  ReadFilled,
  ReadOutlined,
  ReconciliationFilled,
  ReconciliationOutlined,
  ReconciliationTwoTone,
  RedditCircleFilled,
  RedditOutlined,
  RedditSquareFilled,
  RedEnvelopeFilled,
  RedEnvelopeOutlined,
  RedEnvelopeTwoTone,
  RedoOutlined,
  ReloadOutlined,
  RestFilled,
  RestOutlined,
  RestTwoTone,
  RetweetOutlined,
  RightCircleFilled,
  RightCircleOutlined,
  RightCircleTwoTone,
  RightOutlined,
  RightSquareFilled,
  RightSquareOutlined,
  RightSquareTwoTone,
  RiseOutlined,
  RobotFilled,
  RobotOutlined,
  RocketFilled,
  RocketOutlined,
  RocketTwoTone,
  RollbackOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SafetyCertificateTwoTone,
  SafetyOutlined,
  SaveFilled,
  SaveOutlined,
  SaveTwoTone,
  ScanOutlined,
  ScheduleFilled,
  ScheduleOutlined,
  ScheduleTwoTone,
  ScissorOutlined,
  SearchOutlined,
  SecurityScanFilled,
  SecurityScanOutlined,
  SecurityScanTwoTone,
  SelectOutlined,
  SendOutlined,
  SettingFilled,
  SettingOutlined,
  SettingTwoTone,
  ShakeOutlined,
  ShareAltOutlined,
  ShopFilled,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingFilled,
  ShoppingOutlined,
  ShoppingTwoTone,
  ShopTwoTone,
  ShrinkOutlined,
  SignalFilled,
  SisternodeOutlined,
  SketchCircleFilled,
  SketchOutlined,
  SketchSquareFilled,
  SkinFilled,
  SkinOutlined,
  SkinTwoTone,
  SkypeFilled,
  SkypeOutlined,
  SlackCircleFilled,
  SlackOutlined,
  SlackSquareFilled,
  SlackSquareOutlined,
  SlidersFilled,
  SlidersOutlined,
  SlidersTwoTone,
  SmallDashOutlined,
  SmileFilled,
  SmileOutlined,
  SmileTwoTone,
  SnippetsFilled,
  SnippetsOutlined,
  SnippetsTwoTone,
  SolutionOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  SoundFilled,
  SoundOutlined,
  SoundTwoTone,
  SplitCellsOutlined,
  StarFilled,
  StarOutlined,
  StarTwoTone,
  StepBackwardFilled,
  StepBackwardOutlined,
  StepForwardFilled,
  StepForwardOutlined,
  StockOutlined,
  StopFilled,
  StopOutlined,
  StopTwoTone,
  StrikethroughOutlined,
  SubnodeOutlined,
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
  SwitcherFilled,
  SwitcherOutlined,
  SwitcherTwoTone,
  SyncOutlined,
  TableOutlined,
  TabletFilled,
  TabletOutlined,
  TabletTwoTone,
  TagFilled,
  TagOutlined,
  TagsFilled,
  TagsOutlined,
  TagsTwoTone,
  TagTwoTone,
  TaobaoCircleFilled,
  TaobaoCircleOutlined,
  TaobaoOutlined,
  TaobaoSquareFilled,
  TeamOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  ThunderboltTwoTone,
  ToolFilled,
  ToolOutlined,
  ToolTwoTone,
  ToTopOutlined,
  TrademarkCircleFilled,
  TrademarkCircleOutlined,
  TrademarkCircleTwoTone,
  TrademarkOutlined,
  TransactionOutlined,
  TranslationOutlined,
  TrophyFilled,
  TrophyOutlined,
  TrophyTwoTone,
  TwitterCircleFilled,
  TwitterOutlined,
  TwitterSquareFilled,
  UnderlineOutlined,
  UndoOutlined,
  UngroupOutlined,
  UnlockFilled,
  UnlockOutlined,
  UnlockTwoTone,
  UnorderedListOutlined,
  UpCircleFilled,
  UpCircleOutlined,
  UpCircleTwoTone,
  UploadOutlined,
  UpOutlined,
  UpSquareFilled,
  UpSquareOutlined,
  UpSquareTwoTone,
  UsbFilled,
  UsbOutlined,
  UsbTwoTone,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VerifiedOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VideoCameraAddOutlined,
  VideoCameraFilled,
  VideoCameraOutlined,
  VideoCameraTwoTone,
  WalletFilled,
  WalletOutlined,
  WalletTwoTone,
  WarningFilled,
  WarningOutlined,
  WarningTwoTone,
  WechatFilled,
  WechatOutlined,
  WeiboCircleFilled,
  WeiboCircleOutlined,
  WeiboOutlined,
  WeiboSquareFilled,
  WeiboSquareOutlined,
  WhatsAppOutlined,
  WifiOutlined,
  WindowsOutlined,
  WomanOutlined,
  YahooFilled,
  YahooOutlined,
  YoutubeFilled,
  YoutubeOutlined,
  YuqueFilled,
  YuqueOutlined,
  ZhihuCircleFilled,
  ZhihuOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

const icon = [
  {
    name: 'AccountBookOutlined',
    icon: AccountBookOutlined,
    content: 'outilined',
  },
  {
    name: 'AimOutlined',
    icon: AimOutlined,
    content: 'outilined',
  },
  {
    name: 'AlertOutlined',
    icon: AlertOutlined,
    content: 'outilined',
  },
  {
    name: 'ApartmentOutlined',
    icon: ApartmentOutlined,
    content: 'outilined',
  },
  {
    name: 'ApiOutlined',
    icon: ApiOutlined,
    content: 'outilined',
  },
  {
    name: 'AppstoreAddOutlined',
    icon: AppstoreAddOutlined,
    content: 'outilined',
  },
  {
    name: 'AppstoreOutlined',
    icon: AppstoreOutlined,
    content: 'outilined',
  },
  {
    name: 'AudioOutlined',
    icon: AudioOutlined,
    content: 'outilined',
  },
  {
    name: 'AudioMutedOutlined',
    icon: AudioMutedOutlined,
    content: 'outilined',
  },
  {
    name: 'AuditOutlined',
    icon: AuditOutlined,
    content: 'outilined',
  },
  {
    name: 'BankOutlined',
    icon: BankOutlined,
    content: 'outilined',
  },
  {
    name: 'BarcodeOutlined',
    icon: BarcodeOutlined,
    content: 'outilined',
  },
  {
    name: 'BarsOutlined',
    icon: BarsOutlined,
    content: 'outilined',
  },
  {
    name: 'BellOutlined',
    icon: BellOutlined,
    content: 'outilined',
  },
  {
    name: 'BlockOutlined',
    icon: BlockOutlined,
    content: 'outilined',
  },
  {
    name: 'BookOutlined',
    icon: BookOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderOutlined',
    icon: BorderOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderlessTableOutlined',
    icon: BorderlessTableOutlined,
    content: 'outilined',
  },
  {
    name: 'BranchesOutlined',
    icon: BranchesOutlined,
    content: 'outilined',
  },
  {
    name: 'BugOutlined',
    icon: BugOutlined,
    content: 'outilined',
  },
  {
    name: 'BuildOutlined',
    icon: BuildOutlined,
    content: 'outilined',
  },
  {
    name: 'BulbOutlined',
    icon: BulbOutlined,
    content: 'outilined',
  },
  {
    name: 'CalculatorOutlined',
    icon: CalculatorOutlined,
    content: 'outilined',
  },
  {
    name: 'CalendarOutlined',
    icon: CalendarOutlined,
    content: 'outilined',
  },
  {
    name: 'CameraOutlined',
    icon: CameraOutlined,
    content: 'outilined',
  },
  {
    name: 'CarOutlined',
    icon: CarOutlined,
    content: 'outilined',
  },
  {
    name: 'CarryOutOutlined',
    icon: CarryOutOutlined,
    content: 'outilined',
  },
  {
    name: 'CiCircleOutlined',
    icon: CiCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'CiOutlined',
    icon: CiOutlined,
    content: 'outilined',
  },
  {
    name: 'ClearOutlined',
    icon: ClearOutlined,
    content: 'outilined',
  },
  {
    name: 'CloudDownloadOutlined',
    icon: CloudDownloadOutlined,
    content: 'outilined',
  },
  {
    name: 'CloudOutlined',
    icon: CloudOutlined,
    content: 'outilined',
  },
  {
    name: 'CloudServerOutlined',
    icon: CloudServerOutlined,
    content: 'outilined',
  },
  {
    name: 'CloudSyncOutlined',
    icon: CloudSyncOutlined,
    content: 'outilined',
  },
  {
    name: 'CloudUploadOutlined',
    icon: CloudUploadOutlined,
    content: 'outilined',
  },
  {
    name: 'ClusterOutlined',
    icon: ClusterOutlined,
    content: 'outilined',
  },
  {
    name: 'CodeOutlined',
    icon: CodeOutlined,
    content: 'outilined',
  },
  {
    name: 'CoffeeOutlined',
    icon: CoffeeOutlined,
    content: 'outilined',
  },
  {
    name: 'CommentOutlined',
    icon: CommentOutlined,
    content: 'outilined',
  },
  {
    name: 'CompassOutlined',
    icon: CompassOutlined,
    content: 'outilined',
  },
  {
    name: 'CompressOutlined',
    icon: CompressOutlined,
    content: 'outilined',
  },
  {
    name: 'ConsoleSqlOutlined',
    icon: ConsoleSqlOutlined,
    content: 'outilined',
  },
  {
    name: 'ContactsOutlined',
    icon: ContactsOutlined,
    content: 'outilined',
  },
  {
    name: 'ContainerOutlined',
    icon: ContainerOutlined,
    content: 'outilined',
  },
  {
    name: 'ControlOutlined',
    icon: ControlOutlined,
    content: 'outilined',
  },
  {
    name: 'CopyrightOutlined',
    icon: CopyrightOutlined,
    content: 'outilined',
  },
  {
    name: 'CreditCardOutlined',
    icon: CreditCardOutlined,
    content: 'outilined',
  },
  {
    name: 'CrownOutlined',
    icon: CrownOutlined,
    content: 'outilined',
  },
  {
    name: 'CustomerServiceOutlined',
    icon: CustomerServiceOutlined,
    content: 'outilined',
  },
  {
    name: 'DashboardOutlined',
    icon: DashboardOutlined,
    content: 'outilined',
  },
  {
    name: 'DatabaseOutlined',
    icon: DatabaseOutlined,
    content: 'outilined',
  },
  {
    name: 'DeleteColumnOutlined',
    icon: DeleteColumnOutlined,
    content: 'outilined',
  },
  {
    name: 'DeleteRowOutlined',
    icon: DeleteRowOutlined,
    content: 'outilined',
  },
  {
    name: 'DeliveredProcedureOutlined',
    icon: DeliveredProcedureOutlined,
    content: 'outilined',
  },
  {
    name: 'DeploymentUnitOutlined',
    icon: DeploymentUnitOutlined,
    content: 'outilined',
  },
  {
    name: 'DesktopOutlined',
    icon: DesktopOutlined,
    content: 'outilined',
  },
  {
    name: 'DingtalkOutlined',
    icon: DingtalkOutlined,
    content: 'outilined',
  },
  {
    name: 'DisconnectOutlined',
    icon: DisconnectOutlined,
    content: 'outilined',
  },
  {
    name: 'DislikeOutlined',
    icon: DislikeOutlined,
    content: 'outilined',
  },
  {
    name: 'DollarCircleOutlined',
    icon: DollarCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'DollarOutlined',
    icon: DollarOutlined,
    content: 'outilined',
  },
  {
    name: 'DownloadOutlined',
    icon: DownloadOutlined,
    content: 'outilined',
  },
  {
    name: 'EllipsisOutlined',
    icon: EllipsisOutlined,
    content: 'outilined',
  },
  {
    name: 'EnvironmentOutlined',
    icon: EnvironmentOutlined,
    content: 'outilined',
  },
  {
    name: 'EuroCircleOutlined',
    icon: EuroCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'EuroOutlined',
    icon: EuroOutlined,
    content: 'outilined',
  },
  {
    name: 'ExceptionOutlined',
    icon: ExceptionOutlined,
    content: 'outilined',
  },
  {
    name: 'ExpandAltOutlined',
    icon: ExpandAltOutlined,
    content: 'outilined',
  },
  {
    name: 'ExpandOutlined',
    icon: ExpandOutlined,
    content: 'outilined',
  },
  {
    name: 'ExperimentOutlined',
    icon: ExperimentOutlined,
    content: 'outilined',
  },
  {
    name: 'ExportOutlined',
    icon: ExportOutlined,
    content: 'outilined',
  },
  {
    name: 'EyeOutlined',
    icon: EyeOutlined,
    content: 'outilined',
  },
  {
    name: 'EyeInvisibleOutlined',
    icon: EyeInvisibleOutlined,
    content: 'outilined',
  },
  {
    name: 'FieldBinaryOutlined',
    icon: FieldBinaryOutlined,
    content: 'outilined',
  },
  {
    name: 'FieldNumberOutlined',
    icon: FieldNumberOutlined,
    content: 'outilined',
  },
  {
    name: 'FieldStringOutlined',
    icon: FieldStringOutlined,
    content: 'outilined',
  },
  {
    name: 'FieldTimeOutlined',
    icon: FieldTimeOutlined,
    content: 'outilined',
  },
  {
    name: 'FileAddOutlined',
    icon: FileAddOutlined,
    content: 'outilined',
  },
  {
    name: 'FileDoneOutlined',
    icon: FileDoneOutlined,
    content: 'outilined',
  },
  {
    name: 'FileExcelOutlined',
    icon: FileExcelOutlined,
    content: 'outilined',
  },
  {
    name: 'FileExclamationOutlined',
    icon: FileExclamationOutlined,
    content: 'outilined',
  },
  {
    name: 'FileOutlined',
    icon: FileOutlined,
    content: 'outilined',
  },
  {
    name: 'FileGifOutlined',
    icon: FileGifOutlined,
    content: 'outilined',
  },
  {
    name: 'FileImageOutlined',
    icon: FileImageOutlined,
    content: 'outilined',
  },
  {
    name: 'FileJpgOutlined',
    icon: FileJpgOutlined,
    content: 'outilined',
  },
  {
    name: 'FileMarkdownOutlined',
    icon: FileMarkdownOutlined,
    content: 'outilined',
  },
  {
    name: 'FilePdfOutlined',
    icon: FilePdfOutlined,
    content: 'outilined',
  },
  {
    name: 'FilePptOutlined',
    icon: FilePptOutlined,
    content: 'outilined',
  },
  {
    name: 'FileProtectOutlined',
    icon: FileProtectOutlined,
    content: 'outilined',
  },
  {
    name: 'FileSearchOutlined',
    icon: FileSearchOutlined,
    content: 'outilined',
  },
  {
    name: 'FileSyncOutlined',
    icon: FileSyncOutlined,
    content: 'outilined',
  },
  {
    name: 'FileTextOutlined',
    icon: FileTextOutlined,
    content: 'outilined',
  },
  {
    name: 'FileUnknownOutlined',
    icon: FileUnknownOutlined,
    content: 'outilined',
  },
  {
    name: 'FileWordOutlined',
    icon: FileWordOutlined,
    content: 'outilined',
  },
  {
    name: 'FileZipOutlined',
    icon: FileZipOutlined,
    content: 'outilined',
  },
  {
    name: 'FilterOutlined',
    icon: FilterOutlined,
    content: 'outilined',
  },
  {
    name: 'FireOutlined',
    icon: FireOutlined,
    content: 'outilined',
  },
  {
    name: 'FlagOutlined',
    icon: FlagOutlined,
    content: 'outilined',
  },
  {
    name: 'FolderAddOutlined',
    icon: FolderAddOutlined,
    content: 'outilined',
  },
  {
    name: 'FolderOutlined',
    icon: FolderOutlined,
    content: 'outilined',
  },
  {
    name: 'FolderOpenOutlined',
    icon: FolderOpenOutlined,
    content: 'outilined',
  },
  {
    name: 'FolderViewOutlined',
    icon: FolderViewOutlined,
    content: 'outilined',
  },
  {
    name: 'ForkOutlined',
    icon: ForkOutlined,
    content: 'outilined',
  },
  {
    name: 'FormatPainterOutlined',
    icon: FormatPainterOutlined,
    content: 'outilined',
  },
  {
    name: 'FrownOutlined',
    icon: FrownOutlined,
    content: 'outilined',
  },
  {
    name: 'FunctionOutlined',
    icon: FunctionOutlined,
    content: 'outilined',
  },
  {
    name: 'FundProjectionScreenOutlined',
    icon: FundProjectionScreenOutlined,
    content: 'outilined',
  },
  {
    name: 'FundViewOutlined',
    icon: FundViewOutlined,
    content: 'outilined',
  },
  {
    name: 'FunnelPlotOutlined',
    icon: FunnelPlotOutlined,
    content: 'outilined',
  },
  {
    name: 'GatewayOutlined',
    icon: GatewayOutlined,
    content: 'outilined',
  },
  {
    name: 'GifOutlined',
    icon: GifOutlined,
    content: 'outilined',
  },
  {
    name: 'GiftOutlined',
    icon: GiftOutlined,
    content: 'outilined',
  },
  {
    name: 'GlobalOutlined',
    icon: GlobalOutlined,
    content: 'outilined',
  },
  {
    name: 'GoldOutlined',
    icon: GoldOutlined,
    content: 'outilined',
  },
  {
    name: 'GroupOutlined',
    icon: GroupOutlined,
    content: 'outilined',
  },
  {
    name: 'HddOutlined',
    icon: HddOutlined,
    content: 'outilined',
  },
  {
    name: 'HeartOutlined',
    icon: HeartOutlined,
    content: 'outilined',
  },
  {
    name: 'HistoryOutlined',
    icon: HistoryOutlined,
    content: 'outilined',
  },
  // {
  //   name: 'HolderOutlined',
  //   icon: HolderOutlined,
  //   content: 'outilined',
  // },
  {
    name: 'HomeOutlined',
    icon: HomeOutlined,
    content: 'outilined',
  },
  {
    name: 'HourglassOutlined',
    icon: HourglassOutlined,
    content: 'outilined',
  },
  {
    name: 'IdcardOutlined',
    icon: IdcardOutlined,
    content: 'outilined',
  },
  {
    name: 'ImportOutlined',
    icon: ImportOutlined,
    content: 'outilined',
  },
  {
    name: 'InboxOutlined',
    icon: InboxOutlined,
    content: 'outilined',
  },
  {
    name: 'InsertRowAboveOutlined',
    icon: InsertRowAboveOutlined,
    content: 'outilined',
  },
  {
    name: 'InsertRowBelowOutlined',
    icon: InsertRowBelowOutlined,
    content: 'outilined',
  },
  {
    name: 'InsertRowLeftOutlined',
    icon: InsertRowLeftOutlined,
    content: 'outilined',
  },
  {
    name: 'InsertRowRightOutlined',
    icon: InsertRowRightOutlined,
    content: 'outilined',
  },
  {
    name: 'InsuranceOutlined',
    icon: InsuranceOutlined,
    content: 'outilined',
  },
  {
    name: 'InteractionOutlined',
    icon: InteractionOutlined,
    content: 'outilined',
  },
  {
    name: 'KeyOutlined',
    icon: KeyOutlined,
    content: 'outilined',
  },
  {
    name: 'LaptopOutlined',
    icon: LaptopOutlined,
    content: 'outilined',
  },
  {
    name: 'LayoutOutlined',
    icon: LayoutOutlined,
    content: 'outilined',
  },
  {
    name: 'LikeOutlined',
    icon: LikeOutlined,
    content: 'outilined',
  },
  {
    name: 'LineOutlined',
    icon: LineOutlined,
    content: 'outilined',
  },
  {
    name: 'LinkOutlined',
    icon: LinkOutlined,
    content: 'outilined',
  },
  {
    name: 'Loading3QuartersOutlined',
    icon: Loading3QuartersOutlined,
    content: 'outilined',
  },
  {
    name: 'LoadingOutlined',
    icon: LoadingOutlined,
    content: 'outilined',
  },
  {
    name: 'LockOutlined',
    icon: LockOutlined,
    content: 'outilined',
  },
  {
    name: 'MacCommandOutlined',
    icon: MacCommandOutlined,
    content: 'outilined',
  },
  {
    name: 'MailOutlined',
    icon: MailOutlined,
    content: 'outilined',
  },
  {
    name: 'ManOutlined',
    icon: ManOutlined,
    content: 'outilined',
  },
  {
    name: 'MedicineBoxOutlined',
    icon: MedicineBoxOutlined,
    content: 'outilined',
  },
  {
    name: 'MehOutlined',
    icon: MehOutlined,
    content: 'outilined',
  },
  {
    name: 'MenuOutlined',
    icon: MenuOutlined,
    content: 'outilined',
  },
  {
    name: 'MergeCellsOutlined',
    icon: MergeCellsOutlined,
    content: 'outilined',
  },
  {
    name: 'MessageOutlined',
    icon: MessageOutlined,
    content: 'outilined',
  },
  {
    name: 'MobileOutlined',
    icon: MobileOutlined,
    content: 'outilined',
  },
  {
    name: 'MoneyCollectOutlined',
    icon: MoneyCollectOutlined,
    content: 'outilined',
  },
  {
    name: 'MonitorOutlined',
    icon: MonitorOutlined,
    content: 'outilined',
  },
  {
    name: 'MoreOutlined',
    icon: MoreOutlined,
    content: 'outilined',
  },
  {
    name: 'NodeCollapseOutlined',
    icon: NodeCollapseOutlined,
    content: 'outilined',
  },
  {
    name: 'NodeExpandOutlined',
    icon: NodeExpandOutlined,
    content: 'outilined',
  },
  {
    name: 'NodeIndexOutlined',
    icon: NodeIndexOutlined,
    content: 'outilined',
  },
  {
    name: 'NotificationOutlined',
    icon: NotificationOutlined,
    content: 'outilined',
  },
  {
    name: 'NumberOutlined',
    icon: NumberOutlined,
    content: 'outilined',
  },
  {
    name: 'OneToOneOutlined',
    icon: OneToOneOutlined,
    content: 'outilined',
  },
  {
    name: 'PaperClipOutlined',
    icon: PaperClipOutlined,
    content: 'outilined',
  },
  {
    name: 'PartitionOutlined',
    icon: PartitionOutlined,
    content: 'outilined',
  },
  {
    name: 'PayCircleOutlined',
    icon: PayCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'PercentageOutlined',
    icon: PercentageOutlined,
    content: 'outilined',
  },
  {
    name: 'PhoneOutlined',
    icon: PhoneOutlined,
    content: 'outilined',
  },
  {
    name: 'PictureOutlined',
    icon: PictureOutlined,
    content: 'outilined',
  },
  {
    name: 'PlaySquareOutlined',
    icon: PlaySquareOutlined,
    content: 'outilined',
  },
  {
    name: 'PoundCircleOutlined',
    icon: PoundCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'PoundOutlined',
    icon: PoundOutlined,
    content: 'outilined',
  },
  {
    name: 'PoweroffOutlined',
    icon: PoweroffOutlined,
    content: 'outilined',
  },
  {
    name: 'PrinterOutlined',
    icon: PrinterOutlined,
    content: 'outilined',
  },
  {
    name: 'ProfileOutlined',
    icon: ProfileOutlined,
    content: 'outilined',
  },
  {
    name: 'ProjectOutlined',
    icon: ProjectOutlined,
    content: 'outilined',
  },
  {
    name: 'PropertySafetyOutlined',
    icon: PropertySafetyOutlined,
    content: 'outilined',
  },
  {
    name: 'PullRequestOutlined',
    icon: PullRequestOutlined,
    content: 'outilined',
  },
  {
    name: 'PushpinOutlined',
    icon: PushpinOutlined,
    content: 'outilined',
  },
  {
    name: 'QrcodeOutlined',
    icon: QrcodeOutlined,
    content: 'outilined',
  },
  {
    name: 'ReadOutlined',
    icon: ReadOutlined,
    content: 'outilined',
  },
  {
    name: 'ReconciliationOutlined',
    icon: ReconciliationOutlined,
    content: 'outilined',
  },
  {
    name: 'RedEnvelopeOutlined',
    icon: RedEnvelopeOutlined,
    content: 'outilined',
  },
  {
    name: 'ReloadOutlined',
    icon: ReloadOutlined,
    content: 'outilined',
  },
  {
    name: 'RestOutlined',
    icon: RestOutlined,
    content: 'outilined',
  },
  {
    name: 'RobotOutlined',
    icon: RobotOutlined,
    content: 'outilined',
  },
  {
    name: 'RocketOutlined',
    icon: RocketOutlined,
    content: 'outilined',
  },
  {
    name: 'RotateLeftOutlined',
    icon: RotateLeftOutlined,
    content: 'outilined',
  },
  {
    name: 'RotateRightOutlined',
    icon: RotateRightOutlined,
    content: 'outilined',
  },
  {
    name: 'SafetyCertificateOutlined',
    icon: SafetyCertificateOutlined,
    content: 'outilined',
  },
  {
    name: 'SafetyOutlined',
    icon: SafetyOutlined,
    content: 'outilined',
  },
  {
    name: 'SaveOutlined',
    icon: SaveOutlined,
    content: 'outilined',
  },
  {
    name: 'ScanOutlined',
    icon: ScanOutlined,
    content: 'outilined',
  },
  {
    name: 'ScheduleOutlined',
    icon: ScheduleOutlined,
    content: 'outilined',
  },
  {
    name: 'SearchOutlined',
    icon: SearchOutlined,
    content: 'outilined',
  },
  {
    name: 'SecurityScanOutlined',
    icon: SecurityScanOutlined,
    content: 'outilined',
  },
  {
    name: 'SelectOutlined',
    icon: SelectOutlined,
    content: 'outilined',
  },
  {
    name: 'SendOutlined',
    icon: SendOutlined,
    content: 'outilined',
  },
  {
    name: 'SettingOutlined',
    icon: SettingOutlined,
    content: 'outilined',
  },
  {
    name: 'ShakeOutlined',
    icon: ShakeOutlined,
    content: 'outilined',
  },
  {
    name: 'ShareAltOutlined',
    icon: ShareAltOutlined,
    content: 'outilined',
  },
  {
    name: 'ShopOutlined',
    icon: ShopOutlined,
    content: 'outilined',
  },
  {
    name: 'ShoppingCartOutlined',
    icon: ShoppingCartOutlined,
    content: 'outilined',
  },
  {
    name: 'ShoppingOutlined',
    icon: ShoppingOutlined,
    content: 'outilined',
  },
  {
    name: 'SisternodeOutlined',
    icon: SisternodeOutlined,
    content: 'outilined',
  },
  {
    name: 'SkinOutlined',
    icon: SkinOutlined,
    content: 'outilined',
  },
  {
    name: 'SmileOutlined',
    icon: SmileOutlined,
    content: 'outilined',
  },
  {
    name: 'SolutionOutlined',
    icon: SolutionOutlined,
    content: 'outilined',
  },
  {
    name: 'SoundOutlined',
    icon: SoundOutlined,
    content: 'outilined',
  },
  {
    name: 'SplitCellsOutlined',
    icon: SplitCellsOutlined,
    content: 'outilined',
  },
  {
    name: 'StarOutlined',
    icon: StarOutlined,
    content: 'outilined',
  },
  {
    name: 'SubnodeOutlined',
    icon: SubnodeOutlined,
    content: 'outilined',
  },
  {
    name: 'SwitcherOutlined',
    icon: SwitcherOutlined,
    content: 'outilined',
  },
  {
    name: 'SyncOutlined',
    icon: SyncOutlined,
    content: 'outilined',
  },

  {
    name: 'TableOutlined',
    icon: TableOutlined,
    content: 'outilined',
  },
  {
    name: 'TabletOutlined',
    icon: TabletOutlined,
    content: 'outilined',
  },
  {
    name: 'TagOutlined',
    icon: TagOutlined,
    content: 'outilined',
  },
  {
    name: 'TagsOutlined',
    icon: TagsOutlined,
    content: 'outilined',
  },
  {
    name: 'TeamOutlined',
    icon: TeamOutlined,
    content: 'outilined',
  },
  {
    name: 'ThunderboltOutlined',
    icon: ThunderboltOutlined,
    content: 'outilined',
  },
  {
    name: 'ToTopOutlined',
    icon: ToTopOutlined,
    content: 'outilined',
  },
  {
    name: 'ToolOutlined',
    icon: ToolOutlined,
    content: 'outilined',
  },
  {
    name: 'TrademarkCircleOutlined',
    icon: TrademarkCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'TrademarkOutlined',
    icon: TrademarkOutlined,
    content: 'outilined',
  },
  {
    name: 'TransactionOutlined',
    icon: TransactionOutlined,
    content: 'outilined',
  },
  {
    name: 'TranslationOutlined',
    icon: TranslationOutlined,
    content: 'outilined',
  },
  {
    name: 'TrophyOutlined',
    icon: TrophyOutlined,
    content: 'outilined',
  },
  {
    name: 'UngroupOutlined',
    icon: UngroupOutlined,
    content: 'outilined',
  },
  {
    name: 'UnlockOutlined',
    icon: UnlockOutlined,
    content: 'outilined',
  },
  {
    name: 'UploadOutlined',
    icon: UploadOutlined,
    content: 'outilined',
  },
  {
    name: 'UsbOutlined',
    icon: UsbOutlined,
    content: 'outilined',
  },
  {
    name: 'UserAddOutlined',
    icon: UserAddOutlined,
    content: 'outilined',
  },
  {
    name: 'UserDeleteOutlined',
    icon: UserDeleteOutlined,
    content: 'outilined',
  },
  {
    name: 'UserOutlined',
    icon: UserOutlined,
    content: 'outilined',
  },
  {
    name: 'UserSwitchOutlined',
    icon: UserSwitchOutlined,
    content: 'outilined',
  },
  {
    name: 'UsergroupAddOutlined',
    icon: UsergroupAddOutlined,
    content: 'outilined',
  },
  {
    name: 'UsergroupDeleteOutlined',
    icon: UsergroupDeleteOutlined,
    content: 'outilined',
  },
  {
    name: 'VerifiedOutlined',
    icon: VerifiedOutlined,
    content: 'outilined',
  },
  {
    name: 'VideoCameraAddOutlined',
    icon: VideoCameraAddOutlined,
    content: 'outilined',
  },
  {
    name: 'VideoCameraOutlined',
    icon: VideoCameraOutlined,
    content: 'outilined',
  },
  {
    name: 'WalletOutlined',
    icon: WalletOutlined,
    content: 'outilined',
  },
  {
    name: 'WhatsAppOutlined',
    icon: WhatsAppOutlined,
    content: 'outilined',
  },
  {
    name: 'WifiOutlined',
    icon: WifiOutlined,
    content: 'outilined',
  },
  {
    name: 'WomanOutlined',
    icon: WomanOutlined,
    content: 'outilined',
  },
  { name: 'AndroidOutlined', icon: AndroidOutlined, content: 'outilined' },
  { name: 'AppleOutlined', icon: AppleOutlined, content: 'outilined' },
  { name: 'WindowsOutlined', icon: WindowsOutlined, content: 'outilined' },
  { name: 'IeOutlined', icon: IeOutlined, content: 'outilined' },
  { name: 'ChromeOutlined', icon: ChromeOutlined, content: 'outilined' },
  { name: 'GithubOutlined', icon: GithubOutlined, content: 'outilined' },
  {
    name: 'AliwangwangOutlined',
    icon: AliwangwangOutlined,
    content: 'outilined',
  },
  { name: 'DingdingOutlined', icon: DingdingOutlined, content: 'outilined' },
  {
    name: 'WeiboSquareOutlined',
    icon: WeiboSquareOutlined,
    content: 'outilined',
  },
  {
    name: 'WeiboCircleOutlined',
    icon: WeiboCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'TaobaoCircleOutlined',
    icon: TaobaoCircleOutlined,
    content: 'outilined',
  },
  { name: 'Html5Outlined', icon: Html5Outlined, content: 'outilined' },
  { name: 'WeiboOutlined', icon: WeiboOutlined, content: 'outilined' },
  { name: 'TwitterOutlined', icon: TwitterOutlined, content: 'outilined' },
  { name: 'WechatOutlined', icon: WechatOutlined, content: 'outilined' },
  { name: 'YoutubeOutlined', icon: YoutubeOutlined, content: 'outilined' },
  {
    name: 'AlipayCircleOutlined',
    icon: AlipayCircleOutlined,
    content: 'outilined',
  },
  { name: 'TaobaoOutlined', icon: TaobaoOutlined, content: 'outilined' },
  { name: 'SkypeOutlined', icon: SkypeOutlined, content: 'outilined' },
  { name: 'QqOutlined', icon: QqOutlined, content: 'outilined' },
  {
    name: 'MediumWorkmarkOutlined',
    icon: MediumWorkmarkOutlined,
    content: 'outilined',
  },
  { name: 'GitlabOutlined', icon: GitlabOutlined, content: 'outilined' },
  { name: 'MediumOutlined', icon: MediumOutlined, content: 'outilined' },
  { name: 'LinkedinOutlined', icon: LinkedinOutlined, content: 'outilined' },
  {
    name: 'GooglePlusOutlined',
    icon: GooglePlusOutlined,
    content: 'outilined',
  },
  { name: 'DropboxOutlined', icon: DropboxOutlined, content: 'outilined' },
  { name: 'FacebookOutlined', icon: FacebookOutlined, content: 'outilined' },
  { name: 'CodepenOutlined', icon: CodepenOutlined, content: 'outilined' },
  {
    name: 'CodeSandboxOutlined',
    icon: CodeSandboxOutlined,
    content: 'outilined',
  },
  { name: 'AmazonOutlined', icon: AmazonOutlined, content: 'outilined' },
  { name: 'GoogleOutlined', icon: GoogleOutlined, content: 'outilined' },
  {
    name: 'CodepenCircleOutlined',
    icon: CodepenCircleOutlined,
    content: 'outilined',
  },
  { name: 'AlipayOutlined', icon: AlipayOutlined, content: 'outilined' },
  { name: 'AntDesignOutlined', icon: AntDesignOutlined, content: 'outilined' },
  { name: 'AntCloudOutlined', icon: AntCloudOutlined, content: 'outilined' },
  { name: 'AliyunOutlined', icon: AliyunOutlined, content: 'outilined' },
  { name: 'ZhihuOutlined', icon: ZhihuOutlined, content: 'outilined' },
  { name: 'SlackOutlined', icon: SlackOutlined, content: 'outilined' },
  {
    name: 'SlackSquareOutlined',
    icon: SlackSquareOutlined,
    content: 'outilined',
  },
  { name: 'BehanceOutlined', icon: BehanceOutlined, content: 'outilined' },
  {
    name: 'BehanceSquareOutlined',
    icon: BehanceSquareOutlined,
    content: 'outilined',
  },
  { name: 'DribbbleOutlined', icon: DribbbleOutlined, content: 'outilined' },
  {
    name: 'DribbbleSquareOutlined',
    icon: DribbbleSquareOutlined,
    content: 'outilined',
  },
  { name: 'InstagramOutlined', icon: InstagramOutlined, content: 'outilined' },
  { name: 'YuqueOutlined', icon: YuqueOutlined, content: 'outilined' },
  { name: 'AlibabaOutlined', icon: AlibabaOutlined, content: 'outilined' },
  { name: 'YahooOutlined', icon: YahooOutlined, content: 'outilined' },
  { name: 'RedditOutlined', icon: RedditOutlined, content: 'outilined' },
  { name: 'SketchOutlined', icon: SketchOutlined, content: 'outilined' },
  { name: 'AreaChartOutlined', icon: AreaChartOutlined, content: 'outilined' },
  { name: 'PieChartOutlined', icon: PieChartOutlined, content: 'outilined' },
  { name: 'BarChartOutlined', icon: BarChartOutlined, content: 'outilined' },
  { name: 'DotChartOutlined', icon: DotChartOutlined, content: 'outilined' },
  { name: 'LineChartOutlined', icon: LineChartOutlined, content: 'outilined' },
  {
    name: 'RadarChartOutlined',
    icon: RadarChartOutlined,
    content: 'outilined',
  },
  { name: 'HeatMapOutlined', icon: HeatMapOutlined, content: 'outilined' },
  { name: 'FallOutlined', icon: FallOutlined, content: 'outilined' },
  { name: 'RiseOutlined', icon: RiseOutlined, content: 'outilined' },
  { name: 'StockOutlined', icon: StockOutlined, content: 'outilined' },
  { name: 'BoxPlotOutlined', icon: BoxPlotOutlined, content: 'outilined' },
  { name: 'FundOutlined', icon: FundOutlined, content: 'outilined' },
  { name: 'SlidersOutlined', icon: SlidersOutlined, content: 'outilined' },
  { name: 'EditOutlined', icon: EditOutlined, content: 'outilined' },
  { name: 'FormOutlined', icon: FormOutlined, content: 'outilined' },
  { name: 'CopyOutlined', icon: CopyOutlined, content: 'outilined' },
  { name: 'ScissorOutlined', icon: ScissorOutlined, content: 'outilined' },
  { name: 'DeleteOutlined', icon: DeleteOutlined, content: 'outilined' },
  { name: 'SnippetsOutlined', icon: SnippetsOutlined, content: 'outilined' },
  { name: 'DiffOutlined', icon: DiffOutlined, content: 'outilined' },
  { name: 'HighlightOutlined', icon: HighlightOutlined, content: 'outilined' },
  {
    name: 'AlignCenterOutlined',
    icon: AlignCenterOutlined,
    content: 'outilined',
  },
  { name: 'AlignLeftOutlined', icon: AlignLeftOutlined, content: 'outilined' },
  {
    name: 'AlignRightOutlined',
    icon: AlignRightOutlined,
    content: 'outilined',
  },
  { name: 'BgColorsOutlined', icon: BgColorsOutlined, content: 'outilined' },
  { name: 'BoldOutlined', icon: BoldOutlined, content: 'outilined' },
  { name: 'ItalicOutlined', icon: ItalicOutlined, content: 'outilined' },
  { name: 'UnderlineOutlined', icon: UnderlineOutlined, content: 'outilined' },
  {
    name: 'StrikethroughOutlined',
    icon: StrikethroughOutlined,
    content: 'outilined',
  },
  { name: 'RedoOutlined', icon: RedoOutlined, content: 'outilined' },
  { name: 'UndoOutlined', icon: UndoOutlined, content: 'outilined' },
  { name: 'ZoomInOutlined', icon: ZoomInOutlined, content: 'outilined' },
  { name: 'ZoomOutOutlined', icon: ZoomOutOutlined, content: 'outilined' },
  {
    name: 'FontColorsOutlined',
    icon: FontColorsOutlined,
    content: 'outilined',
  },
  { name: 'FontSizeOutlined', icon: FontSizeOutlined, content: 'outilined' },
  {
    name: 'LineHeightOutlined',
    icon: LineHeightOutlined,
    content: 'outilined',
  },
  { name: 'DashOutlined', icon: DashOutlined, content: 'outilined' },
  { name: 'SmallDashOutlined', icon: SmallDashOutlined, content: 'outilined' },
  {
    name: 'SortAscendingOutlined',
    icon: SortAscendingOutlined,
    content: 'outilined',
  },
  {
    name: 'SortDescendingOutlined',
    icon: SortDescendingOutlined,
    content: 'outilined',
  },
  { name: 'DragOutlined', icon: DragOutlined, content: 'outilined' },
  {
    name: 'OrderedListOutlined',
    icon: OrderedListOutlined,
    content: 'outilined',
  },
  {
    name: 'UnorderedListOutlined',
    icon: UnorderedListOutlined,
    content: 'outilined',
  },
  {
    name: 'RadiusSettingOutlined',
    icon: RadiusSettingOutlined,
    content: 'outilined',
  },
  {
    name: 'ColumnWidthOutlined',
    icon: ColumnWidthOutlined,
    content: 'outilined',
  },
  {
    name: 'ColumnHeightOutlined',
    icon: ColumnHeightOutlined,
    content: 'outilined',
  },
  { name: 'QuestionOutlined', icon: QuestionOutlined, content: 'outilined' },
  {
    name: 'QuestionCircleOutlined',
    icon: QuestionCircleOutlined,
    content: 'outilined',
  },
  { name: 'PlusOutlined', icon: PlusOutlined, content: 'outilined' },
  {
    name: 'PlusCircleOutlined',
    icon: PlusCircleOutlined,
    content: 'outilined',
  },
  { name: 'PauseOutlined', icon: PauseOutlined, content: 'outilined' },
  {
    name: 'PauseCircleOutlined',
    icon: PauseCircleOutlined,
    content: 'outilined',
  },
  { name: 'MinusOutlined', icon: MinusOutlined, content: 'outilined' },
  {
    name: 'MinusCircleOutlined',
    icon: MinusCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'PlusSquareOutlined',
    icon: PlusSquareOutlined,
    content: 'outilined',
  },
  {
    name: 'MinusSquareOutlined',
    icon: MinusSquareOutlined,
    content: 'outilined',
  },
  { name: 'InfoOutlined', icon: InfoOutlined, content: 'outilined' },
  {
    name: 'InfoCircleOutlined',
    icon: InfoCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'ExclamationOutlined',
    icon: ExclamationOutlined,
    content: 'outilined',
  },
  {
    name: 'ExclamationCircleOutlined',
    icon: ExclamationCircleOutlined,
    content: 'outilined',
  },
  { name: 'CloseOutlined', icon: CloseOutlined, content: 'outilined' },
  {
    name: 'CloseCircleOutlined',
    icon: CloseCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'CloseSquareOutlined',
    icon: CloseSquareOutlined,
    content: 'outilined',
  },
  { name: 'CheckOutlined', icon: CheckOutlined, content: 'outilined' },
  {
    name: 'CheckCircleOutlined',
    icon: CheckCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'CheckSquareOutlined',
    icon: CheckSquareOutlined,
    content: 'outilined',
  },
  {
    name: 'ClockCircleOutlined',
    icon: ClockCircleOutlined,
    content: 'outilined',
  },
  { name: 'WarningOutlined', icon: WarningOutlined, content: 'outilined' },
  {
    name: 'IssuesCloseOutlined',
    icon: IssuesCloseOutlined,
    content: 'outilined',
  },
  { name: 'StopOutlined', icon: StopOutlined, content: 'outilined' },
  {
    name: 'StepBackwardOutlined',
    icon: StepBackwardOutlined,
    content: 'outilined',
  },
  {
    name: 'StepForwardOutlined',
    icon: StepForwardOutlined,
    content: 'outilined',
  },
  {
    name: 'FastBackwardOutlined',
    icon: FastBackwardOutlined,
    content: 'outilined',
  },
  {
    name: 'FastForwardOutlined',
    icon: FastForwardOutlined,
    content: 'outilined',
  },
  { name: 'ShrinkOutlined', icon: ShrinkOutlined, content: 'outilined' },
  { name: 'ArrowsAltOutlined', icon: ArrowsAltOutlined, content: 'outilined' },
  { name: 'DownOutlined', icon: DownOutlined, content: 'outilined' },
  { name: 'UpOutlined', icon: UpOutlined, content: 'outilined' },
  { name: 'LeftOutlined', icon: LeftOutlined, content: 'outilined' },
  { name: 'RightOutlined', icon: RightOutlined, content: 'outilined' },
  { name: 'CaretUpOutlined', icon: CaretUpOutlined, content: 'outilined' },
  { name: 'CaretDownOutlined', icon: CaretDownOutlined, content: 'outilined' },
  { name: 'CaretLeftOutlined', icon: CaretLeftOutlined, content: 'outilined' },
  {
    name: 'CaretRightOutlined',
    icon: CaretRightOutlined,
    content: 'outilined',
  },
  { name: 'UpCircleOutlined', icon: UpCircleOutlined, content: 'outilined' },
  {
    name: 'DownCircleOutlined',
    icon: DownCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'LeftCircleOutlined',
    icon: LeftCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'RightCircleOutlined',
    icon: RightCircleOutlined,
    content: 'outilined',
  },
  {
    name: 'DoubleRightOutlined',
    icon: DoubleRightOutlined,
    content: 'outilined',
  },
  {
    name: 'DoubleLeftOutlined',
    icon: DoubleLeftOutlined,
    content: 'outilined',
  },
  {
    name: 'VerticalLeftOutlined',
    icon: VerticalLeftOutlined,
    content: 'outilined',
  },
  {
    name: 'VerticalRightOutlined',
    icon: VerticalRightOutlined,
    content: 'outilined',
  },
  {
    name: 'VerticalAlignTopOutlined',
    icon: VerticalAlignTopOutlined,
    content: 'outilined',
  },
  {
    name: 'VerticalAlignMiddleOutlined',
    icon: VerticalAlignMiddleOutlined,
    content: 'outilined',
  },
  {
    name: 'VerticalAlignBottomOutlined',
    icon: VerticalAlignBottomOutlined,
    content: 'outilined',
  },
  { name: 'ForwardOutlined', icon: ForwardOutlined, content: 'outilined' },
  { name: 'BackwardOutlined', icon: BackwardOutlined, content: 'outilined' },
  { name: 'RollbackOutlined', icon: RollbackOutlined, content: 'outilined' },
  { name: 'EnterOutlined', icon: EnterOutlined, content: 'outilined' },
  { name: 'RetweetOutlined', icon: RetweetOutlined, content: 'outilined' },
  { name: 'SwapOutlined', icon: SwapOutlined, content: 'outilined' },
  { name: 'SwapLeftOutlined', icon: SwapLeftOutlined, content: 'outilined' },
  { name: 'SwapRightOutlined', icon: SwapRightOutlined, content: 'outilined' },
  { name: 'ArrowUpOutlined', icon: ArrowUpOutlined, content: 'outilined' },
  { name: 'ArrowDownOutlined', icon: ArrowDownOutlined, content: 'outilined' },
  { name: 'ArrowLeftOutlined', icon: ArrowLeftOutlined, content: 'outilined' },
  {
    name: 'ArrowRightOutlined',
    icon: ArrowRightOutlined,
    content: 'outilined',
  },
  {
    name: 'PlayCircleOutlined',
    icon: PlayCircleOutlined,
    content: 'outilined',
  },
  { name: 'UpSquareOutlined', icon: UpSquareOutlined, content: 'outilined' },
  {
    name: 'DownSquareOutlined',
    icon: DownSquareOutlined,
    content: 'outilined',
  },
  {
    name: 'LeftSquareOutlined',
    icon: LeftSquareOutlined,
    content: 'outilined',
  },
  {
    name: 'RightSquareOutlined',
    icon: RightSquareOutlined,
    content: 'outilined',
  },
  { name: 'LoginOutlined', icon: LoginOutlined, content: 'outilined' },
  { name: 'LogoutOutlined', icon: LogoutOutlined, content: 'outilined' },
  { name: 'MenuFoldOutlined', icon: MenuFoldOutlined, content: 'outilined' },
  {
    name: 'MenuUnfoldOutlined',
    icon: MenuUnfoldOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderBottomOutlined',
    icon: BorderBottomOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderHorizontalOutlined',
    icon: BorderHorizontalOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderInnerOutlined',
    icon: BorderInnerOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderOuterOutlined',
    icon: BorderOuterOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderLeftOutlined',
    icon: BorderLeftOutlined,
    content: 'outilined',
  },
  {
    name: 'BorderRightOutlined',
    icon: BorderRightOutlined,
    content: 'outilined',
  },
  { name: 'BorderTopOutlined', icon: BorderTopOutlined, content: 'outilined' },
  {
    name: 'BorderVerticleOutlined',
    icon: BorderVerticleOutlined,
    content: 'outilined',
  },
  { name: 'PicCenterOutlined', icon: PicCenterOutlined, content: 'outilined' },
  { name: 'PicLeftOutlined', icon: PicLeftOutlined, content: 'outilined' },
  { name: 'PicRightOutlined', icon: PicRightOutlined, content: 'outilined' },
  {
    name: 'RadiusBottomleftOutlined',
    icon: RadiusBottomleftOutlined,
    content: 'outilined',
  },
  {
    name: 'RadiusBottomrightOutlined',
    icon: RadiusBottomrightOutlined,
    content: 'outilined',
  },
  {
    name: 'RadiusUpleftOutlined',
    icon: RadiusUpleftOutlined,
    content: 'outilined',
  },
  {
    name: 'RadiusUprightOutlined',
    icon: RadiusUprightOutlined,
    content: 'outilined',
  },
  {
    name: 'FullscreenOutlined',
    icon: FullscreenOutlined,
    content: 'outilined',
  },
  {
    name: 'FullscreenExitOutlined',
    icon: FullscreenExitOutlined,
    content: 'outilined',
  },
  { name: 'StepBackwardFilled', icon: StepBackwardFilled, content: 'filled' },
  { name: 'StepForwardFilled', icon: StepForwardFilled, content: 'filled' },
  { name: 'FastBackwardFilled', icon: FastBackwardFilled, content: 'filled' },
  { name: 'FastForwardFilled', icon: FastForwardFilled, content: 'filled' },
  { name: 'CaretUpFilled', icon: CaretUpFilled, content: 'filled' },
  { name: 'CaretDownFilled', icon: CaretDownFilled, content: 'filled' },
  { name: 'CaretLeftFilled', icon: CaretLeftFilled, content: 'filled' },
  { name: 'CaretRightFilled', icon: CaretRightFilled, content: 'filled' },
  { name: 'UpCircleFilled', icon: UpCircleFilled, content: 'filled' },
  { name: 'DownCircleFilled', icon: DownCircleFilled, content: 'filled' },
  { name: 'LeftCircleFilled', icon: LeftCircleFilled, content: 'filled' },
  { name: 'RightCircleFilled', icon: RightCircleFilled, content: 'filled' },
  { name: 'ForwardFilled', icon: ForwardFilled, content: 'filled' },
  { name: 'BackwardFilled', icon: BackwardFilled, content: 'filled' },
  { name: 'PlayCircleFilled', icon: PlayCircleFilled, content: 'filled' },
  { name: 'UpSquareFilled', icon: UpSquareFilled, content: 'filled' },
  { name: 'DownSquareFilled', icon: DownSquareFilled, content: 'filled' },
  { name: 'LeftSquareFilled', icon: LeftSquareFilled, content: 'filled' },
  { name: 'RightSquareFilled', icon: RightSquareFilled, content: 'filled' },
  {
    name: 'QuestionCircleFilled',
    icon: QuestionCircleFilled,
    content: 'filled',
  },
  { name: 'PlusCircleFilled', icon: PlusCircleFilled, content: 'filled' },
  { name: 'PauseCircleFilled', icon: PauseCircleFilled, content: 'filled' },
  { name: 'MinusCircleFilled', icon: MinusCircleFilled, content: 'filled' },
  { name: 'PlusSquareFilled', icon: PlusSquareFilled, content: 'filled' },
  { name: 'MinusSquareFilled', icon: MinusSquareFilled, content: 'filled' },
  { name: 'InfoCircleFilled', icon: InfoCircleFilled, content: 'filled' },
  {
    name: 'ExclamationCircleFilled',
    icon: ExclamationCircleFilled,
    content: 'filled',
  },
  { name: 'CloseCircleFilled', icon: CloseCircleFilled, content: 'filled' },
  { name: 'CloseSquareFilled', icon: CloseSquareFilled, content: 'filled' },
  { name: 'CheckCircleFilled', icon: CheckCircleFilled, content: 'filled' },
  { name: 'CheckSquareFilled', icon: CheckSquareFilled, content: 'filled' },
  { name: 'ClockCircleFilled', icon: ClockCircleFilled, content: 'filled' },
  { name: 'WarningFilled', icon: WarningFilled, content: 'filled' },
  { name: 'StopFilled', icon: StopFilled, content: 'filled' },
  { name: 'EditFilled', icon: EditFilled, content: 'filled' },
  { name: 'CopyFilled', icon: CopyFilled, content: 'filled' },
  { name: 'DeleteFilled', icon: DeleteFilled, content: 'filled' },
  { name: 'SnippetsFilled', icon: SnippetsFilled, content: 'filled' },
  { name: 'DiffFilled', icon: DiffFilled, content: 'filled' },
  { name: 'HighlightFilled', icon: HighlightFilled, content: 'filled' },
  { name: 'PieChartFilled', icon: PieChartFilled, content: 'filled' },
  { name: 'BoxPlotFilled', icon: BoxPlotFilled, content: 'filled' },
  { name: 'FundFilled', icon: FundFilled, content: 'filled' },
  { name: 'SlidersFilled', icon: SlidersFilled, content: 'filled' },
  { name: 'WeiboSquareFilled', icon: WeiboSquareFilled, content: 'filled' },
  { name: 'WeiboCircleFilled', icon: WeiboCircleFilled, content: 'filled' },
  { name: 'TaobaoCircleFilled', icon: TaobaoCircleFilled, content: 'filled' },
  { name: 'Html5Filled', icon: Html5Filled, content: 'filled' },
  { name: 'WechatFilled', icon: WechatFilled, content: 'filled' },
  { name: 'YoutubeFilled', icon: YoutubeFilled, content: 'filled' },
  { name: 'AlipayCircleFilled', icon: AlipayCircleFilled, content: 'filled' },
  { name: 'SkypeFilled', icon: SkypeFilled, content: 'filled' },
  { name: 'GitlabFilled', icon: GitlabFilled, content: 'filled' },
  { name: 'LinkedinFilled', icon: LinkedinFilled, content: 'filled' },
  { name: 'FacebookFilled', icon: FacebookFilled, content: 'filled' },
  {
    name: 'CodeSandboxCircleFilled',
    icon: CodeSandboxCircleFilled,
    content: 'filled',
  },
  { name: 'CodepenCircleFilled', icon: CodepenCircleFilled, content: 'filled' },
  { name: 'SlackSquareFilled', icon: SlackSquareFilled, content: 'filled' },
  { name: 'BehanceSquareFilled', icon: BehanceSquareFilled, content: 'filled' },
  {
    name: 'DribbbleSquareFilled',
    icon: DribbbleSquareFilled,
    content: 'filled',
  },
  { name: 'InstagramFilled', icon: InstagramFilled, content: 'filled' },
  { name: 'YuqueFilled', icon: YuqueFilled, content: 'filled' },
  { name: 'YahooFilled', icon: YahooFilled, content: 'filled' },
  { name: 'BugFilled', icon: BugFilled, content: 'filled' },
  { name: 'BuildFilled', icon: BuildFilled, content: 'filled' },
  { name: 'BulbFilled', icon: BulbFilled, content: 'filled' },
  { name: 'CalculatorFilled', icon: CalculatorFilled, content: 'filled' },
  { name: 'CalendarFilled', icon: CalendarFilled, content: 'filled' },
  { name: 'CameraFilled', icon: CameraFilled, content: 'filled' },
  { name: 'CarFilled', icon: CarFilled, content: 'filled' },
  { name: 'CarryOutFilled', icon: CarryOutFilled, content: 'filled' },
  { name: 'CiCircleFilled', icon: CiCircleFilled, content: 'filled' },
  { name: 'CloudFilled', icon: CloudFilled, content: 'filled' },
  { name: 'CodeFilled', icon: CodeFilled, content: 'filled' },
  {
    name: 'CodeSandboxSquareFilled',
    icon: CodeSandboxSquareFilled,
    content: 'filled',
  },
  { name: 'CodepenSquareFilled', icon: CodepenSquareFilled, content: 'filled' },
  { name: 'CompassFilled', icon: CompassFilled, content: 'filled' },
  { name: 'ContactsFilled', icon: ContactsFilled, content: 'filled' },
  { name: 'ContainerFilled', icon: ContainerFilled, content: 'filled' },
  { name: 'ControlFilled', icon: ControlFilled, content: 'filled' },
  { name: 'CreditCardFilled', icon: CreditCardFilled, content: 'filled' },
  { name: 'CrownFilled', icon: CrownFilled, content: 'filled' },
  {
    name: 'CustomerServiceFilled',
    icon: CustomerServiceFilled,
    content: 'filled',
  },
  { name: 'DashboardFilled', icon: DashboardFilled, content: 'filled' },
  { name: 'DatabaseFilled', icon: DatabaseFilled, content: 'filled' },
  {
    name: 'DingtalkCircleFilled',
    icon: DingtalkCircleFilled,
    content: 'filled',
  },
  {
    name: 'DingtalkSquareFilled',
    icon: DingtalkSquareFilled,
    content: 'filled',
  },
  { name: 'DislikeFilled', icon: DislikeFilled, content: 'filled' },
  { name: 'DollarCircleFilled', icon: DollarCircleFilled, content: 'filled' },
  {
    name: 'DribbbleCircleFilled',
    icon: DribbbleCircleFilled,
    content: 'filled',
  },
  { name: 'DropboxCircleFilled', icon: DropboxCircleFilled, content: 'filled' },
  { name: 'DropboxSquareFilled', icon: DropboxSquareFilled, content: 'filled' },
  { name: 'EnvironmentFilled', icon: EnvironmentFilled, content: 'filled' },
  { name: 'EuroCircleFilled', icon: EuroCircleFilled, content: 'filled' },
  { name: 'ExperimentFilled', icon: ExperimentFilled, content: 'filled' },
  { name: 'EyeFilled', icon: EyeFilled, content: 'filled' },
  { name: 'EyeInvisibleFilled', icon: EyeInvisibleFilled, content: 'filled' },
  { name: 'FileAddFilled', icon: FileAddFilled, content: 'filled' },
  { name: 'FileExcelFilled', icon: FileExcelFilled, content: 'filled' },
  {
    name: 'FileExclamationFilled',
    icon: FileExclamationFilled,
    content: 'filled',
  },
  { name: 'FileFilled', icon: FileFilled, content: 'filled' },
  { name: 'FileImageFilled', icon: FileImageFilled, content: 'filled' },
  { name: 'FileMarkdownFilled', icon: FileMarkdownFilled, content: 'filled' },
  { name: 'FilePdfFilled', icon: FilePdfFilled, content: 'filled' },
  { name: 'FilePptFilled', icon: FilePptFilled, content: 'filled' },
  { name: 'FileTextFilled', icon: FileTextFilled, content: 'filled' },
  { name: 'FileUnknownFilled', icon: FileUnknownFilled, content: 'filled' },
  { name: 'FileWordFilled', icon: FileWordFilled, content: 'filled' },
  { name: 'FileZipFilled', icon: FileZipFilled, content: 'filled' },
  { name: 'FilterFilled', icon: FilterFilled, content: 'filled' },
  { name: 'FireFilled', icon: FireFilled, content: 'filled' },
  { name: 'FlagFilled', icon: FlagFilled, content: 'filled' },
  { name: 'FolderAddFilled', icon: FolderAddFilled, content: 'filled' },
  { name: 'FolderFilled', icon: FolderFilled, content: 'filled' },
  { name: 'FolderOpenFilled', icon: FolderOpenFilled, content: 'filled' },
  { name: 'FormatPainterFilled', icon: FormatPainterFilled, content: 'filled' },
  { name: 'FrownFilled', icon: FrownFilled, content: 'filled' },
  { name: 'FunnelPlotFilled', icon: FunnelPlotFilled, content: 'filled' },
  { name: 'GiftFilled', icon: GiftFilled, content: 'filled' },
  { name: 'GoldFilled', icon: GoldFilled, content: 'filled' },
  { name: 'GoldenFilled', icon: GoldenFilled, content: 'filled' },
  { name: 'GoogleCircleFilled', icon: GoogleCircleFilled, content: 'filled' },
  {
    name: 'GooglePlusCircleFilled',
    icon: GooglePlusCircleFilled,
    content: 'filled',
  },
  {
    name: 'GooglePlusSquareFilled',
    icon: GooglePlusSquareFilled,
    content: 'filled',
  },
  { name: 'GoogleSquareFilled', icon: GoogleSquareFilled, content: 'filled' },
  { name: 'HddFilled', icon: HddFilled, content: 'filled' },
  { name: 'HeartFilled', icon: HeartFilled, content: 'filled' },
  { name: 'HomeFilled', icon: HomeFilled, content: 'filled' },
  { name: 'HourglassFilled', icon: HourglassFilled, content: 'filled' },
  { name: 'IdcardFilled', icon: IdcardFilled, content: 'filled' },
  { name: 'IeCircleFilled', icon: IeCircleFilled, content: 'filled' },
  { name: 'IeSquareFilled', icon: IeSquareFilled, content: 'filled' },
  { name: 'InsuranceFilled', icon: InsuranceFilled, content: 'filled' },
  { name: 'InteractionFilled', icon: InteractionFilled, content: 'filled' },
  { name: 'LayoutFilled', icon: LayoutFilled, content: 'filled' },
  { name: 'LikeFilled', icon: LikeFilled, content: 'filled' },
  { name: 'LockFilled', icon: LockFilled, content: 'filled' },
  { name: 'MacCommandFilled', icon: MacCommandFilled, content: 'filled' },
  { name: 'MailFilled', icon: MailFilled, content: 'filled' },
  { name: 'MedicineBoxFilled', icon: MedicineBoxFilled, content: 'filled' },
  { name: 'MediumCircleFilled', icon: MediumCircleFilled, content: 'filled' },
  { name: 'MediumSquareFilled', icon: MediumSquareFilled, content: 'filled' },
  { name: 'MehFilled', icon: MehFilled, content: 'filled' },
  { name: 'MessageFilled', icon: MessageFilled, content: 'filled' },
  { name: 'MobileFilled', icon: MobileFilled, content: 'filled' },
  { name: 'MoneyCollectFilled', icon: MoneyCollectFilled, content: 'filled' },
  { name: 'NotificationFilled', icon: NotificationFilled, content: 'filled' },
  { name: 'PayCircleFilled', icon: PayCircleFilled, content: 'filled' },
  { name: 'PhoneFilled', icon: PhoneFilled, content: 'filled' },
  { name: 'PictureFilled', icon: PictureFilled, content: 'filled' },
  { name: 'PlaySquareFilled', icon: PlaySquareFilled, content: 'filled' },
  { name: 'PoundCircleFilled', icon: PoundCircleFilled, content: 'filled' },
  { name: 'PrinterFilled', icon: PrinterFilled, content: 'filled' },
  { name: 'ProfileFilled', icon: ProfileFilled, content: 'filled' },
  { name: 'ProjectFilled', icon: ProjectFilled, content: 'filled' },
  {
    name: 'PropertySafetyFilled',
    icon: PropertySafetyFilled,
    content: 'filled',
  },
  { name: 'PushpinFilled', icon: PushpinFilled, content: 'filled' },
  { name: 'QqCircleFilled', icon: QqCircleFilled, content: 'filled' },
  { name: 'QqSquareFilled', icon: QqSquareFilled, content: 'filled' },
  { name: 'ReadFilled', icon: ReadFilled, content: 'filled' },
  {
    name: 'ReconciliationFilled',
    icon: ReconciliationFilled,
    content: 'filled',
  },
  { name: 'RedEnvelopeFilled', icon: RedEnvelopeFilled, content: 'filled' },
  { name: 'RedditCircleFilled', icon: RedditCircleFilled, content: 'filled' },
  { name: 'RedditSquareFilled', icon: RedditSquareFilled, content: 'filled' },
  { name: 'RestFilled', icon: RestFilled, content: 'filled' },
  { name: 'RobotFilled', icon: RobotFilled, content: 'filled' },
  { name: 'RocketFilled', icon: RocketFilled, content: 'filled' },
  {
    name: 'SafetyCertificateFilled',
    icon: SafetyCertificateFilled,
    content: 'filled',
  },
  { name: 'SaveFilled', icon: SaveFilled, content: 'filled' },
  { name: 'ScheduleFilled', icon: ScheduleFilled, content: 'filled' },
  { name: 'SecurityScanFilled', icon: SecurityScanFilled, content: 'filled' },
  { name: 'SettingFilled', icon: SettingFilled, content: 'filled' },
  { name: 'ShopFilled', icon: ShopFilled, content: 'filled' },
  { name: 'ShoppingFilled', icon: ShoppingFilled, content: 'filled' },
  { name: 'SignalFilled', icon: SignalFilled, content: 'filled' },
  { name: 'SketchCircleFilled', icon: SketchCircleFilled, content: 'filled' },
  { name: 'SketchSquareFilled', icon: SketchSquareFilled, content: 'filled' },
  { name: 'SkinFilled', icon: SkinFilled, content: 'filled' },
  { name: 'SlackCircleFilled', icon: SlackCircleFilled, content: 'filled' },
  { name: 'SmileFilled', icon: SmileFilled, content: 'filled' },
  { name: 'SoundFilled', icon: SoundFilled, content: 'filled' },
  { name: 'StarFilled', icon: StarFilled, content: 'filled' },
  { name: 'SwitcherFilled', icon: SwitcherFilled, content: 'filled' },
  { name: 'TabletFilled', icon: TabletFilled, content: 'filled' },
  { name: 'TagFilled', icon: TagFilled, content: 'filled' },
  { name: 'TagsFilled', icon: TagsFilled, content: 'filled' },
  { name: 'TaobaoSquareFilled', icon: TaobaoSquareFilled, content: 'filled' },
  { name: 'ThunderboltFilled', icon: ThunderboltFilled, content: 'filled' },
  { name: 'ToolFilled', icon: ToolFilled, content: 'filled' },
  {
    name: 'TrademarkCircleFilled',
    icon: TrademarkCircleFilled,
    content: 'filled',
  },
  { name: 'TrophyFilled', icon: TrophyFilled, content: 'filled' },
  { name: 'TwitterCircleFilled', icon: TwitterCircleFilled, content: 'filled' },
  { name: 'TwitterSquareFilled', icon: TwitterSquareFilled, content: 'filled' },
  { name: 'UnlockFilled', icon: UnlockFilled, content: 'filled' },
  { name: 'UsbFilled', icon: UsbFilled, content: 'filled' },
  { name: 'VideoCameraFilled', icon: VideoCameraFilled, content: 'filled' },
  { name: 'WalletFilled', icon: WalletFilled, content: 'filled' },
  { name: 'ZhihuCircleFilled', icon: ZhihuCircleFilled, content: 'filled' },
  { name: 'AccountBookFilled', icon: AccountBookFilled, content: 'filled' },
  { name: 'AlertFilled', icon: AlertFilled, content: 'filled' },
  { name: 'AlipaySquareFilled', icon: AlipaySquareFilled, content: 'filled' },
  { name: 'AmazonCircleFilled', icon: AmazonCircleFilled, content: 'filled' },
  { name: 'AmazonSquareFilled', icon: AmazonSquareFilled, content: 'filled' },
  { name: 'ApiFilled', icon: ApiFilled, content: 'filled' },
  { name: 'AudioFilled', icon: AudioFilled, content: 'filled' },
  { name: 'BankFilled', icon: BankFilled, content: 'filled' },
  { name: 'BehanceCircleFilled', icon: BehanceCircleFilled, content: 'filled' },
  { name: 'BellFilled', icon: BellFilled, content: 'filled' },
  { name: 'BookFilled', icon: BookFilled, content: 'filled' },
  { name: 'UpCircleTwoTone', icon: UpCircleTwoTone, content: 'twotone' },
  { name: 'DownCircleTwoTone', icon: DownCircleTwoTone, content: 'twotone' },
  { name: 'LeftCircleTwoTone', icon: LeftCircleTwoTone, content: 'twotone' },
  { name: 'RightCircleTwoTone', icon: RightCircleTwoTone, content: 'twotone' },
  { name: 'PlayCircleTwoTone', icon: PlayCircleTwoTone, content: 'twotone' },
  { name: 'UpSquareTwoTone', icon: UpSquareTwoTone, content: 'twotone' },
  { name: 'DownSquareTwoTone', icon: DownSquareTwoTone, content: 'twotone' },
  { name: 'LeftSquareTwoTone', icon: LeftSquareTwoTone, content: 'twotone' },
  { name: 'RightSquareTwoTone', icon: RightSquareTwoTone, content: 'twotone' },
  {
    name: 'QuestionCircleTwoTone',
    icon: QuestionCircleTwoTone,
    content: 'twotone',
  },
  { name: 'PlusCircleTwoTone', icon: PlusCircleTwoTone, content: 'twotone' },
  { name: 'PauseCircleTwoTone', icon: PauseCircleTwoTone, content: 'twotone' },
  { name: 'MinusCircleTwoTone', icon: MinusCircleTwoTone, content: 'twotone' },
  { name: 'PlusSquareTwoTone', icon: PlusSquareTwoTone, content: 'twotone' },
  { name: 'MinusSquareTwoTone', icon: MinusSquareTwoTone, content: 'twotone' },
  { name: 'InfoCircleTwoTone', icon: InfoCircleTwoTone, content: 'twotone' },
  {
    name: 'ExclamationCircleTwoTone',
    icon: ExclamationCircleTwoTone,
    content: 'twotone',
  },
  { name: 'CloseCircleTwoTone', icon: CloseCircleTwoTone, content: 'twotone' },
  { name: 'CloseSquareTwoTone', icon: CloseSquareTwoTone, content: 'twotone' },
  { name: 'CheckCircleTwoTone', icon: CheckCircleTwoTone, content: 'twotone' },
  { name: 'CheckSquareTwoTone', icon: CheckSquareTwoTone, content: 'twotone' },
  { name: 'ClockCircleTwoTone', icon: ClockCircleTwoTone, content: 'twotone' },
  { name: 'WarningTwoTone', icon: WarningTwoTone, content: 'twotone' },
  { name: 'StopTwoTone', icon: StopTwoTone, content: 'twotone' },
  { name: 'EditTwoTone', icon: EditTwoTone, content: 'twotone' },
  { name: 'CopyTwoTone', icon: CopyTwoTone, content: 'twotone' },
  { name: 'DeleteTwoTone', icon: DeleteTwoTone, content: 'twotone' },
  { name: 'SnippetsTwoTone', icon: SnippetsTwoTone, content: 'twotone' },
  { name: 'DiffTwoTone', icon: DiffTwoTone, content: 'twotone' },
  { name: 'HighlightTwoTone', icon: HighlightTwoTone, content: 'twotone' },
  { name: 'PieChartTwoTone', icon: PieChartTwoTone, content: 'twotone' },
  { name: 'BoxPlotTwoTone', icon: BoxPlotTwoTone, content: 'twotone' },
  { name: 'FundTwoTone', icon: FundTwoTone, content: 'twotone' },
  { name: 'SlidersTwoTone', icon: SlidersTwoTone, content: 'twotone' },
  { name: 'Html5TwoTone', icon: Html5TwoTone, content: 'twotone' },
  { name: 'AccountBookTwoTone', icon: AccountBookTwoTone, content: 'twotone' },
  { name: 'AlertTwoTone', icon: AlertTwoTone, content: 'twotone' },
  { name: 'ApiTwoTone', icon: ApiTwoTone, content: 'twotone' },
  { name: 'AppstoreTwoTone', icon: AppstoreTwoTone, content: 'twotone' },
  { name: 'AudioTwoTone', icon: AudioTwoTone, content: 'twotone' },
  { name: 'BankTwoTone', icon: BankTwoTone, content: 'twotone' },
  { name: 'BellTwoTone', icon: BellTwoTone, content: 'twotone' },
  { name: 'BookTwoTone', icon: BookTwoTone, content: 'twotone' },
  { name: 'BugTwoTone', icon: BugTwoTone, content: 'twotone' },
  { name: 'BuildTwoTone', icon: BuildTwoTone, content: 'twotone' },
  { name: 'BulbTwoTone', icon: BulbTwoTone, content: 'twotone' },
  { name: 'CalculatorTwoTone', icon: CalculatorTwoTone, content: 'twotone' },
  { name: 'CalendarTwoTone', icon: CalendarTwoTone, content: 'twotone' },
  { name: 'CameraTwoTone', icon: CameraTwoTone, content: 'twotone' },
  { name: 'CarTwoTone', icon: CarTwoTone, content: 'twotone' },
  { name: 'CarryOutTwoTone', icon: CarryOutTwoTone, content: 'twotone' },
  { name: 'CiCircleTwoTone', icon: CiCircleTwoTone, content: 'twotone' },
  { name: 'CiTwoTone', icon: CiTwoTone, content: 'twotone' },
  { name: 'CloudTwoTone', icon: CloudTwoTone, content: 'twotone' },
  { name: 'CodeTwoTone', icon: CodeTwoTone, content: 'twotone' },
  { name: 'CompassTwoTone', icon: CompassTwoTone, content: 'twotone' },
  { name: 'ContactsTwoTone', icon: ContactsTwoTone, content: 'twotone' },
  { name: 'ContainerTwoTone', icon: ContainerTwoTone, content: 'twotone' },
  { name: 'ControlTwoTone', icon: ControlTwoTone, content: 'twotone' },
  { name: 'CopyrightTwoTone', icon: CopyrightTwoTone, content: 'twotone' },
  { name: 'CreditCardTwoTone', icon: CreditCardTwoTone, content: 'twotone' },
  { name: 'CrownTwoTone', icon: CrownTwoTone, content: 'twotone' },
  {
    name: 'CustomerServiceTwoTone',
    icon: CustomerServiceTwoTone,
    content: 'twotone',
  },
  { name: 'DashboardTwoTone', icon: DashboardTwoTone, content: 'twotone' },
  { name: 'DatabaseTwoTone', icon: DatabaseTwoTone, content: 'twotone' },
  { name: 'DislikeTwoTone', icon: DislikeTwoTone, content: 'twotone' },
  {
    name: 'DollarCircleTwoTone',
    icon: DollarCircleTwoTone,
    content: 'twotone',
  },
  { name: 'DollarTwoTone', icon: DollarTwoTone, content: 'twotone' },
  { name: 'EnvironmentTwoTone', icon: EnvironmentTwoTone, content: 'twotone' },
  { name: 'EuroCircleTwoTone', icon: EuroCircleTwoTone, content: 'twotone' },
  { name: 'EuroTwoTone', icon: EuroTwoTone, content: 'twotone' },
  { name: 'ExperimentTwoTone', icon: ExperimentTwoTone, content: 'twotone' },
  { name: 'EyeTwoTone', icon: EyeTwoTone, content: 'twotone' },
  {
    name: 'EyeInvisibleTwoTone',
    icon: EyeInvisibleTwoTone,
    content: 'twotone',
  },
  { name: 'FileAddTwoTone', icon: FileAddTwoTone, content: 'twotone' },
  { name: 'FileExcelTwoTone', icon: FileExcelTwoTone, content: 'twotone' },
  {
    name: 'FileExclamationTwoTone',
    icon: FileExclamationTwoTone,
    content: 'twotone',
  },
  { name: 'FileTwoTone', icon: FileTwoTone, content: 'twotone' },
  { name: 'FileImageTwoTone', icon: FileImageTwoTone, content: 'twotone' },
  {
    name: 'FileMarkdownTwoTone',
    icon: FileMarkdownTwoTone,
    content: 'twotone',
  },
  { name: 'FilePdfTwoTone', icon: FilePdfTwoTone, content: 'twotone' },
  { name: 'FilePptTwoTone', icon: FilePptTwoTone, content: 'twotone' },
  { name: 'FileTextTwoTone', icon: FileTextTwoTone, content: 'twotone' },
  { name: 'FileUnknownTwoTone', icon: FileUnknownTwoTone, content: 'twotone' },
  { name: 'FileWordTwoTone', icon: FileWordTwoTone, content: 'twotone' },
  { name: 'FileZipTwoTone', icon: FileZipTwoTone, content: 'twotone' },
  { name: 'FilterTwoTone', icon: FilterTwoTone, content: 'twotone' },
  { name: 'FireTwoTone', icon: FireTwoTone, content: 'twotone' },
  { name: 'FlagTwoTone', icon: FlagTwoTone, content: 'twotone' },
  { name: 'FolderAddTwoTone', icon: FolderAddTwoTone, content: 'twotone' },
  { name: 'FolderTwoTone', icon: FolderTwoTone, content: 'twotone' },
  { name: 'FolderOpenTwoTone', icon: FolderOpenTwoTone, content: 'twotone' },
  { name: 'FrownTwoTone', icon: FrownTwoTone, content: 'twotone' },
  { name: 'FunnelPlotTwoTone', icon: FunnelPlotTwoTone, content: 'twotone' },
  { name: 'GiftTwoTone', icon: GiftTwoTone, content: 'twotone' },
  { name: 'GoldTwoTone', icon: GoldTwoTone, content: 'twotone' },
  { name: 'HddTwoTone', icon: HddTwoTone, content: 'twotone' },
  { name: 'HeartTwoTone', icon: HeartTwoTone, content: 'twotone' },
  { name: 'HomeTwoTone', icon: HomeTwoTone, content: 'twotone' },
  { name: 'HourglassTwoTone', icon: HourglassTwoTone, content: 'twotone' },
  { name: 'IdcardTwoTone', icon: IdcardTwoTone, content: 'twotone' },
  { name: 'InsuranceTwoTone', icon: InsuranceTwoTone, content: 'twotone' },
  { name: 'InteractionTwoTone', icon: InteractionTwoTone, content: 'twotone' },
  { name: 'LayoutTwoTone', icon: LayoutTwoTone, content: 'twotone' },
  { name: 'LikeTwoTone', icon: LikeTwoTone, content: 'twotone' },
  { name: 'LockTwoTone', icon: LockTwoTone, content: 'twotone' },
  { name: 'MailTwoTone', icon: MailTwoTone, content: 'twotone' },
  { name: 'MedicineBoxTwoTone', icon: MedicineBoxTwoTone, content: 'twotone' },
  { name: 'MehTwoTone', icon: MehTwoTone, content: 'twotone' },
  { name: 'MessageTwoTone', icon: MessageTwoTone, content: 'twotone' },
  { name: 'MobileTwoTone', icon: MobileTwoTone, content: 'twotone' },
  {
    name: 'MoneyCollectTwoTone',
    icon: MoneyCollectTwoTone,
    content: 'twotone',
  },
  {
    name: 'NotificationTwoTone',
    icon: NotificationTwoTone,
    content: 'twotone',
  },
  { name: 'PhoneTwoTone', icon: PhoneTwoTone, content: 'twotone' },
  { name: 'PictureTwoTone', icon: PictureTwoTone, content: 'twotone' },
  { name: 'PlaySquareTwoTone', icon: PlaySquareTwoTone, content: 'twotone' },
  { name: 'PoundCircleTwoTone', icon: PoundCircleTwoTone, content: 'twotone' },
  { name: 'PrinterTwoTone', icon: PrinterTwoTone, content: 'twotone' },
  { name: 'ProfileTwoTone', icon: ProfileTwoTone, content: 'twotone' },
  { name: 'ProjectTwoTone', icon: ProjectTwoTone, content: 'twotone' },
  {
    name: 'PropertySafetyTwoTone',
    icon: PropertySafetyTwoTone,
    content: 'twotone',
  },
  { name: 'PushpinTwoTone', icon: PushpinTwoTone, content: 'twotone' },
  {
    name: 'ReconciliationTwoTone',
    icon: ReconciliationTwoTone,
    content: 'twotone',
  },
  { name: 'RedEnvelopeTwoTone', icon: RedEnvelopeTwoTone, content: 'twotone' },
  { name: 'RestTwoTone', icon: RestTwoTone, content: 'twotone' },
  { name: 'RocketTwoTone', icon: RocketTwoTone, content: 'twotone' },
  {
    name: 'SafetyCertificateTwoTone',
    icon: SafetyCertificateTwoTone,
    content: 'twotone',
  },
  { name: 'SaveTwoTone', icon: SaveTwoTone, content: 'twotone' },
  { name: 'ScheduleTwoTone', icon: ScheduleTwoTone, content: 'twotone' },
  {
    name: 'SecurityScanTwoTone',
    icon: SecurityScanTwoTone,
    content: 'twotone',
  },
  { name: 'SettingTwoTone', icon: SettingTwoTone, content: 'twotone' },
  { name: 'ShopTwoTone', icon: ShopTwoTone, content: 'twotone' },
  { name: 'ShoppingTwoTone', icon: ShoppingTwoTone, content: 'twotone' },
  { name: 'SkinTwoTone', icon: SkinTwoTone, content: 'twotone' },
  { name: 'SmileTwoTone', icon: SmileTwoTone, content: 'twotone' },
  { name: 'SoundTwoTone', icon: SoundTwoTone, content: 'twotone' },
  { name: 'StarTwoTone', icon: StarTwoTone, content: 'twotone' },
  { name: 'SwitcherTwoTone', icon: SwitcherTwoTone, content: 'twotone' },
  { name: 'TabletTwoTone', icon: TabletTwoTone, content: 'twotone' },
  { name: 'TagTwoTone', icon: TagTwoTone, content: 'twotone' },
  { name: 'TagsTwoTone', icon: TagsTwoTone, content: 'twotone' },
  { name: 'ThunderboltTwoTone', icon: ThunderboltTwoTone, content: 'twotone' },
  { name: 'ToolTwoTone', icon: ToolTwoTone, content: 'twotone' },
  {
    name: 'TrademarkCircleTwoTone',
    icon: TrademarkCircleTwoTone,
    content: 'twotone',
  },
  { name: 'TrophyTwoTone', icon: TrophyTwoTone, content: 'twotone' },
  { name: 'UnlockTwoTone', icon: UnlockTwoTone, content: 'twotone' },
  { name: 'UsbTwoTone', icon: UsbTwoTone, content: 'twotone' },
  { name: 'VideoCameraTwoTone', icon: VideoCameraTwoTone, content: 'twotone' },
  { name: 'WalletTwoTone', icon: WalletTwoTone, content: 'twotone' },
];

export default icon;
