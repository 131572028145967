const Tc = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      fill="#00247D"
      d="M32 5H4c-.205 0-.407.015-.604.045l-.004 1.754-2.73-.004C.244 7.427 0 8.185 0 9v18c0 2.209 1.791 4 4 4h28c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#FFF"
      d="M23 12v3.726c0 5.18 2.887 7.072 5.156 8.305 2.27-1.233 4.802-3.125 4.802-8.305V12H23z"
    />
    <path
      fill="#FEC400"
      d="M28.156 23.43c-2.202-1.231-4.739-3.008-4.739-7.704v-3.059h9.196v3.059c0 4.696-2.255 6.473-4.457 7.704z"
    />
    <path
      fill="#00A728"
      d="M29.953 20.984c-.062-.547-.516-2.266-1.047-2.781-.531-.516-.75-.117-.75-.117s-.219-.398-.75.117c-.531.516-.984 2.234-1.047 2.781s.062.672.344.672c.125.266.609.312.609.312s.547.359.844 0c.297.359.844 0 .844 0s.484-.047.609-.312c.282 0 .407-.125.344-.672z"
    />
    <path
      fill="#F0082D"
      d="M28.953 17.576c0 .476.126.861-.781.861s-.781-.386-.781-.861c0-.476.35-.862.781-.862.432 0 .781.386.781.862z"
    />
    <path
      fill="#FF9F3E"
      d="M26.484 16.953c-.25.422-.641.125-.812-.375s-.859-.641-1.109-.781-.312-.375 0-.547.125-.391.344-.453c-.281-.062-.533.094-.556 0-.022-.094.196-.354.556-.396-.297-.37-.125-1.151 0-1.167s.381.219.511.453.505.188.474.516c.078.078.344.031.594.438.248.406-.002 2.312-.002 2.312z"
    />
    <path
      fill="#FF927F"
      d="M26.596 16.891c-.354.027-.486-.281-.549-.734-.062-.453-.703-.284-.406-.962.297-.678.408-.267.438-.793s.202-.963.358-.963c.156 0 .359.484.438.688s.379.156.51.859-.025 1.219-.197 1.375c-.172.156-.25.179-.281.355-.032.175-.079.157-.311.175z"
    />
    <path
      fill="#FE9DE0"
      d="M26.596 13.906c.107.078.111.188 0 .375-.111.188.072.375.082.688.01.312-.152.422-.118.578.034.156.284.453.253.812-.031.359-.105.469-.217.531-.111.062-.064-.188-.041-.422.023-.234-.133-.5-.211-.734-.078-.234.105-.406.154-.641.049-.234-.076-.448-.092-.693-.015-.244.19-.494.19-.494z"
    />
    <path
      fill="#BA5B1F"
      d="M30.219 14.5c.047-.344.031-.875-.078-1.062-.109-.188-.281-.297-.172-.328.109-.031.625.516.344 1.625-.282 1.109-.094-.235-.094-.235z"
    />
    <path
      fill="#BA5B1F"
      d="M30.164 14.404c-.211-.123-.484-.436-.457-.607.027-.172.23-.484.262-.422.031.062-.094.256 0 .511s.14.269.267.439c.126.171-.072.079-.072.079zm-.115.237c-.126-.152-1.517-1.047-1.377-1.109s1.338.688 1.528 1.109c.191.421-.151 0-.151 0z"
    />
    <path
      fill="#BA5B1F"
      d="M29.969 14.906c-.509-.505-1.766-.594-1.703-.672s1.674.07 1.899.535c.226.465-.196.137-.196.137z"
    />
    <path
      fill="#BA5B1F"
      d="M29.934 15.173c-.574-.298-1.578-.361-1.67-.345-.092.016.969-.298 1.727.171.759.47-.057.174-.057.174zm.035.421c-.594-.266-1.469-.188-1.625-.094-.156.094.453-.354.875-.31.422.044.83.132.946.213.116.081.011.283-.196.191z"
    />
    <path
      fill="#BA5B1F"
      d="M30.315 14.401c-.072-.384-.073-.708-.588-.669-.515.039-.608 1.002-.479 2.346.128 1.344-.028 1.784-.052 1.861-.023.077.287-.267.265-.861-.022-.594-.153-1.899-.111-2.379.042-.48.275-.84.585-.746.31.095.24.336.231.629-.01.293.149-.181.149-.181z"
    />
    <path
      fill="#BA5B1F"
      d="M30.656 16.9s.156-.088.359-1.15c.203-1.062-.234-1.516-.625-1.516s-.828.453-.625 1.516c.203 1.062.359 1.15.359 1.15s-.479.455-.411.676c.067.221.677.226.677.226s.609-.005.677-.226c.068-.221-.411-.676-.411-.676z"
    />
    <path
      fill="#BA5B1F"
      d="M30.567 14.5c-.047-.344-.031-.875.078-1.062s.281-.297.172-.328c-.109-.031-.625.516-.344 1.625.282 1.109.094-.235.094-.235z"
    />
    <path
      fill="#BA5B1F"
      d="M30.622 14.404c.211-.123.484-.436.457-.607s-.23-.484-.262-.422c-.031.062.094.256 0 .511s-.14.269-.267.439c-.126.171.072.079.072.079zm.115.237c.126-.152 1.518-1.047 1.377-1.109-.141-.062-1.338.688-1.528 1.109-.19.421.151 0 .151 0z"
    />
    <path
      fill="#BA5B1F"
      d="M30.817 14.906c.509-.505 1.766-.594 1.703-.672s-1.674.07-1.899.535c-.225.465.196.137.196.137z"
    />
    <path
      fill="#BA5B1F"
      d="M30.853 15.173c.574-.298 1.578-.361 1.67-.345.092.016-.969-.298-1.728.171-.759.47.058.174.058.174zm-.036.421c.594-.266 1.469-.188 1.625-.094.156.094-.453-.354-.875-.31-.422.044-.83.132-.946.213-.116.081-.011.283.196.191z"
    />
    <path
      fill="#BA5B1F"
      d="M30.472 14.401c.072-.384.073-.708.588-.669.515.039.607 1.002.479 2.346s.028 1.784.052 1.861c.024.077-.287-.267-.265-.861.022-.594.153-1.899.111-2.379-.042-.48-.275-.84-.585-.746-.31.095-.24.336-.231.629.009.293-.149-.181-.149-.181z"
    />
    <path
      fill="#00247D"
      d="M19 18V5H4c-.32 0-.604.045-.604.045l-.004 1.754-2.73-.004S.62 6.854.535 7C.195 7.589 0 8.272 0 9v9h19z"
    />
    <path
      fill="#EEE"
      d="M19 5h-2.331L12 8.269V5H7v2.569L3.396 5.045c-.614.094-1.19.325-1.672.665L6.426 9H4.69L.967 6.391c-.11.129-.211.262-.305.404L3.813 9H0v5h3.885L0 16.766V18h3.332L7 15.432V18h5v-3.269L16.668 18H19v-2.029L16.185 14H19V9h-2.814L19 7.029V5z"
    />
    <path fill="#CF1B2B" d="M11 5H8v5H0v3h8v5h3v-5h8v-3h-8z" />
    <path
      fill="#CF1B2B"
      d="M19 5h-1.461L12 8.879V9h1.571L19 5.198zm-17.276.71c-.281.195-.534.423-.757.681L4.69 9h1.735L1.724 5.71zM6.437 14L.734 18h1.727L7 14.822V14zM19 17.802v-1.22L15.313 14H13.57z"
    />
  </svg>
);

export default Tc;
