const It = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path fill="#006A44" d="M0 14h36v8H0z" />
    <path
      fill="#FDB913"
      d="M32 5H4C1.791 5 0 6.791 0 9v5h36V9c0-2.209-1.791-4-4-4z"
    />
    <path
      fill="#C1272D"
      d="M4 31h28c2.209 0 4-1.791 4-4v-5H0v5c0 2.209 1.791 4 4 4z"
    />
  </svg>
);

export default It;
